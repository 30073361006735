import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import WithRender from './YearDropdown.html'

import { 
  MDropdown,
  MDropdownItem
} from '@ulaval/modul-components'

import { IYearModel } from './Year.model'
import YearService from './Year.service'

import { STORE_GETTERS } from '@/store'

@WithRender
@Component({
  components: {
    MDropdown,
    MDropdownItem
  }
})
export default class YearDropdown extends mixins(YearService) {
  private isLoadingStateActive: boolean = false
  private selectedYearSlug: string | null = null

  private get years(): Array<IYearModel> {
    return this.$store.getters[STORE_GETTERS.YEAR_GET_YEARS]
  }
  
  private get yearsBySlug(): { [key: string]: IYearModel } {
    return this.$store.getters[STORE_GETTERS.YEAR_GET_YEARS_BY_SLUG]
  }

  private get currentYear(): IYearModel | null {
    return this.$store.getters[STORE_GETTERS.YEAR_GET_CURRENT_YEAR]
  }

  private get hasYears(): boolean {
    return !!(this.years && this.years.length > 0)
  }

  private get hasCurrentYear(): boolean {
    return !!this.currentYear
  }

  @Watch('currentYear')
  private onCurrentYearChange(): void {
    if (!this.selectedYearSlug && this.currentYear) {
      this.selectedYearSlug = this.currentYear.slug
    }
  }

  @Watch('selectedYearSlug')
  private onSelectedYearSlugChange(): void {
    if (this.selectedYearSlug) {
      const matchingYear: IYearModel | undefined = this.yearsBySlug[this.selectedYearSlug]

      if (matchingYear) {
        this.$emit('input', matchingYear)
      }
    }
  }

  private mounted(): void {
    if (!this.hasYears) {
      this.loadYears()
    } else if (this.hasCurrentYear) {
      this.onCurrentYearChange()
    } else if (!this.hasCurrentYear) {
      this.loadCurrentYear()
    }
  }

  private loadYears(): void {
    this.isLoadingStateActive = true

    this.service_yearService_fetchList()
      .then(() => {
        if (!this.hasCurrentYear) {
          this.loadCurrentYear()
        }
      }).finally(() => {
        this.isLoadingStateActive = false
      })  
  }

  private loadCurrentYear(): void {
    this.isLoadingStateActive = true

    this.service_yearService_fetchCurrent()
      .finally(() => {
        this.isLoadingStateActive = false
      })  
  }
}
