import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './EvaluatorCategoryAssignment.html'

import {
  MMessage,
  MMessageSkin,
  MMessageState
} from '@ulaval/modul-components'

import { IEvaluatorCategoryAssignmentModel } from '../EvaluatorCategoryAsssignment.model';

import EvaluatorApplicationFormAssignmentRow from '../../evaluator-application-form-assignment-management/evaluator-application-form-assignment/EvaluatorApplicationFormAssignmentRow';

@WithRender
@Component({
  components: {
    EvaluatorApplicationFormAssignmentRow,
    MMessage
  }
})
export default class EvaluatorCategoryAssignment extends mixins() {
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MMessageState: object = MMessageState
  
  @Prop({ required: true })
  private evaluatorCategoryAssignment!: IEvaluatorCategoryAssignmentModel
}
