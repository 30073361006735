<m-panel class="year-configuration-category-sections-panel">
  <h4
    slot="header"
    class="mb-4"
  >
    {{ $i18next.t('modules.year-configuration-management.year-configuration.YearConfigurationCategorySectionsPanel.panel_title') }}
  </h4>
  <template #header-right-content>
    <template v-if="isCreateApplicationFormButtonAvailable">
      <m-button @click="onCreateApplicationFormButtonClick">
        {{ $i18next.t('modules.year-configuration-management.year-configuration.YearConfigurationCategorySectionsPanel.button.create_application_form.label') }}
      </m-button>
    </template>
  </template>
  <template v-for="section in yearConfiguration.category_sections">
    <section class="year-configuration-category-sections-panel__section">
      <h5 class="year-configuration-category-sections-panel__section--title">{{ section.title }}</h5>
      <div class="year-configuration-category-sections-panel__section--categories">
        <template v-for="category in sortCategories(section.categories)">
          <template v-if="!category.deleted_at">
            <public-category-card :category="category" />
          </template>
        </template>
      </div>
    </section>
  </template>
</m-panel>