import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './ApplicationFormDepositDeadlineExtensionOverlay.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MButton,
  MButtonSkin,
  MDatepicker,
  MIconButton,
  MForm,
  MMessage,
  MMessageSkin,
  MMessageState,
  MMultiSelect,
  MOverlay,
  MTimepicker,
  RequiredValidator
} from '@ulaval/modul-components'

import ApplicationFormService from '../ApplicationForm.service'
import { IAdminApplicationFormModel } from '../ApplicationForm.model'

export enum ApplicationFormDepositDeadlineExtensionOverlay_EVENTS {
  AFTER_CLOSE = 'after-close'
}

@WithRender
@Component({
  components: {
    MButton,
    MDatepicker,
    MIconButton,
    MForm,
    MMessage,
    MMultiSelect,
    MOverlay,
    MTimepicker
  }
})
export default class ApplicationFormDepositDeadlineExtensionOverlay extends mixins(ApplicationFormService) {
  public formGroup: FormGroup = new FormGroup({
    date: new FormControl<string>([RequiredValidator()]),
    time: new FormControl<string>([RequiredValidator()]),
  })
  private isLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showSuccessMessage: boolean = false

  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin

  @Prop({ required: true })
  private applicationForm!: IAdminApplicationFormModel

  public get dateField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('date');
  }

  public get timeField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('time');
  }

  private mounted(): void {
    this.populateFormFromModel()
  }

  private populateFormFromModel(): void {
    if (this.applicationForm) {
      this.dateField.value = this.mixin_timestamp_extractDate(this.applicationForm.deposit_deadline_extension_timestamp || this.applicationForm.category_application_deposit_limit_timestamp)
      this.timeField.value = this.mixin_timestamp_extractTime(this.applicationForm.deposit_deadline_extension_timestamp || this.applicationForm.category_application_deposit_limit_timestamp)
    }
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private onTriggerButtonClick(): void {
    this.isOverlayOpen = true
  }

  private async onSaveButtonClick(): Promise<void> {
    await this.formGroup.submit()

    if (!this.formGroup.hasErrorDeep()) {
      this.updateDepositDeadlineExtension()
    }
  }

  private updateDepositDeadlineExtension(): void {
    this.isLoadingStateActive = true
    
    this.service_applicationFormService_updateDepositDeadlineExtension(this.applicationForm.id, {
      timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
        this.dateField.value || '',
        this.timeField.value || ''
      )
    }).then(() => {
      this.showSuccessMessage = true
    }).catch((error: AxiosError) => {
      this.mixin_router_redirectRequestError(error)
    }).finally(() => {
      this.isLoadingStateActive = false
    })
  }

  private afterOverlayClose(): void {
    this.isLoadingStateActive = false
    this.showSuccessMessage = false

    this.formGroup.reset()

    this.$emit(ApplicationFormDepositDeadlineExtensionOverlay_EVENTS.AFTER_CLOSE)
  }
}
