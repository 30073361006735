<section class="dashboard-evaluator-assignment-management main-content">
  <m-panel class="main-content__panel">
    <h3 slot="header">
      {{ $i18next.t('modules.dashboard.dashboard-evaluator-assignment-management.DashboardEvaluatorAssignmentManagement.panel_title') }}
    </h3>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="year">
        <template v-if="evaluatorCategoryAssignments.length > 0">
          <evaluator-category-assignments :evaluator-category-assignments="evaluatorCategoryAssignments" />
        </template>
        <template v-else>
          <m-message
            class="px-4 pb-4"
            :state="MMessageState.Information"
          >
            {{ $i18next.t('modules.dashboard.dashboard-evaluator-assignment-management.DashboardEvaluatorAssignmentManagement.message.empty_list.content') }}
          </m-message>
        </template>
      </template>
    </transition>
  </m-panel>
</section>