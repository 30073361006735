<nav id="breadcrumbs">
  <ol>
    <template v-for="item, index in items">
      <li class="display--flex">
        <template v-if="index === 0">
          <m-link
            :mode="MLinkMode.Link"
            :url="item.href"
          >
            <m-icon-button
              icon-name="pd-svg__home"
              icon-size="24px"
              :skin="MIconButtonSkin.Link"
              button-size="24px"
            />
          </m-link>
        </template>
        <template v-else>
          <m-link
            class="my-auto pr-2"
            :mode="MLinkMode.Link"
            :url="item.href"
          >
            {{ item.label }}
          </m-link>
        </template>
      </li>
    </template>
  </ol>
</nav>