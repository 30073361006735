<m-form
  class="admin-evaluator-management__evaluator-category-assignment-form"
  :form-group="formGroup"
>
  <div class="admin-evaluator-management__evaluator-category-assignment-form--row">
    <div class="admin-evaluator-management__evaluator-category-assignment-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-form.AdminEvaluatorCategoryAssignmentForm.label.evaluator_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <template v-if="evaluatorIdul && evaluatorFullNameWithUsername">
    <p>
      {{ evaluatorFullNameWithUsername }}
    </p>
  </template>
  <template v-else>
    <user-form
      :ref="USER_FORM_REF"
      class="mt-4"
      :with-role-selection="false"
    />
  </template>
  <div class="admin-evaluator-management__evaluator-category-assignment-form--row mt-12">
    <div class="admin-evaluator-management__evaluator-category-assignment-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-form.AdminEvaluatorCategoryAssignmentForm.label.category_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="admin-evaluator-management__evaluator-category-assignment-form--row">
    <div class="admin-evaluator-management__evaluator-category-assignment-form--column">
      <template v-if="categoryMultiSelect">
        <m-multi-select
          v-model="selectedCategories"
          :options="categories"
          v-m-control="categoryMultiSelect"
          :required-marker="!evaluatorIdul"
          max-width="none"
          :link-select-all="true"
          :waiting="isCategoryLoadingStateActive"
          :defaultChipCharsTrunk="100"
          :label="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-form.AdminEvaluatorCategoryAssignmentForm.field.categories.label')"
          :placeholder="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-form.AdminEvaluatorCategoryAssignmentForm.field.categories.placeholder')"
          :label-up="true"
          :error="categoryMultiSelect.hasError()"
          :error-message="categoryMultiSelect.errorMessage"
        >
          <template #items="{ item }">
            {{ item.title }}
          </template>
          <template #chips="{ item }">
            {{ item.title }}
          </template>
        </m-multi-select>
      </template>
    </div>
  </div>
</m-form>