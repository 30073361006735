<div
  @dragover="onElementDragOver($event)"
  @drop="onElementDrop($event)"
>
  <template v-if="dense">
    <div class="form-template-update__drop-zone--insert">
      <div class="form-template-update__drop-zone--insert-left" />
      <div class="form-template-update__drop-zone--insert-icon-prepend" />
      <div class="form-template-update__drop-zone--insert-icon">
        <m-icon
          name="m-svg__add-circle-filled"
          size="18px"
        />
      </div>
      <div class="form-template-update__drop-zone--insert-icon-append" />
      <div class="form-template-update__drop-zone--insert-right" />
    </div>
  </template>
  <template v-else>
    <div class="form-template-update__drop-zone--drag-drop-zone">
      <m-icon
        name="m-svg__add-circle-filled"
        size="24px"
      />
    </div>
  </template>
</div>