import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './DashboardEvaluatorAssignmentManagement.html'

import {
  MMessage,
  MMessageState,
  MPanel
} from '@ulaval/modul-components'

import EvaluatorCategoryAssignments from '@/modules/evaluator-management/evaluator-category-assignment-management/evaluator-category-assignments/EvaluatorCategoryAssignments'

import { IYearModel } from '@/modules/global/year/Year.model'
import { IEvaluatorCategoryAssignmentModel } from '@/modules/evaluator-management/evaluator-category-assignment-management/EvaluatorCategoryAsssignment.model'

@WithRender
@Component({
  components: {
    EvaluatorCategoryAssignments,
    MMessage,
    MPanel,
  }
})
export default class DashboardEvaluatorAssignmentManagement extends mixins() {
  private readonly MMessageState: object = MMessageState
  
  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ required: true })
  private evaluatorCategoryAssignments!: Array<IEvaluatorCategoryAssignmentModel>
}
