<m-form
  :form-group="formGroup"
  class="m-u--margin-top authentication-form"
  @submit="authenticate"
>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="requestErrors[ERROR_KEY_INVALID_CREDENTIALS]">
      <div class="authentication-form__message">
        <m-message state="error">
          <p v-html="$i18next.t('modules.authentication.form.AuthenticationForm.errors.invalid_credentials')" />
        </m-message>
      </div>
    </template>
  </transition>
  <div class="authentication-form__fields authentication-form__fields--identifiant">
    <m-textfield
      v-model="usernameField.value"
      v-m-control="usernameField"
      :label="$i18next.t('modules.authentication.form.AuthenticationForm.components.username.label')"
      :error="usernameField.hasError()"
      :error-message="usernameField.errorMessage"
    >
      <template #suffix>
        <m-tooltip placement="right">
          {{ $i18next.t('modules.authentication.form.AuthenticationForm.components.username.explication') }}
        </m-tooltip>
      </template>
    </m-textfield>
  </div>
  <div class="authentication-form__fields">
    <m-textfield
      v-model="passwordField.value"
      v-m-control="passwordField"
      :label="$i18next.t('modules.authentication.form.AuthenticationForm.components.password.label')"
      :type="MTextfieldType.Password"
      :error="passwordField.hasError()"
      :error-message="passwordField.errorMessage"
    />
  </div>
  <div class="authentication-form__actions">
    <m-button
      :type="MButtonType.Submit"
      :waiting="isAuthenticationLoadingStateActive"
    >
      {{ $i18next.t('modules.authentication.form.AuthenticationForm.components.submit.label') }}
    </m-button>
  </div>
</m-form>