<m-overlay
  id="application-form-management__submit-application-form-support-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @save="onSubmitButtonClick"
  @portal-after-close="afterOverlayClose"
>
  <template #header>
    <h2>
      {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.overlay_title') }}
    </h2>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showSubmitSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.message.submit_success.title')"
      />
    </template>
    <template v-else-if="showSubmitErrorMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :state="MMessageState.Error"
        :title="$i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.message.submit_error.title')"
      />
    </template>
    <template v-else>
      <div class="application-form-management__submit-application-form-support-overlay--content">
        <template v-if="applicationForm && !mixin_string_isEmpty(applicationForm.category_application_support_creation_dialog_message)">
          <div class="application-form-management__submit-application-form-support-overlay-form--row mb-12">
            <div class="application-form-management__submit-application-form-support-overlay-form--column">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.label.informations_zone') }}
              </label>
              <hr />
            </div>
            <div class="application-form-management__submit-application-form-support-overlay-form--column">
              <span v-html="applicationForm.category_application_support_creation_dialog_message" />
            </div>
          </div>
        </template>
        <div class="application-form-management__submit-application-form-support-overlay-form--row">
          <div class="application-form-management__submit-application-form-support-overlay-form--column">
            <label class="m-u--font-weight--bold">
              <template v-if="applicationForm.application_form_supports.length > 1">
                {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.label.current_application_form_support_zone.plural') }}
              </template>
              <template v-else>
                {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.label.current_application_form_support_zone.singular') }}
              </template>
            </label>
            <hr />
          </div>
          <div class="application-form-management__submit-application-form-support-overlay-form--column">
            <template v-if="showDeleteSuccessMessage">
              <m-message
                class="mt-2"
                :state="MMessageState.Success"
              >
                {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.message.delete_success.content') }}
              </m-message>
            </template>
            <template v-else-if="showDeleteSuccessMessage">
              <m-message
                class="mt-2"
                :state="MMessageState.Error"
              >
                {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.message.delete_error.content') }}
              </m-message>
            </template>
            <template v-if="applicationForm.application_form_supports.length === 0">
              <m-message
                class="mt-2"
                :state="MMessageState.Warning"
              >
                {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.message.empty_supporter_list.content') }}
              </m-message>
            </template>
            <template v-else>
              <div>
                <template v-for="applicationFormSupport, index in applicationForm.application_form_supports">
                  <div class="display--flex">
                    <div class="display--flex flex__direction--column">
                      <p>
                        {{ applicationFormSupport.supporter_name_with_email }}
                      </p>
                      <div class="display--flex my-2">
                        <template v-if="mixin_applicationFormSupport_computeStatusIcon(applicationFormSupport)">
                          <m-icon
                            class="mr-2 my-auto"
                            size="22px"
                            :style="mixin_applicationFormSupport_computeStatusIconStyles(applicationFormSupport)"
                            :name="mixin_applicationFormSupport_computeStatusIcon(applicationFormSupport)"
                          />
                        </template>
                        {{ mixin_applicationFormSupport_computeStatusLabel(applicationFormSupport.status) }}
                      </div>
                    </div>
                    <template v-if="applicationFormSupport.is_deleteable">
                      <m-icon-button
                        icon-name="m-svg__delete"
                        class="my-auto ml-auto"
                        @click="onDisplayDeleteConfirmationIconClick(applicationFormSupport)"
                      />
                    </template>
                  </div>
                  <template v-if="index < applicationForm.application_form_supports.length - 1">
                    <hr />
                  </template>
                </template>
              </div>
            </template>
          </div>
        </div>
        <div class="application-form-management__submit-application-form-support-overlay-form--row mt-12">
          <div class="application-form-management__submit-application-form-support-overlay-form--column">
            <label class="m-u--font-weight--bold">
              {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.label.new_application_form_support_zone') }}
            </label>
            <hr />
          </div>
        </div>
        <div class="application-form-management__submit-application-form-support-overlay-form--row">
          <div class="application-form-management__submit-application-form-support-overlay-form--column">
            <template v-if="applicationFormSupportLeftToDepositCount === 0">
              <m-message
                class="mt-2"
                :state="MMessageState.Information"
              >
                {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.message.supporter_limit_hit.content') }}
              </m-message>
            </template>
            <template v-else>
              <template v-if="applicationFormSupportForms.length === 0">
                <m-add
                  class="mt-4"
                  @click="increaseApplicationFormSupportFormCount(0)"
                >
                  {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.label.add_application_form_support') }}
                </m-add>
              </template>
              <template v-for="key, index in applicationFormSupportForms">
                <application-form-support-form
                  :key="key"
                  class="mt-2"
                  :ref="APPLICATION_FORM_SUPPORT_FORM_REF"
                  :is-add-icon-displayed="(index === applicationFormSupportForms.length - 1) && ((index + 1) < applicationFormSupportLeftToDepositCount)"
                  :is-delete-icon-displayed="true"
                  :is-add-icon-column-displayed="applicationFormSupportForms.length < applicationFormSupportLeftToDepositCount"
                  :is-delete-icon-column-displayed="true"
                  @add-icon:click="increaseApplicationFormSupportFormCount(key)"
                  @delete-icon:click="decreaseApplicationFormSupportFormCount(index)"
                />
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
  </transition>
  <template #footer>
    <template v-if="showSubmitSuccessMessage || showSubmitErrorMessage || applicationFormSupportForms.length === 0">
      <m-button
        :skin="MButtonSkin.Secondary"
        @click="closeOverlay"
      >
        {{ $i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.button.close.label') }}
      </m-button>
    </template>
  </template>
  <template v-if="applicationFormSupportToDelete">
    <m-dialog
      :state="MDialogState.Warning"
      :width="MDialogWidth.Large"
      :open="true"
      :message="$i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.dialog.delete_confirmation.label')"
      :okLabel="$i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOverlay.dialog.delete_confirmation.submit_button.label')"
      @portal-after-close="hideDeleteConfirmationDialog"
      @ok="deleteApplicationFormSupport"
    />
  </template>
</m-overlay>