import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './AdminApplicationFormValidations.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MColumnTable,
  MIcon,
  MIconButton,
  MOption,
  MOptionItem,
  MPagination,
  MPopup,
  MTable,
  MTableHeader,
  MToggleButtons,
  MToggleButton,
  MSearchfield,
  MColumnTextAlign
} from '@ulaval/modul-components'

import ApplicationFormValidationMixin from '../ApplicationFormValidation.mixin'
import ApplicationFormValidationService, { IFetchApplicationFormValidationsListQueryParameters, IFetchApplicationFormValidationsListResponse } from '../ApplicationFormValidation.service'
import { ApplicationFormValidationStatus, IApplicationFormValidationModel } from '../ApplicationFormValidation.model'
import { IPaginationResultData } from '@/modules/global/utilities/Pagination.model'
import { IYearModel } from '@/modules/global/year/Year.model'
import CONSTANTS from '@/CONSTANTS'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    MIcon,
    MIconButton,
    MOption,
    MOptionItem,
    MPagination,
    MPopup, 
    MTable,
    MTableHeader,
    MToggleButtons,
    MSearchfield
  }
})
export default class AdminApplicationFormValidations extends mixins(ApplicationFormValidationMixin, ApplicationFormValidationService) {
  public formGroup: FormGroup = new FormGroup({
    'search-field': new FormControl()
  })
  private applicationFormValidations: Array<IApplicationFormValidationModel> = []
  private selectedApplicationFormValidationStatus: Array<string> = Object.values(ApplicationFormValidationStatus)
  private paginationData: IPaginationResultData | null = null
  private isLoadingStateActive: boolean = false

  @Prop({ required: true })
  private year!: IYearModel

  public get searchField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('search-field');
  }

  private get statusToggleButtons(): Array<MToggleButton> {
    return Object.values(ApplicationFormValidationStatus).reduce((toggleButtons: Array<MToggleButton>, key: string) => {
      return [
        ...toggleButtons,
        {
          id: key,
          title: this.mixin_applicationFormValidation_computeStatusLabel(key),
          pressed: this.selectedApplicationFormValidationStatus.includes(key) 
        } as MToggleButton
      ]
    }, [])
  }

  private get tableColumns(): Array<MColumnTable> {
    return [
      {
        id: 'candidate',
        title: this.$i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.table.columns.candidate.title'),
        dataProp: 'candidate_full_name_with_username'
      },
      {
        id: 'category',
        title: this.$i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.table.columns.category.title'),
        dataProp: 'category_title'
      },
      {
        id: 'timestamp',
        title: this.$i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.table.columns.timestamp.title'),
        dataProp: 'timestamp',
        width: '550px'
      },
      {
        id: 'status',
        title: this.$i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.table.columns.status.title'),
        dataProp: 'status',
        width: '200px'
      },
      {
        id: 'options',
        title: '',
        dataProp: 'options',
        textAlign: MColumnTextAlign.Right,
        width: '50px'
      }
    ]
  }

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadApplicationForms()
  }

  @Watch('selectedApplicationFormValidationStatus')
  private onSelectedApplicationFormValidationStatusChange(): void {
    if (this.paginationData) {
      this.loadApplicationForms(this.paginationData.current_page_index)
    }
  }

  private loadApplicationForms(pageIndex: number = 0): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    const query: IFetchApplicationFormValidationsListQueryParameters = {
      year_id: this.year.id,
      query: this.searchField.value,
      status: this.selectedApplicationFormValidationStatus,
      page: pageIndex
    }

    this.service_applicationFormValidationService_fetchAdminApplicationFormValidationList(query)  
      .then((responseData: IFetchApplicationFormValidationsListResponse) => {
        this.applicationFormValidations = responseData.application_form_validations || []
        this.paginationData = responseData.pagination || null
      }).catch(() => {
        this.applicationFormValidations = []
        this.paginationData = null
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private onSearchFieldChange(): void {
    this.loadApplicationForms(0)
  }

  private onPageChange(newIndex: number): void {
    this.loadApplicationForms(newIndex - 1)
  }

  private onToggleButtonClick(toggleButton: MToggleButton): void {
    if (toggleButton.pressed) {
      const statusIndex: number | undefined = this.selectedApplicationFormValidationStatus.indexOf(toggleButton.id)

      if (!~statusIndex) {
        this.$set(this.selectedApplicationFormValidationStatus, this.selectedApplicationFormValidationStatus.length, toggleButton.id)
      }
    } else {
      const statusIndex: number | undefined = this.selectedApplicationFormValidationStatus.indexOf(toggleButton.id)

      if (~statusIndex) {
        this.$delete(this.selectedApplicationFormValidationStatus, statusIndex)
      }
    }
  }

  private onShowApplicationFormValidationIconClick(event: Event, applicationFormValidation: IApplicationFormValidationModel): void {
    this.mixin_router_navigate({
      name: ROUTES.ADMIN_APPLICATION_FORM_VALIDATION_FORM,
      params: {
        idApplicationFormValidation: applicationFormValidation.id
      }
    }, event)
  }
}
