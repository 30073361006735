import Component, { mixins } from 'vue-class-component'
import { Model, Prop, Watch } from 'vue-property-decorator'

import WithRender from './RichTextEditor.html'

import Editor from '@tinymce/tinymce-vue'
import { MIcon } from '@ulaval/modul-components'

import { GLOBAL_EVENTS } from '../../utilities/Events'

@WithRender
@Component({
  components: {
    Editor,
    MIcon
  }
})
export default class RichTextEditor extends mixins() {
  private content: string = ''
  private isFocused: boolean = false
  private hasError: boolean = false

  @Prop({ required: false })
  private label!: string

  @Model(GLOBAL_EVENTS.MODEL_UPDATED)
  private readonly model!: string

  private get richTextEditorClasses(): object {
    return {
      'rich-text-editor--error': this.hasError,
      'rich-text-editor--focused': this.isFocused
    }
  }

  @Watch('model', {
    immediate: true
  })
  private onModelChange(): void {
    this.content = this.model
  }

  @Watch('content')
  private onContentChange(): void {
    this.$emit(GLOBAL_EVENTS.MODEL_UPDATED, this.content)
    this.hasError = false
  }

  private onEditorFocus(): void {
    this.isFocused = true
  }

  private onEditorBlur(): void {
    this.isFocused = false
  }

  public async validate(): Promise<boolean> {
    this.hasError = this.mixin_string_isEmpty(this.model)

    return !this.hasError
  }
}
