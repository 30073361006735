import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import BaseService, { IPaginationQueryParameters } from "@/modules/global/utilities/Base.service";
import { IPaginatedResponse } from "@/modules/global/utilities/Pagination.model";
import { IEvaluatorCategoryAssignmentAdminModel, IEvaluatorCategoryAssignmentModel } from "./EvaluatorCategoryAsssignment.model";
import { ICategoryModel } from "@/modules/category-management/Category.model";
import { EVENT_BUS_EVENTS } from "@/modules/global/utilities/Events";

export interface IAdminFetchEvaluatorsCategoryAssignmentsQueryParameters extends IPaginationQueryParameters {
  year_id: string;
  query?: string;
}

export interface IAdminFetchEvaluatorsCategoryAssignmentsResponse extends IPaginatedResponse {
  evaluator_category_assignments: Array<IEvaluatorCategoryAssignmentAdminModel>;
}

export interface ICreateEvaluatorCategoryAssignmentForm {
  evaluator_idul: string;
  categories_id: Array<string>;
}

export interface IAdminFetchEvaluatorCategoryAssignmentsQueryParameters {
  year_id: string;
  evaluator_id: string;
}

export interface IFetchEvaluatorCategoryAssignmentsQueryParameters {
  year_id: string;
}

export interface IUpdateEvaluatorCategoryAssignmentForm {
  evaluator_idul: string;
  categories_id: Array<string>;
  year_id: string;
}

@Component
export default class EvaluatorCategoryAssignmentService extends BaseService {
  protected service_evaluatorCategoryAssignmentService_adminFetchEvaluatorsCategoryAssignments(query: IAdminFetchEvaluatorsCategoryAssignmentsQueryParameters): Promise<IAdminFetchEvaluatorsCategoryAssignmentsResponse> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/admin/evaluator-category-assignments?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorCategoryAssignmentService_fetchAvailableCategories(yearId: string): Promise<Array<ICategoryModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery({
        year_id: yearId
      })

      this.$axios.get(`/api/admin/evaluator-category-assignments/available-categories?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.categories))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorCategoryAssignmentService_createEvaluatorCategoryAssignment(payload: ICreateEvaluatorCategoryAssignmentForm): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/admin/evaluator-category-assignments`, payload)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.EVALUATOR_CATEGORY_ASSIGNMENT_CREATED, null)
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorCategoryAssignmentService_adminFetchEvaluatorCategoryAssignments(query: IAdminFetchEvaluatorCategoryAssignmentsQueryParameters): Promise<Array<IEvaluatorCategoryAssignmentAdminModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/admin/evaluator-category-assignments/evaluator?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.evaluator_category_assignments))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorCategoryAssignmentService_updateEvaluatorCategoryAssignment(payload: IUpdateEvaluatorCategoryAssignmentForm): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/admin/evaluator-category-assignments`, payload)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.EVALUATOR_CATEGORY_ASSIGNMENT_UPDATED, null)
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorCategoryAssignmentService_fetchEvaluatorCategoryAssignments(query: IFetchEvaluatorCategoryAssignmentsQueryParameters): Promise<Array<IEvaluatorCategoryAssignmentModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/evaluator-category-assignments?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.evaluator_category_assignments))
        .catch((error: AxiosError) => reject(error))
    })
  }
}