<article class="evaluator-category-assignments__evaluator-category-assignment">
  <div class="display--flex py-2 evaluator-category-assignments__evaluator-category-assignment__header">
    <div class="m-u--font-weight--bold evaluator-category-assignments__evaluator-category-assignment__header--category-title">
      {{ evaluatorCategoryAssignment.category_title }}
    </div>
    <div class="ml-auto evaluator-category-assignments__evaluator-category-assignment__header--limit-timestamp">
      {{
        $i18next.t(
          'modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment.EvaluatorCategoryAssignment.label.evaluation_period_limit_timestamp',
          {
            timestamp: mixin_timestamp_pretty(evaluatorCategoryAssignment.category_evaluation_period_limit_timestamp)
          }
        ) 
      }}
    </div>
  </div>
  <template v-if="evaluatorCategoryAssignment.evaluator_application_form_assignments.length === 0">
    <m-message :state="MMessageState.Warning">
      {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment.EvaluatorCategoryAssignment.message.empty_list.content') }}
    </m-message>
  </template>
  <template v-else>
    <template v-for="evaluatorApplicationFormAssignment in evaluatorCategoryAssignment.evaluator_application_form_assignments">
      <evaluator-application-form-assignment-row :evaluator-application-form-assignment="evaluatorApplicationFormAssignment" />
    </template>
  </template>
</article>