import Component, { mixins } from 'vue-class-component'

import { AxiosError } from 'axios'

import WithRender from './PublicYearConfiguration.html'

import { 
  MSpinner,
  MMessage,
  MMessageSkin,
  MMessageState
} from '@ulaval/modul-components'

import YearConfigurationLayout from './YearConfigurationLayout'

import { IYearModel } from '@/modules/global/year/Year.model'
import { IYearConfigurationModel } from '../YearConfiguration.model'
import YearConfigurationService, { IPublicYearConfigurationResponse } from '../YearConfiguration.service'
import CONSTANTS from '@/CONSTANTS'

@WithRender
@Component({
  components: {
    MMessage,
    MSpinner,
    YearConfigurationLayout
  }
})
export default class PublicYearConfiguration extends mixins(YearConfigurationService) {
  private year: IYearModel | null = null
  private yearConfiguration: IYearConfigurationModel | null = null
  private isLoadingStateActive: boolean = false
  private requestErrors: { [key: string]: boolean } = {}

  private readonly MMessageSkin: object = MMessageSkin
  private readonly MMessageState: object = MMessageState

  private created(): void {
    this.loadYearConfiguration()
  }

  private loadYearConfiguration(): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    this.service_yearConfigurationService_publicFetchYearConfiguration()
      .then((response: IPublicYearConfigurationResponse) => {
        this.yearConfiguration = response.year_configuration || null
        this.year = response.year || null
      }).catch((error: AxiosError<any>) => {
        if (error.response) {
          if (error.response.data && error.response.data.validation) {
            this.requestErrors = error.response.data.validation
          }
        }

        if (Object.keys(this.requestErrors).length === 0) {
          this.mixin_router_redirectRequestError(error)
        }
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }
}
