import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './FormTemplateUpdateAvailableElements.html'

import { IFormTemplateAvailableElementModel } from '../FormTemplate.model'
import { DRAG_AND_DROP_EVENTS_IDENTIFIER, DRAG_AND_DROP_EVENTS_IDENTIFIER_KEY, EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {}
})
export default class FormTemplateUpdateAvailableElements extends mixins() {
  @Prop({ required: true })
  private availableFormTemplateElements!: Array<IFormTemplateAvailableElementModel>

  private get mappedFormTemplateElementsBySection(): { [key: string]: Array<IFormTemplateAvailableElementModel> } {
    return this.availableFormTemplateElements.reduce((mappedElements: { [key: string]: Array<IFormTemplateAvailableElementModel> }, element: IFormTemplateAvailableElementModel) => {
      if (!mappedElements[element.element_section]) {
        mappedElements[element.element_section] = []
      }

      mappedElements[element.element_section].push(element)

      return mappedElements
    }, {})
  }

  private sortedFormTemplateElements(elements: Array<IFormTemplateAvailableElementModel>): Array<IFormTemplateAvailableElementModel> {
    return elements.sort((leftHandSide: IFormTemplateAvailableElementModel, rightHandSide: IFormTemplateAvailableElementModel) => {
      return leftHandSide.element_type.localeCompare(rightHandSide.element_type)
    })
  }

  private onDragStart(element: IFormTemplateAvailableElementModel, event: DragEvent): void {
    if (event && event.dataTransfer) {
      event.dataTransfer.setData(DRAG_AND_DROP_EVENTS_IDENTIFIER_KEY, DRAG_AND_DROP_EVENTS_IDENTIFIER.FORM_TEMPLATE_ELEMENT_INSERT)
      event.dataTransfer.setData('text/plain', JSON.stringify(element))

      EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_STARTED, element)
    }
  }

  private onDragEnd(): void {
    EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_ENDED)
  }
}