import Component, { mixins } from 'vue-class-component'

import WithRender from './StepWizard.html'

import { 
  MButton,
  MButtonSkin,
  MSteppers
} from '@ulaval/modul-components'

import FormElementMixin from '@/modules/form-management/form-element/FormElement.mixin'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import { IInternalFormTemplateElementModel } from '@/modules/form-template-management/FormTemplate.model'

export const STEP_WIZARD_STEP_SELECTED: string = 'step-wizard-step:selected'

@WithRender
@Component({
  components: {
    FormElement: () => import('@/modules/form-management/form-element/FormElement'),
    MButton,
    MSteppers
  }
})
export default class StepWizard extends mixins(FormElementMixin) {
  private selectedStepWizardIdentifier: string | null = null

  private readonly MButtonSkin: object = MButtonSkin

  private created(): void {
    this.onStepWizardStepSelected()
  }

  private onStepWizardStepSelected(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_ELEMENT_STEP_WIZARD_STEP_SELECTED, (elementId: string) => {
      this.selectedStepWizardIdentifier = elementId || null
    })
  }

  private onPreviousStepButtonClick(currentElementIndex: number): void {
    const previousElement: IInternalFormTemplateElementModel = this.internalElement.childrens[currentElementIndex - 1]

    EventBus.$emit(EVENT_BUS_EVENTS.FORM_ELEMENT_STEP_WIZARD_STEP_SELECTED, previousElement.id)
  }

  private onNextStepButtonClick(currentElementIndex: number): void {
    const nextElement: IInternalFormTemplateElementModel = this.internalElement.childrens[currentElementIndex + 1]

    EventBus.$emit(EVENT_BUS_EVENTS.FORM_ELEMENT_STEP_WIZARD_STEP_SELECTED, nextElement.id)
  }
}
