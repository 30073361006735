enum ROUTES {
  DEFAULT = 'default',
  LOGIN = 'login',
  ERROR = 'error',
  WILDCARD = 'wildcard',
  DASHBOARD = "home",
  USER_MANAGEMENT_USER_LIST = 'userManagementList',
  USER_MANAGEMENT_USER_CREATION = 'userManagementCreation',
  USER_MANAGEMENT_USER_UPDATE = 'userManagementUpdate',
  CATEGORY_MANAGEMENT_CATEGORY_LIST = 'categoryManagementList',
  CATEGORY_MANAGEMENT_CATEGORY_CREATION = 'categoryManagementCreation',
  CATEGORY_MANAGEMENT_CATEGORY_UPDATE = 'categoryManagementUpdate',
  CATEGORY_MANAGEMENT_CATEGORY_EVALUATION_CARD_DEPOSIT = 'categoryManagementEvaluationCardDeposit',
  EMAIL_TEMPLATE_MANAGEMENT_EMAIL_TEMPLATE_LIST = 'emailTemplateManagementList',
  EMAIL_TEMPLATE_MANAGEMENT_EMAIL_TEMPLATE_UPDATE = 'emailTemplateManagementUpdate',
  FORM_TEMPLATE_MANAGEMENT = 'formTemplateManagement',
  FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_UPDATE = 'formTemplateManagementUpdate',
  FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_PREVIEW = 'formTemplateManagementPreview',
  ADMIN_APPLICATION_FORM_MANAGEMENT = 'adminApplicationFormManagement',
  ADMIN_APPLICATION_FORM = "adminApplicationForm",
  APPLICATION_FORM = "applicationForm",
  APPLICATION_FORM_CREATION = "applicationFormCreation",
  APPLICATION_FORM_SUPPORT = 'applicationFormSupport',
  ADMIN_APPLICATION_FORM_SUPPORT = 'adminApplicationFormSupport',
  ADMIN_APPLICATION_FORM_SUPPORT_MANAGEMENT = 'adminApplicationFormSupportManagement',
  ADMIN_APPLICATION_FORM_VALIDATION_MANAGEMENT = 'adminApplicationFormValidationManagement',
  ADMIN_APPLICATION_FORM_VALIDATION_FORM = 'adminApplicationFormValidationForm',
  EVALUATOR_MANAGEMENT_LIST = 'evaluatorManagmentList',
  EVALUATOR_MANAGEMENT_CREATION = 'evaluatorManagmentCreation',
  EVALUATOR_MANAGEMENT_UPDATE = 'evaluatorManagmentUpdate',
  EVALUATOR_MANAGEMENT_EVALUATOR_TOOLBOX_FILE_DEPOSIT = 'evaluatorManagementEvaluatorToolboxFileDeposit',
  EVALUATOR_APPLICATION_FORM_DISPLAY = 'evaluatorApplicationFormDisplay',
  EVALUATOR_APPLICATION_FORM_SUPPORT_DISPLAY = 'evaluatorApplicationFormSupportDisplay',
  YEAR_CONFIGURATION_MANAGEMENT_LIST = 'yearConfigurationManagementList',
  YEAR_CONFIGURATION_MANAGEMENT_UPDATE = 'yearConfigurationManagementUpdate',
  PUBLIC_FORM_TEMPLATE_PREVIEW = 'publicFormTemplatePreview'
}

export default ROUTES