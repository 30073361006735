<article class="category-management__categories">
  <m-table-header>
    <template #left>
      <m-button @click="onCreateCategoryButtonClick">
        {{ $i18next.t('modules.category-management.categories.Categories.button.create_category.label') }}
      </m-button>
      <m-button
        class="ml-2"
        :skin="MButtonSkin.Secondary"
        :disabled="!hasSelectedCategoriesForCopy"
        @click="displayCategoryCopyDialog"
      >
        {{ $i18next.t('modules.category-management.categories.Categories.button.copy_categories.label') }}
      </m-button>
    </template>
    <template #right>
      <m-switch v-model="withSoftDeletedCategoriesFilter">
        {{ $i18next.t('modules.category-management.categories.Categories.switch.with_soft_deleted_categories_filter.label') }}
      </m-switch>
    </template>
  </m-table-header>
  <m-table
    :columns="tableColumns"
    :loading="isLoadingStateActive"
    :rows="sortedCategories"
    @sort-applied="onTableSortApplied($event)"
  >
    <template #header.copy-selector="{ column }">
      <m-checkbox
        :indeterminate="hasSelectedCategoriesForCopy"
        :value="hasSelectedAllCategoriesForCopy"
        @change="onSelectAllCategoriesForCopyChange($event)"
      />
    </template>
    <template #body.copy-selector="{ data }">
      <m-checkbox
        :key="`category-management__categories[${data.id}]--checkbox[copy-selector]`"
        :value="selectedCopyCategoriesId.includes(data.id)"
        @change="onSelectCategoryForCopyChange(data.id, $event)"
      />
    </template>
    <template #body.application-deposit-limit-timestamp="{ data }">
      {{ mixin_timestamp_pretty(data.application_deposit_limit_timestamp) }}
    </template>
    <template #body.options="{ data }">
      <m-option>
        <m-option-item-edit @click="onCategoryUpdateOptionClick(data)" />
        <m-option-item
          icon-name="evaluation"
          @click="onTriggerEvaluationCardDepositOverlayOptionClick(data)"
        >
          {{ $i18next.t('modules.category-management.categories.Categories.button.evaluation_card_deposit.label') }}
        </m-option-item>
        <template v-if="data.is_deleteable">
          <m-option-item-delete @click="onCategoryDeleteOptionClick(data)" />
        </template>
        <template v-if="mixin_userrole_hasGestionnaireGabaritFormulaireRole">
          <template v-for="formTemplate in data.form_templates">
            <m-option-item
              icon-name="grouping"
              @click="onFormTemplateOptionClick(data, formTemplate)"
            >
              {{ $i18next.t(`modules.form-template-management.FormTemplateModel.types.${formTemplate.type}`) }}
            </m-option-item>
          </template>
        </template>
      </m-option>
    </template>
    <template #body.soft-delete-state="{ data }">
      <m-switch
        :key="`category-management__categories[${data.id}]--switch[soft-delete-state]`"
        :value="!data.deleted_at"
        @change="onCategorySoftDeleteStateChange(data, $event)"
      />
    </template>
    <template #body.timestamp="{ data }">
      <template v-if="!mixin_string_isEmpty(data.updated_at)">
        {{ $i18next.t('modules.category-management.categories.Categories.table.headers.timestamp.content', {
          timestamp: mixin_timestamp_pretty(data.updated_at),
          user: data.updated_by_name
        }) }}
      </template>
      <template v-else-if="!mixin_string_isEmpty(data.created_at)">
        {{ $i18next.t('modules.category-management.categories.Categories.table.headers.timestamp.content', {
          timestamp: mixin_timestamp_pretty(data.created_at),
          user: data.created_by_name
        }) }}
      </template>
    </template>
    <template #body.title="{ data }">
      <template v-if="data.is_new_category">
        {{ data.title }}
        <div class="display--flex mt-1">
          <m-icon
            name="m-svg__information"
            class="category-management__categories--new-category-flag my-auto mr-1"
            size="16px"
          />
          <small class="category-management__categories--new-category-flag">
            {{ $i18next.t('modules.category-management.categories.Categories.table.content.title.new_category_flag') }}
          </small>
        </div>
      </template>
    </template>
  </m-table>
  <template v-if="isCategoryCopyDialogVisible">
    <category-copy-dialog
      :selected-categories="selectedCopyCategories"
      @after-close="hideCategoryCopyDialog"
    />
  </template>
  <template v-if="isCategoryDeleteDialogVisible && selectedCategoryForDelete">
    <m-dialog
      :state="MDialogState.Warning"
      :width="MDialogWidth.Large"
      :open="true"
      :message="$i18next.t('modules.category-management.categories.Categories.dialog.delete_confirmation.label', { 'category': selectedCategoryForDelete })"
      :okLabel="$i18next.t('modules.category-management.categories.Categories.dialog.delete_confirmation.submit_button.label')"
      @portal-after-close="hideCategoryDeleteDialog"
      @ok="deleteCategory"
    >
      <template v-if="isCategoryDeleteLoadingStateActive">
        <m-progress
          class="mt-6"
          indeterminate="true"
        />
      </template>
    </m-dialog>
  </template>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isCategoryDeleteSuccessToastOpen"
  >
    {{ $i18next.t('modules.category-management.categories.Categories.toast.delete_success.label') }}
  </m-toast>
</article>