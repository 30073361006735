<m-overlay
  id="evaluator-management__evaluator-category-assignment-creation-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
>
  <h2 slot="header">
    {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-creation.AdminEvaluatorCategoryAssignmentCreationOverlay.overlay_title') }}
  </h2>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showCreationSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-creation.AdminEvaluatorCategoryAssignmentCreationOverlay.message.creation_success.title')"
      />
    </template>
    <template v-else-if="showCreationErrorMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :state="MMessageState.Error"
        :title="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-creation.AdminEvaluatorCategoryAssignmentCreationOverlay.message.creation_error.title')"
      />
    </template>
    <template v-else>
      <div class="evaluator-management__evaluator-category-assignment-creation-overlay--content">
        <m-message
          class="mb-6"
          :state="MMessageState.Warning"
        >
          <p
            class="m-0"
            v-html="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-creation.AdminEvaluatorCategoryAssignmentCreationOverlay.message.email_send_informations.warning')"
          />
        </m-message>
        <admin-evaluator-category-assignment-form
          :year="year"
          :ref="FORM_REF"
        />
      </div>
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="showCreationSuccessMessage || showCreationErrorMessage">
        <div>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-creation.AdminEvaluatorCategoryAssignmentCreationOverlay.button.close.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-creation.AdminEvaluatorCategoryAssignmentCreationOverlay.button.assign.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-creation.AdminEvaluatorCategoryAssignmentCreationOverlay.button.cancel.label') }}
          </m-button>
        </div>
      </template>
    </transition>
  </template>
</m-overlay>