import Component, { mixins } from 'vue-class-component'

import { ApplicationFormSupportStatus, IApplicationFormSupportModel } from './ApplicationFormSupport.model'

@Component
export default class ApplicationFormSupportMixin extends mixins() {
  protected mixin_applicationFormSupport_computeStatusColor(applicationFormSupport: IApplicationFormSupportModel): string {
    switch (applicationFormSupport.status) {
      case ApplicationFormSupportStatus.IN_PROGRESS:
        return '#ffc103'
      case ApplicationFormSupportStatus.OVERDUE:
        return '#e30513'
      case ApplicationFormSupportStatus.SUBMITTED:
        return '#00c77f'
      default:
        return 'grey'
    }
  }

  protected mixin_applicationFormSupport_computeStatusIcon(applicationFormSupport: IApplicationFormSupportModel): string | null {
    switch (applicationFormSupport.status) {
      case ApplicationFormSupportStatus.IN_PROGRESS:
        return 'pd-svg__user-wait'
      case ApplicationFormSupportStatus.OVERDUE:
        return 'm-svg__error'
      case ApplicationFormSupportStatus.SUBMITTED:
        return 'm-svg__confirmation'
      default:
        return null
    }
  }

  protected mixin_applicationFormSupport_computeStatusIconStyles(applicationFormSupport: IApplicationFormSupportModel): object {
    return {
      'color': this.mixin_applicationFormSupport_computeStatusColor(applicationFormSupport)
    }
  }

  protected mixin_applicationFormSupport_computeStatusLabel(status: ApplicationFormSupportStatus | string): string | null {
    return this.$i18next.t(`modules.application-form-support-management.ApplicationFormSupportMixin.status.${status}`)
  }
}