import Component, { mixins } from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import store, { STORE_ACTIONS } from "@/store";

export interface IAuthenticateRequestPayload {
  username: string;
  password: string;
}

export interface IAuthenticateRequestResponse {
  token: string;
}

@Component
export default class AuthenticationService extends mixins() {
  protected service_authenticationService_authenticate(requestPayload: IAuthenticateRequestPayload): Promise<IAuthenticateRequestResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post('/api/authenticate', requestPayload)
        .then((response: AxiosResponse) => {
          const requestResponse: IAuthenticateRequestResponse = response.data.data

          store.dispatch(STORE_ACTIONS.SESSION_STORE_LOGIN, requestResponse.token)

          resolve(requestResponse)
        }).catch((error: AxiosError) => reject(error))
    })
  }
}