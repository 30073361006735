<article class="application-forms">
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="isLoadingStateActive">
      <div
        key="spinner"
        class="my-15"
      >
        <m-spinner
          :title="true"
          :description="true"
        />
      </div>
    </template>
    <template v-else-if="applicationForms.length === 0">
      <m-message
        key="message[empty-list]"
        :state="MMessageState.Information"
      >
        {{ $i18next.t('modules.application-form-management.application-forms.ApplicationForms.message.empty_list.label', year) }}
      </m-message>
    </template>
    <template v-else>
      <div
        key="elements"
        class="application-forms__elements"
      >
        <template v-for="applicationForm in applicationForms">
          <application-form-card
            :key="applicationForm.id"
            :application-form="applicationForm"
          />
        </template>
      </div>
    </template>
  </transition>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isApplicationFormDeleteSuccessToastOpen"
  >
    {{ $i18next.t('modules.application-form-management.application-forms.ApplicationForms.toast.delete_success.label') }}
  </m-toast>
</article>