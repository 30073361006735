<main id="dashboard-page">
  <section class="dashboard-page__header display--flex">
    <year-dropdown
      v-model="year"
      class="ml-auto"
    />
  </section>
  <template v-if="year">
    <dashboard-evaluator-section :year="year" />
    <dashboard-application-form-management :year="year" />
  </template>
</main>