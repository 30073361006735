import Component, { mixins } from 'vue-class-component'

import { ApplicationFormStatus, IApplicationFormModel } from './ApplicationForm.model';

@Component
export default class ApplicationFormMixin extends mixins() {
  protected mixin_applicationForm_computeStatusColor(applicationForm: IApplicationFormModel): string {
    switch (applicationForm.status) {
      case ApplicationFormStatus.INACTIVE:
        return '#d7d7d7'
      case ApplicationFormStatus.IN_PROGRESS:
        return '#ffc103'
      case ApplicationFormStatus.OVERDUE:
        return '#e30513'
      case ApplicationFormStatus.SUBMITTED:
        return '#00c77f'
      default:
        return 'grey'
    }
  }

  protected mixin_applicationForm_computeStatusIcon(applicationForm: IApplicationFormModel): string | null {
    switch (applicationForm.status) {
      case ApplicationFormStatus.INACTIVE:
        return 'pd-svg__circle-block'
      case ApplicationFormStatus.IN_PROGRESS:
        return 'pd-svg__user-wait'
      case ApplicationFormStatus.OVERDUE:
        return 'm-svg__error'
      case ApplicationFormStatus.SUBMITTED:
        return 'm-svg__confirmation'
      default:
        return null
    }
  }

  protected mixin_applicationForm_computeStatusIconStyles(applicationForm: IApplicationFormModel): object {
    return {
      'color': this.mixin_applicationForm_computeStatusColor(applicationForm)
    }
  }

  protected mixin_applicationForm_isApplicationFormInactive(applicationForm: IApplicationFormModel): boolean {
    return applicationForm.status === ApplicationFormStatus.INACTIVE
  }

  protected mixin_applicationForm_computeStatusLabel(status: ApplicationFormStatus | string): string | null {
    return this.$i18next.t(`modules.application-form-management.ApplicationFormMixin.status.${status}`)
  }
}