export enum EmailStatusType {
  QUEUED = 'QUEUED',
  ERROR = 'ERROR',
  SENT = 'SENT'
}

export interface IEmailStatusModel {
  correlation_id: string;
  error_message?: string;
  sent_at: string;
  sent_by: string;
  status: EmailStatusType;
  recipients?: Array<string>;
  carbon_copy_recipients?: Array<string>;
  blind_carbon_copy_recipients?: Array<string>;
}