import Component, { mixins } from 'vue-class-component'

import WithRender from './AdminApplicationFormManagement.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import AdminApplicationForms from './application-forms/AdminApplicationForms'
import YearDropdown from '../global/year/YearDropdown'
import Breadcrumbs from '../global/breadcrumbs/Breadcrumbs'

import { IYearModel } from '../global/year/Year.model'

@WithRender
@Component({
  components: {
    AdminApplicationForms,
    Breadcrumbs,
    MPanel,
    YearDropdown
  }
})
export default class AdminApplicationFormManagement extends mixins() {
  private year: IYearModel | null = null
}
