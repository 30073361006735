<section class="year-configuration">
  <template v-if="isLoadingStateActive">
    <div
      key="spinner"
      class="my-15"
    >
      <m-spinner
        :title="true"
        :description="true"
      />
    </div>
  </template>
  <template v-else-if="!isLoadingStateActive">
    <template v-if="requestErrors['year_configuration_not_available']">
      <m-message
        :skin="MMessageSkin.Page"
        :state="MMessageState.Warning"
        :title="$i18next.t('modules.year-configuration-management.year-configuration.PublicYearConfiguration.message.year_configuration_not_available.title')"
        class="text-align--center"
      >
        <span v-html="$i18next.t('modules.year-configuration-management.year-configuration.PublicYearConfiguration.message.year_configuration_not_available.content')" />
      </m-message>
    </template>
    <template v-else-if="yearConfiguration">
      <year-configuration-layout
        :year-configuration="yearConfiguration"
        :year="year"
        :is-preview="true"
      />
    </template>
  </template>
</section>