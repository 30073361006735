import Component, { mixins } from 'vue-class-component'

import { Prop } from 'vue-property-decorator'

import WithRender from './YearConfigurationLayout.html'

import YearConfigurationDescriptionAreaPanel from './YearConfigurationDescriptionAreaPanel'
import YearConfigurationHelpAreaPanel from './YearConfigurationHelpAreaPanel'
import YearConfigurationCategorySectionsPanel from './YearConfigurationCategorySectionsPanel'

import { IYearConfigurationModel } from '../YearConfiguration.model'
import { IYearModel } from '@/modules/global/year/Year.model'

@WithRender
@Component({
  components: {
    YearConfigurationCategorySectionsPanel,
    YearConfigurationDescriptionAreaPanel,
    YearConfigurationHelpAreaPanel
  }
})
export default class YearConfigurationLayout extends mixins() {
  @Prop({ required: true })
  private yearConfiguration!: IYearConfigurationModel

  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ 
    required: false, 
    default: false 
  })
  private isPreview!: boolean
}
