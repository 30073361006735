import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './YearConfigurationUpdateDialog.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay,
  MProgress
} from '@ulaval/modul-components'

import ROUTES from '@/router/ROUTES'
import YearConfigurationService, { IYearConfigurationUpdateForm } from '../YearConfiguration.service'
import { IYearConfigurationModel } from '../YearConfiguration.model'
import { IYearModel } from '@/modules/global/year/Year.model'
import YearConfigurationForm, { IYearConfigurationFormPayloadData } from '../year-configuration-form/YearConfigurationForm'
import { ICategorySectionFormRowPayloadData } from '@/modules/category-section-management/category-section-form/CategorySectionFormRow'

const FORM_REF: string = 'year-configuration-form'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MMessage,
    MOverlay,
    MProgress,
    YearConfigurationForm
  }
})
export default class YearConfigurationUpdateDialog extends mixins(YearConfigurationService) {
  private isLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showUpdateSuccessMessage: boolean = false
  private requestErrors: { [key: string]: string | boolean } = {}

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin

  private readonly FORM_REF: string = FORM_REF

  @Prop({ required: true })
  private yearConfiguration!: IYearConfigurationModel

  @Prop({ required: true })
  private year!: IYearModel

  @Ref(FORM_REF)
  private yearConfigurationFormRef!: YearConfigurationForm

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.YEAR_CONFIGURATION_MANAGEMENT_LIST
    })
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.yearConfigurationFormRef) {
      const formPayload: IYearConfigurationFormPayloadData | null = await this.yearConfigurationFormRef.getFormPayload()

      if (formPayload) {
        this.updateYearConfiguration(formPayload)
      }
    }
  }

  private updateYearConfiguration(formPayload: IYearConfigurationFormPayloadData): void {
      this.isLoadingStateActive = true

      const requestPayload: IYearConfigurationUpdateForm = {
        is_public_area_available: formPayload.is_public_area_available,
        public_area_description_zone: formPayload.public_area_description_zone,
        public_area_help_zone: formPayload.public_area_help_zone,
        public_area_description_zone_title: formPayload.public_area_description_zone_title,
        public_area_help_zone_title: formPayload.public_area_help_zone_title,
        category_sections: formPayload.category_sections.category_sections.reduce(
          (acc: IYearConfigurationUpdateForm['category_sections'], categorySection: ICategorySectionFormRowPayloadData) => {
            return [
              ...acc,
              {
                id: categorySection.id,
                title: categorySection.title,
                categories_id: categorySection.categories_id
              }
            ]
          }, []
        )
      }

      this.service_yearConfigurationService_update(this.yearConfiguration.id, requestPayload)
        .then(() => {
          this.showUpdateSuccessMessage = true
        }).catch((error: AxiosError<any>) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.validation
          ) {
            this.requestErrors = error.response.data.validation
          } else {
            this.requestErrors = {}
          }
        }).finally(() => {
          this.isLoadingStateActive = false
        })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }
}
