import Vue from 'vue'

import App from './App'
import router from './router'
import store from './store'
import Localization from './localization'
import UtilitiesMixin, { IUtilitiesMixin } from './modules/global/utilities/Utilities.mixin'
import IconPlugin from './plugins/icons'

import Axios, { AxiosStatic } from 'axios'
import * as Moment from 'moment'
import { i18n } from 'i18next'

import '@ulaval/modul-components/lib/modul.min.css'
import '@/assets/styles/main.scss'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import {
  DialogServicePlugin,
  FormPlugin,
  ToastServicePlugin,
  RichTextLicensePlugin,
  UtilsPlugin,
  UtilsPluginOptions
} from '@ulaval/modul-components'
import { fileSize as FileSizeFilter } from '@ulaval/modul-components/dist/filters/filesize/filesize'
import { FILESIZE_NAME } from '@ulaval/modul-components/dist/filters/filter-names'
// @ts-ignore
import FrenchPlugin from '@ulaval/modul-components/lib/fr'
// @ts-ignore
import DefaultSpritesPlugin from '@ulaval/modul-components/lib/sprites'

const utilsPluginOptions: UtilsPluginOptions = {
  i18PluginOptions: {
      curLang: Localization.CURRENT_LOCALE,
  },
  propagateVueParserErrors: false,
}

const localizationPromise: Promise<any> = import('@/localization/fr.plugin');
Moment.locale(Localization.MOMENT_LOCALES.FR)
require('moment/locale/fr')

Axios.defaults.baseURL = process.env.VUE_APP_API_URL
Axios.defaults.headers['Locale'] = Localization.CURRENT_LOCALE.toUpperCase()

Vue.config.productionTip = false;
Vue.prototype.$moment = Moment
Vue.prototype.$axios = Axios
Vue.prototype.$i18next = Localization.i18next

Vue.use(UtilsPlugin, utilsPluginOptions)
Vue.use(FrenchPlugin)
Vue.use(DefaultSpritesPlugin)
Vue.use(RichTextLicensePlugin);
Vue.use(FormPlugin)
Vue.use(DialogServicePlugin)
Vue.use(ToastServicePlugin)
Vue.use(IconPlugin)

Vue.filter(FILESIZE_NAME, FileSizeFilter)

Vue.mixin(UtilitiesMixin)

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Vue extends IUtilitiesMixin {
    $axios: AxiosStatic;
    // @ts-ignore
    $moment: Moment;
    $i18next: i18n;
  }
}

localizationPromise.then((localizationPlugin: any) => {
    Vue.use(localizationPlugin.default)

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
});
