import { IBaseModel } from "@/modules/global/utilities/Base.model";
import { IApplicationFormModel } from "../application-form-management/ApplicationForm.model";
import { IEmailStatusModel } from "../global/email/EmailStatus.model";

export enum ApplicationFormSupportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  OVERDUE = 'OVERDUE'
}

export interface IApplicationFormSupportModel extends IBaseModel {
  candidate_full_name_with_username: string;
  supporter_name_with_email: string;
  category_title: string;
  category_application_support_deposit_limit_timestamp: string;
  is_updateable: boolean;
  is_deleteable: boolean;
  status: ApplicationFormSupportStatus;
  token: string;
}

export interface IAdminApplicationFormSupportModel extends IApplicationFormModel {
  reminder_emails_status: Array<IEmailStatusModel>;
  creation_emails_status: Array<IEmailStatusModel>;
}