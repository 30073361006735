import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import jwt, { JwtPayload } from 'jsonwebtoken'

export enum STORE_ACTIONS {
  SESSION_STORE_LOGIN = 'session/login',
  SESSION_STORE_LOGOUT = 'session/logout',
  SESSION_STORE_SYNCHRONISE_WITH_LOCAL_STORAGE = 'session/synchroniseWithLocalStorage'
}

export enum STORE_GETTERS {
  SESSION_GET_TOKEN = 'session/token',
  SESSION_GET_IS_LOGGED_IN = 'session/isLoggedIn',
  SESSION_GET_FIRST_NAME = 'session/firstName',
  SESSION_GET_LAST_NAME = 'session/lastName',
  SESSION_GET_EMAIL = 'session/email',
  SESSION_GET_USERNAME = 'session/username',
  SESSION_GET_ROLES = 'session/roles',
  SESSION_GET_USER_FULL_NAME = 'session/userFullName',
  SESSION_GET_USER_FULL_NAME_WITH_USERNAME = 'session/userFullNameWithUsername',
  SESSION_GET_USER_FULL_NAME_WITH_EMAIL = 'session/userFullNameWithEmail'
}

export const TOKEN_LOCAL_STORAGE_KEY: string = 'session-token'

@Module({ namespaced: true })
export default class SessionStore extends VuexModule {
  public readonly TOKEN_LOCAL_STORAGE_KEY: string = TOKEN_LOCAL_STORAGE_KEY

  private _token: string | null = null
  private _isLoggedIn: boolean = false
  private _firstName: string | null = null
  private _lastName: string | null = null
  private _email: string | null = null
  private _username: string | null = null
  private _roles: string[] = []

  @Mutation
  private storeTokenMutation(token: string): void {
    localStorage.setItem(this.TOKEN_LOCAL_STORAGE_KEY, token)
  }

  @Mutation
  private synchroniseWithLocalStorageMutation(): void {
    this._token = localStorage.getItem(this.TOKEN_LOCAL_STORAGE_KEY)
    this._isLoggedIn = !!this._token
  }

  @Mutation
  private decodeTokenInformationsMutation(): void {
    if (this._token) {
      const decodedToken: JwtPayload = jwt.decode(this._token || '') as JwtPayload

      if (decodedToken) {
        this._firstName = decodedToken.firstName
        this._lastName = decodedToken.lastName
        this._email = decodedToken.email
        this._username = decodedToken.username
        this._roles = decodedToken.roles
      }
    }
  }

  @Mutation
  private clearTokenMutation(): void {
    localStorage.removeItem(this.TOKEN_LOCAL_STORAGE_KEY)
  }

  @Mutation
  private clearUserInformationsMutation(): void {
    this._firstName = null
    this._lastName = null
    this._email = null
    this._username = null
    this._roles = []
  }

  @Action
  public login(token: string): void {
    this.context.commit('storeTokenMutation', token)
    this.context.commit('synchroniseWithLocalStorageMutation')
    this.context.commit('decodeTokenInformationsMutation')
  }

  @Action
  public logout(): void {
    this.context.commit('clearTokenMutation')
    this.context.commit('synchroniseWithLocalStorageMutation')
    this.context.commit('clearUserInformationsMutation')
  }

  @Action
  public synchroniseWithLocalStorage(): void {
    this.context.commit('synchroniseWithLocalStorageMutation')
    this.context.commit('decodeTokenInformationsMutation')
  }

  private get token(): string | null {
    return this._token
  }

  private get isLoggedIn(): boolean {
    return this._isLoggedIn
  }

  private get firstName(): string | null {
    return this._firstName
  }

  private get lastName(): string | null {
    return this._lastName
  }

  private get email(): string | null {
    return this._email
  }

  private get username(): string | null {
    return this._username
  }

  private get roles(): string[] {
    return this._roles
  }

  private get userFullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  private get userFullNameWithUsername(): string {
    return `${this.userFullName} (${this.username})`
  }

  private get userFullNameWithEmail(): string {
    return `${this.userFullName} (${this.email})`
  }
}
