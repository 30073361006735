<m-overlay
  id="year-configuration-management__year-configuration-update-dialog"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
  @save="onSaveButtonClick"
>
  <template #header>
    <h2>
      {{ $i18next.t('modules.year-configuration-management.year-configuration-update.YearConfigurationUpdateDialog.overlay_title') }}
    </h2>
    <template v-if="year">
      <h6 class="mt-3">
        {{ year.slug }}
      </h6>
    </template>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showUpdateSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.year-configuration-management.year-configuration-update.YearConfigurationUpdateDialog.message.update_success.title')"
      />
    </template>
    <template v-else>
      <year-configuration-form
        :ref="FORM_REF"
        :request-errors="requestErrors"
        :year-configuration="yearConfiguration"
        :year="year"
      />
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="!showUpdateSuccessMessage">
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.year-configuration-management.year-configuration-update.YearConfigurationUpdateDialog.button.save.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.year-configuration-management.year-configuration-update.YearConfigurationUpdateDialog.button.cancel.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="closeOverlay"
        >
          {{ $i18next.t('modules.year-configuration-management.year-configuration-update.YearConfigurationUpdateDialog.button.close.label') }}
        </m-button>
      </template>
    </transition>
  </template>
</m-overlay>