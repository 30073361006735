import Component from 'vue-class-component'

import {
  MOptionItemPredefined,
} from '@ulaval/modul-components'


@Component({})
export default class SubmitApplicationFormSupportOption extends MOptionItemPredefined {
  protected get iconName(): string {
    return 'm-svg__profile'
  }

  protected get label(): string {
    return this.$i18next.t('modules.application-form-management.application-form.SubmitApplicationFormSupportOption.label')
  }
}
