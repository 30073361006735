import Component, { mixins } from 'vue-class-component'

import WithRender from './UserManagement.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import Breadcrumbs from '../global/breadcrumbs/Breadcrumbs'
import Users from './users/Users'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    MPanel,
    Users
  }
})
export default class UserManagement extends mixins() {}
