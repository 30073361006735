import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { ICategoryModel } from "./Category.model";
import BaseService from "../global/utilities/Base.service";
import { EVENT_BUS_EVENTS } from "../global/utilities/Events";
import { IFileUploadModel } from "../global/file/File.model";
import { IEvaluationCardModel } from "./evaluation-card-management/EvaluationCard.model";

export interface IFetchCategoryListQueryParameters {
  year_id: string;
  with_soft_deleted?: boolean;
}

export interface ICreateCategoryForm {
  title: string;
  description: string;
  year_id: string;
  application_deposit_limit_timestamp: string;
  evaluation_period_limit_timestamp: string;
  sort_order: number;
  is_new_category: boolean;
  has_application_support: boolean;
  application_support_count_lower_bound? : number;
  application_support_count_upper_bound?: number;
  application_support_deposit_limit_timestamp?: string;
  application_support_submit_limit_timestamp?: string;
  application_support_creation_dialog_message?: string;
  application_support_creation_email_message?: string;
}

export interface IUpdateCategoryForm {
  title: string;
  description: string;
  application_deposit_limit_timestamp: string;
  evaluation_period_limit_timestamp: string;
  sort_order: number;
  is_new_category: boolean;
  has_application_support: boolean;
  application_support_count_lower_bound? : number;
  application_support_count_upper_bound?: number;
  application_support_deposit_limit_timestamp?: string;
  application_support_submit_limit_timestamp?: string;
  application_support_creation_dialog_message?: string;
  application_support_creation_email_message?: string;
}

export interface ICopyCategoryForm {
  target_year_id: string;
  categories_id: Array<string>;
}

export type IEvaluationCardDepositForm = IFileUploadModel<IEvaluationCardModel>

@Component
export default class CategoryService extends BaseService {
  protected service_categoryService_fetchList(query: IFetchCategoryListQueryParameters): Promise<Array<ICategoryModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/categories?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.categories))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_fetch(id: string): Promise<ICategoryModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/categories/${id}`)
        .then((response: AxiosResponse) => resolve(response.data.data.category))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_softDelete(id: string): Promise<ICategoryModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/categories/${id}/soft-delete`)
        .then((response: AxiosResponse) => {
          const updatedCategory: ICategoryModel = response.data.data.category

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.CATEGORY_UPDATED, updatedCategory)

          resolve(updatedCategory)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_restore(id: string): Promise<ICategoryModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/categories/${id}/restore`)
        .then((response: AxiosResponse) => {
          const updatedCategory: ICategoryModel = response.data.data.category

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.CATEGORY_UPDATED, updatedCategory)

          resolve(updatedCategory)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_create(payload: ICreateCategoryForm): Promise<ICategoryModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/categories`, payload)
        .then((response: AxiosResponse) => {
          const createdCAtegory: ICategoryModel = response.data.data.category

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.CATEGORY_CREATED, createdCAtegory)

          resolve(createdCAtegory)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_update(id: string, payload: IUpdateCategoryForm): Promise<ICategoryModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/categories/${id}`, payload)
        .then((response: AxiosResponse) => {
          const updatedCategory: ICategoryModel = response.data.data.category

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.CATEGORY_UPDATED, updatedCategory)

          resolve(updatedCategory)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_copy(payload: ICopyCategoryForm): Promise<Array<ICategoryModel>> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/categories/copy`, payload)
        .then((response: AxiosResponse) => {
          const copiedCategories: ICategoryModel = response.data.data.categories

          resolve(copiedCategories)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_delete(id: string): Promise<ICategoryModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.delete(`/api/categories/${id}`)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.CATEGORY_DELETED, id)
          
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_categoryService_evaluationCardDeposit(id: string, file: IEvaluationCardDepositForm): Promise<ICategoryModel> {
    return new Promise((resolve: any, reject: any): void => {
      const data: FormData = new FormData()

      if (file.server_file) {
        data.set(`id`, file.server_file.id)
      } else if (file && file.file) {
        data.append(`file`, file.file.file)
      }

      this.$axios.put(`/api/categories/${id}/evaluation-card`, data, {
        headers: this.service_baseService_multipartFormDataHeader()
      }).then((response: AxiosResponse) => {
          const updatedCategory: ICategoryModel = response.data.data.category

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.CATEGORY_UPDATED, updatedCategory)

          resolve(updatedCategory)
        }).catch((error: AxiosError) => reject(error))
    })
  }
}