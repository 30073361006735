import Component, { mixins } from 'vue-class-component'

import { AxiosError } from 'axios';

import Localization from '@/localization';
import { extractLocalizedRouteParameters, generateLocalizedRouteName } from '@/router/helpers';
import ROUTES from './ROUTES';

export interface IRouterMixin {
  mixin_router_navigate(route: any, event?: Event): void;
  mixin_router_generateHref(route: any): string;
  mixin_router_redirectRequestError(error: AxiosError): void;
}

@Component
export default class RouterMixin extends mixins() implements IRouterMixin {
  public mixin_router_navigate(route: any, event?: Event): void {
    if (!route) {
      throw new Error("Unable to mixin_router_navigate. Route not provided.")
    }

    if (this.isEventNewTab(event)) {
      const routeHref = this.mixin_router_generateHref(route)

      const tab: Window | null = window.open(routeHref, '_blank')

      if (tab) {
        tab.focus()
      }
    } else {
      this.$router.push({
        ...route,
        name: generateLocalizedRouteName({
          name: route.name as string,
          locale: Localization.CURRENT_LOCALE
        })
      })
    }
  }

  private isEventNewTab(event?: any) {
    if (
      event && 
      (
        event.ctrlKey ||
        event.metaKey
      )
    ) {
        return true
      }

      return false
  }

  public mixin_router_generateHref(route: any): string {
    if (!route) {
      throw new Error("Unable to mixin_router_generateHref. Route not provided.")
    }

    return this.$router.resolve({
      ...route,
      name: generateLocalizedRouteName({
        name: route.name as string,
        locale: Localization.CURRENT_LOCALE
      })
    }).href
  }

  public mixin_router_extractLocalizedRouteName(name: string): string {
    return extractLocalizedRouteParameters(name || '').name
  }

  public mixin_router_redirectRequestError(error: AxiosError): void {
    if (error.response) {
      this.mixin_router_navigate({
        name: ROUTES.ERROR,
        params: {
          status: error.response.status.toString()
        },
        query: {
          token: this.$route.params.token
        }
      })
    }
  }
}
