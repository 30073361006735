import Component, { mixins } from 'vue-class-component'

import WithRender from './EmailTemplateManagement.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import Breadcrumbs from '../global/breadcrumbs/Breadcrumbs'
import EmailTemplates from './email-templates/EmailTemplates'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    EmailTemplates,
    MPanel
  }
})
export default class EmailTemplateManagement extends mixins() {

}
