import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './ApplicationForm.html'

import { 
  MButton,
  MDialog,
  MDialogState,
  MDialogWidth,
  MIcon,
  MMessage,
  MMessageState,
  MPanel,
  MSpinner,
  MToast,
  MToastDuration,
  MToastPosition,
  MToastState,
  MToastTimeout,
} from '@ulaval/modul-components'

import Breadcrumbs from '@/modules/global/breadcrumbs/Breadcrumbs'
import FormElements from '@/modules/form-management/form-element/FormElements'

import ApplicationFormService, { IFetchApplicationFormResponse } from '../ApplicationForm.service'
import { IApplicationFormModel } from '../ApplicationForm.model'
import { IFormModel } from '@/modules/form-management/Form.model'
import { IInternalFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'
import { IFormAnswerForm } from '@/modules/form-management/Form.service'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import ApplicationFormMixin from '../ApplicationForm.mixin'

const FORM_ELEMENTS_REF = 'form-elements'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    FormElements,
    MButton,
    MDialog,
    MIcon,
    MMessage,
    MPanel,
    MSpinner,
    MToast
  }
})
export default class ApplicationForm extends mixins(ApplicationFormService, ApplicationFormMixin) {
  private applicationForm: IApplicationFormModel | null = null
  private form: IFormModel | null = null
  private internalFormTemplate: IInternalFormTemplateModel | null = null
  private isLoadingStateActive: boolean = true
  private isSubmitConfirmationDialogActive: boolean = false
  private isSubmitSuccessToastOpen: boolean = false
  private isSubmitErrorToastOpen: boolean = false
  private isValidationErrorToastOpen: boolean = false
  private isSaveSuccessToastOpen: boolean = false
  private requestErrors: { [key: string]: boolean } = {}

  private readonly FORM_ELEMENTS_REF: string = FORM_ELEMENTS_REF
  private readonly MMessageState: object = MMessageState
  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout
  private readonly VALIDATION_KEY_PROVIDED_SUPPORT_UNDER_THRESHOLD: string = 'application_form_support_provided_under_threshold'

  @Ref(FORM_ELEMENTS_REF)
  private formElementsComponent!: FormElements

  private get submitDialogState(): MDialogState {
    if (this.isSubmitErrorToastOpen || this.hasSubmitErrors) {
      return MDialogState.Error
    } else if (this.isSubmitSuccessToastOpen) {
      return MDialogState.Confirmation
    }

    return MDialogState.Warning
  }
  
  private get computeSubmitDialogLabel(): string {
    if (this.requestErrors[this.VALIDATION_KEY_PROVIDED_SUPPORT_UNDER_THRESHOLD]) {
      return this.$i18next.t('modules.application-form-management.application-form.ApplicationForm.dialog.submit_confirmation.label_provided_support_under_threshold', this.applicationForm as any)
    }

    return this.$i18next.t('modules.application-form-management.application-form.ApplicationForm.dialog.submit_confirmation.label')
  }

  private get hasSubmitErrors(): boolean {
    return Object.keys(this.requestErrors).length > 0
  }

  @Watch('$route.params.idApplicationForm', { immediate: true })
  private onRouteParamsIdChange(value: string | null) {
    if (value) {
      this.loadApplicationForm(value)
    }
  }

  private mounted(): void {
    this.onFormNextStepButtonClick()
  }

  private loadApplicationForm(id: string, isBackgroundRefresh: boolean = false): void {
    this.isLoadingStateActive = !isBackgroundRefresh
    this.isValidationErrorToastOpen = false

    this.service_applicationFormService_fetchApplicationForm(id)
      .then((response: IFetchApplicationFormResponse) => {
        this.applicationForm = response.application_form || null
        this.form = response.form || null
        this.internalFormTemplate = response.internal_form_template || null
      }).catch(() => {
        this.applicationForm = null
        this.form = null
        this.internalFormTemplate = null
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private onFormNextStepButtonClick(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_ELEMENT_STEP_WIZARD_STEP_SELECTED, () => {
      if (this.applicationForm && this.applicationForm.is_updateable) {
        this.onSaveButtonClick(true)
      }
    })
  }

  private async onSaveButtonClick(isBackgroundSave: boolean = false): Promise<void> {
    const answers: Array<IFormAnswerForm> | null = await this.formElementsComponent.getElementAnswers(false)

    if (answers) {
      this.saveApplicationForm(answers, isBackgroundSave)
    }
  }

  private saveApplicationForm(answers: Array<IFormAnswerForm>, isBackgroundSave: boolean = false): void {
    if (this.applicationForm) {
      this.isLoadingStateActive = !isBackgroundSave
      this.isSaveSuccessToastOpen = false

      this.service_applicationFormService_saveApplicationForm(this.applicationForm.id, answers)
        .then(() => {
          this.loadApplicationForm(this.$route.params.idApplicationForm, isBackgroundSave)

          if (!isBackgroundSave) {
            this.isSaveSuccessToastOpen = true
          }
        }).catch((error: AxiosError) => {
          this.isLoadingStateActive = false
          this.mixin_router_redirectRequestError(error)
        })
    }
  }

  private async onSubmitButtonClick(): Promise<void> {
    this.requestErrors = {}
    const answers: Array<IFormAnswerForm> | null = await this.formElementsComponent.getElementAnswers()

    if (answers) {
      this.submitApplicationForm(answers)
    }
  }

  private submitApplicationForm(answers: Array<IFormAnswerForm>): void {
    if (this.applicationForm) {
      this.isLoadingStateActive = true

      this.service_applicationFormService_submitApplicationForm(this.applicationForm.id, answers)
        .then(() => {
          this.loadApplicationForm(this.$route.params.idApplicationForm)

          this.isSubmitSuccessToastOpen = true
          this.isSubmitErrorToastOpen = false
        }).catch((errorResponse: AxiosError) => {
          if (errorResponse.response && errorResponse.response.data) {
            this.requestErrors = (errorResponse.response.data as any).validation || {}
          }

          this.isSubmitSuccessToastOpen = false
          this.isSubmitErrorToastOpen = true
          this.isLoadingStateActive = false
        }).finally(() => {
          setTimeout(() => {
            this.isSubmitSuccessToastOpen = false
          }, MToastDuration.DesktopXShort)
          setTimeout(() => {
            this.isSubmitErrorToastOpen = false
          }, MToastDuration.DesktopXShort)
        })
    }
  }

  private async displaySubmitConfirmationDialog(): Promise<void> {
    const answers: Array<IFormAnswerForm> | null = await this.formElementsComponent.getElementAnswers()

    if (answers) {
      this.isSubmitConfirmationDialogActive = true
    }

    this.isValidationErrorToastOpen = !answers
  }

  private hideSubmitConfirmationDialog(): void {
    this.isSubmitConfirmationDialogActive = false
    this.requestErrors = {}
  }
}
