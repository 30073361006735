<article class="admin-evaluator-management__evaluator-category-assignments">
  <m-table-header>
    <template #left>
      <m-button @click="onTriggerCreationButtonClick">
        {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.button.trigger_creation.label') }}
      </m-button>
      <m-button
        class="ml-2"
        @click="onTriggerEvaluatorToolboxFileDepositButtonClick"
      >
        {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.button.trigger_evaluator_toolbox_file_deposit.label') }}
      </m-button>
    </template>
    <template #right>
      <m-searchfield
        v-model="searchField.value"
        v-m-control="searchField"
        :label-up="true"
        :label="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.search_field.label')"
        :placeholder="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.search_field.placeholder')"
        @search="onSearchFieldChange"
        @empty-field="onSearchFieldChange"
      />
    </template>
  </m-table-header>
  <m-table
    :columns="tableColumns"
    :loading="isLoadingStateActive"
    :rows="evaluatorCategoryAssignments"
  >
    <template #body.options="{ data }">
      <div class="display--flex justify-content--end">
        <template v-if="(data.assignation_emails_status && data.assignation_emails_status.length > 0) || data.evaluation_period_done_emails_status && data.evaluation_period_done_emails_status.length > 0">
          <m-popup placement="left">
            <template #trigger>
              <m-icon-button icon-name="m-svg__email" />
            </template>
            <template #header>
              <strong>
                {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.popover.emails.header') }}
              </strong>
              <hr />
            </template>
            <template v-if="data.assignation_emails_status && data.assignation_emails_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.popover.emails.assignation_emails') }}
              </label>
              <ul>
                <template v-for="status, index in data.assignation_emails_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.assignation_emails_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
            <template v-if="data.evaluation_period_done_emails_status && data.evaluation_period_done_emails_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.popover.emails.evaluation_period_done_emails') }}
              </label>
              <ul>
                <template v-for="status, index in data.evaluation_period_done_emails_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.evaluation_period_done_emails_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
          </m-popup>
        </template>
        <template v-if="data.evaluator_application_form_assignments && data.evaluator_application_form_assignments.length > 0">
          <m-popup placement="left">
            <template #trigger>
              <m-icon-button icon-name="m-svg__evaluation" />
            </template>
            <template #header>
              <strong>
                {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.popover.evaluator_application_form_assignments.header') }}
              </strong>
            </template>
            <ul>
              <template v-for="evaluatorApplicationFormAssignment, index in data.evaluator_application_form_assignments">
                <li>
                  <evaluator-application-form-assignment-popover-row :evaluator-application-form-assignment="evaluatorApplicationFormAssignment" />
                  <template v-if="index < data.evaluator_application_form_assignments.length - 1">
                    <hr />
                  </template>
                </li>
              </template>
            </ul>
          </m-popup>
        </template>
        <m-option max-width="500px">
          <m-option-item
            icon-name="edit"
            @click="onTriggerUpdateButtonClick(data)"
          >
            {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.options.edit.label') }}
          </m-option-item>
        </m-option>
      </div>
    </template>
  </m-table>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="paginationData">
      <m-pagination
        :items-per-page="paginationData.element_count_per_page"
        :items-total="paginationData.total_element_count"
        :value="paginationData.current_page_index + 1"
        @change="onPageChange($event)"
      />
    </template>
  </transition>
</article>