<div
  class="width--100"
  :id="internalElement.id"
>
  <template v-if="displayDuplicateAnswers && formElementAnswers.length > 1">
    <template v-for="answerIndex in formElementAnswerCount(internalElement)">
      <m-textarea
        v-bind="assembledElementProps"
        :value="formElementAnswers[answerIndex - 1].value"
        class="form-element"
        :class="elementClasses"
        :style="elementStyles"
        style="max-width: none; padding-left: 3px; padding-right: 3px;"
        :internal-element="internalElement"
        :answers="answers"
        :row-position-index="rowPositionIndex"
        :readonly="readonly"
        :error="!mixin_string_isEmpty(errorMessage)"
        :error-message="errorMessage"
        :display-duplicate-answers="displayDuplicateAnswers"
        :length‑overflow="false"
        @change="onValueChange($event)"
      >
        <template v-for="slot in elementSlots">
          <span :slot="slot.frontend_key || slot.key">
            <span v-html="slot.value" />
          </span>
        </template>
        <template v-if="componentHasOptions">
          <template v-for="option in internalElement.element_options">
            <div>
              <component
                :is="internalElement.option_component_name"
                :value="option.value"
              >
                {{ option.value }}
              </component>
            </div>
          </template>
        </template>
        <template v-if="elementContent">
          <span v-html="elementContent" />
        </template>
      </m-textarea>
    </template>
  </template>
  <template v-else>
    <m-textarea
      v-bind="assembledElementProps"
      :value="elementValue"
      class="form-element"
      :class="elementClasses"
      :style="elementStyles"
      style="max-width: none; padding-left: 3px; padding-right: 3px;"
      :internal-element="internalElement"
      :answers="answers"
      :row-position-index="rowPositionIndex"
      :readonly="readonly"
      :error="!mixin_string_isEmpty(errorMessage)"
      :error-message="errorMessage"
      :display-duplicate-answers="displayDuplicateAnswers"
      :length‑overflow="false"
      @change="onValueChange($event)"
    >
      <template v-for="slot in elementSlots">
        <span :slot="slot.frontend_key || slot.key">
          <span v-html="slot.value" />
        </span>
      </template>
      <template v-if="componentHasOptions">
        <template v-for="option in internalElement.element_options">
          <div>
            <component
              :is="internalElement.option_component_name"
              :value="option.value"
            >
              {{ option.value }}
            </component>
          </div>
        </template>
      </template>
      <template v-if="elementContent">
        <span v-html="elementContent" />
      </template>
    </m-textarea>
  </template>
</div>