import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './FormTemplateManagement.html'

import { 
  MMessage,
  MMessageState,
  MPanel,
  MSpinner,
  MToast
} from '@ulaval/modul-components'

import FormTemplateUpdateDropZone from './form-template-update/FormTemplateUpdateDropZone'
import FormTemplateUpdateElementPanel from './form-template-update/FormTemplateUpdateElementPanel'
import Breadcrumbs from '../global/breadcrumbs/Breadcrumbs'

import FormTemplateService from './FormTemplate.service'
import { IInternalFormTemplateElementModel, IInternalFormTemplateModel } from './FormTemplate.model'

import { EventBus, EVENT_BUS_EVENTS, IFormTemplateElementInsertDragDroppedEventPayload, IFormTemplateElementMoveDragDroppedEventPayload, IFormTemplateElementUpdatedEventPayload } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    FormTemplateUpdateDropZone,
    FormTemplateUpdateElementPanel,
    MMessage,
    MPanel,
    MSpinner,
    MToast
  }
})
export default class FormTemplateManagement extends mixins(FormTemplateService) {
  private formTemplate: IInternalFormTemplateModel | null = null
  private isLoadingOverlayActive: boolean = true
  private insertTimedout: boolean = false

  private readonly MMessageState: object = MMessageState

  @Watch('$route.params.idFormTemplate', { immediate: true })
  private onRouteIdFormTemplateChange(id: string): void {
    if (id) {
      this.loadFormTemplate(id)
    }
  }

  private created(): void {
    this.onFormTemplateInsertElementDrop()
    this.onFormTemplateElementDelete()
    this.onFormTemplateElementUpdate()
    this.onFormTemplateMoveElementDrop()
    this.afterFormTemplateUpdate()
  }

  private loadFormTemplate(id: string): void {
    this.isLoadingOverlayActive = true

    this.service_formTemplateService_fetchMappedToInternalFormTemplate(id)
      .then((response: IInternalFormTemplateModel) => {
        this.formTemplate = response || null
      }).catch((error: AxiosError) => {
        this.mixin_router_redirectRequestError(error)
      }).finally(() => {
        this.isLoadingOverlayActive = false
      })
  }

  private afterFormTemplateUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_UPDATED, (payload: IInternalFormTemplateModel) => {
      this.formTemplate = payload || null
    })
  }

  private onFormTemplateInsertElementDrop(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_DROPPED, (payload: IFormTemplateElementInsertDragDroppedEventPayload) => {
      this.insertTimedout = true
      setTimeout(() => {
        this.insertTimedout = false
      }, 1000)

      if (payload && this.formTemplate) {
        if (payload.parentInternalIdentifier == null) {
          const internalFormTemplateElement: IInternalFormTemplateElementModel = {
            internal_identifier: payload.insertionElementPositionIndex.toString(),
            parent_internal_identifier: payload.parentInternalIdentifier,
            element_type: payload.element_type.element_type,
            element_position_index: payload.insertionElementPositionIndex,
            element_options: [],
            element_parameters: [],
            childrens: []
          }

          this.formTemplate.elements.splice(payload.insertionElementPositionIndex, 0, internalFormTemplateElement)
        } else {
          this.recalculateFormTemplatePositionRecursive(this.formTemplate.elements, 0)
          this.insertElementInFormTemplateRecursive(payload, this.formTemplate.elements)
        }
        
        this.recalculateFormTemplatePositionRecursive(this.formTemplate.elements, 0)
      }
    })
  }

  private onFormTemplateMoveElementDrop(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_DROPPED, (payload: IFormTemplateElementMoveDragDroppedEventPayload) => {
      if (payload && this.formTemplate) {
        if (payload.parentInternalIdentifier == null) {
          const movedInternalFormTemplateElement: IInternalFormTemplateElementModel = Object.assign({}, payload.originalElement)
          movedInternalFormTemplateElement.element_position_index = payload.insertionElementPositionIndex

          this.formTemplate.elements.splice(payload.originalElement.element_position_index, 1)
          this.formTemplate.elements.splice(payload.insertionElementPositionIndex, 0, movedInternalFormTemplateElement)
        } else {
          this.recalculateFormTemplatePositionRecursive(this.formTemplate.elements, 0)
          this.moveElementInFormTemplateRecursive(payload, this.formTemplate.elements)
        }

        this.recalculateFormTemplatePositionRecursive(this.formTemplate.elements, 0)
      }
    })
  }

  private onFormTemplateElementDelete(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_DELETED, (elementToDelete: IInternalFormTemplateElementModel) => {
      if (elementToDelete && this.formTemplate) {
        this.deleteFormTemplateElementRecursive(this.formTemplate.elements, elementToDelete)
      }
    })
  }

  private onFormTemplateElementUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_UPDATED, (eventPayload: IFormTemplateElementUpdatedEventPayload) => {
      if (eventPayload && this.formTemplate) {
        this.updateFormTemplateElementRecursive(this.formTemplate.elements, eventPayload)
      }
    })
  }

  private insertElementInFormTemplateRecursive(payload: IFormTemplateElementInsertDragDroppedEventPayload, elements: Array<IInternalFormTemplateElementModel>): void {
    elements.forEach((element: IInternalFormTemplateElementModel) => {
      if (element.internal_identifier === payload.parentInternalIdentifier) {
        const internalFormTemplateElement: IInternalFormTemplateElementModel = {
          internal_identifier: `${payload.parentInternalIdentifier}-${payload.insertionElementPositionIndex.toString()}`,
          parent_internal_identifier: payload.parentInternalIdentifier,
          element_type: payload.element_type.element_type,
          element_position_index: payload.insertionElementPositionIndex,
          element_options: [],
          element_parameters: [],
          childrens: []
        }

        element.childrens.splice(payload.insertionElementPositionIndex, 0, internalFormTemplateElement)
      } else {
        this.insertElementInFormTemplateRecursive(payload, element.childrens)
      }      
    })
  }

  private moveElementInFormTemplateRecursive(payload: IFormTemplateElementMoveDragDroppedEventPayload, elements: Array<IInternalFormTemplateElementModel>): void {
    elements.forEach((element: IInternalFormTemplateElementModel) => {
      if (element.internal_identifier === payload.parentInternalIdentifier) {
        const movedInternalFormTemplateElement: IInternalFormTemplateElementModel = Object.assign({}, payload.originalElement)
        movedInternalFormTemplateElement.element_position_index = payload.insertionElementPositionIndex

        element.childrens.splice(payload.originalElement.element_position_index, 1)
        element.childrens.splice(payload.insertionElementPositionIndex, 0, movedInternalFormTemplateElement)
      } else {
        this.moveElementInFormTemplateRecursive(payload, element.childrens)
      }
    })
  }

  private recalculateFormTemplatePositionRecursive(elements: Array<IInternalFormTemplateElementModel>, moveStartingIndex: number, parentInternalIdentifier?: string): void {
    for (let i: number = elements.length - 1; i >= moveStartingIndex; i--) {
      elements[i].element_position_index = i
      elements[i].parent_internal_identifier = parentInternalIdentifier
      elements[i].internal_identifier = parentInternalIdentifier ? `${parentInternalIdentifier}-${i}` : i.toString()

      this.$set(elements, i,  elements[i])

      this.recalculateFormTemplatePositionRecursive(elements[i].childrens, 0, elements[i].internal_identifier)
    }
  }

  private deleteFormTemplateElementRecursive(elements: Array<IInternalFormTemplateElementModel>, elementToDelete: IInternalFormTemplateElementModel, parentInternalIdentifier?: string): void {
    let elementToDeleteIndex: number | null = null

    elements.forEach((element: IInternalFormTemplateElementModel, index: number) => {
      if (element.internal_identifier === elementToDelete.internal_identifier) {
        elementToDeleteIndex = index
      } else {
        this.deleteFormTemplateElementRecursive(element.childrens, elementToDelete, element.internal_identifier)
      }
    })

    if (elementToDeleteIndex != null) {
      this.$delete(elements, elementToDeleteIndex)
      this.recalculateFormTemplatePositionRecursive(elements, 0, parentInternalIdentifier)
    }
  }

  private updateFormTemplateElementRecursive(elements: Array<IInternalFormTemplateElementModel>, eventPayload: IFormTemplateElementUpdatedEventPayload): void {
    elements.forEach((element: IInternalFormTemplateElementModel) => {
      if (element.internal_identifier === eventPayload.elementInternalIdentifier) {
        element.element_options = eventPayload.elementOptions

        const newElementParameters: { [key: string]: any } = Object.assign({}, eventPayload.elementParametersValue)
        for (const elementParameter of element.element_parameters) {
          if (typeof newElementParameters[elementParameter.key] !== 'undefined') {
            elementParameter.value = newElementParameters[elementParameter.key]
            delete newElementParameters[elementParameter.key]
          }
        }

        Object.entries(newElementParameters).forEach(([key, value]) => {
          element.element_parameters.push({
            key,
            value,
          })
        })
      } else {
        this.updateFormTemplateElementRecursive(element.childrens, eventPayload)
      }
    })
  }
}