<div
  class="evaluator-application-form-assignment-row display--flex py-3"
  :key="evaluatorApplicationFormAssignment.id"
>
  <h6
    class="my-auto"
    @click="onDisplayApplicationFormLinkClick"
  >
    <m-link
      :disabled="isApplicationFormLinkDisabled"
      :mode="MLinkMode.Button"
      :href="null"
    >
      {{ evaluatorApplicationFormAssignment.candidate_full_name }}
    </m-link>
  </h6>
  <div class="ml-auto">
    <m-switch
      :value="isStatusSwitchActive"
      :disabled="isStatusSwitchDisabled"
      @change="onStatusSwitchChange($event)"
      @mouseover.native="onStatusSwitchMouseOver"
      @mouseleave.native="onStatusSwitchMouseLeave"
    />
  </div>
  <m-tooltip
    :mode="MTooltipMode.Definition"
    :open="isStatusTooltipOpen"
    :closeButton="false"
    :size="MTooltipSize.Large"
  >
    <span v-html="$i18next.t(`modules.evaluator-management.evaluator-application-form-assignment-management.evaluator-application-form-assignment.EvaluatorApplicationFormAssignmentRow.tooltip.status.content`)" />
  </m-tooltip>
</div>