import Component, { mixins } from 'vue-class-component'

import { ApplicationFormValidationStatus, IApplicationFormValidationModel } from './ApplicationFormValidation.model'

@Component
export default class ApplicationFormValidationMixin extends mixins() {
  protected mixin_applicationFormValidation_computeStatusColor(applicationForm: IApplicationFormValidationModel): string {
    switch (applicationForm.status) {
      case ApplicationFormValidationStatus.IN_PROGRESS:
        return '#ffc103'
      case ApplicationFormValidationStatus.INVALID:
        return '#e30513'
      case ApplicationFormValidationStatus.VALID:
        return '#00c77f'
      default:
        return 'grey'
    }
  }

  protected mixin_applicationFormValidation_computeStatusIcon(applicationForm: IApplicationFormValidationModel): string | null {
    switch (applicationForm.status) {
      case ApplicationFormValidationStatus.IN_PROGRESS:
        return 'pd-svg__user-wait'
      case ApplicationFormValidationStatus.INVALID:
        return 'm-svg__error'
      case ApplicationFormValidationStatus.VALID:
        return 'm-svg__confirmation'
      default:
        return null
    }
  }

  protected mixin_applicationFormValidation_computeStatusIconStyles(applicationForm: IApplicationFormValidationModel): object {
    return {
      'color': this.mixin_applicationFormValidation_computeStatusColor(applicationForm)
    }
  }

  protected mixin_applicationFormValidation_computeStatusLabel(status: ApplicationFormValidationStatus | string): string | null {
    return this.$i18next.t(`modules.application-form-validation-management.ApplicationFormValidationMixin.status.${status}`)
  }
}