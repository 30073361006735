import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './FormTemplateUpdateSelectedElement.html'

import { 
  MAdd,
  MButton,
  MButtonSkin,
  MDropdown,
  MDropdownItem,
  MIconButton,
  MMessage,
  MMessageState,
  MTextfield,
  MTextfieldType,
  MTooltip,
  MSwitch,
} from '@ulaval/modul-components'

import RichTextEditor from '@/modules/global/components/rich-text-editor/RichTextEditor'

import { 
  IFormTemplateAvailableElementModel, 
  IFormTemplateAvailableElementModelParameter, 
  IInternalFormTemplateElementModel, 
  IInternalFormTemplateElementParameterModel, 
  IInternalFormTemplateElementOptionModel 
} from '../FormTemplate.model'

import { EventBus, EVENT_BUS_EVENTS, IFormTemplateElementUpdatedEventPayload } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {
    MAdd,
    MButton,
    MDropdown,
    MDropdownItem,
    MIconButton,
    MMessage,
    MTextfield,
    MTooltip,
    MSwitch,
    RichTextEditor
  }
})
export default class FormTemplateUpdateSelectedElement extends mixins() { 
  private parametersValue: { [key: string]: any } = {}
  private elementOptions: Array<IInternalFormTemplateElementOptionModel> = []
  
  private readonly MTextfieldType: any = MTextfieldType
  private readonly MButtonSkin: any = MButtonSkin
  private readonly MMessageState: any = MMessageState

  @Prop({ required: true })
  private availableFormTemplateElements!: Array<IFormTemplateAvailableElementModel>

  @Prop({ required: true })
  private selectedElement!: IInternalFormTemplateElementModel

  @Prop({ required: true })
  private requestErrors!: { [key: string]: string }

  private get mappedAvailableFormTemplateElements(): { [key: string]: IFormTemplateAvailableElementModel } {
    return Array.from(this.availableFormTemplateElements).reduce(
      (
        elements: { [key: string]: IFormTemplateAvailableElementModel }, 
        element: IFormTemplateAvailableElementModel
      ) => {
        return {
          ...elements,
          [element.element_type]: element
        }
      }, {})
  }

  private get selectedElementAvailableElementType(): IFormTemplateAvailableElementModel | null {
    return this.mappedAvailableFormTemplateElements[this.selectedElement.element_type] || null
  }

  private get sortedAvailableElementTypeParameters(): Array<IFormTemplateAvailableElementModelParameter> {
    if (!this.selectedElementAvailableElementType) {
      return []
    }

    return Array.from(this.selectedElementAvailableElementType.element_parameters).sort((leftHandSide: IFormTemplateAvailableElementModelParameter, rightHandSide: IFormTemplateAvailableElementModelParameter) => {
      return leftHandSide.type.localeCompare(rightHandSide.type)
    })
  }

  private get availableElementTypeHasParameters(): boolean {
    if (!this.selectedElementAvailableElementType) {
      return false
    }

    return this.selectedElementAvailableElementType.element_parameters.length > 0
  }

  private hasParameterTooltipLabel(parameter: IInternalFormTemplateElementParameterModel): boolean {
    return this.$i18next.exists(`modules.form-template-management.FormTemplateModel.element_parameters_tooltip.${parameter.key}`)
  }

  private hasParameterHelperMessageLabel(parameter: IInternalFormTemplateElementParameterModel): boolean {
    return this.$i18next.exists(`modules.form-template-management.FormTemplateModel.element_parameters_helper_message.${parameter.key}`)
  }

  @Watch('selectedElementAvailableElementType', { immediate: true })
  private onSelectedElementAvailableElementTypeChange(): void {
    this.initializeParametersValue()
  }

  @Watch('selectedElement', { immediate: true })
  private onSelectedElementChange(): void {
    this.mapSelectedElementOptions()
    this.validateForm()
  }

  private getParameterComponentName(parameter: IFormTemplateAvailableElementModelParameter): string {
    if (!this.selectedElementAvailableElementType) {
      return ''
    }

    if (parameter.options.length > 0) {
      return 'm-dropdown'
    }

    switch (parameter.type) {
      case 'Boolean':
        return 'm-switch'
      case 'Integer':
      case 'Number':
      case 'String':
        return 'm-textfield'
      case 'RichTextParameterType':
        return 'rich-text-editor'
      default:
        return ''
    }
  }

  private getParameterComponentType(parameter: IFormTemplateAvailableElementModelParameter): string {
    switch (parameter.type) {
      case 'Integer':
      case 'Number':
        return MTextfieldType.Number
      case 'String':
        return MTextfieldType.Text
      default:
        return ''
    }
  }

  private initializeParametersValue(): void {
    if (this.selectedElementAvailableElementType) {
      this.selectedElementAvailableElementType.element_parameters.forEach((parameter: IFormTemplateAvailableElementModelParameter) => {
        switch (parameter.type) {
          case 'Boolean':
            this.parametersValue[parameter.key] = false
            break
          case 'Integer':
            this.parametersValue[parameter.key] = 0
            break
          case 'RichTextParameterType':
          case 'String':
            this.parametersValue[parameter.key] = ''
            break
          default:
            this.parametersValue[parameter.key] = null
            break
        }
      })
    }
    
    this.mapSelectedElementParametersValue()
  }

  private mapSelectedElementParametersValue(): void {
    const selectedElementMappedParameters: { [key: string]: IInternalFormTemplateElementParameterModel } = this.selectedElement.element_parameters.reduce(
      (
        parameters: { [key: string]: IInternalFormTemplateElementParameterModel },
        parameter: IInternalFormTemplateElementParameterModel
      ) => {
        return {
          ...parameters,
          [parameter.key]: parameter
        }
      },
      {}
    )

    Object.keys(this.parametersValue).forEach((parameterKey: string) => {
      if (selectedElementMappedParameters[parameterKey]) {
        try {
          this.parametersValue[parameterKey] = JSON.parse(selectedElementMappedParameters[parameterKey].value)
        } catch(exception) {
          this.parametersValue[parameterKey] = selectedElementMappedParameters[parameterKey].value
        }
      }
    })
  }

  private onParameterValueChange(parameterKey: string, value: any): void {
    this.$set(this.parametersValue, parameterKey, value)
    this.$forceUpdate()
  }

  private mapSelectedElementOptions(): void {
    this.elementOptions = []

    this.selectedElement.element_options.forEach((option: IInternalFormTemplateElementOptionModel) => {
      this.$set(this.elementOptions, this.elementOptions.length, {
        value: option.value,
        order: option.order
      })
    })

    this.sortElementOptions()
  }

  private addElementOption(): void {
    this.$set(this.elementOptions, this.elementOptions.length, {
      label: '',
      value: '',
      order: ''
    })
  }

  private deleteElementOption(index: number): void {
    this.$delete(this.elementOptions, index)
  }

  private onDeleteButtonClick(): void {
    EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_DELETED, this.selectedElement)
  }

  private onUpdateButtonClick(): void {
    if (this.validateForm()) {
      EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_UPDATED, {
        elementInternalIdentifier: this.selectedElement.internal_identifier,
        elementOptions: this.elementOptions,
        elementParametersValue: this.parametersValue
      } as IFormTemplateElementUpdatedEventPayload)

      this.sortElementOptions()
    }
  }

  private onOptionValueChange(optionIndex: number, value: string): void {
    this.$set(this.elementOptions[optionIndex], 'value', value)
  }

  private onOptionOrderChange(optionIndex: number, order: string): void {
    this.$set(this.elementOptions[optionIndex], 'order', order)
  }

  private onParameterOptionSelect(parameterKey: string, option: string): void {
    this.parametersValue[parameterKey] = option
  }

  private sortElementOptions(): void {
    this.elementOptions = this.elementOptions
      .sort((lhs: IInternalFormTemplateElementOptionModel, rhs: IInternalFormTemplateElementOptionModel) => {
        return lhs.order - rhs.order
      })
  }

  private validateForm(): boolean {
    let hasErrors: boolean = false

    this.elementOptions.forEach((option: any, index: number) => {
      if (!option.value) {
        hasErrors = true
        option.valueError = this.$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.errors.option.value.empty`)
      } else {
        option.valueError = null
      }
      
      if (!option.order) {
        hasErrors = true
        option.orderError = this.$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.errors.option.order.empty`)
      } else if (isNaN(option.order)) {
        hasErrors = true
        option.orderError = this.$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.errors.option.order.numeric`)
      } else {
        option.orderError = null
      }
      
      this.$set(this.elementOptions, index, option)
    })

    return !hasErrors
  }

}