import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import WithRender from './AdminEvaluatorCategoryAssignmentForm.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MForm,
  MMultiSelect,
  RequiredValidator
} from '@ulaval/modul-components'

import UserForm, { IUserFormPayloadData } from '@/modules/user-management/user-form/UserForm'
import { IYearModel } from '@/modules/global/year/Year.model'
import { ICategoryModel } from '@/modules/category-management/Category.model'
import EvaluatorCategoryAssignmentService from '../EvaluatorCategoryAssignment.service'
import { IEvaluatorCategoryAssignmentAdminModel } from '../EvaluatorCategoryAsssignment.model'

const USER_FORM_REF: string = 'user-form'

export interface IAdminEvaluatorCategoryAssignmentFormFormPayloadData {
  evaluator_idul: string;
  categories: Array<ICategoryModel>;
}

@WithRender
@Component({
  components: {
    MForm,
    MMultiSelect,
    UserForm
  }
})
export default class AdminEvaluatorCategoryAssignmentForm extends mixins(EvaluatorCategoryAssignmentService) {
  public formGroup: FormGroup = new FormGroup({
    'category-multi-select': new FormControl<Array<ICategoryModel>>([RequiredValidator()])
  })
  private categories: Array<ICategoryModel> = []
  private selectedCategories: Array<ICategoryModel> = []
  private isCategoryLoadingStateActive: boolean = false

  private readonly USER_FORM_REF: string = USER_FORM_REF

  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ required: false })
  private evaluatorIdul: string | undefined

  @Prop({ required: false })
  private evaluatorFullNameWithUsername: string | undefined

  @Prop({ required: false })
  private evaluatorCategoryAssignments: Array<IEvaluatorCategoryAssignmentAdminModel> | undefined

  @Ref(USER_FORM_REF)
  private userFormComponent!: UserForm

  public get categoryMultiSelect(): AbstractControl<Array<ICategoryModel>> | null {
    if (!this.formGroup.containsControl('category-multi-select')) {
      return null
    }

    return this.formGroup.getControl('category-multi-select');
  }

  public get categoriesMappedById(): { [key: string]: ICategoryModel } {
    return this.categories.reduce((acc: { [key: string]: ICategoryModel }, category: ICategoryModel) => {
      return {
        ...acc,
        [category.id]: category
      }
    }, {}) || {}
  }

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadCategories()
  }

  @Watch('evaluatorIdul', { immediate: true })
  private onEvaluatorIdulChange(): void {
    if (this.evaluatorIdul) {
      this.formGroup.setControl('category-multi-select', new FormControl<Array<ICategoryModel>>([]))
    } else {
      this.formGroup.setControl('category-multi-select', new FormControl<Array<ICategoryModel>>([RequiredValidator()]))
    }
  }

  private loadCategories(): void {
    this.isCategoryLoadingStateActive = true

    this.service_evaluatorCategoryAssignmentService_fetchAvailableCategories(this.year.id)
      .then((response: Array<ICategoryModel>) => {
        this.categories = response || []

        if (this.evaluatorCategoryAssignments) {
          this.evaluatorCategoryAssignments.forEach((evaluatorCategoryAssignment: IEvaluatorCategoryAssignmentAdminModel) => {
            if (!this.categoriesMappedById[evaluatorCategoryAssignment.category_id]) {
              this.$set(
                this.categories, 
                this.categories.length, 
                {
                  id: evaluatorCategoryAssignment.category_id,
                  title: evaluatorCategoryAssignment.category_title
                }
              )
            }
          })
        }
      }).catch(() => {
        this.categories = []
      }).finally(() => {
        this.isCategoryLoadingStateActive = false
        this.preselectCategories()
      })
  }

  public async getFormPayload(): Promise<IAdminEvaluatorCategoryAssignmentFormFormPayloadData | null> {
    if (!this.categoryMultiSelect) {
      return null
    }

    this.categoryMultiSelect.value = this.selectedCategories

    await this.formGroup.submit()
    const evaluatorIdul: string | null = await this.getCandidateIdul()

    if (this.formGroup.hasErrorDeep() || !evaluatorIdul) {
      return null
    }

    return {
      evaluator_idul: evaluatorIdul,
      categories: this.categoryMultiSelect.value
    }
  }

  private async getCandidateIdul(): Promise<string | null> {
    if (this.evaluatorIdul) {
      return this.evaluatorIdul
    } else if (this.userFormComponent) {
      const userFormPayload: IUserFormPayloadData | null = await this.userFormComponent.getFormPayload()

      if (userFormPayload && userFormPayload.idul) {
        return userFormPayload.idul
      }
    } 

    return null
  }

  private preselectCategories(): void {
    if (this.evaluatorCategoryAssignments) {
      this.evaluatorCategoryAssignments.forEach((evaluatorCategoryAssignment: IEvaluatorCategoryAssignmentAdminModel) => {
        if (this.categoriesMappedById[evaluatorCategoryAssignment.category_id]) {
          this.$set(this.selectedCategories, this.selectedCategories.length, this.categoriesMappedById[evaluatorCategoryAssignment.category_id])
        }
      })
    }
  }
}
