import Component, { mixins } from 'vue-class-component'
import { EmailStatusType, IEmailStatusModel } from './EmailStatus.model'

@Component
export default class EmailStatusMixin extends mixins() {
  protected mixin_emailStatus_computeStatusColor(emailStatus: IEmailStatusModel): string {
    switch (emailStatus.status) {
      case EmailStatusType.QUEUED:
        return 'grey'
      case EmailStatusType.ERROR:
        return '#e30513'
      case EmailStatusType.SENT:
        return '#00c77f'
      default:
        return 'grey'
    }
  }

  protected mixin_emailStatus_computeStatusIcon(emailStatus: IEmailStatusModel): string | null {
    switch (emailStatus.status) {
      case EmailStatusType.QUEUED:
        return 'm-svg__clock'
      case EmailStatusType.ERROR:
        return 'm-svg__error'
      case EmailStatusType.SENT:
        return 'm-svg__confirmation'
      default:
        return null
    }
  }

  protected mixin_emailStatus_computeStatusIconStyles(emailStatus: IEmailStatusModel): object {
    return {
      'color': this.mixin_emailStatus_computeStatusColor(emailStatus)
    }
  }

  protected mixin_emailStatus_computeStatusLabel(status: EmailStatusType | string): string | null {
    return this.$i18next.t(`modules.global.email.EmailStatus.status.${status}`)
  }
}