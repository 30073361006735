<m-overlay
  id="application-form-management__application-form-deposit-deadline-extension-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
>
  <template #header>
    <h2>
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormDepositDeadlineExtensionOverlay.overlay_title') }}
    </h2>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.application-form-management.application-form.ApplicationFormDepositDeadlineExtensionOverlay.message.success.title')"
      />
    </template>
    <template v-else>
      <m-form :form-group="formGroup">
        <div class="application-form-management__application-form-deposit-deadline-extension-overlay--row">
          <div class="application-form-management__application-form-deposit-deadline-extension-overlay--column-half pr-2">
            <m-datepicker
              v-model="dateField.value"
              v-m-control="dateField"
              :label="$i18next.t('modules.application-form-management.application-form.ApplicationFormDepositDeadlineExtensionOverlay.field.date.label')"
              max-width="none"
              :error="dateField.hasError()"
              :error-message="dateField.errorMessage"
              :requiredMarker="true"
            />
          </div>
          <div class="application-form-management__application-form-deposit-deadline-extension-overlay--column-half pl-2">
            <m-timepicker
              v-model="timeField.value"
              v-m-control="timeField"
              :label="$i18next.t('modules.application-form-management.application-form.ApplicationFormDepositDeadlineExtensionOverlay.field.time.label')"
              max-width="none"
              :error="timeField.hasError()"
              :error-message="timeField.errorMessage"
              :requiredMarker="true"
            />
          </div>
        </div>
      </m-form>
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="!showSuccessMessage">
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormDepositDeadlineExtensionOverlay.button.save.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormDepositDeadlineExtensionOverlay.button.cancel.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="closeOverlay"
        >
          {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormDepositDeadlineExtensionOverlay.button.close.label') }}
        </m-button>
      </template>
    </transition>
  </template>
</m-overlay>