import Component, { mixins } from 'vue-class-component'

import WithRender from './ApplicationFormSupportForm.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MIconButton,
  MIconButtonSkin,
  MTextfield,
  MTextfieldType,
  MForm,
  RequiredValidator,
  EmailValidator,
} from '@ulaval/modul-components'
import { Prop } from 'vue-property-decorator'

export interface IApplicationFormSupportFormPayloadData {
  supporter_name: string;
  supporter_email: string;
}

export enum ApplicationFormSupportForm_EVENTS {
  ADD_ICON_CLICK = 'add-icon:click',
  DELETE_ICON_CLICK = 'delete-icon:click'
}

@WithRender
@Component({
  components: {
    MForm,
    MIconButton,
    MTextfield,
  }
})
export default class ApplicationFormSupportForm extends mixins() {
  public formGroup: FormGroup = new FormGroup({
    supporterName: new FormControl<string>([RequiredValidator()]),
    supporterEmail: new FormControl<string>([RequiredValidator(), EmailValidator()]),
  })

  private readonly MIconButtonSkin: object = MIconButtonSkin
  private readonly MTextfieldType: object = MTextfieldType

  @Prop({ default: false })
  private isAddIconDisplayed!: boolean

  @Prop({ default: false })
  private isDeleteIconDisplayed!: boolean

  @Prop({ default: false })
  private isAddIconColumnDisplayed!: boolean

  @Prop({ default: false })
  private isDeleteIconColumnDisplayed!: boolean

  public get supporterNameField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('supporterName');
  }

  public get supporterEmailField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('supporterEmail');
  }

  public async getFormPayload(): Promise<IApplicationFormSupportFormPayloadData | null> {
    await this.formGroup.submit()

    if (this.formGroup.hasErrorDeep()) {
      return null
    }

    const formPayloadData: IApplicationFormSupportFormPayloadData = {
      supporter_name: this.supporterNameField.value,
      supporter_email: this.supporterEmailField.value
    }

    return formPayloadData
  }

  private onAddIconClick(): void {
    this.$emit(ApplicationFormSupportForm_EVENTS.ADD_ICON_CLICK)
  }

  private onDeleteIconClick(): void {
    this.$emit(ApplicationFormSupportForm_EVENTS.DELETE_ICON_CLICK)
  }
}
