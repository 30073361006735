import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './FormTemplateUpdate.html'

import { 
  MButton,
  MButtonSkin,
  MDialog,
  MMessage,
  MMessageState,
  MMessageSkin,
  MPanel,
  MToast,
  MToastPosition,
  MToastState,
  MToastTimeout,
} from '@ulaval/modul-components'

import FormTemplateUpdateDropZone from './FormTemplateUpdateDropZone'
import FormTemplateUpdateElementPanel from './FormTemplateUpdateElementPanel'

import FormTemplateService from '../FormTemplate.service'
import { IInternalFormTemplateElementModel, IInternalFormTemplateModel } from '../FormTemplate.model'

import ROUTES from '@/router/ROUTES'
import { EventBus, EVENT_BUS_EVENTS, IFormTemplateElementUpdatedEventPayload } from '@/modules/global/utilities/Events'
import { AxiosError } from 'axios'

@WithRender
@Component({
  components: {
    FormTemplateUpdateDropZone,
    FormTemplateUpdateElementPanel,
    MButton,
    MDialog,
    MMessage,
    MPanel,
    MToast
  }
})
export default class FormTemplateUpdate extends mixins(FormTemplateService) {
  private isFormTemplateUpdateStateActive: boolean = false
  private isFormTemplateUpdateSuccessToastActive: boolean = false
  private isFormTemplateUpdateErrorToastActive: boolean = false
  private requestErrors: { [key: string]: string } = {}

  private readonly MButtonSkin: any = MButtonSkin
  private readonly MToastTimeout: any = MToastTimeout
  private readonly MToastPosition: any = MToastPosition
  private readonly MToastState: any = MToastState
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin

  @Prop({ required: true })
  private formTemplate!: IInternalFormTemplateModel

  @Watch('formTemplate', { immediate: true })
  private onFormTemplateChange(value: IInternalFormTemplateModel): void {
    if (!value.is_updateable) {
      this.mixin_router_navigate({
        name: ROUTES.FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_PREVIEW,
        params: {
          idCategory: value.category.id,
          idFormTemplate: value.id
        }
      })
    }
  }

  private created(): void {
    this.loadAvailableElements()
  }

  private mounted(): void {
    this.onFormTemplateElementDelete()
    this.onFormTemplateElementUpdate()
  }

  private onFormTemplateElementDelete(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_DELETED, (elementToDelete: IInternalFormTemplateElementModel) => {
      if (elementToDelete) {
        this.$delete(this.requestErrors, elementToDelete.internal_identifier)
      }
    })
  }

  private onFormTemplateElementUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_UPDATED, (eventPayload: IFormTemplateElementUpdatedEventPayload) => {
      if (eventPayload) {
        this.$delete(this.requestErrors, eventPayload.elementInternalIdentifier)
      }
    })
  }

  private updateFormTemplate(): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      if (this.formTemplate) {
        this.isFormTemplateUpdateStateActive = true
        this.isFormTemplateUpdateSuccessToastActive = false
        this.isFormTemplateUpdateErrorToastActive = false

        this.service_formTemplateService_update(this.formTemplate)
          .then(() => {
            this.isFormTemplateUpdateSuccessToastActive = true
            this.$forceUpdate()
            resolve()
          }).catch((error: AxiosError<any>) => {
            this.isFormTemplateUpdateErrorToastActive = true

            if (
              error.response &&
              error.response.data &&
              error.response.data.validation
            ) {
              this.mapRequestErrors(error.response.data.validation)
            }

            reject(error)
          }).finally(() => {
            this.isFormTemplateUpdateStateActive = false
          })
      } else {
        resolve()
      }
    })
  }

  private loadAvailableElements(): void {
    this.service_formTemplateService_fetchAvailableElements()
  }

  private onPreviewButtonClick(): void {
    this.updateFormTemplate()
      .then(() => {
        this.mixin_router_navigate({
          name: ROUTES.FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_PREVIEW,
          params: this.$route.params
        })
      })
  }

  private navigateToCategoriesList(): void {
    this.mixin_router_navigate({
      name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_LIST
    })
  }

  private onFormTemplateUpdateSuccessToastClose(): void {
    this.isFormTemplateUpdateSuccessToastActive = false
  }

  private onFormTemplateUpdateErrorToastClose(): void {
    this.isFormTemplateUpdateErrorToastActive = false
  }

  private mapRequestErrors(errors: object): void {
    this.requestErrors = {}

    Object.entries(errors || {}).forEach(([key, value]: [string, string]) => {
      const elementPositionIdentifiers: Array<string> = Array.from(key.matchAll(/\[(.*?)\]/g), (match: any) => match[1])
      const internalIdentifierFromErrorIdentifiers: string = elementPositionIdentifiers.reduce((internalIdentifier: string, errorIdentifier: string) => {
        if (internalIdentifier) {
          return `${internalIdentifier}-${errorIdentifier}`
        }

        return errorIdentifier
      })

      if (key.includes("element_options")) {
        const parentInternalIdentifier: string = internalIdentifierFromErrorIdentifiers.substring(0, internalIdentifierFromErrorIdentifiers.lastIndexOf('-'))
        this.requestErrors[parentInternalIdentifier] = 'FormTemplateElementOptionConstraint'
        this.requestErrors[internalIdentifierFromErrorIdentifiers] = 'FormTemplateElementOptionConstraint'
      } else {
        this.requestErrors[internalIdentifierFromErrorIdentifiers] = value
      }
    })
  }
}