<div class="administrator-menu__wrapper">
  <m-popup id="administrator-menu">
    <template #trigger>
      <m-icon-button
        icon-name="pd-svg__gear"
        icon-size="28px"
      />
    </template>
    <template #header>
      <p>
        <strong>
          {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.popup_title') }}
        </strong>
      </p>
    </template>
    <template v-if="mixin_userrole_hasAdministratorRole">
      <m-button
        icon-name="m-svg__profile"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageUsersOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.user_management.label') }}
      </m-button>
    </template>
    <template v-if="mixin_userrole_hasGestionnaireCategorieRole">
      <m-button
        icon-name="m-svg__list"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageCategoriesOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.category_management.label') }}
      </m-button>
    </template>
    <template v-if="mixin_userrole_hasGestionnaireGabaritCourrielRole">
      <m-button
        icon-name="m-svg__email"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageEmailTemplatesOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.email_template_management.label') }}
      </m-button>
    </template>
    <template v-if="mixin_userrole_hasGestionnaireCandidatureRole">
      <m-button
        icon-name="pd-svg__folder"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageApplicationFormsOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.application_form_management.label') }}
      </m-button>
    </template>
    <template v-if="mixin_userrole_hasGestionnaireAppuiCandidatureRole">
      <m-button
        icon-name="m-svg__evaluation"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageApplicationFormSupportsOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.application_form_support_management.label') }}
      </m-button>
    </template>
    <template v-if="mixin_userrole_hasGestionnaireValidationCandidatureRole">
      <m-button
        icon-name="m-svg__confirmation"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageApplicationFormValidationsOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.application_form_validation_management.label') }}
      </m-button>
    </template>
    <template v-if="mixin_userrole_hasGestionnaireEvaluateurRole">
      <m-button
        icon-name="m-svg__grouping"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageEvaluatorsOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.evaluators_management.label') }}
      </m-button>
    </template>
    <template v-if="mixin_userrole_hasGestionnaireSectionPubliqueRole">
      <m-button
        icon-name="m-svg__information"
        icon-size="26px"
        :full-size="true"
        :skin="MButtonSkin.Secondary"
        @click="onManageYearConfigurationsOptionClick"
      >
        {{ $i18next.t('modules.global.navigation-bar.AdministratorMenu.options.year_configurations.label') }}
      </m-button>
    </template>
  </m-popup>
</div>