import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import WithRender from './EvaluationCardDepositOverlay.html'

import { 
  MButton,
  MButtonSkin,
  MForm,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay,
  MProgress,
  MValidationMessage,
  FormGroup
} from '@ulaval/modul-components'

import UploadedFileRow from '@/modules/global/file/UploadedFileRow'

import { ICategoryModel } from '../../Category.model'
import CategoryService from '../../Category.service'
import ROUTES from '@/router/ROUTES'
import { IEvaluationCardModel } from '../EvaluationCard.model'
import { IFileUploadModel } from '@/modules/global/file/File.model'
import { MFile } from '@ulaval/modul-components/dist/utils/file/file'

@WithRender
@Component({
  components: {
    MButton,
    MForm,
    MIcon,
    MMessage,
    MOverlay,
    MProgress,
    MValidationMessage,
    UploadedFileRow
  }
})
export default class EvaluationCardDepositOverlay extends mixins(CategoryService) {
  public formGroup: FormGroup = new FormGroup({
    
  })
  private file: IFileUploadModel<IEvaluationCardModel> | null = null
  protected errorMessage: string | null = null
  private isOverlayOpen: boolean = true
  private isLoadingStateActive: boolean = false
  private isCategoryFetchLoadingStateActive: boolean = false
  private showSubmitSuccessMessage: boolean = false
  private showSubmitErrorMessage: boolean = false
  private category: ICategoryModel | null = null

  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly allowedExtensions: Array<string> = ['pdf']

  @Watch('$route.params.idCategory', { immediate: true })
  private onCategoryIdRouteParameterChange(value: string): void {
    this.loadCategory(value)
  }

  @Watch('file')
  private onFileChange(): void {
    if (!this.file) {
      this.errorMessage = this.$i18next.t(
        'modules.category-management.evaluation-card-management.evaluation-card-deposit.EvaluationCardDepositOverlay.validation.required'
      )
    } else {
      this.errorMessage = null
    }
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_LIST
    })
  }

  private async onSubmitButtonClick(): Promise<void> {
    if (this.validate()) {
      this.updateCategory()
    }
  }

  private loadCategory(id: string): void {
    this.isCategoryFetchLoadingStateActive = true

    this.service_categoryService_fetch(id)
      .then((responseData: ICategoryModel) => {
        this.category = responseData || null
        
        if (this.category.evaluation_card) {
          this.file = {
            server_file: this.category.evaluation_card
          }
        }
      }).catch(() => {
        this.category = null
      }).finally(() => {
        this.isCategoryFetchLoadingStateActive = false
      })
  }

  private updateCategory(): void {
    if (this.category && this.file) {
      this.isLoadingStateActive = true

      this.service_categoryService_evaluationCardDeposit(this.category.id, this.file)
      .then(() => {
        this.showSubmitSuccessMessage = true
        this.showSubmitErrorMessage = false
      }).catch(() => {
        this.showSubmitSuccessMessage = false
        this.showSubmitErrorMessage = true
      }).finally(() => {
        this.isLoadingStateActive = false
      })
    }
  }

  private onFilesReady(files: Array<MFile>): void {
    this.$file.uploadTemp(files)
  }

  private onFilesUploadCompleted(files: Array<MFile>): void {
    if (files[0]) {
      this.file = {
        file: files[0]
      }
    } else {
      this.file = null
    }
  }

  private removeFile(): void {
    this.file = null
  }

  private validate(): boolean {
    this.errorMessage = null
    
    if (!this.file) {
      this.errorMessage = this.$i18next.t('modules.category-management.evaluation-card-management.evaluation-card-deposit.EvaluationCardDepositOverlay.validation.required')
    }

    return this.mixin_string_isEmpty(this.errorMessage)
  }
}
