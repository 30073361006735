import { Moment } from 'moment'
import Component, { mixins } from 'vue-class-component'

export interface ITimestampMixin {
  mixin_timestamp_pretty(timestamp: string): string;
  mixin_timestamp_prettyDate(date: string): string;
  mixin_timestamp_prettyTime(time: string): string;
  mixin_timstamp_dateAndTimeToTimestamp(date: string, time: string): string;
  mixin_timestamp_extractDate(timestamp: string): string;
  mixin_timestamp_extractTime(timestamp: string): string;
}

@Component
export default class TimestampMixin extends mixins() implements ITimestampMixin {
  public mixin_timestamp_pretty(timestamp: string): string {
    if (!timestamp) {
      return ''
    }

    return this.$i18next.t('modules.global.utilities.TimestampMixin.mixin_timestamp_prettyTimestamp', {
      date: this.mixin_timestamp_prettyDate(timestamp),
      time: this.mixin_timestamp_prettyTime(timestamp)
    })
  }

  public mixin_timestamp_prettyDate(date: string): string {
    const momentTimestamp: Moment = this.$moment(date)

    return momentTimestamp.format('Do MMMM YYYY')
  }

  public mixin_timestamp_prettyTime(time: string): string {
    const momentTimestamp: Moment = this.$moment(time)

    return this.$i18next.t('modules.global.utilities.TimestampMixin.mixin_timestamp_prettyTime', {
      hour: momentTimestamp.format('HH'),
      minute: momentTimestamp.format('mm')
    })
  }

  public mixin_timstamp_dateAndTimeToTimestamp(date: string, time: string): string {
    if (!date || !time) {
      return ''
    }
    
    return `${date}T${time}`
  }

  public mixin_timestamp_extractDate(timestamp: string): string {
    if (!timestamp) {
      return ''
    }

    const momentTimestamp: Moment = this.$moment(timestamp)

    return momentTimestamp.format('YYYY-MM-DD')
  }

  public mixin_timestamp_extractTime(timestamp: string): string {
    if (!timestamp) {
      return ''
    }
    
    const momentTimestamp: Moment = this.$moment(timestamp)

    return momentTimestamp.format('HH:mm')
  }
}
