import { IBaseModel } from "@/modules/global/utilities/Base.model";
import { IApplicationFormSupportModel } from "../application-form-support-management/ApplicationFormSupport.model";
import { IEmailStatusModel } from "../global/email/EmailStatus.model";
import { IUserModel } from "../user-management/User.model";

export enum ApplicationFormStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  INACTIVE = 'INACTIVE',
  OVERDUE = 'OVERDUE'
}

export interface IApplicationFormModel extends IBaseModel {
  candidate_full_name_with_username: string;
  category_title: string;
  category_application_deposit_limit_timestamp: string;
  is_deleteable: boolean;
  is_restorable: boolean;
  is_soft_deleteable: boolean;
  is_updateable: boolean;
  requester_full_name_with_username: string;
  status: ApplicationFormStatus;
  deposit_deadline_extension_timestamp?: string;
  category_application_support_submit_limit_timestamp: string;
  is_application_form_support_submittable: boolean;
  application_form_supports: Array<IApplicationFormSupportModel>;
  category_application_support_count_lower_bound?: number;
  category_application_support_count_upper_bound?: number;
  category_application_support_creation_dialog_message?: string;
  is_application_form_support_count_under_threshold: boolean;
}

export interface IAdminApplicationFormModel extends IApplicationFormModel {
  is_deleteable_admin: boolean;
  reminder_emails_status: Array<IEmailStatusModel>;
  support_reminder_emails_status: Array<IEmailStatusModel>;
  support_submit_over_threshold_email_status: Array<IEmailStatusModel>;
  application_form_created_email_status: Array<IEmailStatusModel>;
  application_form_submitted_email_status: Array<IEmailStatusModel>;
  authorized_users: Array<IUserModel>;
}