<section class="display--flex flex__grow--1">
  <template v-if="isUserEvaluator">
    <dashboard-evaluator-assignment-management
      :year="year"
      :evaluator-category-assignments="evaluatorCategoryAssignments"
    />
    <dashboard-evaluator-toolbox
      :year="year"
      :evaluator-toolbox-files="evaluatorToolboxFiles"
    />
  </template>
</section>