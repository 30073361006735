<div
  class="width--100"
  :id="internalElement.id"
>
  <template v-if="displayDuplicateAnswers && formElementAnswers.length > 1">
    <template v-for="answerIndex in formElementAnswerCount(internalElement)">
      <m-textfield
        v-bind="assembledElementProps"
        :value="formElementAnswers[answerIndex - 1].value"
        class="form-element"
        :class="elementClasses"
        :style="elementStyles"
        style="max-width: none; padding-left: 3px; padding-right: 3px;"
        :internal-element="internalElement"
        :answers="answers"
        :row-position-index="rowPositionIndex"
        :readonly="readonly"
        :error="!mixin_string_isEmpty(errorMessage)"
        :error-message="errorMessage"
        :display-duplicate-answers="displayDuplicateAnswers"
        @change="onValueChange($event)"
      >
        <template v-for="slot in elementSlots">
          <span :slot="slot.frontend_key || slot.key">
            <span v-html="slot.value" />
          </span>
        </template>
        <template v-if="componentHasOptions">
          <template v-for="option in internalElement.element_options">
            <div>
              <component
                :is="internalElement.option_component_name"
                :value="option.value"
              >
                {{ option.value }}
              </component>
            </div>
          </template>
        </template>
        <template v-if="elementContent">
          <span v-html="elementContent" />
        </template>
      </m-textfield>
      <template v-if="formatEmbeddedUrl(formElementAnswers[answerIndex - 1].value)">
        <iframe
          class="mt-4"
          width="100%"
          height="300"
          :src="formatEmbeddedUrl(formElementAnswers[answerIndex - 1].value)"
        />
      </template>
      <template v-else-if="!mixin_string_isEmpty(formElementAnswers[answerIndex - 1].value)">
        <m-link
          :url="formElementAnswers[answerIndex - 1].value"
          class="mt-4"
          mode="link"
          target="_blank"
        >
          {{ $i18next.t('modules.form-management.form-custom-element.embedded-video-player.EmbeddedVideoPlayer.label.link') }}
        </m-link>
      </template>
    </template>
  </template>
  <template v-else>
    <m-textfield
      v-bind="assembledElementProps"
      :value="elementValue"
      class="form-element"
      :class="elementClasses"
      :style="elementStyles"
      style="max-width: none; padding-left: 3px; padding-right: 3px;"
      :internal-element="internalElement"
      :answers="answers"
      :row-position-index="rowPositionIndex"
      :readonly="readonly"
      :error="!mixin_string_isEmpty(errorMessage)"
      :error-message="errorMessage"
      :display-duplicate-answers="displayDuplicateAnswers"
      @change="onValueChange($event)"
    >
      <template v-for="slot in elementSlots">
        <span :slot="slot.frontend_key || slot.key">
          <span v-html="slot.value" />
        </span>
      </template>
      <template v-if="componentHasOptions">
        <template v-for="option in internalElement.element_options">
          <div>
            <component
              :is="internalElement.option_component_name"
              :value="option.value"
            >
              {{ option.value }}
            </component>
          </div>
        </template>
      </template>
      <template v-if="elementContent">
        <span v-html="elementContent" />
      </template>
    </m-textfield>
    <template v-if="formatEmbeddedUrl(elementValue)">
      <iframe
        class="mt-4"
        width="100%"
        height="300"
        :src="formatEmbeddedUrl(elementValue)"
      />
    </template>
    <template v-else-if="!mixin_string_isEmpty(elementValue)">
      <m-link
        :url="elementValue"
        class="mt-4"
        mode="link"
        target="_blank"
        iconName="m-svg__logout"
      >
        {{ $i18next.t('modules.form-management.form-custom-element.embedded-video-player.EmbeddedVideoPlayer.label.link') }}
      </m-link>
    </template>
  </template>
</div>