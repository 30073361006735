<div class="uploaded-file-row__wrapper">
  <div class="uploaded-file-row my-4">
    <div class="uploaded-file-row__icon">
      <m-icon-file
        :extension="fileExtension"
        size="30px"
      />
    </div>
    <div class="uploaded-file-row__informations">
      <p class="uploaded-file-row__informations--name">
        <template v-if="isFileDownloadAvailable">
          <m-link
            :mode="MLinkMode.Button"
            @click="downloadFile"
          >
            {{ fileName }}
          </m-link>
        </template>
        <template v-else>
          {{ fileName }}
        </template>
      </p>
      <p class="uploaded-file-row__informations--size">
        <small>
          {{ fileSize | f-m-filesize }}
        </small>
      </p>
    </div>
    <template v-if="!disabled">
      <div class="uploaded-file-row__options">
        <m-icon-button
          icon-name="m-svg__delete"
          button-size="32px"
          @click="onDeleteOptionClick"
        />
      </div>
    </template>
  </div>
  <template v-if="displayDivider">
    <hr />
  </template>
  <a
    :ref="REF_DOWNLOAD_LINK"
    class="display--none"
  />
</div>