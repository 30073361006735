import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'
import HttpStatusCode from 'http-status-codes'

import WithRender from './AdminYearConfiguration.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MSpinner,
  MMessage,
  MMessageState
} from '@ulaval/modul-components'

import YearConfigurationLayout from './YearConfigurationLayout'

import { IYearModel } from '@/modules/global/year/Year.model'
import { IYearConfigurationModel } from '../YearConfiguration.model'
import YearConfigurationService from '../YearConfiguration.service'
import CONSTANTS from '@/CONSTANTS'
import ROUTES from '@/router/ROUTES'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import { generateLocalizedRouteName } from '@/router/helpers'
import localization from '@/localization'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MMessage,
    MSpinner,
    YearConfigurationLayout
  }
})
export default class AdminYearConfiguration extends mixins(YearConfigurationService) {
  private yearConfiguration: IYearConfigurationModel | null = null
  private isLoadingStateActive: boolean = false
  private hasLoadingError: boolean = false

  private readonly MMessageState: object = MMessageState
  private readonly MButtonSkin: object = MButtonSkin

  @Prop({ required: true })
  private year!: IYearModel

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadYearConfiguration()
  }

  private created(): void {
    if (
      this.$route.name === generateLocalizedRouteName({
        name: ROUTES.YEAR_CONFIGURATION_MANAGEMENT_UPDATE,
        locale: localization.CURRENT_LOCALE
      })
    ) {
      this.mixin_router_navigate({
        name: ROUTES.YEAR_CONFIGURATION_MANAGEMENT_LIST
      })
    }
  }

  private mounted(): void {
    this.afterYearConfigurationUpdate()
  }

  private afterYearConfigurationUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.YEAR_CONFIGURATION_UPDATED, (yearConfiguration: IYearConfigurationModel) => {
      if (yearConfiguration) {
        this.yearConfiguration = yearConfiguration
      }
    })
  }

  private loadYearConfiguration(): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)
    this.hasLoadingError = false

    this.service_yearConfigurationService_adminFetchYearConfiguration(this.year.id)
      .then((response: IYearConfigurationModel) => {
        this.yearConfiguration = response || null
      }).catch((error: AxiosError) => {
        if (error.response && error.response.status === HttpStatusCode.NOT_FOUND) {
          this.yearConfiguration = null
          this.hasLoadingError = true
        } else {
          this.mixin_router_redirectRequestError(error)
        }
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private onTriggerUpdateButtonClick(): void {
    if (this.yearConfiguration) {
      this.mixin_router_navigate({
        name: ROUTES.YEAR_CONFIGURATION_MANAGEMENT_UPDATE,
        params: {
          idYearConfiguration: this.yearConfiguration.id
        }
      })
    }
  }
}
