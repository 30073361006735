import Component, { mixins } from 'vue-class-component'

import WithRender from './TextArea.html'

import { 
  MTextarea
} from '@ulaval/modul-components'

import FormElementMixin from '../../form-element/FormElement.mixin'

@WithRender
@Component({
  components: {
    MTextarea
  }
})
export default class TextArea extends mixins(FormElementMixin) {

}