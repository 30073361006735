enum ROLES {
  ADMINISTRATOR = 'administrator',
  GESTIONNAIRE_CATEGORIE = 'gestionnaire-categorie',
  GESTIONNAIRE_GABARIT_COURRIEL = 'gestionnaire-gabarit-courriel',
  GESTIONNAIRE_GABARIT_FORMULAIRE = 'gestionnaire-gabarit-formulaire',
  GESTIONNAIRE_CANDIDATURE = 'gestionnaire-candidature',
  GESTIONNAIRE_APPUI_CANDIDATURE = 'gestionnaire-appui-candidature',
  GESTIONNAIRE_VALIDATION_CANDIDATURE = 'gestionnaire-validation-candidature',
  GESTIONNAIRE_EVALUATEUR = 'gestionnaire-evaluateur',
  GESTIONNAIRE_SECTION_PUBLIQUE = 'gestionnaire-section-publique'
}

export default ROLES