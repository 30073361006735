import { IBaseModel } from "@/modules/global/utilities/Base.model";

export enum ApplicationFormValidationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  VALID = 'VALID',
  INVALID = 'INVALID'
}

export interface IApplicationFormValidationModel extends IBaseModel {
  candidate_full_name_with_username: string;
  category_title: string;
  is_updateable: boolean;
  status: ApplicationFormValidationStatus;
}