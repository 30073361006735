import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import WithRender from './AdminEvaluatorCategoryAssignmentUpdateOverlay.html'

import { 
  MButton,
  MButtonSkin,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay
} from '@ulaval/modul-components'

import { IYearModel } from '@/modules/global/year/Year.model'
import ROUTES from '@/router/ROUTES'
import AdminEvaluatorCategoryAssignmentForm, { IAdminEvaluatorCategoryAssignmentFormFormPayloadData } from '../evaluator-category-assignment-form/AdminEvaluatorCategoryAssignmentForm'
import EvaluatorCategoryAssignmentService, { IUpdateEvaluatorCategoryAssignmentForm } from '../EvaluatorCategoryAssignment.service'
import { ICategoryModel } from '@/modules/category-management/Category.model'
import { IEvaluatorCategoryAssignmentAdminModel } from '../EvaluatorCategoryAsssignment.model'

const FORM_REF: string = 'admin-evaluator-category-assignment-update-form'

@WithRender
@Component({
  components: {
    AdminEvaluatorCategoryAssignmentForm,
    MButton,
    MMessage,
    MOverlay
  }
})
export default class AdminEvaluatorCategoryAssignmentUpdateOverlay extends mixins(EvaluatorCategoryAssignmentService) {
  private isOverlayOpen: boolean = true
  private isLoadingStateActive: boolean = false
  private showUpdateSuccessMessage: boolean = false
  private showUpdateErrorMessage: boolean = false
  private evaluatorCategoryAssignments: Array<IEvaluatorCategoryAssignmentAdminModel> = []

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin
  private readonly FORM_REF: string = FORM_REF

  @Prop({ required: true })
  private year!: IYearModel

  @Ref(FORM_REF)
  private formComponent!: AdminEvaluatorCategoryAssignmentForm

  private get evaluatorIdul(): string | undefined {
    if (this.evaluatorCategoryAssignments.length === 0) {
      return undefined
    }

    return this.evaluatorCategoryAssignments[0].evaluator_username
  }

  private get evaluatorFullNameWithUsername(): string | undefined {
    if (this.evaluatorCategoryAssignments.length === 0) {
      return undefined
    }

    return this.evaluatorCategoryAssignments[0].evaluator_full_name_with_username
  }

  @Watch('year', { immediate: true })
  @Watch('$route.params.idEvaluator')
  private onParametersChange(): void {
    this.loadEvaluatorCategoryAssignments(this.year.id, this.$route.params.idEvaluator)
  }

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.EVALUATOR_MANAGEMENT_LIST
    })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private loadEvaluatorCategoryAssignments(yearId: string, evaluatorId: string): void {
    this.isLoadingStateActive = true

    this.service_evaluatorCategoryAssignmentService_adminFetchEvaluatorCategoryAssignments({
      year_id: yearId,
      evaluator_id: evaluatorId
    }).then((response: Array<IEvaluatorCategoryAssignmentAdminModel>) => {
      this.evaluatorCategoryAssignments = response || []
    }).catch(() => {
      this.evaluatorCategoryAssignments = []
      this.showUpdateErrorMessage = true
    }).finally(() => {
      this.isLoadingStateActive = false
    })
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.formComponent) {
      const formPayload: IAdminEvaluatorCategoryAssignmentFormFormPayloadData | null = await this.formComponent.getFormPayload()

      if (formPayload) {
        this.updateEvaluatorCategoryAssignments(formPayload)
      }
    }
  }

  private updateEvaluatorCategoryAssignments(formPayload: IAdminEvaluatorCategoryAssignmentFormFormPayloadData): void {
    this.isLoadingStateActive = true

    const requestPayload: IUpdateEvaluatorCategoryAssignmentForm = {
      evaluator_idul: formPayload.evaluator_idul,
      categories_id: formPayload.categories.map((category: ICategoryModel) => category.id),
      year_id: this.year.id
    }

    this.service_evaluatorCategoryAssignmentService_updateEvaluatorCategoryAssignment(requestPayload)
      .then(() => {
        this.showUpdateSuccessMessage = true
        this.showUpdateErrorMessage = false
      }).catch(() => {
        this.showUpdateSuccessMessage = false
        this.showUpdateErrorMessage = true
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }
}
