import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import WithRender from './CategorySectionForm.html'

import { 
  MAdd,
  MForm
} from '@ulaval/modul-components'

import CategorySectionFormRow from './CategorySectionFormRow'

import { IYearConfigurationModel } from '@/modules/year-configuration-management/YearConfiguration.model'
import { ICategorySectionFormRowPayloadData } from './CategorySectionFormRow'
import { ICategorySectionModel } from '../CategorySection.model'
import { ICategoryModel } from '@/modules/category-management/Category.model'
import { IYearModel } from '@/modules/global/year/Year.model'
import YearConfigurationService from '@/modules/year-configuration-management/YearConfiguration.service'

export interface ICategorySectionsFormPayloadData {
  category_sections: Array<ICategorySectionFormRowPayloadData>;
}

const REF_CATEGORY_SECTION_FORM_ROW: string = 'category-section-form-row'

@WithRender
@Component({
  components: {
    CategorySectionFormRow,
    MAdd,
    MForm
  }
})
export default class CategorySectionForm extends mixins(YearConfigurationService) {
  private categorySections: Array<ICategorySectionFormRowPayloadData> = []
  private availableCategories: Array<ICategoryModel> = []
  private formErrors: { [key: string]: string | boolean } = {}

  private readonly REF_CATEGORY_SECTION_FORM_ROW: string = REF_CATEGORY_SECTION_FORM_ROW

  @Prop({ required: true })
  private yearConfiguration!: IYearConfigurationModel

  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ default: {} })
  private requestErrors!: { [key: string]: string | boolean }

  @Ref(REF_CATEGORY_SECTION_FORM_ROW)
  private categorySectionFormRowComponents!: Array<CategorySectionFormRow>

  private get errors(): { [key: string]: string | boolean } {
    return {
      ...this.formErrors,
      ...this.requestErrors
    }
  }

  private created(): void {
    this.loadAvailableCategories()
  }

  private mounted(): void {
    this.populateFormFromModel()
  }

  private loadAvailableCategories(): void {
    this.service_yearConfigurationService_fetchAvailableCategories(this.year.id)
      .then((response: Array<ICategoryModel>) => {
        this.availableCategories = response || []
      })
  }

  private populateFormFromModel(): void {
    if (this.yearConfiguration) {
      this.categorySections = this.yearConfiguration.category_sections.reduce(
        (acc: Array<ICategorySectionFormRowPayloadData>, categorySection: ICategorySectionModel) => {
        return [
          ...acc,
          {
            id: categorySection.id,
            title: categorySection.title,
            categories_id: categorySection.categories.map((category: ICategoryModel) => category.id)
          } as ICategorySectionFormRowPayloadData
        ]
      }, [])
    }
  }

  private onAddNewSectionButtonClick(): void {
    this.$set(this.categorySections, this.categorySections.length, {
      title: '',
      categories_id: []
    })
  }

  private onCategorySectionFormRowDeleteEvent(index: number): void {
    this.$delete(this.categorySections, index)
  }

  public async getFormPayload(): Promise<ICategorySectionsFormPayloadData | null> {
    let hasErrors: boolean = false
    const assembledCategorySections: Array<ICategorySectionFormRowPayloadData> = []

    for (const categorySectionFormRowComponent of this.categorySectionFormRowComponents || []) {
      const categorySectionFormRowComponentFormPayload: ICategorySectionFormRowPayloadData | null = await categorySectionFormRowComponent.getFormPayload()

      if (categorySectionFormRowComponentFormPayload) {
        assembledCategorySections.push(categorySectionFormRowComponentFormPayload)
      } else {
        hasErrors = true
      }
    }

    if (hasErrors) {
      return null
    }

    const formPayloadData: ICategorySectionsFormPayloadData = {
      category_sections: assembledCategorySections
    }

    return formPayloadData
  }
}
