<m-overlay
  id="email-template-management__email-template-update-dialog"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
  @save="onSaveButtonClick"
>
  <template #header>
    <h2>
      {{ $i18next.t('modules.email-template-management.email-template-update.EmailTemplateUpdateDialog.overlay_title') }}
    </h2>
    <template v-if="originalEmailTemplate">
      <h6 class="mt-3">
        {{ originalEmailTemplate.slug }}
      </h6>
    </template>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="isEmailTemplateFetchLoadingStateActive">
      <div class="email-template-management__email-template-update-dialog--loading-overlay">
        <m-progress
          class="mx-auto"
          :indeterminate="true"
          :circle="true"
        />
        <p>
          <strong>
            {{ $i18next.t('modules.email-template-management.email-template-update.EmailTemplateUpdateDialog.loading_overlay_message') }}
          </strong>
        </p>
      </div>
    </template>
    <template v-else-if="showUpdateSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.email-template-management.email-template-update.EmailTemplateUpdateDialog.message.update_success.title')"
      />
    </template>
    <template v-else-if="originalEmailTemplate">
      <email-template-form
        :ref="FORM_REF"
        :request-errors="requestErrors"
        :email-template="originalEmailTemplate"
      />
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="!showUpdateSuccessMessage">
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.email-template-management.email-template-update.EmailTemplateUpdateDialog.button.save.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.email-template-management.email-template-update.EmailTemplateUpdateDialog.button.cancel.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="closeOverlay"
        >
          {{ $i18next.t('modules.email-template-management.email-template-update.EmailTemplateUpdateDialog.button.close.label') }}
        </m-button>
      </template>
    </transition>
  </template>
</m-overlay>