<m-form
  :form-group="formGroup"
  class="year-configuration-management__year-configuration-form"
>
  <div class="year-configuration-management__year-configuration-form--row">
    <div class="year-configuration-management__year-configuration-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.label.general_informations') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="year-configuration-management__year-configuration-form--row">
    <div class="year-configuration-management__year-configuration-form--column">
      <m-checkbox
        v-model="isPublicAreaAvailableCheckbox.value"
        v-m-control="isPublicAreaAvailableCheckbox"
        :error="isPublicAreaAvailableCheckbox.hasError()"
        :error-message="isPublicAreaAvailableCheckbox.errorMessage"
      >
        {{ $i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.checkbox.is_public_area_available.label') }}
      </m-checkbox>
    </div>
    <div class="year-configuration-management__year-configuration-form--column mt-2">
      <m-message
        :state="MMessageState.Information"
        :skin="MMessageSkin.Light"
      >
        <span v-html="$i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.label.publication_setting_help')" />
      </m-message>
    </div>
  </div>
  <div class="year-configuration-management__year-configuration-form--row mt-6">
    <div class="year-configuration-management__year-configuration-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.label.description_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="year-configuration-management__year-configuration-form--row">
    <div class="year-configuration-management__year-configuration-form--column mt-2">
      <m-textfield
        v-model="publicAreaDescriptionZoneTitleField.value"
        v-m-control="publicAreaDescriptionZoneTitleField"
        :error="publicAreaDescriptionZoneTitleField.hasError()"
        :error-message="publicAreaDescriptionZoneTitleField.errorMessage"
        :label="$i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.field.public_area_description_zone_title.label')"
        :requiredMarker="true"
        max-width="none"
      />
    </div>
    <div class="year-configuration-management__year-configuration-form--column">
      <rich-text-editor
        :label="$i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.field.public_area_description_zone.label')"
        :ref="REF_PUBLIC_AREA_DESCRIPTION_ZONE"
        v-model="publicAreaDescriptionZoneModel"
      />
    </div>
  </div>
  <div class="year-configuration-management__year-configuration-form--row mt-12">
    <div class="year-configuration-management__year-configuration-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.label.help_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="year-configuration-management__year-configuration-form--row">
    <div class="year-configuration-management__year-configuration-form--column mt-2">
      <m-textfield
        v-model="publicAreaHelpZoneTitleField.value"
        v-m-control="publicAreaHelpZoneTitleField"
        :error="publicAreaHelpZoneTitleField.hasError()"
        :error-message="publicAreaHelpZoneTitleField.errorMessage"
        :label="$i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.field.public_area_help_zone_title.label')"
        :requiredMarker="true"
        max-width="none"
      />
    </div>
    <div class="year-configuration-management__year-configuration-form--column">
      <rich-text-editor
        :label="$i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.field.public_area_help_zone.label')"
        :ref="REF_PUBLIC_AREA_HELP_ZONE"
        v-model="publicAreaHelpZoneModel"
      />
    </div>
  </div>
  <div class="year-configuration-management__year-configuration-form--row mt-12">
    <div class="year-configuration-management__year-configuration-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.year-configuration-management.year-configuration-form.YearConfigurationForm.label.category_sections') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="year-configuration-management__year-configuration-form--row mt-4 pb-8">
    <div class="year-configuration-management__year-configuration-form--column">
      <category-section-form
        :ref="REF_CATEGORY_SECTION_FORM"
        :year-configuration="yearConfiguration"
        :request-errors="requestErrors"
        :year="year"
      />
    </div>
  </div>
</m-form>