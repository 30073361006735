<div :id="internalElement.id">
  <m-steppers>
    <template v-for="children in internalElement.childrens">
      <form-element
        :internal-element="children"
        :answers="answers"
        :readonly="readonly"
        :display-duplicate-answers="displayDuplicateAnswers"
        :hide-sensitive-informations="hideSensitiveInformations"
        :display-evaluator-additionnal-informations="displayEvaluatorAdditionnalInformations"
      />
    </template>
  </m-steppers>
  <template v-for="children, index in internalElement.childrens">
    <transition name="fade-out-in">
      <div v-show="children.id === selectedStepWizardIdentifier">
        <template v-for="stepChildren in children.childrens">
          <form-element
            :internal-element="stepChildren"
            :answers="answers"
            :readonly="readonly"
            :display-duplicate-answers="displayDuplicateAnswers"
            :hide-sensitive-informations="hideSensitiveInformations"
            :display-evaluator-additionnal-informations="displayEvaluatorAdditionnalInformations"
          />
        </template>
        <div
          style="max-width: none; padding-left: 6px; padding-right: 6px;"
          class="mt-8 display--flex"
        >
          <template v-if="index > 0">
            <m-button
              :skin="MButtonSkin.Secondary"
              @click="onPreviousStepButtonClick(index)"
            >
              {{ $i18next.t('modules.form-management.form-custom-element.step-wizard-step.StepWizard.button.previous_step.label') }}
            </m-button>
          </template>
          <template v-if="index < internalElement.childrens.length - 1">
            <m-button
              :skin="MButtonSkin.Secondary"
              class="ml-auto"
              @click="onNextStepButtonClick(index)"
            >
              {{ $i18next.t('modules.form-management.form-custom-element.step-wizard-step.StepWizard.button.next_step.label') }}
            </m-button>
          </template>
        </div>
      </div>
    </transition>
  </template>
</div>