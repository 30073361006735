import Component, { mixins } from 'vue-class-component'

import WithRender from './FormElement.html'

import { 
  MAccordion,
  MAccordionGroup,
  MButton,
  MCheckbox,
  MDatepicker,
  MDropdown,
  MDropdownItem,
  MFileUpload,
  MIconButton,
  MIconButtonSkin,
  MMessage,
  MRadio,
  MSteppers,
  MSteppersItem,
  MSwitch,
  MTimepicker
} from '@ulaval/modul-components'

import StepWizard from '../form-custom-element/step-wizard-step/StepWizard'
import StepWizardStep from '../form-custom-element/step-wizard-step/StepWizardStep'
import FileUpload from '@/modules/form-management/form-custom-element/file-upload/FileUpload'
import MultiAnswerRow from '../form-custom-element/multi-answer-row/MultiAnswerRow'
import FormElementMixin from './FormElement.mixin'
import RadioGroup from '../form-custom-element/radio-group/RadioGroup'
import TextField from '../form-custom-element/text-field/TextField'
import TextArea from '../form-custom-element/text-area/TextArea'
import EmbeddedVideoPlayer from '../form-custom-element/embedded-video-player/EmbeddedVideoPlayer'

@WithRender
@Component({
  components: {
    EmbeddedVideoPlayer,
    FileUpload,
    FormElement: () => import('./FormElement'),
    MAccordion,
    MAccordionGroup,
    MButton,
    MCheckbox,
    MDatepicker,
    MDropdown,
    MDropdownItem,
    MFileUpload,
    MIconButton,
    MMessage,
    MRadio,
    MSteppers,
    MSteppersItem,
    MSwitch,
    MTimepicker,
    MultiAnswerRow,
    RadioGroup,
    StepWizard,
    StepWizardStep,
    TextArea,
    TextField
  }
})
export default class FormElement extends mixins(FormElementMixin) {
  private readonly MIconButtonSkin: object = MIconButtonSkin
}