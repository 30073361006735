import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import WithRender from './AdminApplicationFormSupport.html'

import { 
  MButton,
  MDialog,
  MDialogState,
  MDialogWidth,
  MIcon,
  MMessage,
  MMessageState,
  MPanel,
  MSpinner,
  MToast,
  MToastDuration,
  MToastPosition,
  MToastState,
  MToastTimeout,
} from '@ulaval/modul-components'

import Breadcrumbs from '@/modules/global/breadcrumbs/Breadcrumbs'
import FormElements from '@/modules/form-management/form-element/FormElements'

import { IFormModel } from '@/modules/form-management/Form.model'
import { IInternalFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'
import { IApplicationFormSupportModel } from '../ApplicationFormSupport.model'
import ApplicationFormSupportService, { IFetchApplicationFormSupportResponse } from '../ApplicationFormSupport.service'
import ApplicationFormSupportMixin from '../ApplicationFormSupport.mixin'
import { IFormAnswerForm } from '@/modules/form-management/Form.service'

const FORM_ELEMENTS_REF = 'form-elements'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    FormElements,
    MButton,
    MDialog,
    MIcon,
    MMessage,
    MPanel,
    MSpinner,
    MToast
  }
})
export default class AdminApplicationFormSupport extends mixins(ApplicationFormSupportService, ApplicationFormSupportMixin) {
  private applicationFormSupport: IApplicationFormSupportModel | null = null
  private form: IFormModel | null = null
  private internalFormTemplate: IInternalFormTemplateModel | null = null
  private isLoadingStateActive: boolean = true
  private isSubmitConfirmationDialogActive: boolean = false
  private isSubmitSuccessToastOpen: boolean = false
  private isSubmitErrorToastOpen: boolean = false
  private isValidationErrorToastOpen: boolean = false

  private readonly FORM_ELEMENTS_REF: string = FORM_ELEMENTS_REF
  private readonly MMessageState: object = MMessageState
  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout

  @Ref(FORM_ELEMENTS_REF)
  private formElementsComponent!: FormElements

  private get submitDialogState(): MDialogState {
    if (this.isSubmitErrorToastOpen) {
      return MDialogState.Error
    } else if (this.isSubmitSuccessToastOpen) {
      return MDialogState.Confirmation
    }

    return MDialogState.Warning
  }

  @Watch('$route.params.idApplicationFormSupport', { immediate: true })
  private onRouteParamdIdChange(value: string | null) {
    if (value) {
      this.loadApplicationFormSupport(value)
    }
  }

  private loadApplicationFormSupport(id: string): void {
    if (id) {
      this.isLoadingStateActive = true
  
      this.service_applicationFormSupportService_fetchAdminApplicationFormSupport(id)
        .then((response: IFetchApplicationFormSupportResponse) => {
          this.applicationFormSupport = response.application_form_support || null
          this.form = response.form || null
          this.internalFormTemplate = response.internal_form_template || null
        }).catch(() => {
          this.applicationFormSupport = null
          this.form = null
          this.internalFormTemplate = null
        }).finally(() => {
          this.isLoadingStateActive = false
        })
    }
  }

  private async onSubmitButtonClick(): Promise<void> {
    const answers: Array<IFormAnswerForm> | null = await this.formElementsComponent.getElementAnswers(false)

    if (answers) {
      this.submitApplicationForm(answers)
    }
  }

  private submitApplicationForm(answers: Array<IFormAnswerForm>): void {
    if (this.applicationFormSupport) {
      this.isLoadingStateActive = true

      this.service_applicationFormSupportService_saveAdmin(this.applicationFormSupport.id, answers)
        .then(() => {
          this.loadApplicationFormSupport(this.$route.params.idApplicationFormSupport)

          this.isSubmitSuccessToastOpen = true
          this.isSubmitErrorToastOpen = false
        }).catch(() => {
          this.isSubmitSuccessToastOpen = false
          this.isSubmitErrorToastOpen = true
          this.isLoadingStateActive = false
        }).finally(() => {
          setTimeout(() => {
            this.isSubmitSuccessToastOpen = false
          }, MToastDuration.DesktopXShort)
          setTimeout(() => {
            this.isSubmitErrorToastOpen = false
          }, MToastDuration.DesktopXShort)
        })
    }
  }

  private async displaySubmitConfirmationDialog(): Promise<void> {
    this.isSubmitConfirmationDialogActive = true
  }

  private hideSubmitConfirmationDialog(): void {
    this.isSubmitConfirmationDialogActive = false
  }
}
