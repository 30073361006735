<div :id="internalElement.id">
  <template v-if="displayDuplicateAnswers && formElementAnswers.length > 1">
    <ul
      class="form-element"
      :class="elementClasses"
      :style="elementStyles"
      style="max-width: none; padding-left: 3px; padding-right: 3px;"
    >
      <template v-for="option in internalElement.element_options">
        <li>
          {{ option.value }}
          <template v-if="optionAnswerCount(option)">
            ({{ optionAnswerCount(option) }})
          </template>
        </li>
      </template>
    </ul>
  </template>
  <template v-else>
    <m-radio-group
      v-bind="assembledElementProps"
      :value="elementValue"
      class="form-element"
      :class="elementClasses"
      :style="elementStyles"
      style="max-width: none; padding-left: 3px; padding-right: 3px;"
      :internal-element="internalElement"
      :answers="answers"
      :row-position-index="rowPositionIndex"
      :readonly="readonly"
      :error="!mixin_string_isEmpty(errorMessage)"
      :error-message="errorMessage"
      :display-duplicate-answers="displayDuplicateAnswers"
      @change="onValueChange($event)"
    >
      <template v-for="slot in elementSlots">
        <span :slot="slot.frontend_key || slot.key">
          <span v-html="slot.value" />
        </span>
      </template>
      <template v-for="option in internalElement.element_options">
        <div>
          <component
            :is="internalElement.option_component_name"
            :value="option.value"
          >
            {{ option.value }}
          </component>
        </div>
      </template>
    </m-radio-group>
  </template>
</div>