import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './YearConfigurationDescriptionAreaPanel.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import { IYearConfigurationModel } from '../YearConfiguration.model'
import { IYearModel } from '@/modules/global/year/Year.model'


@WithRender
@Component({
  components: {
    MPanel
  }
})
export default class YearConfigurationDescriptionAreaPanel extends mixins() {
  @Prop({ required: true })
  private yearConfiguration!: IYearConfigurationModel

  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ 
    required: false, 
    default: false 
  })
  private isPreview!: boolean
}
