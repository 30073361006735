<article class="user-management__users">
  <m-table-header>
    <template #left>
      <m-button @click="onCreateUserButtonClick">
        {{ $i18next.t('modules.user-management.users.Users.button.create_user.label') }}
      </m-button>
    </template>
    <template #right>
      <m-searchfield
        v-model="searchField.value"
        v-m-control="searchField"
        :label="$i18next.t('modules.user-management.users.Users.search_field.label')"
        :label-up="true"
        :placeholder="$i18next.t('modules.user-management.users.Users.search_field.placeholder')"
        @search="onSearchFieldChange"
        @empty-field="onSearchFieldChange"
      />
    </template>
  </m-table-header>
  <m-table
    :columns="tableColumns"
    :loading="isLoadingStateActive"
    :rows="users"
  >
    <template #body.options="{ data }">
      <m-option>
        <m-option-item-edit @click="onUserUpdateOptionClick(data)" />
      </m-option>
    </template>
  </m-table>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="paginationData">
      <m-pagination
        :items-per-page="paginationData.element_count_per_page"
        :items-total="paginationData.total_element_count"
        :value="paginationData.current_page_index + 1"
        @change="onPageChange($event)"
      />
    </template>
  </transition>
</article>