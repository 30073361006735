import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './CategoryCreationDialog.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay
} from '@ulaval/modul-components'

import CategoryForm, { ICategoryFormPayloadData } from '../category-form/CategoryForm'
import CategoryService, { ICreateCategoryForm } from '../Category.service'
import { IYearModel } from '@/modules/global/year/Year.model'

import ROUTES from '@/router/ROUTES'

const FORM_REF: string = 'category-form'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MMessage,
    MOverlay,
    CategoryForm
  }
})
export default class CategoryCreationDialog extends mixins(CategoryService) {
  private isLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showCreationSuccessMessage: boolean = false
  private requestErrors: { [key: string]: string | boolean } = {}

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin

  private readonly FORM_REF: string = FORM_REF

  @Prop({ required: true })
  private year!: IYearModel

  @Ref(FORM_REF)
  private categoryFormRef!: CategoryForm

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_LIST
    })
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.categoryFormRef) {
      const formPayload: ICategoryFormPayloadData | null = await this.categoryFormRef.getFormPayload()

      if (formPayload) {
        this.createCategory(formPayload)
      }
    }
  }

  private createCategory(formPayload: ICategoryFormPayloadData): void {
    this.isLoadingStateActive = true

    const requestPayload: ICreateCategoryForm = {
      title: formPayload.title,
      description: formPayload.description,
      year_id: this.year.id,
      application_deposit_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
        formPayload.application_deposit_limit_date, 
        formPayload.application_deposit_limit_time
      ),
      evaluation_period_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
        formPayload.evaluation_period_limit_date, 
        formPayload.evaluation_period_limit_time
      ),
      is_new_category: formPayload.is_new_category || false,
      has_application_support: formPayload.has_application_support || false,
      application_support_count_lower_bound: formPayload.application_support_count_lower_bound,
      application_support_count_upper_bound: formPayload.application_support_count_upper_bound,
      application_support_deposit_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
        formPayload.application_support_deposit_limit_date || '', 
        formPayload.application_support_deposit_limit_time || ''
      ),
      application_support_submit_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
        formPayload.application_support_submit_limit_date || '', 
        formPayload.application_support_submit_limit_time || ''
      ),
      application_support_creation_dialog_message: formPayload.application_support_creation_dialog_message,
      application_support_creation_email_message: formPayload.application_support_creation_email_message,
      sort_order: formPayload.sort_order
    }

    this.service_categoryService_create(requestPayload)
      .then(() => {
        this.showCreationSuccessMessage = true
      }).catch((error: AxiosError<any>) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.validation
        ) {
          this.requestErrors = error.response.data.validation
        } else {
          this.requestErrors = {}
        }
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }
}
