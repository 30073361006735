import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './AdminApplicationFormSupports.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MColumnTable,
  MIcon,
  MIconButton,
  MOption,
  MOptionItem,
  MPagination,
  MPopup,
  MTable,
  MTableHeader,
  MToggleButtons,
  MToggleButton,
  MSearchfield,
  MColumnTextAlign
} from '@ulaval/modul-components'

import EmailStatusRow from '@/modules/global/email/EmailStatusRow'
import ApplicationFormSupportRow from '@/modules/application-form-support-management/application-form-support/ApplicationFormSupportRow'
import ApplicationFormSupportReminderEmailOverlay from '../application-form-support/ApplicationFormSupportReminderEmailOverlay'

import { IYearModel } from '@/modules/global/year/Year.model'
import { IPaginationResultData } from '@/modules/global/utilities/Pagination.model'
import CONSTANTS from '@/CONSTANTS'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import ROUTES from '@/router/ROUTES'
import ApplicationFormSupportService, { IFetchAdminApplicationFormSupportsListQueryParameters, IFetchAdminApplicationFormSupportsListResponse } from '../ApplicationFormSupport.service'
import ApplicationFormSupportMixin from '../ApplicationFormSupport.mixin'
import { ApplicationFormSupportStatus, IAdminApplicationFormSupportModel } from '../ApplicationFormSupport.model'

@WithRender
@Component({
  components: {
    ApplicationFormSupportReminderEmailOverlay,
    ApplicationFormSupportRow,
    EmailStatusRow,
    MIcon,
    MIconButton,
    MOption,
    MOptionItem,
    MPagination,
    MPopup, 
    MTable,
    MTableHeader,
    MToggleButtons,
    MSearchfield
  }
})
export default class AdminApplicationFormSupports extends mixins(ApplicationFormSupportService, ApplicationFormSupportMixin) {
  public formGroup: FormGroup = new FormGroup({
    'search-field': new FormControl()
  })
  private applicationFormSupports: Array<IAdminApplicationFormSupportModel> = []
  private selectedApplicationFormSupportStatus: Array<string> = Object.values(ApplicationFormSupportStatus)
  private paginationData: IPaginationResultData | null = null
  private isLoadingStateActive: boolean = false

  @Prop({ required: true })
  private year!: IYearModel

  public get searchField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('search-field');
  }

  private get statusToggleButtons(): Array<MToggleButton> {
    return Object.values(ApplicationFormSupportStatus).reduce((toggleButtons: Array<MToggleButton>, key: string) => {
      return [
        ...toggleButtons,
        {
          id: key,
          title: this.mixin_applicationFormSupport_computeStatusLabel(key),
          pressed: this.selectedApplicationFormSupportStatus.includes(key) 
        } as MToggleButton
      ]
    }, [])
  }

  private get tableColumns(): Array<MColumnTable> {
    return [
      {
        id: 'candidate',
        title: this.$i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.table.columns.candidate.title'),
        dataProp: 'candidate_full_name_with_username'
      },
      {
        id: 'supporter',
        title: this.$i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.table.columns.supporter.title'),
        dataProp: 'supporter_name_with_email'
      },
      {
        id: 'category',
        title: this.$i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.table.columns.category.title'),
        dataProp: 'category_title'
      },
      {
        id: 'status',
        title: this.$i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.table.columns.status.title'),
        dataProp: 'status',
        width: '200px'
      },
      {
        id: 'options',
        title: '',
        dataProp: 'options',
        textAlign: MColumnTextAlign.Right,
        width: '50px'
      }
    ]
  }

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadApplicationForms()
  }

  @Watch('selectedApplicationFormSupportStatus')
  private onSelectedApplicationFormSupportStatusChange(): void {
    if (this.paginationData) {
      this.loadApplicationForms(this.paginationData.current_page_index)
    }
  }

  private mounted(): void {
    this.afterApplicationFormReminderEmailSent()
  }

  private afterApplicationFormReminderEmailSent(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_SUPPORT_REMINDER_EMAIL_SENT, () => {
      if (this.paginationData) {
        this.loadApplicationForms(this.paginationData.current_page_index)
      }
    })
  }

  private loadApplicationForms(pageIndex: number = 0): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    const query: IFetchAdminApplicationFormSupportsListQueryParameters = {
      year_id: this.year.id,
      query: this.searchField.value,
      status: this.selectedApplicationFormSupportStatus,
      page: pageIndex
    }

    this.service_applicationFormSupportService_fetchAdminApplicationFormList(query)  
      .then((responseData: IFetchAdminApplicationFormSupportsListResponse) => {
        this.applicationFormSupports = responseData.application_form_supports || []
        this.paginationData = responseData.pagination || null
      }).catch(() => {
        this.applicationFormSupports = []
        this.paginationData = null
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private onSearchFieldChange(): void {
    this.loadApplicationForms(0)
  }

  private onPageChange(newIndex: number): void {
    this.loadApplicationForms(newIndex - 1)
  }

  private onToggleButtonClick(toggleButton: MToggleButton): void {
    if (toggleButton.pressed) {
      const statusIndex: number | undefined = this.selectedApplicationFormSupportStatus.indexOf(toggleButton.id)

      if (!~statusIndex) {
        this.$set(this.selectedApplicationFormSupportStatus, this.selectedApplicationFormSupportStatus.length, toggleButton.id)
      }
    } else {
      const statusIndex: number | undefined = this.selectedApplicationFormSupportStatus.indexOf(toggleButton.id)

      if (~statusIndex) {
        this.$delete(this.selectedApplicationFormSupportStatus, statusIndex)
      }
    }
  }

  private onShowApplicationFormSupportIconClick(event: Event, applicationFormSupport: IAdminApplicationFormSupportModel): void {
    this.mixin_router_navigate({
      name: ROUTES.ADMIN_APPLICATION_FORM_SUPPORT,
      params: {
        idApplicationFormSupport: applicationFormSupport.id
      }
    }, event)
  }
}
