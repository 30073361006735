import Vue from 'vue'
import Vuex from 'vuex'

import SessionStore, { STORE_ACTIONS as SESSION_STORE_ACTIONS, STORE_GETTERS as SESSION_STORE_GETTERS } from '../modules/session/Session.store'
import YearStore, { STORE_ACTIONS as YEAR_STORE_ACTIONS, STORE_GETTERS as YEAR_STORE_GETTERS } from '../modules/global/year/Year.store'
import FormTemplateAvailableElementStore, { STORE_ACTIONS as FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_ACTIONS, STORE_GETTERS as FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_GETTERS } from '../modules/form-template-management/FormTemplateAvailableElement.store'

Vue.use(Vuex)

export const STORE_ACTIONS = {
  ...SESSION_STORE_ACTIONS,
  ...YEAR_STORE_ACTIONS,
  ...FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_ACTIONS
}
type STORE_ACTIONS = SESSION_STORE_ACTIONS | YEAR_STORE_ACTIONS | FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_ACTIONS

export const STORE_GETTERS = {
  ...SESSION_STORE_GETTERS,
  ...YEAR_STORE_GETTERS,
  ...FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_GETTERS
}
type STORE_GETTERS = SESSION_STORE_GETTERS | YEAR_STORE_GETTERS | FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_GETTERS

export default new Vuex.Store({
  modules: {
    session: SessionStore,
    year: YearStore,
    formTemplateAvailableElement: FormTemplateAvailableElementStore
  }
})
