<section class="application-form-validation main-content">
  <breadcrumbs />
  <section class="application-form-validation__panels">
    <m-panel class="application-form-validation__panels-left main-content__panel">
      <template v-if="applicationForm">
        <h3 slot="header">
          {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.header.title') }}
        </h3>
      </template>
      <template v-if="!isLoadingStateActive && applicationForm">
        <div class="application-form-validation--informations mb-6">
          <m-panel>
            <ul class="mb-0">
              <li>
                <strong>
                  {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.informations.category') }}
                </strong>
                {{ applicationForm.category_title }}
              </li>
              <li class="mt-2">
                <strong>
                  {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.informations.candidate') }}
                </strong>
                {{ applicationForm.candidate_full_name }}
              </li>
              <template v-if="applicationForm.deposit_deadline_extension_timestamp">
                <li class="mt-2">
                  <strong>
                    {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.informations.deposit_deadline_extension_timestamp') }}
                  </strong>
                  {{ mixin_timestamp_pretty(applicationForm.deposit_deadline_extension_timestamp) }}
                </li>
              </template>
              <li class="mt-2">
                <strong>
                  {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.informations.category_application_deposit_limit_timestamp') }}
                </strong>
                {{ mixin_timestamp_pretty(applicationForm.category_application_deposit_limit_timestamp) }}
              </li>
              <template v-if="applicationForm.category_application_support_submit_limit_timestamp">
                <li class="mt-2">
                  <strong>
                    {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.informations.category_application_support_submit_limit_timestamp') }}
                  </strong>
                  {{ mixin_timestamp_pretty(applicationForm.category_application_support_submit_limit_timestamp) }}
                </li>
              </template>
              <li class="mt-2">
                <div class="display--flex">
                  <strong class="mx-0 my-auto">
                    {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.informations.status') }}
                  </strong>
                  <template v-if="mixin_applicationForm_computeStatusIcon(applicationForm)">
                    <m-icon
                      class="mx-2 my-auto"
                      size="22px"
                      :style="mixin_applicationForm_computeStatusIconStyles(applicationForm)"
                      :name="mixin_applicationForm_computeStatusIcon(applicationForm)"
                    />
                  </template>
                  <div class="mx-0 mt-auto">
                    {{ mixin_applicationForm_computeStatusLabel(applicationForm.status) }}
                  </div>
                </div>
              </li>
            </ul>
          </m-panel>
        </div>
      </template>
      <template v-if="isLoadingStateActive">
        <div
          key="spinner"
          class="my-15"
        >
          <m-spinner
            :title="true"
            :description="true"
          />
        </div>
      </template>
      <template v-if="!isLoadingStateActive && applicationForm">
        <m-message
          class="mb-10"
          :state="MMessageState.Information"
        >
          {{ $i18next.t(`modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form.message.update_not_available.label`) }}
        </m-message>
      </template>
      <div v-show="!isLoadingStateActive">
        <template v-if="applicationFormInternalFormTemplate && applicationFormForm && applicationForm">
          <form-elements
            :key="applicationFormInternalFormTemplate.internal_identifier"
            :elements="applicationFormInternalFormTemplate.elements"
            :answers="applicationFormForm.form_answers"
            :is-form-readonly="true"
            :display-evaluator-additionnal-informations="true"
          />
        </template>
      </div>
    </m-panel>
    <m-panel class="application-form-validation__panels-right">
      <template v-if="applicationFormValidation">
        <h3 slot="header">
          {{ $i18next.t(`modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form_validation.panel_title`) }}
        </h3>
      </template>
      <template v-if="isLoadingStateActive">
        <div
          key="spinner"
          class="my-15"
        >
          <m-spinner
            :title="true"
            :description="true"
          />
        </div>
      </template>
      <template v-if="!isLoadingStateActive && applicationFormValidation && !applicationFormValidation.is_updateable">
        <m-message
          class="mb-10"
          :state="MMessageState.Information"
        >
          {{ $i18next.t(`modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form_validation.message.update_not_available.label`) }}
        </m-message>
      </template>
      <div v-show="!isLoadingStateActive">
        <template v-if="applicationFormValidationInternalFormTemplate && applicationFormValidationForm && applicationFormValidation">
          <form-elements
            :ref="APPLICATION_FORM_VALIDATION_FORM_ELEMENTS"
            :key="applicationFormValidationInternalFormTemplate.internal_identifier"
            :elements="applicationFormValidationInternalFormTemplate.elements"
            :answers="applicationFormValidationForm.form_answers"
            :is-form-readonly="!applicationFormValidation.is_updateable"
            :display-evaluator-additionnal-informations="true"
          />
        </template>
      </div>
      <template
        #footer
        v-if="!isLoadingStateActive && applicationFormValidation && applicationFormValidation.is_updateable"
      >
        <div>
          <m-button @click="onSaveApplicationFormValidationButtonClick">
            {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form_validation.button.save.label') }}
          </m-button>
        </div>
        <div class="mt-2">
          <m-button
            :skin="MButtonSkin.Secondary"
            class="application-form-validation__buttons--flag-valid"
            @click="onFlagApplicationFormValidationAsValidButtonClick"
          >
            {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form_validation.button.flag_as_valid.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            class="application-form-validation__buttons--flag-invalid ml-1"
            @click="onFlagApplicationFormValidationAsInvalidButtonClick"
          >
            {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form_validation.button.flag_as_invalid.label') }}
          </m-button>
        </div>
      </template>
    </m-panel>
    <m-toast
      :state="MToastState.Success"
      :position="MToastPosition.TopRight"
      :open="isUpdateApplicationFormValidationSuccessToastOpen"
    >
      {{ $i18next.t('modules.application-form-validation-management.application-form-validation.AdminApplicationFormValidation.application_form_validation.toast.update_success.label') }}
    </m-toast>
  </section>
</section>