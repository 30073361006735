import Component, { mixins } from 'vue-class-component'

import WithRender from './LoginPage.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import AuthenticationForm from '@/modules/authentication/form/AuthenticationForm'
import { STORE_GETTERS } from '@/store'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    AuthenticationForm,
    MPanel
  }
})
export default class LoginPage extends mixins() {
  private mounted(): void {
    if (this.$store.getters[STORE_GETTERS.SESSION_GET_IS_LOGGED_IN]) {
      this.mixin_router_navigate({
        name: ROUTES.DASHBOARD
      })
    }
  }
}
