import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './ApplicationFormAuthorizedUsersOverlay.html'

import { 
  MButton,
  MButtonSkin,
  MDatepicker,
  MIconButton,
  MForm,
  MMessage,
  MMessageSkin,
  MMessageState,
  MMultiSelect,
  MOverlay,
  MTimepicker
} from '@ulaval/modul-components'

import UserForm, { IUserFormPayloadData } from '@/modules/user-management/user-form/UserForm'
import ApplicationFormService from '../ApplicationForm.service'
import { IAdminApplicationFormModel } from '../ApplicationForm.model'
import { IUserModel } from '@/modules/user-management/User.model'

const USER_FORM_REF: string = 'user-form'

export enum ApplicationFormAuthorizedUsersOverlay_EVENTS {
  AFTER_CLOSE = 'after-close'
}

@WithRender
@Component({
  components: {
    MButton,
    MDatepicker,
    MIconButton,
    MForm,
    MMessage,
    MMultiSelect,
    MOverlay,
    MTimepicker,
    UserForm
  }
})
export default class ApplicationFormAuthorizedUsersOverlay extends mixins(ApplicationFormService) {
  private isLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showSuccessMessage: boolean = false
  private authorizedUsers: IUserModel[] = []

  private readonly USER_FORM_REF: string = USER_FORM_REF
  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin

  @Prop({ required: true })
  private applicationForm!: IAdminApplicationFormModel

  @Ref(USER_FORM_REF)
  private userFormComponent!: UserForm

  private get isAuthorizedUserListEmpty(): boolean {
    return this.authorizedUsers.length === 0
  }

  private mounted(): void {
    this.populateFormFromModel()
  }

  private populateFormFromModel(): void {
    if (this.applicationForm) {
      this.authorizedUsers = Array.from(this.applicationForm.authorized_users)
    }
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private onTriggerButtonClick(): void {
    this.isOverlayOpen = true
  }

  private async onSaveButtonClick(): Promise<void> {
    this.updateAuthorizedUsersList()
  }

  private updateAuthorizedUsersList(): void {
    this.isLoadingStateActive = true
    
    this.service_applicationFormService_saveAuthorizedUsersAdmin(
      this.applicationForm.id,
      {
        iduls: this.authorizedUsers.map((user: any) => user.username || user.idul)
      }
    ).then(() => {
      this.showSuccessMessage = true
    }).catch((error: AxiosError) => {
      this.mixin_router_redirectRequestError(error)
    }).finally(() => {
      this.isLoadingStateActive = false
    })
  }

  private afterOverlayClose(): void {
    this.isLoadingStateActive = false
    this.showSuccessMessage = false

    this.$emit(ApplicationFormAuthorizedUsersOverlay_EVENTS.AFTER_CLOSE)
  }

  private async onAddUserButtonClick(): Promise<void> {
    if (this.userFormComponent) {
      const payload: IUserFormPayloadData | null = await this.userFormComponent.getFormPayload()

      if (payload) {
        this.$set(this.authorizedUsers, this.authorizedUsers.length, payload)
        this.userFormComponent.clear()
      }
    }
  }

  private onRemoveAuthorizedUserIconClick(index: number): void {
    this.$delete(this.authorizedUsers, index)
  }

  private computeUserFullName(user: any): string {
    if (!this.mixin_string_isEmpty(user.full_name_with_email)) {
      return user.full_name_with_email
    }

    return `${user.first_name} ${user.last_name} (${user.email})`
  }
}
