<m-overlay
  id="evaluator-management__evaluator-category-assignment-update-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
>
  <template #header>
    <h2>
      {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-update.AdminEvaluatorCategoryAssignmentUpdateOverlay.overlay_title') }}
    </h2>
    <template v-if="evaluatorFullNameWithUsername">
      <h6 class="mt-3">
        {{ evaluatorFullNameWithUsername }}
      </h6>
    </template>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showUpdateSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-update.AdminEvaluatorCategoryAssignmentUpdateOverlay.message.update_success.title')"
      />
    </template>
    <template v-else-if="showUpdateErrorMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :state="MMessageState.Error"
        :title="$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-update.AdminEvaluatorCategoryAssignmentUpdateOverlay.message.update_error.title')"
      />
    </template>
    <template v-else>
      <admin-evaluator-category-assignment-form
        :year="year"
        :ref="FORM_REF"
        :key="`admin-evaluator-category-assignment-form[${evaluatorIdul}][${evaluatorCategoryAssignments.length}]`"
        :evaluator-idul="evaluatorIdul"
        :evaluator-category-assignments="evaluatorCategoryAssignments"
        :evaluator-full-name-with-username="evaluatorFullNameWithUsername"
      />
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="showUpdateSuccessMessage || showUpdateErrorMessage">
        <div>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-update.AdminEvaluatorCategoryAssignmentUpdateOverlay.button.close.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-update.AdminEvaluatorCategoryAssignmentUpdateOverlay.button.assign.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignment-update.AdminEvaluatorCategoryAssignmentUpdateOverlay.button.cancel.label') }}
          </m-button>
        </div>
      </template>
    </transition>
  </template>
</m-overlay>