import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './DashboardEvaluatorToolbox.html'

import {
  MMessage,
  MMessageSkin,
  MMessageState,
  MPanel
} from '@ulaval/modul-components'

import UploadedFileRow from '../global/file/UploadedFileRow'

import { IYearModel } from '../global/year/Year.model'
import EvaluatorToolboxFileService from '../evaluator-toolbox-file-management/EvaluatorToolboxFile.service'
import { IEvaluatorToolboxFileModel } from '../evaluator-toolbox-file-management/EvaluatorToolboxFile.model'
import { IFileUploadModel } from '../global/file/File.model'

@WithRender
@Component({
  components: {
    MMessage,
    MPanel,
    UploadedFileRow
  }
})
export default class DashboardEvaluatorToolbox extends mixins(EvaluatorToolboxFileService) {
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MMessageState: object = MMessageState

  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ required: true })
  private evaluatorToolboxFiles!: Array<IEvaluatorToolboxFileModel>

  private get mappedEvaluatorToolboxFiles(): Array<IFileUploadModel<IEvaluatorToolboxFileModel>> {
    return this.evaluatorToolboxFiles.reduce((acc: Array<IFileUploadModel<IEvaluatorToolboxFileModel>>, evaluatorToolboxFile: IEvaluatorToolboxFileModel) => {
      return [
        ...acc,
        {
          server_file: evaluatorToolboxFile
        }
      ]
    }, [])
  }
}
