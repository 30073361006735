import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";
import BaseService from "../global/utilities/Base.service";
import { IYearConfigurationModel } from "./YearConfiguration.model";
import { EVENT_BUS_EVENTS } from "../global/utilities/Events";
import { ICategoryModel } from "../category-management/Category.model";
import { IYearModel } from "../global/year/Year.model";

export interface IYearConfigurationUpdateForm {
  public_area_description_zone: string;
  public_area_help_zone: string;
  public_area_description_zone_title: string;
  public_area_help_zone_title: string;
  is_public_area_available: boolean;
  category_sections: Array<{
    id?: string;
    title: string;
    categories_id: Array<string>;
  }>;
}

export interface IPublicYearConfigurationResponse {
  year_configuration: IYearConfigurationModel;
  year: IYearModel;
}

@Component
export default class YearConfigurationService extends BaseService {
  protected service_yearConfigurationService_adminFetchYearConfiguration(yearId: string): Promise<IYearConfigurationModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/years/${yearId}/configurations`)
        .then((response: AxiosResponse) => resolve(response.data.data.year_configuration))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_yearConfigurationService_publicFetchYearConfiguration(): Promise<IPublicYearConfigurationResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/public/year-configuration`)
        .then((response: AxiosResponse) => resolve(response.data.data))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_yearConfigurationService_update(yearConfigurationId: string, payload: IYearConfigurationUpdateForm): Promise<IYearConfigurationModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/years/configurations/${yearConfigurationId}`, payload)
        .then((response: AxiosResponse) => {
          const updatedYearConfiguration: IYearConfigurationModel = response.data.data.year_configuration

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.YEAR_CONFIGURATION_UPDATED, updatedYearConfiguration)

          resolve(updatedYearConfiguration)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_yearConfigurationService_fetchAvailableCategories(yearId: string): Promise<Array<ICategoryModel>> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/years/${yearId}/configurations/available-categories`)
        .then((response: AxiosResponse) => resolve(response.data.data.categories))
        .catch((error: AxiosError) => reject(error))
    })
  }
}