<div class="form-template-update__selected-element">
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="requestErrors[selectedElement.internal_identifier]">
      <m-message
        class="mb-4"
        :state="MMessageState.Error"
      >
        <div class="display--flex">
          <p>
            {{ $i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.errors.${requestErrors[selectedElement.internal_identifier]}.label`) }}
          </p>
          <m-tooltip
            placement="right"
            class="py-auto my-auto ml-2"
          >
            {{ $i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.label.error_tooltip`) }}
          </m-tooltip>
        </div>
      </m-message>
    </template>
  </transition>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <div
      class="form-template-update__selected-element--parameters"
      :key="selectedElementAvailableElementType ? selectedElementAvailableElementType.element_type : ''"
    >
      <template v-if="selectedElementAvailableElementType && availableElementTypeHasParameters">
        <div class="pb-3">
          {{ $i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.label.parameters`) }}
          <hr />
        </div>
        <template v-for="parameter in sortedAvailableElementTypeParameters">
          <div class="mb-2 display--flex flex__direction--row min-width--100">
            <component
              :is="getParameterComponentName(parameter)"
              v-model="parametersValue[parameter.key]"
              :label="$i18next.t(`modules.form-template-management.FormTemplateModel.element_parameters.${parameter.key}`)"
              :type="getParameterComponentType(parameter)"
              class="flex__grow--1 max-width--none"
              :helper-message="hasParameterHelperMessageLabel(parameter) ? $i18next.t(`modules.form-template-management.FormTemplateModel.element_parameters_helper_message.${parameter.key}`) : null"
              @input="onParameterValueChange(parameter.key, $event)"
              @change="onParameterValueChange(parameter.key, $event)"
            >
              <template v-if="parameter.options.length > 0">
                <template v-for="option in parameter.options">
                  <m-dropdown-item :value="option">
                    {{ option }}
                  </m-dropdown-item>
                </template>
              </template>
              <template v-else>
                {{ $i18next.t(`modules.form-template-management.FormTemplateModel.element_parameters.${parameter.key}`) }}
              </template>
            </component>
            <template v-if="hasParameterTooltipLabel(parameter)">
              <m-tooltip
                placement="right"
                class="py-auto my-auto"
              >
                <p
                  class="mt-0"
                  v-html="$i18next.t(`modules.form-template-management.FormTemplateModel.element_parameters_tooltip.${parameter.key}`)"
                />
              </m-tooltip>
            </template>
          </div>
        </template>
      </template>
    </div>
  </transition>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="selectedElementAvailableElementType && selectedElementAvailableElementType.allow_options">
      <div class="form-template-update__selected-element--options mt-6">
        <div class="pb-3">
          {{ $i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.label.options`) }}
          <hr />
        </div>
        <template v-for="option, index in elementOptions">
          <div class="display--flex mb-3">
            <m-textfield
              :label="$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.text_field.options.value.label`)"
              :value="option.value"
              :error="!mixin_string_isEmpty(option.valueError)"
              :error-message="option.valueError"
              @input="onOptionValueChange(index, $event)"
            />
            <m-textfield
              :label="$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.text_field.options.order.label`)"
              :value="option.order"
              max-width="120px"
              :error="!mixin_string_isEmpty(option.orderError)"
              :error-message="option.orderError"
              @input="onOptionOrderChange(index, $event)"
            />
            <m-icon-button
              class="my-auto"
              icon-name="m-svg__delete"
              button-size="32px"
              @click="deleteElementOption(index)"
            />
          </div>
        </template>
        <div>
          <m-add @click="addElementOption">
            {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.button.add_element_option.label') }}
          </m-add>
        </div>
      </div>
    </template>
  </transition>
  <div class="mt-6">
    <template v-if="availableElementTypeHasParameters || selectedElementAvailableElementType.allow_options">
      <m-button @click="onUpdateButtonClick">
        {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.button.update_element.label') }}
      </m-button>
    </template>
    <m-button
      :skin="MButtonSkin.Secondary"
      @click="onDeleteButtonClick"
    >
      {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdateSelectedElement.button.delete_element.label') }}
    </m-button>
  </div>
</div>