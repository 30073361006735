import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './CategoryUpdateDialog.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay,
  MProgress
} from '@ulaval/modul-components'

import CategoryForm, { ICategoryFormPayloadData } from '../category-form/CategoryForm'
import CategoryService, { IUpdateCategoryForm } from '../Category.service'

import ROUTES from '@/router/ROUTES'
import { ICategoryModel } from '../Category.model'

const FORM_REF: string = 'category-form'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MMessage,
    MOverlay,
    MProgress,
    CategoryForm
  }
})
export default class CategoryUpdateDialog extends mixins(CategoryService) {
  private isLoadingStateActive: boolean = false
  private isCategoryFetchLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showUpdateSuccessMessage: boolean = false
  private requestErrors: { [key: string]: string | boolean } = {}
  private originalCategory: ICategoryModel | null = null

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin

  private readonly FORM_REF: string = FORM_REF

  @Ref(FORM_REF)
  private categoryFormRef!: CategoryForm

  @Watch('$route.params.idCategory', { immediate: true })
  private onCategoryIdRouteParameterChange(value: string): void {
    this.loadCategory(value)
  }

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_LIST
    })
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.categoryFormRef) {
      const formPayload: ICategoryFormPayloadData | null = await this.categoryFormRef.getFormPayload()

      if (formPayload) {
        this.updateCategory(formPayload)
      }
    }
  }

  private updateCategory(formPayload: ICategoryFormPayloadData): void {
    if (this.originalCategory) {
      this.isLoadingStateActive = true

      const requestPayload: IUpdateCategoryForm = {
        title: formPayload.title,
        description: formPayload.description,
        application_deposit_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
          formPayload.application_deposit_limit_date, 
          formPayload.application_deposit_limit_time
        ),
        evaluation_period_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
          formPayload.evaluation_period_limit_date, 
          formPayload.evaluation_period_limit_time
        ),
        is_new_category: formPayload.is_new_category || false,
        has_application_support: formPayload.has_application_support || false,
        application_support_count_lower_bound: formPayload.application_support_count_lower_bound,
        application_support_count_upper_bound: formPayload.application_support_count_upper_bound,
        application_support_deposit_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
          formPayload.application_support_deposit_limit_date || '', 
          formPayload.application_support_deposit_limit_time || ''
        ),
        application_support_submit_limit_timestamp: this.mixin_timstamp_dateAndTimeToTimestamp(
          formPayload.application_support_submit_limit_date || '', 
          formPayload.application_support_submit_limit_time || ''
        ),
        application_support_creation_dialog_message: formPayload.application_support_creation_dialog_message,
        application_support_creation_email_message: formPayload.application_support_creation_email_message,
        sort_order: formPayload.sort_order
      }

      this.service_categoryService_update(this.originalCategory.id, requestPayload)
        .then(() => {
          this.showUpdateSuccessMessage = true
        }).catch((error: AxiosError<any>) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.validation
          ) {
            this.requestErrors = error.response.data.validation
          } else {
            this.requestErrors = {}
          }
        }).finally(() => {
          this.isLoadingStateActive = false
        })
    }
  }

  private loadCategory(id: string): void {
    this.isCategoryFetchLoadingStateActive = true

    this.service_categoryService_fetch(id)
      .then((responseData: ICategoryModel) => {
        this.originalCategory = responseData || null
      }).catch(() => {
        this.originalCategory = null
      }).finally(() => {
        this.isCategoryFetchLoadingStateActive = false
      })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }
}
