<main class="login-page">
  <div class="login-page__dialog-container">
    <m-panel
      :border="false"
      skin="darker"
    >
      <h3 slot="header">
        {{ $i18next.t('modules.authentication.login.LoginPage.informations_container.title') }}
      </h3>
      <p v-html="$i18next.t('modules.authentication.login.LoginPage.informations_container.content.basic_informations')" />
    </m-panel>
    <m-panel
      :border="false"
      class="login-page__dialog-container__form"
    >
      <h3 slot="header">
        {{ $i18next.t('modules.authentication.login.LoginPage.form_container.title') }}
      </h3>
      <authentication-form />
    </m-panel>
    </m-panel>
  </div>
</main>