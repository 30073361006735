import Component, { mixins } from 'vue-class-component'

import WithRender from './Users.html'

import { 
  AbstractControl,
  FormGroup,
  MButton,
  MTable,
  MTableHeader,
  MColumnTable,
  MColumnTextAlign,
  MPagination,
  MOption,
  MOptionItemEdit,
  MSearchfield,
  FormControl
} from '@ulaval/modul-components'

import { IUserModel } from '../User.model'
import UserService, { IFetchUserListQueryParameters, IUserListResponse } from '../User.service'
import { IPaginationResultData } from '@/modules/global/utilities/Pagination.model'
import CONSTANTS from '@/CONSTANTS'
import ROUTES from '@/router/ROUTES'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {
    MButton,
    MOption,
    MOptionItemEdit,
    MPagination,
    MSearchfield,
    MTable,
    MTableHeader
  }
})
export default class Users extends mixins(UserService) {
  public formGroup: FormGroup = new FormGroup({
    'search-field': new FormControl()
  })

  private users: Array<IUserModel> = []
  private paginationData: IPaginationResultData | null = null
  private isLoadingStateActive: boolean = false

  public get searchField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('search-field');
  }

  private get tableColumns(): Array<MColumnTable> {
    return [
      {
        id: 'username',
        title: this.$i18next.t('modules.user-management.users.Users.table.headers.username.title'),
        dataProp: 'full_name_with_username'
      },
      {
        id: 'email',
        title: this.$i18next.t('modules.user-management.users.Users.table.headers.email.title'),
        dataProp: 'email'
      },
      {
        id: 'options',
        title: this.$i18next.t('modules.user-management.users.Users.table.headers.options.title'),
        dataProp: 'options',
        textAlign: MColumnTextAlign.Right,
        width: '50px'
      }
    ]
  }

  private mounted(): void {
    this.loadUsers()

    this.afterUserCreated()
  }

  private afterUserCreated(): void {
    EventBus.$on(EVENT_BUS_EVENTS.USER_CREATED, () => {
      if (this.paginationData) {
        this.loadUsers(this.paginationData.current_page_index)
      }
    })
  }

  private loadUsers(pageIndex: number = 0): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    const query: IFetchUserListQueryParameters = {
      page: pageIndex,
      query: this.searchField.value
    }

    this.service_userService_fetchList(query)
      .then((responseData: IUserListResponse) => {
        this.users = responseData.users || []
        this.paginationData = responseData.pagination || null
      }).catch(() => {
        this.users = []
        this.paginationData = null
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private onPageChange(newIndex: number): void {
    this.loadUsers(newIndex - 1)
  }

  private onSearchFieldChange(): void {
    this.loadUsers(0)
  }

  private onCreateUserButtonClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.USER_MANAGEMENT_USER_CREATION
    })
  }

  private onUserUpdateOptionClick(user: IUserModel): void {
    if (user) {
      this.mixin_router_navigate({
        name: ROUTES.USER_MANAGEMENT_USER_UPDATE,
        params: {
          idUser: user.id
        }
      })
    }
  }
}
