import Component, { mixins } from 'vue-class-component'

import WithRender from './AuthenticationForm.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MButton, 
  MButtonType,
  MForm,
  MMessage,
  MTextfield, 
  MTextfieldType,
  MTooltip,
  RequiredValidator
} from '@ulaval/modul-components'

import AuthenticationService from '@/modules/authentication/Authentication.service';

import ROUTES from '@/router/ROUTES';
import { AxiosError } from 'axios';
import CONSTANTS from '@/CONSTANTS';

@WithRender
@Component({
  components: {
    MButton,
    MForm,
    MMessage,
    MTextfield,
    MTooltip
  }
})
export default class AuthenticationForm extends mixins(AuthenticationService) {
  public formGroup: FormGroup = new FormGroup({
    username: new FormControl<string>([
        RequiredValidator()
    ]),
    password: new FormControl<string>([RequiredValidator()])
  })
  private isAuthenticationLoadingStateActive: boolean = false
  private requestErrors: { [key: string]: boolean } = {}

  private readonly MTextfieldType: object = MTextfieldType
  private readonly MButtonType: object = MButtonType
  private readonly AUTHENTICATION_LOADING_STATE_TIMEOUT: number = 500
  private readonly ERROR_KEY_INVALID_CREDENTIALS: string = 'invalid_credentials'

  private get usernameField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('username');
  }
  private get passwordField(): AbstractControl<string> {
      return this.formGroup.getControl<string>('password');
  }

  private authenticate(): void {
    const authenticationLoadingStateTimeout: NodeJS.Timeout = setTimeout(() => {
      this.isAuthenticationLoadingStateActive = true
    }, this.AUTHENTICATION_LOADING_STATE_TIMEOUT)

    this.service_authenticationService_authenticate({
      username: this.usernameField.value,
      password: this.passwordField.value
    }).then(() => {
      const rememberedRoute: string | null = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_REMEMBERED_ROUTE)

      if (!this.mixin_string_isEmpty(rememberedRoute) && rememberedRoute) {
        this.$router.push(JSON.parse(rememberedRoute))
      } else {
        this.mixin_router_navigate({
          name: ROUTES.DASHBOARD
        })
      }
    }).catch((error: AxiosError<any>) => {
      if (error.response && error.response.data && error.response.data.validation) {
        this.requestErrors = {
          ...this.requestErrors,
          ...error.response.data.validation
        }
      } else {
        this.requestErrors = {}
      }
    }).finally(() => {
      clearTimeout(authenticationLoadingStateTimeout)
      this.isAuthenticationLoadingStateActive = false
    })
  }
}
