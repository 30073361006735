import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import WithRender from './FormTemplateUpdateDropZoneElement.html'

import FormTemplateUpdateInsertZone from './FormTemplateUpdateInsertZone'

import { IFormTemplateAvailableElementModel, IInternalFormTemplateElementModel } from '../FormTemplate.model'

import { DRAG_AND_DROP_EVENTS_IDENTIFIER, DRAG_AND_DROP_EVENTS_IDENTIFIER_KEY, EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import { STORE_GETTERS } from '@/store'

const WRAPPER_REF: string = 'wrapper'
const LABEL_PARAMETER_KEYS: Array<string> = [
  'label',
  'header',
  'icon-title'
]

@WithRender
@Component({
  components: {
    FormTemplateUpdateInsertZone,
    FormTemplateUpdateDropZoneElement: () => import('./FormTemplateUpdateDropZoneElement')
  }
})
export default class FormTemplateUpdateDropZoneElement extends mixins() {
  private insertDraggedElement: IFormTemplateAvailableElementModel | null = null
  private moveDraggedElement: IInternalFormTemplateElementModel | null = null
  private isMouseOver: boolean = false
  private isSelectedElement: boolean = false
  private isInsertDraggedElementOver: boolean = false
  private isNewElement: boolean = false

  private readonly WRAPPER_REF: string = WRAPPER_REF
  private readonly LABEL_PARAMETER_KEYS: Array<string> = LABEL_PARAMETER_KEYS

  @Prop({ required: true })
  private formTemplateElement!: IInternalFormTemplateElementModel

  @Prop({ required: true })
  private requestErrors!: { [key: string]: string }

  @Ref(WRAPPER_REF)
  private wrapperDivComponent!: HTMLElement

  private get labelClasses(): object {
    return {
      'form-template-update__drop-zone-element-label--with-children': this.hasChildren,
      'form-template-update__drop-zone-element-label--without-children': !this.hasChildren
    }
  }

  private get wrapperClasses(): object {
    return {
      'form-template-update__drop-zone-element--hover': this.isMouseOver,
      'form-template-update__drop-zone-element--selected': this.isSelectedElement,
      'form-template-update__drop-zone-element--error': this.requestErrors[this.formTemplateElement.internal_identifier],
      'form-template-update__drop-zone-element--new': this.isNewElement
    }
  }

  private get childrensWrapperClasses(): object {
    return {}
  }

  private get wrapperStyles(): object {
    const styles: { [key: string]: string } = {}

    return styles
  }

  private get sortedChildrens(): Array<IInternalFormTemplateElementModel> {
    return this.formTemplateElement.childrens.sort((leftHandSide: IInternalFormTemplateElementModel, rightHandSide: IInternalFormTemplateElementModel) => {
      return leftHandSide.element_position_index - rightHandSide.element_position_index
    })
  }

  private get hasChildren(): boolean {
    return !!this.formTemplateElement && this.formTemplateElement.childrens.length > 0
  }

  private get availableFormTemplateElements(): { [key: string]: IFormTemplateAvailableElementModel } {
    return this.$store.getters[STORE_GETTERS.FORM_TEMPLATE_AVAILABLE_ELEMENT_GET_FORM_TEMPLATE_AVAILABLE_ELEMENTS_BY_TYPE]
  }

  private get isDraggedElementInsertable(): boolean {
    const formTemplateElementType: IFormTemplateAvailableElementModel = this.availableFormTemplateElements[this.formTemplateElement.element_type]

    if (!this.insertDraggedElement || !formTemplateElementType) {
      return false
    }

    return formTemplateElementType.allowed_children_types.includes(this.insertDraggedElement.element_type)
  }

  private get labelParameter(): string | null {
    for (const parameter of this.formTemplateElement.element_parameters) {
      if (this.LABEL_PARAMETER_KEYS.includes(parameter.key)) {
        return parameter.value
      } 
    }

    return null
  }

  @Watch('formTemplateElement.id')
  private onFormTemplateElementIdChange(value: string): void {
    if (value) {
      this.isNewElement = false
    }
  }

  private mounted(): void {
    this.isNewElement = !this.formTemplateElement.id

    this.onElementInsertDragStart()
    this.onElementInsertDragEnd()
    this.onElementInsertDragDrop()
    this.onSelectedElementChange()
    this.onFormTemplateElementDelete()
    this.onFormTemplateElementUpdate()
    this.onElementMoveDragStart()
    this.onElementMoveDragEnd()
  }

  private onSelectedElementChange(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_SELECTED, (eventSelectedElement: IInternalFormTemplateElementModel) => {
      if (eventSelectedElement) {
        this.isSelectedElement = eventSelectedElement.internal_identifier === this.formTemplateElement.internal_identifier
      } else {
        this.isSelectedElement = false
      }

      this.isNewElement = false
    })
  }

  private onFormTemplateElementUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_UPDATED, () => {
      this.isSelectedElement = false
    })
  }

  private onFormTemplateElementDelete(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_DELETED, () => {
      this.isSelectedElement = false
    })
  }

  private onElementClick(): void {
    EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_SELECTED, this.formTemplateElement)
    this.isSelectedElement = true
  }

  private onMouseOver(): void {
    this.isMouseOver = true   
  }

  private onMouseLeave(): void {
    this.isMouseOver = false
  }

  private onMouseOut(): void {
    if (this.hasChildren) {
      this.isMouseOver = false
    }
  }

  private onDragOver(): void {
    this.isInsertDraggedElementOver = true
  }

  private onDragLeave(event: DragEvent): void {
    if (this.wrapperDivComponent) {
      const cardDomRect: DOMRect = this.wrapperDivComponent.getBoundingClientRect() as DOMRect

      const xLowerBound: number = cardDomRect.x
      const xUpperBound: number = cardDomRect.x + cardDomRect.width
      const yLowerBound: number = cardDomRect.y
      const yUpperBound: number = cardDomRect.y + cardDomRect.height
  
      if (
        event.x < xLowerBound ||
        event.x > xUpperBound ||
        event.y < yLowerBound ||
        event.y > yUpperBound
      ) {
        this.isInsertDraggedElementOver = false
      }
    } else {
      this.isInsertDraggedElementOver = false
    }
  }

  private onElementInsertDragStart(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_STARTED, (element: IFormTemplateAvailableElementModel) => {
      this.insertDraggedElement = element || null
      this.isSelectedElement = false
    })
  }

  private onElementInsertDragEnd(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_ENDED, () => {
      this.insertDraggedElement = null
    })
  }

  private onElementInsertDragDrop(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_DROPPED, () => {
      this.isNewElement = false
      this.insertDraggedElement = null
    })
  }

  private onElementMoveDragStart(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_STARTED, (element: IInternalFormTemplateElementModel) => {
      if (element.parent_internal_identifier === this.formTemplateElement.internal_identifier) {
        this.moveDraggedElement = element || null
      } else {
        this.moveDraggedElement = null
      }
      
      this.isSelectedElement = false
    })
  }

  private onElementMoveDragEnd(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_ENDED, () => {
      this.moveDraggedElement = null
      this.isNewElement = false
    })
  }

  private triggerElementMoveDragStart(event: DragEvent): void {
    if (event && event.dataTransfer) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'

      event.dataTransfer.setData(DRAG_AND_DROP_EVENTS_IDENTIFIER_KEY, DRAG_AND_DROP_EVENTS_IDENTIFIER.FORM_TEMPLATE_ELEMENT_MOVE)
      event.dataTransfer.setData('text/plain', JSON.stringify(this.formTemplateElement))

      setTimeout(() => {
        EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_STARTED, this.formTemplateElement)
      }, 100)
    }
  }

  private triggerElementMoveDragEnd(): void {
    EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_ENDED)
  }

  private isDraggedElementMovable(index: number): boolean {
    if (!this.moveDraggedElement) {
      return false
    }

    if (this.moveDraggedElement.element_position_index === index) {
      return false
    }

    return this.moveDraggedElement.element_position_index !== index &&
      this.moveDraggedElement.element_position_index !== --index
  }
}