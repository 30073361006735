import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './CategoryCopyDialog.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay
} from '@ulaval/modul-components'

import YearDropdown from '@/modules/global/year/YearDropdown'

import CategoryService, { ICopyCategoryForm } from '../Category.service'
import { IYearModel } from '@/modules/global/year/Year.model'

import { ICategoryModel } from '../Category.model'

export enum CategoryCopyDialog_EVENTS {
  DIALOG_CLOSE = 'after-close'
}

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MMessage,
    MOverlay,
    YearDropdown
  }
})
export default class CategoryCopyDialog extends mixins(CategoryService) {
  private isLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showCreationSuccessMessage: boolean = false
  private targetYear: IYearModel | null = null 

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin

  @Prop({ 
    required: true
  })
  private selectedCategories!: Array<ICategoryModel>

  private afterOverlayClose(): void {
    this.$emit(CategoryCopyDialog_EVENTS.DIALOG_CLOSE)
  }

  private onSaveButtonClick(): void {
    if (this.targetYear) {
      this.isLoadingStateActive = true

      const requestPayload: ICopyCategoryForm = {
        target_year_id: this.targetYear.id,
        categories_id: this.selectedCategories.map((category: ICategoryModel) => category.id)
      }
  
      this.service_categoryService_copy(requestPayload)
        .then(() => {
          this.showCreationSuccessMessage = true
        }).finally(() => {
          this.isLoadingStateActive = true
        })
    }
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }
}
