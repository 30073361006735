import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { EVENT_BUS_EVENTS } from "@/modules/global/utilities/Events";
import { EvaluatorApplicationFormAssignmentStatus, IEvaluatorApplicationFormAssignmentModel } from "./EvaluatorApplicationFormAssignment.model";
import FormTemplateService from "@/modules/form-template-management/FormTemplate.service";
import { IInternalFormTemplateModel } from "@/modules/form-template-management/FormTemplate.model";
import { IFormAnswerModel } from "@/modules/form-management/Form.model";

export interface IUpdateEvaluatorApplicationFormAssignmentForm {
  status: EvaluatorApplicationFormAssignmentStatus;
}

export interface IFetchEvaluatorApplicationFormAssignmentApplicationFormResponse {
  evaluator_application_form_assignment: IEvaluatorApplicationFormAssignmentModel;
  form_answers: Array<IFormAnswerModel>;
  internal_form_template: IInternalFormTemplateModel;
}

export interface IFetchEvaluatorApplicationFormSupportsAssignmentApplicationFormResponse {
  evaluator_application_form_assignment: IEvaluatorApplicationFormAssignmentModel;
  form_answers: Array<IFormAnswerModel>;
  internal_form_template: IInternalFormTemplateModel;
}

@Component
export default class EvaluatorApplicationFormAssignmentService extends FormTemplateService {
  protected service_evaluatorApplicationFormAssignmentService_flagAsDone(id: string): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      const payload: IUpdateEvaluatorApplicationFormAssignmentForm = {
        status: EvaluatorApplicationFormAssignmentStatus.DONE
      }

      this.$axios.put(`/api/evaluator-application-form-assignments/${id}`, payload)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.EVALUATOR_APPLICATION_FORM_ASSIGNMENT_UPDATED, null)
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorApplicationFormAssignmentService_flagAsInProgress(id: string): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      const payload: IUpdateEvaluatorApplicationFormAssignmentForm = {
        status: EvaluatorApplicationFormAssignmentStatus.IN_PROGRESS
      }

      this.$axios.put(`/api/evaluator-application-form-assignments/${id}`, payload)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.EVALUATOR_APPLICATION_FORM_ASSIGNMENT_UPDATED, null)
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorApplicationFormAssignmentService_fetchApplicationForm(id: string): Promise<IFetchEvaluatorApplicationFormAssignmentApplicationFormResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/evaluator-application-form-assignments/${id}/application-form`)
        .then((response: AxiosResponse) => {
          resolve({
            evaluator_application_form_assignment: response.data.data.evaluator_application_form_assignment,
            form_answers: response.data.data.form_answers,
            internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form_template)
          } as IFetchEvaluatorApplicationFormAssignmentApplicationFormResponse)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorApplicationFormAssignmentService_fetchApplicationFormSupports(id: string): Promise<IFetchEvaluatorApplicationFormSupportsAssignmentApplicationFormResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/evaluator-application-form-assignments/${id}/application-form-supports`)
        .then((response: AxiosResponse) => {
          resolve({
            evaluator_application_form_assignment: response.data.data.evaluator_application_form_assignment,
            form_answers: response.data.data.form_answers,
            internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form_template)
          } as IFetchEvaluatorApplicationFormSupportsAssignmentApplicationFormResponse)
        }).catch((error: AxiosError) => reject(error))
    })
  }
}