<div class="display--flex py-1">
  <m-icon
    :name="mixin_applicationFormSupport_computeStatusIcon(applicationFormSupport)"
    :style="mixin_applicationFormSupport_computeStatusIconStyles(applicationFormSupport)"
    class="my-auto"
  />
  <p class="m-0 px-2">
    {{ applicationFormSupport.supporter_name_with_email }}
    <br />
    <small>
      <template v-if="!mixin_string_isEmpty(applicationFormSupport.updated_at)">
        {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupportRow.label.update_timestamp', {
          timestamp: mixin_timestamp_pretty(applicationFormSupport.updated_at),
          user: applicationFormSupport.updated_by_name
        }) }}
      </template>
      <template v-else-if="!mixin_string_isEmpty(applicationFormSupport.created_at)">
        {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupportRow.label.update_timestamp', {
          timestamp: mixin_timestamp_pretty(applicationFormSupport.created_at),
          user: applicationFormSupport.created_by_name
        }) }}
      </template>
    </small>
  </p>
  <template v-if="mixin_userrole_hasGestionnaireAppuiCandidatureRole">
    <m-icon-button
      icon-name="m-svg__show"
      class="my-auto ml-auto"
      @click="onShowApplicationFormSupportIconClick"
    />
  </template>
</div>