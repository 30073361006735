<main class="error-page">
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <component
      :is="errorPageComponent"
      :title="title"
      :links="links"
      :hints="hints"
    />
  </transition>
</main>