import Component, { mixins } from 'vue-class-component'

import WithRender from './MultiAnswerRow.html'

import { 
  MIconButton,
  MIconButtonSkin
} from '@ulaval/modul-components'

import FormElementMixin from '@/modules/form-management/form-element/FormElement.mixin'
import { IInternalFormTemplateElementModel } from '@/modules/form-template-management/FormTemplate.model'

@WithRender
@Component({
  components: {
    FormElement: () => import('@/modules/form-management/form-element/FormElement'),
    MIconButton
  }
})
export default class MultiAnswerRow extends mixins(FormElementMixin) {
  private multipleElementRows: Array<IInternalFormTemplateElementModel> = []

  private readonly MIconButtonSkin: object = MIconButtonSkin

  private get multiElementRowMinimumCount(): number | null {
    try {
      return Number.parseInt(this.elementInternalLogicParameters['minimum-answer-count'])
    } catch (exception) {
      return null
    }
  }

  private get multiElementRowMaximumCount(): number | null {
    try {
      return Number.parseInt(this.elementInternalLogicParameters['maximum-answer-count'])
    } catch (exception) {
      return null
    }
  }

  private get isMultiElementRow(): boolean {
    return this.elementInternalLogicParameters['multi-row-answer'] || false
  }

  private get hasDeleteIconInList(): boolean {
    if (!this.isMultiElementRow || this.readonly) {
      return false
    } else if (this.multiElementRowMinimumCount) {
      return this.multipleElementRows.length > this.multiElementRowMinimumCount
    }
    
    return this.multipleElementRows.length > 1
  }

  private get hasAddIconInList(): boolean {
    if (!this.isMultiElementRow || this.readonly) {
      return false
    } else if (this.multiElementRowMaximumCount) {
      return this.multipleElementRows.length < this.multiElementRowMaximumCount
    }
 
    return true
  }

  private get maximumAnswerRowCount(): number | null {
    if (!this.answers || this.answers.length === 0) {
      return null
    }

    return Math.max(...Object.values(this.mappedElementChildrensRecrusive(this.internalElement))) || null
  }

  private mounted(): void {
    this.intitializeMultiElementRows()
  }

  private intitializeMultiElementRows(): void {
    this.multipleElementRows = []

    if (this.isMultiElementRow && (this.multiElementRowMinimumCount || this.maximumAnswerRowCount)) {
      let loopUpperBound: number = 0

      if (this.maximumAnswerRowCount && this.multiElementRowMinimumCount) {
        if (this.maximumAnswerRowCount < this.multiElementRowMinimumCount) {
          loopUpperBound = this.multiElementRowMinimumCount
        } else {
          loopUpperBound = this.maximumAnswerRowCount
        }
      } else if (this.maximumAnswerRowCount) {
        loopUpperBound = this.maximumAnswerRowCount
      } else if (this.multiElementRowMinimumCount) {
        loopUpperBound = this.multiElementRowMinimumCount
      }

      for (let i: number = 0; i < loopUpperBound; i++) {
        this.multipleElementRows.push(this.internalElement)
      }
    } else {
      this.multipleElementRows.push(this.internalElement)
    }
  }



  private isMultiElementRowIndexLast(index: number): boolean {
    if (!this.multiElementRowMaximumCount) {
      if (
        this.isMultiElementRow && 
        index === (this.multipleElementRows.length - 1)
      ) {
        return true
      }

      return false
    }

    return index < (this.multiElementRowMaximumCount - 1) && index === (this.multipleElementRows.length - 1)
  }

  private addMultiElementRowElement(): void {
    this.$set(this.multipleElementRows, this.multipleElementRows.length, this.internalElement)
  }

  private isIndexOverMinimumCount(index: number): boolean {
    if (this.multiElementRowMinimumCount) {
      return index > 0 && index >= this.multiElementRowMinimumCount
    }
    
    return index > 0
  } 

  private removeMultiElementRowElement(index: number): void {
    this.$delete(this.multipleElementRows, index)
  }

  private mappedElementChildrensRecrusive(parentElement: IInternalFormTemplateElementModel): { [key: string]: number } {
    let childrens: { [key: string]: number } = {}

    parentElement.childrens.forEach((children: IInternalFormTemplateElementModel) => {
      if (children.id) {
        childrens = {
          ...childrens,
          [children.id]: this.formElementAnswerCount(children)
        }
      }

      childrens = {
        ...childrens,
        ...this.mappedElementChildrensRecrusive(children)
      }
    })

    return childrens
  }
}
