import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './FormTemplateUpdateInsertZone.html'

import { 
  MIcon
} from '@ulaval/modul-components' 

import { DRAG_AND_DROP_EVENTS_IDENTIFIER, DRAG_AND_DROP_EVENTS_IDENTIFIER_KEY, EventBus, EVENT_BUS_EVENTS, IFormTemplateElementInsertDragDroppedEventPayload, IFormTemplateElementMoveDragDroppedEventPayload } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {
    MIcon
  }
})
export default class FormTemplateUpdateInsertZone extends mixins() {
  @Prop()
  private parentInternalIdentifier!: string | null

  @Prop({ required: true })
  private insertionElementPositionIndex!: number

  @Prop({ default: false })
  private dense!: boolean

  private onElementDrop(event: DragEvent): void {
    if (
      event &&
      event.dataTransfer
    ) {
      const eventType: string = event.dataTransfer.getData(DRAG_AND_DROP_EVENTS_IDENTIFIER_KEY)

      if (eventType === DRAG_AND_DROP_EVENTS_IDENTIFIER.FORM_TEMPLATE_ELEMENT_INSERT) {
        EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_DROPPED, {
          element_type: JSON.parse(event.dataTransfer.getData('text')),
          parentInternalIdentifier: this.parentInternalIdentifier || null,
          insertionElementPositionIndex: this.insertionElementPositionIndex
        } as IFormTemplateElementInsertDragDroppedEventPayload)
      } else if (eventType === DRAG_AND_DROP_EVENTS_IDENTIFIER.FORM_TEMPLATE_ELEMENT_MOVE) {
        EventBus.$emit(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_DROPPED, {
          originalElement: JSON.parse(event.dataTransfer.getData('text')),
          parentInternalIdentifier: this.parentInternalIdentifier || null,
          insertionElementPositionIndex: this.insertionElementPositionIndex
        } as IFormTemplateElementMoveDragDroppedEventPayload)
      }
    }
  }

  private onElementDragOver(event: DragEvent): void {
    if (event) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }
}
