import Component, { mixins } from 'vue-class-component'

import HttpStatusCode from 'http-status-codes'

import WithRender from './ErrorPage.html'

import { 
  MErrorAccessDenied,
  MErrorPageNotFound,
  MErrorResourceUnavailable,
  MErrorTechnicalDifficulty
} from '@ulaval/modul-components'

const MANAGED_STATUSES: Array<string> = [
  HttpStatusCode.NOT_FOUND.toString(),
  HttpStatusCode.UNAUTHORIZED.toString(),
  HttpStatusCode.FORBIDDEN.toString(),
  HttpStatusCode.INTERNAL_SERVER_ERROR.toString()
]

@WithRender
@Component({
  components: {
    MErrorAccessDenied,
    MErrorPageNotFound,
    MErrorResourceUnavailable,
    MErrorTechnicalDifficulty
  }
})
export default class ErrorPage extends mixins() {
  private get status(): string {
    const routeStatus: string = this.$route.params.status ? 
      this.$route.params.status.toString() : 
      HttpStatusCode.SERVICE_UNAVAILABLE.toString()

    return MANAGED_STATUSES.includes(routeStatus) ? 
      routeStatus :
      HttpStatusCode.INTERNAL_SERVER_ERROR.toString()
  }

  private get errorPageComponent(): object {
    if (this.status) {
      if (this.status === HttpStatusCode.NOT_FOUND.toString()) {
        return MErrorPageNotFound
      } else if (
        this.status === HttpStatusCode.UNAUTHORIZED.toString() || 
        this.status === HttpStatusCode.FORBIDDEN.toString()
      ) {
        return MErrorAccessDenied
      } else if (
        this.status === HttpStatusCode.INTERNAL_SERVER_ERROR.toString()
      ) {
        return MErrorTechnicalDifficulty
      }
    }

    return MErrorResourceUnavailable
  }

  private get hints(): string[] {
    return this.$i18next.t(`modules.global.error.ErrorPage.status.${this.status}.hints`, { returnObjects: true })
  }

  private get links(): string [] {
    return [
      ...this.$i18next.t(`modules.global.error.ErrorPage.status.${this.status}.links`, { returnObjects: true }),
      ...this.$i18next.t(`modules.global.error.ErrorPage.global_links`, {
        returnObjects: true
      })
    ]
  }

  private get title(): string {
    return this.$i18next.t(`modules.global.error.ErrorPage.status.${this.status}.title`)
  }
}
