<m-overlay
  id="category-management__category-copy-dialog"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
  @save="onSaveButtonClick"
>
  <h2 slot="header">
    {{ $i18next.t('modules.category-management.category-copy.CategoryCopyDialog.overlay_title') }}
  </h2>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showCreationSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.category-management.category-copy.CategoryCopyDialog.message.copy_success.title')"
      />
    </template>
    <template v-else>
      <section class="category-management__category-copy-dialog--content">
        <m-message
          class="my-auto"
          :state="MMessageState.Information"
        >
          {{ $i18next.t('modules.category-management.category-copy.CategoryCopyDialog.message.asynchornous_informations.content') }}
        </m-message>
        <p>
          {{ $i18next.t('modules.category-management.category-copy.CategoryCopyDialog.label.explanation') }}
        </p>
        <ul>
          <template v-for="category in selectedCategories">
            <li class="pb-2">
              <strong>
                {{ category.title }}
              </strong>
            </li>
          </template>
        </ul>
        <div class="mt-6">
          <year-dropdown v-model="targetYear" />
        </div>
      </section>
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="!showCreationSuccessMessage">
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            :disabled="!targetYear"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.category-management.category-copy.CategoryCopyDialog.button.save.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.category-management.category-copy.CategoryCopyDialog.button.cancel.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="closeOverlay"
        >
          {{ $i18next.t('modules.category-management.category-copy.CategoryCopyDialog.button.close.label') }}
        </m-button>
      </template>
    </transition>
  </template>
</m-overlay>