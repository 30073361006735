import Component, { mixins } from 'vue-class-component'

import WithRender from './PublicArea.html'

import PublicYearConfiguration from '@/modules/year-configuration-management/year-configuration/PublicYearConfiguration'

import { generateLocalizedRouteName } from '@/router/helpers'
import ROUTES from '@/router/ROUTES'
import localization from '@/localization'

@WithRender
@Component({
  components: {
    PublicYearConfiguration
  }
})
export default class PublicArea extends mixins() {
  private get isFormTemplatePreviewActive(): boolean {
    return this.$route.name === generateLocalizedRouteName({
      name: ROUTES.PUBLIC_FORM_TEMPLATE_PREVIEW,
      locale: localization.CURRENT_LOCALE
    })
  }
}
