import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './EvaluatorApplicationFormAssignmentRow.html'

import { 
  MLink,
  MLinkMode,
  MSwitch,
  MTooltip,
  MTooltipMode,
  MTooltipSize
} from '@ulaval/modul-components'

import { EvaluatorApplicationFormAssignmentStatus, IEvaluatorApplicationFormAssignmentModel } from '../EvaluatorApplicationFormAssignment.model'
import EvaluatorApplicationFormAssignmentService from '../EvaluatorApplicationFormAssignment.service'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    MLink,
    MSwitch,
    MTooltip
  }
})
export default class EvaluatorApplicationFormAssignmentRow extends mixins(EvaluatorApplicationFormAssignmentService) {
  private isStatusTooltipOpen: boolean = false

  private readonly MTooltipMode: object = MTooltipMode
  private readonly MTooltipSize: object = MTooltipSize
  private readonly MLinkMode: object = MLinkMode

  @Prop({ required: true })
  private evaluatorApplicationFormAssignment!: IEvaluatorApplicationFormAssignmentModel

  private get isStatusSwitchActive(): boolean {
    return this.evaluatorApplicationFormAssignment.status === EvaluatorApplicationFormAssignmentStatus.DONE  
  }

  private get isStatusSwitchDisabled(): boolean {
    return this.evaluatorApplicationFormAssignment.status === EvaluatorApplicationFormAssignmentStatus.OVERDUE  
  }

  private get isApplicationFormLinkDisabled(): boolean {
    return this.evaluatorApplicationFormAssignment.status === EvaluatorApplicationFormAssignmentStatus.OVERDUE  
  }

  private onStatusSwitchChange(value: boolean): void {
    this.updateStatus(value)
  }

  private updateStatus(value: boolean): void {
    const request: Promise<void> = value ?
      this.service_evaluatorApplicationFormAssignmentService_flagAsDone(this.evaluatorApplicationFormAssignment.id) :
      this.service_evaluatorApplicationFormAssignmentService_flagAsInProgress(this.evaluatorApplicationFormAssignment.id)

    request
  }

  private onDisplayApplicationFormLinkClick(): void {
    if (!this.isApplicationFormLinkDisabled) {
      this.mixin_router_navigate({
        name: ROUTES.EVALUATOR_APPLICATION_FORM_DISPLAY,
        params: {
          idEvaluatorApplicationFormAssignment: this.evaluatorApplicationFormAssignment.id
        }
      })
    }
  }

  private onStatusSwitchMouseOver(): void {
    this.displayStatusTooltip()
  }

  private onStatusSwitchMouseLeave(): void {
    this.hideStatusTooltip()
  }

  private displayStatusTooltip(): void {
    this.isStatusTooltipOpen = true
  }

  private hideStatusTooltip(): void {
    this.isStatusTooltipOpen = false
  }
}
