import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './AdminEvaluatorCategoryAssignments.html'

import { 
  AbstractControl, 
  FormControl, 
  FormGroup, 
  MButton,
  MColumnTable, 
  MColumnTextAlign, 
  MIconButton,
  MOption,
  MOptionItem,
  MPagination, 
  MPopup,
  MSearchfield, 
  MTable, 
  MTableHeader 
} from '@ulaval/modul-components'

import CONSTANTS from '@/CONSTANTS'
import { IPaginationResultData } from '@/modules/global/utilities/Pagination.model'
import { IYearModel } from '@/modules/global/year/Year.model'
import EvaluatorCategoryAssignmentService, { IAdminFetchEvaluatorsCategoryAssignmentsQueryParameters, IAdminFetchEvaluatorsCategoryAssignmentsResponse } from '../EvaluatorCategoryAssignment.service'
import EvaluatorApplicationFormAssignmentPopoverRow from '../../evaluator-application-form-assignment-management/evaluator-application-form-assignment/EvaluatorApplicationFormAssignmentPopoverRow'
import ROUTES from '@/router/ROUTES'
import { IEvaluatorCategoryAssignmentAdminModel } from '../EvaluatorCategoryAsssignment.model'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import EmailStatusRow from '@/modules/global/email/EmailStatusRow'


@WithRender
@Component({
  components: {
    EmailStatusRow,
    EvaluatorApplicationFormAssignmentPopoverRow,
    MButton,
    MIconButton,
    MOption,
    MOptionItem,
    MPagination,
    MPopup,
    MSearchfield,
    MTable,
    MTableHeader
  }
})
export default class AdminEvaluatorCategoryAssignments extends mixins(EvaluatorCategoryAssignmentService) {
  public formGroup: FormGroup = new FormGroup({
    'search-field': new FormControl()
  })
  private evaluatorCategoryAssignments: Array<IEvaluatorCategoryAssignmentAdminModel> = []
  private paginationData: IPaginationResultData | null = null
  private isLoadingStateActive: boolean = false
  
  @Prop({ required: true })
  private year!: IYearModel

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadEvaluatorCategoryAssignments()
  }

  public get searchField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('search-field');
  }

  private get tableColumns(): Array<MColumnTable> {
    return [
      {
        id: 'evaluator',
        title: this.$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.table.columns.evaluator.title'),
        dataProp: 'evaluator_full_name_with_username',
        width: '400px'
      },
      {
        id: 'category',
        title: this.$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.table.columns.category.title'),
        dataProp: 'category_title'
      },
      {
        id: 'options',
        title: this.$i18next.t('modules.evaluator-management.evaluator-category-assignment-management.evaluator-category-assignments.AdminEvaluatorCategoryAssignments.table.columns.options.title'),
        dataProp: 'options',
        textAlign: MColumnTextAlign.Right,
        width: '50px'
      }
    ]
  }

  private mounted(): void {
    this.afterEvaluatorCategoryAssignmentCreation()
    this.afterEvaluatorCategoryAssignmentUpdate()
  }

  private afterEvaluatorCategoryAssignmentCreation(): void {
    EventBus.$on(EVENT_BUS_EVENTS.EVALUATOR_CATEGORY_ASSIGNMENT_CREATED, () => {
      this.loadEvaluatorCategoryAssignments()
    })
  }

  private afterEvaluatorCategoryAssignmentUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.EVALUATOR_CATEGORY_ASSIGNMENT_UPDATED, () => {
      this.loadEvaluatorCategoryAssignments()
    })
  }

  private loadEvaluatorCategoryAssignments(pageIndex: number = 0): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    const query: IAdminFetchEvaluatorsCategoryAssignmentsQueryParameters = {
      year_id: this.year.id,
      query: this.searchField.value,
      page: pageIndex
    }

    this.service_evaluatorCategoryAssignmentService_adminFetchEvaluatorsCategoryAssignments(query)  
      .then((responseData: IAdminFetchEvaluatorsCategoryAssignmentsResponse) => {
        this.evaluatorCategoryAssignments = responseData.evaluator_category_assignments || []
        this.paginationData = responseData.pagination || null
      }).catch(() => {
        this.evaluatorCategoryAssignments = []
        this.paginationData = null
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private onSearchFieldChange(): void {
    this.loadEvaluatorCategoryAssignments(0)
  }

  private onPageChange(newIndex: number): void {
    this.loadEvaluatorCategoryAssignments(newIndex - 1)
  }

  private onTriggerCreationButtonClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.EVALUATOR_MANAGEMENT_CREATION
    })
  }

  private onTriggerUpdateButtonClick(evaluatorCategoryAssignment: IEvaluatorCategoryAssignmentAdminModel): void {
    if (evaluatorCategoryAssignment) {
      this.mixin_router_navigate({
        name: ROUTES.EVALUATOR_MANAGEMENT_UPDATE,
        params: {
          idEvaluator: evaluatorCategoryAssignment.evaluator_id
        }
      })
    }
  }

  private onTriggerEvaluatorToolboxFileDepositButtonClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.EVALUATOR_MANAGEMENT_EVALUATOR_TOOLBOX_FILE_DEPOSIT
    })
  }

}
