import { ICategoryModel } from "../category-management/Category.model";
import { IBaseModel } from "../global/utilities/Base.model";

export interface IFormTemplateElementParameterModel extends IBaseModel {
  key: string;
  value: string;
  frontend_key: string;
  frontend_type: FormTemplateElementParameterType;
}

export interface IFormTemplateElementOptionModel extends IBaseModel {
  value: string;
  order: string;
}

export interface IFormTemplateElementModel extends IBaseModel {
  element_type: string;
  element_position_index: number;
  component_name: string;
  option_component_name: string;
  childrens: Array<IFormTemplateElementModel>;
  element_parameters: Array<IFormTemplateElementParameterModel>;
  element_options: Array<IFormTemplateElementOptionModel>;
}

export interface IFormTemplateModel extends IBaseModel {
  type: string;
  is_updateable: boolean;
  category?: ICategoryModel;
  elements?: Array<IFormTemplateElementModel>;
}

export interface IFormTemplateAvailableElementModelParameter {
  key: string;
  type: string;
  options: Array<string>;
}

export interface IFormTemplateAvailableElementModel {
  element_type: string;
  element_section: string;
  element_parameters: Array<IFormTemplateAvailableElementModelParameter>;
  allow_options: boolean;
  allow_childrens: boolean;
  allow_as_root_element: boolean;
  allowed_children_types: Array<string>;
}

export interface IInternalFormTemplateElementParameterModel {
  id?: string;
  key: string;
  value: string;
  frontend_key?: string;
  frontend_type?: FormTemplateElementParameterType;
}

export interface IInternalFormTemplateElementOptionModel {
  id?: string;
  value: string;
  order: number;
}

export interface IInternalFormTemplateElementModel {
  id?: string;
  internal_identifier: string;
  parent_internal_identifier?: string;
  element_type: string;
  element_position_index: number;
  component_name?: string;
  option_component_name?: string;
  childrens: Array<IInternalFormTemplateElementModel>;
  element_parameters: Array<IInternalFormTemplateElementParameterModel>;
  element_options: Array<IInternalFormTemplateElementOptionModel>;
}

export interface IInternalFormTemplateModel {
  id: string;
  type: string;
  is_updateable: boolean;
  category: ICategoryModel;
  elements: Array<IInternalFormTemplateElementModel>;
}

export enum FormTemplateElementParameterType {
  PROP = 'PROP',
  SLOT = 'SLOT',
  STYLE = 'STYLE',
  CONTENT = 'CONTENT',
  INTERNAL_LOGIC = 'INTERNAL_LOGIC',
  DEFAULT_VALUE = 'DEFAULT_VALUE',
  CSS_CLASS = 'CSS_CLASS',
  CSS_STYLE = 'CSS_STYLE'
}