import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import WithRender from './AdminEvaluatorCategoryAssignmentCreationOverlay.html'

import { 
  MButton,
  MButtonSkin,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay
} from '@ulaval/modul-components'

import { IYearModel } from '@/modules/global/year/Year.model'
import ROUTES from '@/router/ROUTES'
import AdminEvaluatorCategoryAssignmentForm, { IAdminEvaluatorCategoryAssignmentFormFormPayloadData } from '../evaluator-category-assignment-form/AdminEvaluatorCategoryAssignmentForm'
import EvaluatorCategoryAssignmentService, { ICreateEvaluatorCategoryAssignmentForm } from '../EvaluatorCategoryAssignment.service'
import { ICategoryModel } from '@/modules/category-management/Category.model'

const FORM_REF: string = 'admin-evaluator-category-assignment-creation-form'

@WithRender
@Component({
  components: {
    AdminEvaluatorCategoryAssignmentForm,
    MButton,
    MMessage,
    MOverlay
  }
})
export default class AdminEvaluatorCategoryAssignmentCreationOverlay extends mixins(EvaluatorCategoryAssignmentService) {
  private isOverlayOpen: boolean = true
  private isLoadingStateActive: boolean = false
  private showCreationSuccessMessage: boolean = false
  private showCreationErrorMessage: boolean = false

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin
  private readonly FORM_REF: string = FORM_REF

  @Prop({ required: true })
  private year!: IYearModel

  @Ref(FORM_REF)
  private formComponent!: AdminEvaluatorCategoryAssignmentForm

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.EVALUATOR_MANAGEMENT_LIST
    })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.formComponent) {
      const formPayload: IAdminEvaluatorCategoryAssignmentFormFormPayloadData | null = await this.formComponent.getFormPayload()

      if (formPayload) {
        this.createEvaluatorCategoryAssignments(formPayload)
      }
    }
  }

  private createEvaluatorCategoryAssignments(formPayload: IAdminEvaluatorCategoryAssignmentFormFormPayloadData): void {
    this.isLoadingStateActive = true

    const requestPayload: ICreateEvaluatorCategoryAssignmentForm = {
      evaluator_idul: formPayload.evaluator_idul,
      categories_id: formPayload.categories.map((category: ICategoryModel) => category.id)
    }

    this.service_evaluatorCategoryAssignmentService_createEvaluatorCategoryAssignment(requestPayload)
      .then(() => {
        this.showCreationSuccessMessage = true
        this.showCreationErrorMessage = false
      }).catch(() => {
        this.showCreationSuccessMessage = false
        this.showCreationErrorMessage = true
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }
}
