import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { IYearModel } from "./Year.model";
import BaseService from "../utilities/Base.service";
import { STORE_ACTIONS } from "@/store";

@Component
export default class YearService extends BaseService {
  protected service_yearService_fetchList(): Promise<Array<IYearModel>> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/years`)
        .then((response: AxiosResponse) => {
          const years: Array<IYearModel> = response.data.data.years

          this.$store.dispatch(STORE_ACTIONS.YEAR_STORE_STORE_YEARS, years)

          resolve(years)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_yearService_fetchCurrent(): Promise<IYearModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/years/current`)
      .then((response: AxiosResponse) => {
        const year: IYearModel = response.data.data.year

        this.$store.dispatch(STORE_ACTIONS.YEAR_STORE_STORE_CURRENT_YEAR, year)

        resolve(year)
      }).catch((error: AxiosError) => reject(error))
    })
  }
}