import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './PublicCategoryCard.html'

import {
  MIcon,
  MLink,
  MPanel
} from '@ulaval/modul-components'

import { ICategoryModel } from '../Category.model'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    MIcon,
    MLink,
    MPanel
  }
})
export default class PublicCategoryCard extends mixins() {
  @Prop({ required: true })
  private category!: ICategoryModel

  private get isApplicationFormDepositTimestampOverThreshold(): boolean {
    return this.$moment(this.category.application_deposit_limit_timestamp).isBefore()
  }

  private onCategoryTitleClick(): void {
    this.displayFormTemplatePublicPreview()
  }

  private displayFormTemplatePublicPreview(): void {
    this.mixin_router_navigate({
      name: ROUTES.PUBLIC_FORM_TEMPLATE_PREVIEW,
      params: {
        idCategory: this.category.id
      }
    })
  }
}
