import Component from 'vue-class-component'

import {
  MOptionItemPredefined,
} from '@ulaval/modul-components'


@Component({})
export default class RestoreItemOption extends MOptionItemPredefined {
  protected get iconName(): string {
    return 'm-svg__arrow-return'
  }

  protected get label(): string {
    return this.$i18next.t('modules.global.components.option-item.RestoreItemOption.label')
  }
}
