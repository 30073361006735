import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './Categories.html'

import { 
  MButton,
  MButtonSkin,
  MCheckbox,
  MColumnSortDirection,
  MTable,
  MTableHeader,
  MColumnTable,
  MColumnTextAlign,
  MDialog,
  MDialogState,
  MDialogWidth,
  MIcon,
  MProgress,
  MOption,
  MOptionItem,
  MOptionItemEdit,
  MOptionItemDelete,
  MSwitch,
  MToast,
  MToastPosition,
  MToastState,
  MToastTimeout,
  MToastDuration
} from '@ulaval/modul-components'

import CategoryCopyDialog from '../category-copy/CategoryCopyDialog'

import { ICategoryModel } from '../Category.model'
import CategoryService, { IFetchCategoryListQueryParameters } from '../Category.service'
import { IYearModel } from '@/modules/global/year/Year.model'
import { IFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'

import CONSTANTS from '@/CONSTANTS'
import ROUTES from '@/router/ROUTES'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import UserRoleMixin from '@/modules/user-management/UserRole.mixin'

@WithRender
@Component({
  components: {
    CategoryCopyDialog,
    MButton,
    MCheckbox,
    MDialog,
    MIcon,
    MProgress,
    MOption,
    MOptionItem,
    MOptionItemEdit,
    MOptionItemDelete,
    MTable,
    MTableHeader,
    MToast,
    MSwitch
  }
})
export default class Categories extends mixins(CategoryService, UserRoleMixin) {
  private categories: Array<ICategoryModel> = []
  private selectedCopyCategoriesId: Array<string> = []
  private isLoadingStateActive: boolean = false
  private withSoftDeletedCategoriesFilter: boolean = true
  private appliedSortColumn: MColumnTable | null = null
  private isCategoryCopyDialogVisible: boolean = false
  private isCategoryDeleteDialogVisible: boolean = false
  private selectedCategoryForDelete: ICategoryModel | null = null
  private isCategoryDeleteLoadingStateActive: boolean = false
  private isCategoryDeleteSuccessToastOpen: boolean = false

  private readonly MButtonSkin: object = MButtonSkin
  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout

  @Prop({ required: true })
  private year!: IYearModel

  @Watch('withSoftDeletedCategoriesFilter')
  private onWithSoftDeletedCategoriesFilterChange(): void {
    this.loadCategories()
    this.onSelectAllCategoriesForCopyChange(false)
  }

  @Watch('year')
  private onYearChange(): void {
    this.loadCategories()
    this.onSelectAllCategoriesForCopyChange(false)
  }

  private get sortedCategories(): Array<ICategoryModel> {
    if (!this.sortColumn.sortDirection) {
      return this.categories
    }

    return Array.from(this.categories).sort((leftHandSide: any, rightHandSide: any) => {
      const leftHandSideValue: number = leftHandSide[this.sortColumn.dataProp] as number
      const rightHandSideValue: number = rightHandSide[this.sortColumn.dataProp] as number

      return this.sortColumn.sortDirection === MColumnSortDirection.Asc ?
        leftHandSideValue - rightHandSideValue :
        rightHandSideValue - leftHandSideValue
    })
  }

  private get sortColumn(): MColumnTable {
    if (!this.appliedSortColumn) {
      return {
        id: 'sort-order',
        title: '',
        dataProp: 'sort_order',
        sortDirection: MColumnSortDirection.Asc,
      }
    }

    return this.appliedSortColumn
  }

  private get tableColumns(): Array<MColumnTable> {
    return [
      {
        id: 'copy-selector',
        title: this.$i18next.t('modules.category-management.categories.Categories.table.headers.copy_selector.title'),
        dataProp: 'copy-selector',
        textAlign: MColumnTextAlign.Left,
        width: '60px'
      },
      {
        id: 'sort-order',
        title: this.$i18next.t('modules.category-management.categories.Categories.table.headers.sort_order.title'),
        dataProp: 'sort_order',
        textAlign: MColumnTextAlign.Left,
        sortable: true,
        sortDirection: MColumnSortDirection.Asc,
        defaultSortDirection: MColumnSortDirection.Asc,
        width: '75px'
      },
      {
        id: 'title',
        title: this.$i18next.t('modules.category-management.categories.Categories.table.headers.title.title'),
        dataProp: 'title',
        textAlign: MColumnTextAlign.Left
      },
      {
        id: 'application-deposit-limit-timestamp',
        title: this.$i18next.t('modules.category-management.categories.Categories.table.headers.application_limit_timestamp.title'),
        dataProp: 'application-deposit-limit-timestamp',
        textAlign: MColumnTextAlign.Center,
        width: '200px'
      },
      {
        id: 'timestamp',
        title: this.$i18next.t('modules.category-management.categories.Categories.table.headers.timestamp.title'),
        dataProp: 'timestamp'
      },
      {
        id: 'soft-delete-state',
        title: this.$i18next.t('modules.category-management.categories.Categories.table.headers.soft_delete_state.title'),
        dataProp: 'soft-delete-state',
        textAlign: MColumnTextAlign.Center
      },
      {
        id: 'options',
        title: this.$i18next.t('modules.category-management.categories.Categories.table.headers.options.title'),
        dataProp: 'options',
        textAlign: MColumnTextAlign.Right,
        width: '50px'
      }
    ]
  }

  private get hasSelectedCategoriesForCopy(): boolean {
    return this.selectedCopyCategoriesId.length > 0
  }

  private get hasSelectedAllCategoriesForCopy(): boolean {
    return this.categories.length > 0 && this.selectedCopyCategoriesId.length === this.categories.length
  }

  private get selectedCopyCategories(): Array<ICategoryModel> {
    return this.selectedCopyCategoriesId.map((id: string) => {
      return this.categories.find((category: ICategoryModel) => category.id === id) as ICategoryModel
    })
  }

  private mounted(): void {
    this.loadCategories()

    this.afterCategoryCreated()
    this.afterCategoryUpdated()
    this.afterCategoryDeleted()
  }

  private afterCategoryCreated(): void {
    EventBus.$on(EVENT_BUS_EVENTS.CATEGORY_CREATED, () => {
      this.loadCategories()
    })
  }

  private afterCategoryUpdated(): void {
    EventBus.$on(EVENT_BUS_EVENTS.CATEGORY_UPDATED, (updatedCategory: ICategoryModel) => {
      if (updatedCategory) {
        const originalCategoryIndex: number | undefined = this.categories.findIndex((category: ICategoryModel) => category.id === updatedCategory.id)

        if (~originalCategoryIndex) {
          if (!this.withSoftDeletedCategoriesFilter && updatedCategory.deleted_at) {
            this.$delete(this.categories, originalCategoryIndex)
          } else {
            this.$set(this.categories, originalCategoryIndex, updatedCategory)
          }
        }
      }

      this.loadCategories(true)
    })
  }

  private afterCategoryDeleted(): void {
    EventBus.$on(EVENT_BUS_EVENTS.CATEGORY_DELETED, (id: string) => {
      if (id) {
        const originalCategoryIndex: number | undefined = this.categories.findIndex((category: ICategoryModel) => category.id === id)

        if (~originalCategoryIndex) {
          this.$delete(this.categories, originalCategoryIndex)
        }
      }
    })
  }

  private loadCategories(backgroundLoad: boolean = false): void {
    let loadingStateActivationTimeout: any = null

    if (!backgroundLoad) {
      loadingStateActivationTimeout = setTimeout(() => {
        this.isLoadingStateActive = true
      }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)
    }

    const query: IFetchCategoryListQueryParameters = {
      year_id: this.year.id,
      with_soft_deleted: this.withSoftDeletedCategoriesFilter
    }

    this.service_categoryService_fetchList(query)
      .then((responseData: Array<ICategoryModel>) => {
        this.categories = responseData || []
      }).catch(() => {
        this.categories = []
      }).finally(() => {
        if (loadingStateActivationTimeout) {
          clearTimeout(loadingStateActivationTimeout)
        }
        this.isLoadingStateActive = false
      })
  }

  private onCreateCategoryButtonClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_CREATION
    })
  }

  private onCategoryUpdateOptionClick(category: ICategoryModel): void {
    if (category) {
      this.mixin_router_navigate({
        name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_UPDATE,
        params: {
          idCategory: category.id
        }
      })
    }
  }

  private onCategorySoftDeleteStateChange(category: ICategoryModel, newStateValue: boolean): void {
    if (!newStateValue) {
      this.softDeleteCategory(category)
    } else {
      this.restoreCategory(category)
    }
  }

  private softDeleteCategory(category: ICategoryModel): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)
    
    this.service_categoryService_softDelete(category.id)
      .finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private restoreCategory(category: ICategoryModel): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)
    
    this.service_categoryService_restore(category.id)
      .finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private onSelectCategoryForCopyChange(categoryId: string, selected: boolean): void {
    if (selected) {
      if (!this.selectedCopyCategoriesId.includes(categoryId)) {
        this.$set(this.selectedCopyCategoriesId, this.selectedCopyCategoriesId.length, categoryId)
      }
    } else {
      const selectedCategoryId: number | undefined = this.selectedCopyCategoriesId.indexOf(categoryId)

      if (~selectedCategoryId) {
        this.$delete(this.selectedCopyCategoriesId, selectedCategoryId)
      }
    }
  }

  private onSelectAllCategoriesForCopyChange(selected: boolean): void {
    this.categories.forEach((category: ICategoryModel) => this.onSelectCategoryForCopyChange(category.id, selected))
  }

  private onTableSortApplied(column: MColumnTable): void {
    this.appliedSortColumn = column || null
  }

  private displayCategoryCopyDialog(): void {
    this.isCategoryCopyDialogVisible = true
  }

  private hideCategoryCopyDialog(): void {
    this.isCategoryCopyDialogVisible = false

    this.selectedCopyCategoriesId = []
  }

  private onCategoryDeleteOptionClick(category: ICategoryModel): void {
    if (category) {
      this.selectedCategoryForDelete = category
      this.displayCategoryDeleteDialog()
    }
  }

  private displayCategoryDeleteDialog(): void {
    this.isCategoryDeleteDialogVisible = true
  }

  private hideCategoryDeleteDialog(): void {
    this.isCategoryDeleteDialogVisible = false
    this.selectedCategoryForDelete = null
    this.isCategoryDeleteLoadingStateActive = false
  }

  private deleteCategory(): void {
    if (this.selectedCategoryForDelete) {
      this.isCategoryDeleteLoadingStateActive = true

      this.service_categoryService_delete(this.selectedCategoryForDelete.id)
        .then(() => {
          this.isCategoryDeleteSuccessToastOpen = true

          setTimeout(() => {
            this.isCategoryDeleteSuccessToastOpen = false
          }, MToastDuration.DesktopXShort)
        }).finally(() => {
          this.isCategoryDeleteLoadingStateActive = false
        })
    }
  }

  private onFormTemplateOptionClick(category: ICategoryModel, formTemplate: IFormTemplateModel): void {
    this.mixin_router_navigate({
      name: ROUTES.FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_PREVIEW,
      params: {
        idCategory: category.id,
        idFormTemplate: formTemplate.id
      }
    })
  }

  private onTriggerEvaluationCardDepositOverlayOptionClick(category: ICategoryModel): void {
    this.mixin_router_navigate({
      name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_EVALUATION_CARD_DEPOSIT,
      params: {
        idCategory: category.id
      }
    })
  }
}
