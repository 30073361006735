import Component, { mixins } from "vue-class-component";

import { EventBus, EVENT_BUS_EVENTS } from "./Events";

export interface IPaginationQueryParameters {
  page?: number;
  limit?: number;
}

@Component
export default class BaseService extends mixins() {
  protected service_baseService_generateFilterQuery(filters: object): string {
    return Object.entries(filters).reduce((queryParameters: string[], [ attribute, value ]: string[]) => {
      if (value) {
        if (Array.isArray(value)) {
          value = value.join(',')
        } else if (typeof value === 'object') {
          value = this.service_baseService_generateObjectFilter(value)
        }

        return [
          ...queryParameters,
          `${attribute}=${value}`
        ]
      }

      return queryParameters
    }, []).join('&')
  }

  protected service_baseService_generateObjectFilter(object: object): string {
    return Object.entries(object).reduce((queryParameters: string[], [ attribute, value ]: string[]) => {
      if (value) {
        return [
          ...queryParameters,
          `${attribute},${value}`
        ]
      }

      return queryParameters
    }, []).join(';')
  }

  protected service_baseService_emitEventBusEvent(event: EVENT_BUS_EVENTS, payload: any): void {
    EventBus.$emit(event, payload)
  }

  protected service_baseService_multipartFormDataHeader(): object {
    return {
      'Content-Type': 'multipart/form-data'
    }
  }
}