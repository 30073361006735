import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import WithRender from './ApplicationFormCreationDialog.html'

import { 
  MButton,
  MButtonSkin,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay
} from '@ulaval/modul-components'

import ApplicationFormForm, { IApplicationFormFormPayloadData } from '../application-form-form/ApplicationFormForm'

import { IYearModel } from '@/modules/global/year/Year.model'
import ApplicationFormService, { ICreateApplicationFormForm } from '../ApplicationForm.service'
import ROUTES from '@/router/ROUTES'
import { AxiosError } from 'axios'
import { IApplicationFormModel } from '../ApplicationForm.model'

const FORM_REF: string = 'application-form-form'

@WithRender
@Component({
  components: {
    ApplicationFormForm,
    MButton,
    MMessage,
    MOverlay
  }
})
export default class ApplicationFormCreationDialog extends mixins(ApplicationFormService) {
  private isOverlayOpen: boolean = true
  private isLoadingStateActive: boolean = false
  private showCreationSuccessMessage: boolean = false
  private createdApplicationForm: IApplicationFormModel | null = null
  private requestErrors: { [key: string]: string | boolean } = {}

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin
  private readonly FORM_REF: string = FORM_REF

  @Prop({ required: true })
  private year!: IYearModel

  @Ref(FORM_REF)
  private applicationFormFormComponent!: ApplicationFormForm

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.DASHBOARD
    })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.applicationFormFormComponent) {
      const formPayload: IApplicationFormFormPayloadData | null = await this.applicationFormFormComponent.getFormPayload()
      
      if (formPayload) {
        this.createApplicationForm(formPayload)
      }
    }
  }

  private createApplicationForm(formPayload: IApplicationFormFormPayloadData): void {
    this.isLoadingStateActive = true

    const requestPayload: ICreateApplicationFormForm = {
      ...formPayload
    }

    this.service_applicationFormService_createApplicationForm(requestPayload)
      .then((response: IApplicationFormModel) => {
        this.showCreationSuccessMessage = true
        this.createdApplicationForm = response
      }).catch((error: AxiosError<any>) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.validation
        ) {
          this.requestErrors = error.response.data.validation
        } else {
          this.requestErrors = {}
        }
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private navigateToApplicationForm(): void {
    if (this.createdApplicationForm) {
      this.mixin_router_navigate({
        name: ROUTES.APPLICATION_FORM,
        params: {
          idApplicationForm: this.createdApplicationForm.id
        }
      })
    }
  }
}
