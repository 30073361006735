import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import BaseService from "../global/utilities/Base.service";
import { IEvaluatorToolboxFileModel } from "./EvaluatorToolboxFile.model";
import { IFileUploadModel } from "../global/file/File.model";

export interface IFetchEvaluatorToolboxFileListQueryParameters {
  year_id: string;
}

export interface IFetchEvaluatorToolboxFileDepositQueryParameters {
  year_id: string;
}

@Component
export default class EvaluatorToolboxFileService extends BaseService {
  protected service_evaluatorToolboxFileService_fetchList(query: IFetchEvaluatorToolboxFileListQueryParameters): Promise<Array<IEvaluatorToolboxFileModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/admin/evaluator-toolbox-files?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.evaluator_toolbox_files))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorToolboxFileService_deposit(query: IFetchEvaluatorToolboxFileDepositQueryParameters, files: Array<IFileUploadModel<IEvaluatorToolboxFileModel>>): Promise<Array<IEvaluatorToolboxFileModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)
      const data: FormData = new FormData()

      files.forEach((uploadedFile: IFileUploadModel<IEvaluatorToolboxFileModel>, fileIndex: number) => {
        if (uploadedFile.server_file) {
          data.set(`files[${fileIndex}].id`, uploadedFile.server_file.id)
        } else if (uploadedFile && uploadedFile.file) {
          data.append(`files[${fileIndex}].file`, uploadedFile.file.file)
        }
      })

      this.$axios.post(
        `/api/admin/evaluator-toolbox-files?${queryString}`,
        data,
        {
          headers: this.service_baseService_multipartFormDataHeader()
        }
      ).then((response: AxiosResponse) => resolve(response.data.data.evaluator_toolbox_files))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_evaluatorToolboxFileService_fetchEvaluatorList(query: IFetchEvaluatorToolboxFileListQueryParameters): Promise<Array<IEvaluatorToolboxFileModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/evaluator-toolbox-files?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.evaluator_toolbox_files))
        .catch((error: AxiosError) => reject(error))
    })
  }
}