<m-overlay
  id="application-form-management__application-form-authorized-users-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
>
  <template #header>
    <h2>
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.overlay_title') }}
    </h2>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.message.success.title')"
      />
    </template>
    <template v-else>
      <div>
        <div class="application-form-management__application-form-authorized-users-overlay--row">
          <user-form
            :ref="USER_FORM_REF"
            class="mt-4"
            :with-role-selection="false"
            :idul-search-bar-placeholder="$i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.label.user_form_idul_placeholder')"
          />
          <m-button
            :skin="MButtonSkin.Secondary"
            :full-size="true"
            class="mt-4"
            @click="onAddUserButtonClick"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.button.add_user.label') }}
          </m-button>
        </div>
        <div class="mt-12">
          <div class="application-form-management__application-form-authorized-users-overlay--row">
            <div class="application-form-management__application-form-authorized-users-overlay--column">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.label.user_zone') }}
              </label>
              <hr />
            </div>
          </div>
          <transition
            name="fade-fast"
            mode="out-in"
          >
            <template v-if="isAuthorizedUserListEmpty">
              <m-message :state="MMessageState.Error">
                {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.message.empty_list.content') }}
              </m-message>
            </template>
          </transition>
          <template v-for="user, index in authorizedUsers">
            <div class="application-form-management__application-form-authorized-users-overlay--row display--flex">
              <p>
                {{ computeUserFullName(user) }}
                <br />
                <small style="text-transform: uppercase;">
                  {{ user.username || user.idul }}
                </small>
              </p>
              <m-icon-button
                class="ml-auto my-auto"
                icon-name="m-svg__delete"
                @click="onRemoveAuthorizedUserIconClick(index)"
              />
            </div>
            <template v-if="index < authorizedUsers.length - 1">
              <hr />
            </template>
          </template>
        </div>
      </div>
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="!showSuccessMessage">
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            :disabled="isAuthorizedUserListEmpty"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.button.save.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.button.cancel.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="closeOverlay"
        >
          {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormAuthorizedUsersOverlay.button.close.label') }}
        </m-button>
      </template>
    </transition>
  </template>
</m-overlay>