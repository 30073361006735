<section class="application-form-support-evaluation main-content">
  <breadcrumbs />
  <m-panel class="main-content__panel">
    <template v-if="internalFormTemplate && evaluatorApplicationFormAssignment">
      <h3 slot="header">
        {{ $i18next.t('modules.evaluator-management.evaluator-application-form-assignment-management.evaluator-application-form-assignment.EvaluatorApplicationFormSupportAssignment.header.title') }}
      </h3>
      <template v-if="!isLoadingStateActive">
        <div class="application-form-support-evaluation--informations mb-6">
          <m-panel>
            <ul class="mb-0">
              <li>
                <strong>
                  {{ $i18next.t('modules.evaluator-management.evaluator-application-form-assignment-management.evaluator-application-form-assignment.EvaluatorApplicationFormSupportAssignment.informations.category') }}
                </strong>
                {{ internalFormTemplate.category.title }}
              </li>
              <li class="mt-2">
                <strong>
                  {{ $i18next.t('modules.evaluator-management.evaluator-application-form-assignment-management.evaluator-application-form-assignment.EvaluatorApplicationFormSupportAssignment.informations.candidate') }}
                </strong>
                {{ evaluatorApplicationFormAssignment.candidate_full_name }}
              </li>
            </ul>
          </m-panel>
        </div>
      </template>
    </template>
    <template #header-right-content>
      <template v-if="internalFormTemplate">
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="navigateToApplicationForm"
        >
          {{ $i18next.t(`modules.evaluator-management.evaluator-application-form-assignment-management.evaluator-application-form-assignment.EvaluatorApplicationFormSupportAssignment.button.navigate_application_form.label`) }}
        </m-button>
      </template>
      <template v-if="evaluationCard && evaluationCard.download_endpoint">
        <m-button
          class="ml-2"
          @click="downloadEvaluationCard"
        >
          {{ $i18next.t(`modules.evaluator-management.evaluator-application-form-assignment-management.evaluator-application-form-assignment.EvaluatorApplicationFormSupportAssignment.button.evaluation_card_download.label`) }}
        </m-button>
      </template>
    </template>
    <template v-if="isLoadingStateActive">
      <div
        key="spinner"
        class="my-15"
      >
        <m-spinner
          :title="true"
          :description="true"
        />
      </div>
    </template>
    <template v-if="!isLoadingStateActive && evaluatorApplicationFormAssignment">
      <m-message
        class="mb-10"
        :state="MMessageState.Information"
      >
        {{ $i18next.t(`modules.evaluator-management.evaluator-application-form-assignment-management.evaluator-application-form-assignment.EvaluatorApplicationFormSupportAssignment.message.update_not_available.label`) }}
      </m-message>
    </template>
    <div v-show="!isLoadingStateActive">
      <template v-if="internalFormTemplate && evaluatorApplicationFormAssignment">
        <form-elements
          :key="internalFormTemplate.internal_identifier"
          :elements="internalFormTemplate.elements"
          :answers="formAnswers"
          :is-form-readonly="true"
          :display-duplicate-answers="true"
          :hide-sensitive-informations="true"
          :display-evaluator-additionnal-informations="true"
        />
      </template>
    </div>
  </m-panel>
  <a
    :ref="REF_EVALUATION_CARD_DOWNLOAD_LINK"
    class="display--none"
  />
</section>