<div class="multi-answer-row">
  <template v-for="row, index in multipleElementRows">
    <div class="display--flex flex__direction--row width--100">
      <div class="display--flex flex__direction--row width--100">
        <template v-for="children in internalElement.childrens">
          <form-element
            :internal-element="children"
            :answers="answers"
            :row-position-index="index"
            :readonly="readonly"
            :display-duplicate-answers="displayDuplicateAnswers"
            :hide-sensitive-informations="hideSensitiveInformations"
            :display-evaluator-additionnal-informations="displayEvaluatorAdditionnalInformations"
          />
        </template>
      </div>
      <template v-if="hasDeleteIconInList">
        <div class="multi-answer-row--icon">
          <template v-if="isIndexOverMinimumCount(index)">
            <m-icon-button
              class="my-auto py-auto"
              icon-name="m-svg__delete"
              :skin="MIconButtonSkin.Light"
              @click="removeMultiElementRowElement(index)"
            />
          </template>
        </div>
      </template>
      <template v-if="hasAddIconInList">
        <div class="multi-answer-row--icon">
          <template v-if="isMultiElementRowIndexLast(index)">
            <m-icon-button
              class="my-auto"
              icon-name="m-svg__add-circle-filled"
              :skin="MIconButtonSkin.Link"
              @click="addMultiElementRowElement"
            />
          </template>
        </div>
      </template>
    </div>
  </template>
</div>