<m-overlay
  id="application-form-management__application-form-reminder-email-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
>
  <template #trigger>
    <m-button @click="onTriggerButtonClick">
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.trigger.label') }}
    </m-button>
  </template>
  <template #header>
    <h2>
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.overlay_title') }}
    </h2>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.message.success.title')"
      />
    </template>
    <template v-else>
      <m-form :form-group="formGroup">
        <template v-if="!isLoadingStateActive && !hasAvailableCategories">
          <m-message
            class="mb-6"
            :state="MMessageState.Error"
          >
            <p
              class="m-0"
              v-html="$i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.message.categories_list_empty.content')"
            />
          </m-message>
        </template>
        <template v-else>
          <m-message
            class="mb-6"
            :state="MMessageState.Information"
          >
            <p class="m-0">
              {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.message.email_send_informations.async_send') }}
              <br />
              {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.message.email_send_informations.selected_application_forms') }}
            </p>
          </m-message>
          <m-multi-select
            v-model="selectedCategories"
            :options="categoriesSelectOptions"
            v-m-control="categoryMultiSelect"
            :required-marker="true"
            max-width="none"
            :link-select-all="true"
            :waiting="isCategoryLoadingStateActive"
            :defaultChipCharsTrunk="100"
            :label="$i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.field.categories.label')"
            :placeholder="$i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.field.categories.placeholder')"
            :label-up="true"
            :error="categoryMultiSelect.hasError()"
            :error-message="categoryMultiSelect.errorMessage"
          />
        </template>
      </m-form>
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="!showSuccessMessage && hasAvailableCategories">
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.button.save.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.button.cancel.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="closeOverlay"
        >
          {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormReminderEmailOverlay.button.close.label') }}
        </m-button>
      </template>
    </transition>
  </template>
</m-overlay>