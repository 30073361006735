import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './ApplicationFormSupportRow.html'

import { 
  MIcon,
  MIconButton,
  MTooltip,
  MTooltipSize
} from '@ulaval/modul-components'

import { IApplicationFormSupportModel } from '../ApplicationFormSupport.model'
import ApplicationFormSupportMixin from '../ApplicationFormSupport.mixin'
import ROUTES from '@/router/ROUTES'
import UserRoleMixin from '@/modules/user-management/UserRole.mixin'

@WithRender
@Component({
  components: {
    MIcon,
    MIconButton,
    MTooltip
  }
})
export default class ApplicationFormSupportRow extends mixins(ApplicationFormSupportMixin, UserRoleMixin) {
  private readonly MTooltipSize: object = MTooltipSize
  
  @Prop({ required: true })
  private applicationFormSupport!: IApplicationFormSupportModel

  private onShowApplicationFormSupportIconClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.ADMIN_APPLICATION_FORM_SUPPORT,
      params: {
        idApplicationFormSupport: this.applicationFormSupport.id
      }
    })
  }
}
