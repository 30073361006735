<section id="form-template-management__form-template-update">
  <div>
    <div class="form-template-management__form-template-update--form mt-4 mb-4">
      <div class="form-template-management__form-template-update--form-left-column display--flex flex__direction--column mr-4">
        <div class="form-template-management__form-template-update--informations mb-6">
          <m-panel>
            <ul class="mb-0">
              <li>
                <strong>
                  {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.informations.category') }}
                </strong>
                {{ formTemplate.category.title }}
              </li>
              <li class="mt-2">
                <strong>
                  {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.informations.year') }}
                </strong>
                {{ formTemplate.category.year.slug }}
              </li>
              <li class="mt-2">
                <strong>
                  {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.informations.form_type') }}
                </strong>
                {{ $i18next.t(`modules.form-template-management.FormTemplateModel.types.${formTemplate.type}`) }}
              </li>
            </ul>
          </m-panel>
        </div>
        <div class="form-template-management__form-template-update--drop-zone">
          <form-template-update-drop-zone
            :form-template="formTemplate"
            :request-errors="requestErrors"
          />
        </div>
      </div>
      <div class="form-template-management__form-template-update--elements-panel">
        <form-template-update-element-panel :request-errors="requestErrors" />
      </div>
    </div>
    <div>
      <m-button
        :waiting="isFormTemplateUpdateStateActive"
        @click="updateFormTemplate"
      >
        {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.button.save.label') }}
      </m-button>
      <m-button
        :skin="MButtonSkin.Secondary"
        @click="onPreviewButtonClick"
      >
        {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.button.preview.label') }}
      </m-button>
      <m-dialog
        :ok-label="$i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.dialog.cancel_confirmation.ok_label')"
        @ok="navigateToCategoriesList"
      >
        <m-button
          slot="trigger"
          :skin="MButtonSkin.Secondary"
        >
          {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.dialog.cancel_confirmation.trigger_label') }}
        </m-button>
        <m-message
          class="text-align--center"
          :state="MMessageState.Warning"
          :skin="MMessageSkin.Page"
        >
          {{ $i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.dialog.cancel_confirmation.message') }}
        </m-message>
      </m-dialog>
    </div>
  </div>
  <m-toast
    :state="MToastState.Confirmation"
    :position="MToastPosition.TopRight"
    open-trigger="manual"
    :preload="true"
    :open="isFormTemplateUpdateSuccessToastActive"
    :timeout="MToastTimeout.short"
    @close="onFormTemplateUpdateSuccessToastClose"
  >
    <span v-html="$i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.toast.update_success.label')" />
  </m-toast>
  <m-toast
    :state="MToastState.Warning"
    :position="MToastPosition.TopRight"
    open-trigger="manual"
    :preload="true"
    :open="isFormTemplateUpdateErrorToastActive"
    :timeout="MToastTimeout.short"
    @close="onFormTemplateUpdateErrorToastClose"
  >
    <span v-html="$i18next.t('modules.form-template-management.form-template-update.FormTemplateUpdate.toast.update_error.label')" />
  </m-toast>
</section>