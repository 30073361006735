import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { IUserModel } from "./User.model";
import { IPaginatedResponse } from "../global/utilities/Pagination.model";
import BaseService, { IPaginationQueryParameters } from "../global/utilities/Base.service";
import { EVENT_BUS_EVENTS } from "../global/utilities/Events";

export interface IUserListResponse extends IPaginatedResponse {
  users: Array<IUserModel>;
}

export interface IFetchUserListQueryParameters extends IPaginationQueryParameters {
  query?: string;
}

export interface ICreateUserForm {
  idul: string;
  roles: Array<string>;
}

export interface IUpdateUserForm {
  roles: Array<string>;
}

@Component
export default class UserService extends BaseService {
  protected service_userService_fetchList(query: IFetchUserListQueryParameters): Promise<IUserListResponse> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/users?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_userService_fetchUserLdapInformations(idul: string): Promise<IUserModel> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery({ idul })

      this.$axios.get(`/api/users/ldap-search?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.user))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_userService_fetch(id: string): Promise<IUserModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/users/${id}`)
        .then((response: AxiosResponse) => resolve(response.data.data.user))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_userService_create(payload: ICreateUserForm): Promise<IUserModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/users`, payload)
        .then((response: AxiosResponse) => {
          const createdUser: IUserModel = response.data.data.user

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.USER_CREATED, { user: createdUser })

          resolve(createdUser)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_userService_update(id: string, payload: IUpdateUserForm): Promise<IUserModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/users/${id}`, payload)
        .then((response: AxiosResponse) => {
          const updatedUser: IUserModel = response.data.data.user

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.USER_UPDATED, { user: updatedUser })

          resolve(updatedUser)
        }).catch((error: AxiosError) => reject(error))
    })
  }
}