<article class="email-template-management__email-templates">
  <m-table
    :columns="tableColumns"
    :rows="sortedEmailTemplates"
    @sort-applied="onTableSortApplied($event)"
  >
    <template #body.last-update="{ data }">
      {{ mixin_utilities_userTimedTrace(data.updated_by_name || data.created_by_name, data.updated_at || data.created_at) }}
    </template>
    <template #body.options="{ data }">
      <m-option>
        <m-option-item-edit @click="onEmailTemplateUpdateOptionClick(data)" />
      </m-option>
    </template>
  </m-table>
</article>