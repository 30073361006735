<div class="category-section-form">
  <m-add @click="onAddNewSectionButtonClick">
    {{ $i18next.t('modules.category-section-management.category-section-form.CategorySectionForm.label.add_new_section') }}
  </m-add>
  <template v-for="categorySection, index in categorySections">
    <category-section-form-row
      :key="`category-section-form-row[${index}]`"
      :ref="REF_CATEGORY_SECTION_FORM_ROW"
      :year-configuration="yearConfiguration"
      :category-section="categorySection"
      :request-errors="requestErrors"
      :categories="availableCategories"
      @click:delete="onCategorySectionFormRowDeleteEvent(index)"
    />
  </template>
</div>