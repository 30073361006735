<article class="admin-application-form-management__application-forms">
  <div class="display--flex">
    <application-form-reminder-email-overlay
      class="ml-auto"
      :year="year"
    />
    <application-form-support-submit-reminder-email-overlay
      class="ml-2"
      :year="year"
    />
  </div>
  <template v-if="selectedApplicationFormDepositDeadlineExtension">
    <application-form-deposit-deadline-extension-overlay
      :key="selectedApplicationFormDepositDeadlineExtension.id"
      :application-form="selectedApplicationFormDepositDeadlineExtension"
      @after-close="afterDisplayDepositDeadlineExtensionOverlayClose"
    />
  </template>
  <template v-if="selectedApplicationFormAuthorizedUsers">
    <application-form-authorized-users-overlay
      :key="selectedApplicationFormAuthorizedUsers.id"
      :application-form="selectedApplicationFormAuthorizedUsers"
      @after-close="afterAuthorizedUsersOverlayClose"
    />
  </template>
  <template v-if="selectedApplicationFormToDelete">
    <m-dialog
      :open="true"
      :state="MDialogState.Warning"
      :width="MDialogWidth.Large"
      :ok-label="$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.dialog.delete_confirmation.submit_label')"
      @ok="deleteApplicationForm"
      @cancel="resetApplicationFormToDelete"
    >
      <span v-html="$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.dialog.delete_confirmation.message')" />
    </m-dialog>
  </template>
  <m-table-header>
    <template #left>
      <m-toggle-buttons
        :buttons="statusToggleButtons"
        :multiple="true"
        @click="onToggleButtonClick($event)"
      />
    </template>
    <template #right>
      <m-searchfield
        v-model="searchField.value"
        v-m-control="searchField"
        :label-up="true"
        :label="$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.search_field.label')"
        :placeholder="$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.search_field.placeholder')"
        @search="onSearchFieldChange"
        @empty-field="onSearchFieldChange"
      />
    </template>
  </m-table-header>
  <m-table
    :columns="tableColumns"
    :loading="isLoadingStateActive"
    :rows="applicationForms"
  >
    <template #body.status="{ data }">
      <div class="display--flex justify-content-start">
        <template v-if="mixin_applicationForm_computeStatusIcon(data)">
          <m-icon
            class="mr-2"
            size="22px"
            :style="mixin_applicationForm_computeStatusIconStyles(data)"
            :name="mixin_applicationForm_computeStatusIcon(data)"
          />
        </template>
        <div class="mx-0 mt-auto">
          {{ mixin_applicationForm_computeStatusLabel(data.status) }}
        </div>
      </div>
    </template>
    <template #body.options="{ data }">
      <div class="display--flex justify-content--end">
        <template v-if="(data.reminder_emails_status && data.reminder_emails_status.length > 0) || (data.support_reminder_emails_status && data.support_reminder_emails_status.length > 0) || (data.support_submit_over_threshold_email_status && data.support_submit_over_threshold_email_status.length > 0) || (data.application_form_submitted_email_status && data.application_form_submitted_email_status.length > 0) || (data.application_form_created_email_status && data.application_form_created_email_status.length > 0)">
          <m-popup placement="left">
            <template #trigger>
              <m-icon-button icon-name="m-svg__email" />
            </template>
            <template #header>
              <strong>
                {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.popover.emails.header') }}
              </strong>
              <hr />
            </template>
            <template v-if="data.reminder_emails_status && data.reminder_emails_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.popover.emails.reminder_email') }}
              </label>
              <ul>
                <template v-for="status, index in data.reminder_emails_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.reminder_emails_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
            <template v-if="data.support_reminder_emails_status && data.support_reminder_emails_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.popover.emails.support_submit_reminder_email') }}
              </label>
              <ul>
                <template v-for="status, index in data.support_reminder_emails_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.support_reminder_emails_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
            <template v-if="data.support_submit_over_threshold_email_status && data.support_submit_over_threshold_email_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.popover.emails.support_submit_over_threshold_email') }}
              </label>
              <ul>
                <template v-for="status, index in data.support_submit_over_threshold_email_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.support_submit_over_threshold_email_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
            <template v-if="data.application_form_created_email_status && data.application_form_created_email_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.popover.emails.application_form_created_email_status') }}
              </label>
              <ul>
                <template v-for="status, index in data.application_form_created_email_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.application_form_created_email_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
            <template v-if="data.application_form_submitted_email_status && data.application_form_submitted_email_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.popover.emails.application_form_submitted_email_status') }}
              </label>
              <ul>
                <template v-for="status, index in data.application_form_submitted_email_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.application_form_submitted_email_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
          </m-popup>
        </template>
        <template v-if="data.application_form_supports && data.application_form_supports.length > 0">
          <m-popup placement="left">
            <template #trigger>
              <m-icon-button icon-name="m-svg__evaluation" />
            </template>
            <template #header>
              <strong>
                {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.popover.application_form_supports.header') }}
              </strong>
            </template>
            <ul>
              <template v-for="applicationFormSupport, index in data.application_form_supports">
                <li>
                  <application-form-support-row :application-form-support="applicationFormSupport" />
                  <template v-if="index < data.application_form_supports.length - 1">
                    <hr />
                  </template>
                </li>
              </template>
            </ul>
          </m-popup>
        </template>
        <m-option>
          <m-option-item
            icon-name="m-svg__profile"
            @click="onDisplayAuthorizedUsersIconClick(data)"
          >
            {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.options.authorized_users') }}
          </m-option-item>
          <m-option-item
            icon-name="m-svg__clock"
            @click="onDisplayDepositDeadlineExtensionIconClick(data)"
          >
            {{ $i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.options.deposit_deadline') }}
          </m-option-item>
          <m-option-item-edit @click="onShowApplicationFormIconClick($event, data)" />
          <template v-if="data.is_deleteable_admin">
            <m-option-item-delete @click="triggerDeleteConfirmationDialog(data)" />
          </template>
        </m-option>
      </div>
    </template>
  </m-table>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="paginationData">
      <m-pagination
        :items-per-page="paginationData.element_count_per_page"
        :items-total="paginationData.total_element_count"
        :value="paginationData.current_page_index + 1"
        @change="onPageChange($event)"
      />
    </template>
  </transition>
</article>