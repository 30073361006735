<m-form
  class="application-form-management__application-form-form"
  :form-group="formGroup"
>
  <div class="application-form-management__application-form-form--row">
    <div class="application-form-management__application-form-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.category_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="application-form-management__application-form-form--row">
    <div class="application-form-management__application-form-form--column">
      <m-dropdown
        v-model="categoryDropdown.value"
        v-m-control="categoryDropdown"
        :label="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.dropdown.category.label')"
        :required-marker="true"
        :error="categoryDropdown.hasError()"
        :error-message="categoryDropdown.errorMessage"
        max-width="none"
        :waiting="isCategoryLoadingStateActive"
        :filterable="true"
      >
        <template v-for="category in categories">
          <m-dropdown-item
            :value="category.id"
            :label="category.title"
          >
            {{ category.title }}
          </m-dropdown-item>
        </template>
      </m-dropdown>
    </div>
  </div>
  <div class="application-form-management__application-form-form--row mt-12">
    <div class="application-form-management__application-form-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.candidate_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="application-form-management__application-form-form--row">
    <div class="application-form-management__application-form-form--column">
      <m-checkbox
        v-model="candidateIsNotCurrentUserCheckbox.value"
        v-m-control="candidateIsNotCurrentUserCheckbox"
        :error="candidateIsNotCurrentUserCheckbox.hasError()"
        :error-message="candidateIsNotCurrentUserCheckbox.errorMessage"
      >
        {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.checkbox.candidate_is_current_user.label') }}
      </m-checkbox>
    </div>
  </div>
  <template v-if="candidateIsNotCurrentUserCheckbox.value">
    <user-form
      :ref="USER_FORM_REF"
      class="mt-4"
      :with-role-selection="false"
      :idul-search-bar-placeholder="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.user_form_idul_placeholder')"
    />
  </template>
  <template v-if="categoriesMappedById[categoryDropdown.value] && categoriesMappedById[categoryDropdown.value].has_application_support">
    <div class="application-form-management__application-form-form--row mt-12">
      <div class="application-form-management__application-form-form--column">
        <label class="m-u--font-weight--bold">
          {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.application_form_support_zone') }}
        </label>
        <hr />
      </div>
      <div class="application-form-management__application-form-form--column">
        <m-message :state="MMessageState.Information">
          <span v-html="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.message.application_form_support_optional.content')" />
        </m-message>
      </div>
      <template v-if="selectedCategory && !mixin_string_isEmpty(selectedCategory.application_support_creation_dialog_message)">
        <div class="application-form-management__application-form-form--column">
          <span v-html="selectedCategory.application_support_creation_dialog_message" />
        </div>
      </template>
    </div>
    <template v-if="applicationFormSupportForms.length === 0">
      <m-add
        class="mt-4"
        @click="increaseApplicationFormSupportFormCount(0)"
      >
        {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.add_application_form_support') }}
      </m-add>
    </template>
    <template v-for="key, index in applicationFormSupportForms">
      <application-form-support-form
        :key="key"
        class="mt-2"
        :ref="APPLICATION_FORM_SUPPORT_FORM_REF"
        :is-add-icon-displayed="(index === applicationFormSupportForms.length - 1) && (index < categoriesMappedById[categoryDropdown.value].application_support_count_upper_bound)"
        :is-delete-icon-displayed="true"
        :is-add-icon-column-displayed="applicationFormSupportForms.length < categoriesMappedById[categoryDropdown.value].application_support_count_upper_bound"
        :is-delete-icon-column-displayed="true"
        @add-icon:click="increaseApplicationFormSupportFormCount(key)"
        @delete-icon:click="decreaseApplicationFormSupportFormCount(index)"
      />
    </template>
  </template>
</m-form>