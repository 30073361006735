import Component, { mixins } from 'vue-class-component'

import WithRender from './UserProfileMenu.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MIconButton,
  MPopup
} from '@ulaval/modul-components'

import { STORE_ACTIONS, STORE_GETTERS } from '@/store'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MIconButton,
    MPopup
  }
})
export default class UserProfileMenu extends mixins() {
  private readonly MButtonSkin: any = MButtonSkin

  private get userFullNameWithUsername(): string {
    return this.$store.getters[STORE_GETTERS.SESSION_GET_USER_FULL_NAME_WITH_USERNAME]
  }

  private get userEmail(): string {
    return this.$store.getters[STORE_GETTERS.SESSION_GET_EMAIL]
  }

  private onLogoutOptionClick(): void {
    this.$store.dispatch(STORE_ACTIONS.SESSION_STORE_LOGOUT)
    this.mixin_router_navigate({
      name: ROUTES.DEFAULT
    })
  }
}
