<section class="dashboard-evaluator-toolbox main-content">
  <m-panel class="main-content__panel">
    <h3 slot="header">
      {{ $i18next.t('modules.dashboard.DashboardEvaluatorToolbox.panel_title') }}
    </h3>
    <template v-if="mappedEvaluatorToolboxFiles.length === 0">
      <m-message :state="MMessageState.Warning">
        {{ $i18next.t('modules.dashboard.DashboardEvaluatorToolbox.message.empty_list.content') }}
      </m-message>
    </template>
    <template v-else>
      <template v-for="evaluatorToolboxFile, index in mappedEvaluatorToolboxFiles">
        <uploaded-file-row
          :uploaded-file="evaluatorToolboxFile"
          :display-divider="index < mappedEvaluatorToolboxFiles.length - 1"
          :disabled="true"
        />
      </template>
    </template>
  </m-panel>
</section>