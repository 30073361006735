<div class="user-profile-menu__wrapper">
  <m-popup id="user-profile-menu">
    <template #trigger>
      <m-icon-button
        icon-name="m-svg__profile"
        icon-size="28px"
      />
    </template>
    <template #header>
      <p>
        <strong>
          {{ userFullNameWithUsername }}
        </strong>
        <br />
        <small>
          {{ userEmail }}
        </small>
      </p>
    </template>
    <m-button
      icon-name="m-svg__logout"
      icon-size="26px"
      :full-size="true"
      :skin="MButtonSkin.Secondary"
      @click="onLogoutOptionClick"
    >
      {{ $i18next.t('modules.global.navigation-bar.UserProfileMenu.options.logout.label') }}
    </m-button>
  </m-popup>
</div>