import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import WithRender from './FormTemplatePreview.html'

import { 
  MButton,
  MButtonSkin,
  MMessage,
  MMessageState,
  MPanel
} from '@ulaval/modul-components'

import FormElements from '@/modules/form-management/form-element/FormElements'

import { IInternalFormTemplateModel } from '../FormTemplate.model'

import ROUTES from '@/router/ROUTES'

const FORM_ELEMENTS_REF = 'form-elements'

@WithRender
@Component({
  components: {
    FormElements,
    MButton,
    MMessage,
    MPanel
  }
})
export default class FormTemplatePreview extends mixins() {
  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageState: object = MMessageState
  private readonly FORM_ELEMENTS_REF: string = FORM_ELEMENTS_REF

  @Prop({ required: true })
  private formTemplate!: IInternalFormTemplateModel

  @Ref(FORM_ELEMENTS_REF)
  private formElementsComponent!: FormElements

  private get hasFormElements(): boolean {
    return this.formTemplate.elements.length > 0
  }

  private onUpdateButtonClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_UPDATE,
      params: this.$route.params
    })
  }

  private async onShowValidationRulesButtonClick(): Promise<void> {
    await this.formElementsComponent.getElementAnswers()
  }
}