import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './ApplicationForms.html'

import { 
  MMessage,
  MMessageState,
  MSpinner,
  MToast,
  MToastPosition,
  MToastState,
  MToastTimeout,
  MToastDuration
} from '@ulaval/modul-components'

import ApplicationFormCard from '../application-form/ApplicationFormCard'

import { IYearModel } from '@/modules/global/year/Year.model'
import ApplicationFormService from '../ApplicationForm.service'
import { IApplicationFormModel } from '@/modules/application-form-management/ApplicationForm.model'
import CONSTANTS from '@/CONSTANTS'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {
    ApplicationFormCard,
    MMessage,
    MSpinner,
    MToast
  }
})
export default class ApplicationForms extends mixins(ApplicationFormService) {
  private applicationForms: Array<IApplicationFormModel> = []
  private isLoadingStateActive: boolean = true
  private isApplicationFormDeleteSuccessToastOpen: boolean = false

  private readonly MMessageState: object = MMessageState
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout

  @Prop({ required: true })
  private year!: IYearModel

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadApplicationForms()
  }

  private mounted(): void {
    this.afterApplicationFormCreated()
    this.afterApplicationFormUpdated()
    this.afterApplicationFormDeleted()
    this.afterApplicationFormSupportSubmitted()
  }

  private afterApplicationFormCreated(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_CREATED, () => {
      this.loadApplicationForms()
    })
  }

  private afterApplicationFormSupportSubmitted(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_APPLICATION_FORM_SUPPORT_SUBMITTED, () => {
      this.loadApplicationForms()
    })
  }

  private afterApplicationFormUpdated(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_UPDATED, (updatedApplicationForm: IApplicationFormModel) => {
      if (updatedApplicationForm) {
        const originalCategoryIndex: number | undefined = this.applicationForms.findIndex((applicationForm: IApplicationFormModel) => applicationForm.id === updatedApplicationForm.id)

        if (~originalCategoryIndex) {
          this.$set(this.applicationForms, originalCategoryIndex, updatedApplicationForm)
        }
      }
    })
  }

  private afterApplicationFormDeleted(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_DELETED, (id: string) => {
      if (id) {
        const originalApplicationFormIndex: number | undefined = this.applicationForms.findIndex((applicationForm: IApplicationFormModel) => applicationForm.id === id)

        if (~originalApplicationFormIndex) {
          this.isApplicationFormDeleteSuccessToastOpen = true

          this.$delete(this.applicationForms, originalApplicationFormIndex)

          setTimeout(() => {
            this.isApplicationFormDeleteSuccessToastOpen = false
          }, MToastDuration.DesktopXShort)
        }
      }
    })
  }

  private loadApplicationForms(): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    this.service_applicationFormService_fetchUserApplicationFormList(this.year.id)
      .then((response: Array<IApplicationFormModel>) => {
        this.applicationForms = response || []
      }).catch(() => {
        this.applicationForms = []
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }
}
