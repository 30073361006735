<section class="public-area main-content">
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="isFormTemplatePreviewActive">
      <router-view />
    </template>
    <template v-else>
      <public-year-configuration />
    </template>
  </transition>
</section>