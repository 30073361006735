import Component, { mixins } from 'vue-class-component'

import RouterMixin, { IRouterMixin } from '@/router/Router.mixin'
import SessionMixin, { ISessionMixin } from '@/modules/session/Session.mixin'
import TimestampMixin, { ITimestampMixin } from './Timestamp.mixin'
import StringMixin, { IStringMixin } from './String.mixin'

export interface IUtilitiesMixin extends IRouterMixin, ISessionMixin, IStringMixin, ITimestampMixin {
  mixin_utilities_userTimedTrace(user: string, timestamp: string): string;
}

@Component
export default class UtilitiesMixin extends mixins(RouterMixin, SessionMixin, StringMixin, TimestampMixin) implements UtilitiesMixin {
  public mixin_utilities_userTimedTrace(user: string, timestamp: string): string {
    if (!user && !timestamp) {
      return ''
    } else if (!user && timestamp) {
      return this.mixin_timestamp_pretty(timestamp)
    } else if (!timestamp && user) {
      return user
    }

    return this.$i18next.t('modules.global.utilities.Utilities.user_timed_trace.value', {
        user,
        timestamp: this.mixin_timestamp_pretty(timestamp)
      }) 
  }
}
