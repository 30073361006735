import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'

import HttpStatusCode from 'http-status-codes'

import DefaultLayout from '@/layouts/default/DefaultLayout'
import ErrorPage from '@/modules/global/error/ErrorPage'
import LoginPage from '@/modules/authentication/login/LoginPage'
import DashboardPage from '@/modules/dashboard/DashboardPage'
import UserManagement from '@/modules/user-management/UserManagement'
import UserCreationDialog from '@/modules/user-management/user-creation/UserCreationDialog'
import UserUpdateDialog from '@/modules/user-management/user-update/UserUpdateDialog'
import CategoryManagement from '@/modules/category-management/CategoryManagement'
import CategoryCreationDialog from '@/modules/category-management/category-creation/CategoryCreationDialog'
import CategoryUpdateDialog from '@/modules/category-management/category-update/CategoryUpdateDialog'
import EmailTemplateManagement from '@/modules/email-template-management/EmailTemplateManagement'
import EmailTemplateUpdateDialog from '@/modules/email-template-management/email-template-update/EmailTemplateUpdateDialog'
import FormTemplateManagement from '@/modules/form-template-management/FormTemplateManagement'
import FormTemplateUpdate from '@/modules/form-template-management/form-template-update/FormTemplateUpdate'
import FormTemplatePreview from '@/modules/form-template-management/form-template-preview/FormTemplatePreview'
import ApplicationForm from '@/modules/application-form-management/application-form/ApplicationForm'
import ApplicationFormCreationDialog from '@/modules/application-form-management/application-form-creation/ApplicationFormCreationDialog'
import AdminApplicationFormManagement from '@/modules/application-form-management/AdminApplicationFormManagement'
import AdminApplicationForm from '@/modules/application-form-management/application-form/AdminApplicationForm'
import ApplicationFormSupport from '@/modules/application-form-support-management/application-form-support/ApplicationFormSupport'
import AdminApplicationFormSupport from '@/modules/application-form-support-management/application-form-support/AdminApplicationFormSupport'
import AdminApplicationFormSupportManagement from '@/modules/application-form-support-management/AdminApplicationFormSupportManagement'
import AdminApplicationFormValidationManagement from '@/modules/application-form-validation-management/AdminApplicationFormValidationManagement'
import AdminApplicationFormValidation from '@/modules/application-form-validation-management/application-form-validation/AdminApplicationFormValidation'
import EvaluationCardDepositOverlay from '@/modules/category-management/evaluation-card-management/evaluation-card-deposit/EvaluationCardDepositOverlay'
import EvaluatorManagement from '@/modules/evaluator-management/EvaluatorManagement'
import AdminEvaluatorCategoryAssignmentCreationOverlay from '@/modules/evaluator-management/evaluator-category-assignment-management/evaluator-category-assignment-creation/AdminEvaluatorCategoryAssignmentCreationOverlay'
import AdminEvaluatorCategoryAssignmentUpdateOverlay from '@/modules/evaluator-management/evaluator-category-assignment-management/evaluator-category-assignment-update/AdminEvaluatorCategoryAssignmentUpdateOverlay'
import EvaluatorToolboxFileDepositOverlay from '@/modules/evaluator-toolbox-file-management/evaluator-toolbox-file-deposit/EvaluatorToolboxFileDepositOverlay'
import EvaluatorApplicationFormAssignment from '@/modules/evaluator-management/evaluator-application-form-assignment-management/evaluator-application-form-assignment/EvaluatorApplicationFormAssignment'
import EvaluatorApplicationFormSupportAssignment from '@/modules/evaluator-management/evaluator-application-form-assignment-management/evaluator-application-form-assignment/EvaluatorApplicationFormSupportAssignment'
import YearConfigurationManagement from '@/modules/year-configuration-management/YearConfigurationManagement'
import YearConfigurationUpdateDialog from '@/modules/year-configuration-management/year-configuration-update/YearConfigurationUpdateDialog'
import PublicFormTemplatePreview from '@/modules/public-area/form-template-preview/PublicFormTemplatePreview'

import ROUTES from './ROUTES'
import Localization from '@/localization'
import RouterLocalizationHelper, { 
  generateLocalizedRouteName, 
  extractLocalizedRouteParameters, 
  ILocalizedRouteParameters, 
  META_KEY_HIDDEN_BREADCRUMB,
  META_KEY_ADDITIONAL_BREADCRUMBS,
  META_KEY_DISPLAY_PUBLIC_NAVIGATION_BAR
} from '@/router/helpers'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: ROUTES.DEFAULT,
    component: DefaultLayout,
    meta: {
      [META_KEY_DISPLAY_PUBLIC_NAVIGATION_BAR]: true
    },
    children: [
      {
        path: '',
        name: ROUTES.LOGIN,
        component: LoginPage
      },
      {
        path: '',
        name: ROUTES.ERROR,
        component: ErrorPage
      },
      {
        path: '',
        name: ROUTES.DASHBOARD,
        component: DashboardPage,
        children: [
          {
            path: '',
            name: ROUTES.APPLICATION_FORM_CREATION,
            component: ApplicationFormCreationDialog
          }
        ]
      },
      {
        path: '',
        name: ROUTES.USER_MANAGEMENT_USER_LIST,
        component: UserManagement,
        children: [
          {
            path: '',
            name: ROUTES.USER_MANAGEMENT_USER_CREATION,
            component: UserCreationDialog
          },
          {
            path: '',
            name: ROUTES.USER_MANAGEMENT_USER_UPDATE,
            component: UserUpdateDialog
          }
        ]
      },
      {
        path: '',
        name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_LIST,
        component: CategoryManagement,
        children: [
          {
            path: '',
            name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_CREATION,
            component: CategoryCreationDialog
          },
          {
            path: '',
            name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_UPDATE,
            component: CategoryUpdateDialog
          },
          {
            path: '',
            name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_EVALUATION_CARD_DEPOSIT,
            component: EvaluationCardDepositOverlay
          },
        ]
      },
      {
        path: '',
        name: ROUTES.EMAIL_TEMPLATE_MANAGEMENT_EMAIL_TEMPLATE_LIST,
        component: EmailTemplateManagement,
        children: [
          {
            path: '',
            name: ROUTES.EMAIL_TEMPLATE_MANAGEMENT_EMAIL_TEMPLATE_UPDATE,
            component: EmailTemplateUpdateDialog
          }
        ]
      },
      {
        path: '',
        name: ROUTES.FORM_TEMPLATE_MANAGEMENT,
        component: FormTemplateManagement,
        meta: {
          [META_KEY_HIDDEN_BREADCRUMB]: true,
          [META_KEY_ADDITIONAL_BREADCRUMBS]: [
            ROUTES.CATEGORY_MANAGEMENT_CATEGORY_LIST
          ]
        },
        children: [
          {
            path: '',
            name: ROUTES.FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_UPDATE,
            component: FormTemplateUpdate
          },
          {
            path: '',
            name: ROUTES.FORM_TEMPLATE_MANAGEMENT_FORM_TEMPLATE_PREVIEW,
            component: FormTemplatePreview
          }
        ]
      },
      {
        path: '',
        name: ROUTES.APPLICATION_FORM,
        component: ApplicationForm
      },
      {
        path: '',
        name: ROUTES.ADMIN_APPLICATION_FORM,
        component: AdminApplicationForm,
        meta: {
          [META_KEY_ADDITIONAL_BREADCRUMBS]: [
            ROUTES.ADMIN_APPLICATION_FORM_MANAGEMENT
          ]
        }
      },
      {
        path: '',
        name: ROUTES.ADMIN_APPLICATION_FORM_MANAGEMENT,
        component: AdminApplicationFormManagement
      },
      {
        path: '',
        name: ROUTES.ADMIN_APPLICATION_FORM_SUPPORT,
        component: AdminApplicationFormSupport,
        meta: {
          [META_KEY_ADDITIONAL_BREADCRUMBS]: [
            ROUTES.ADMIN_APPLICATION_FORM_SUPPORT_MANAGEMENT
          ]
        }
      },
      {
        path: '',
        name: ROUTES.APPLICATION_FORM_SUPPORT,
        component: ApplicationFormSupport
      },
      {
        path: '',
        name: ROUTES.ADMIN_APPLICATION_FORM_SUPPORT_MANAGEMENT,
        component: AdminApplicationFormSupportManagement
      },
      {
        path: '',
        name: ROUTES.ADMIN_APPLICATION_FORM_VALIDATION_MANAGEMENT,
        component: AdminApplicationFormValidationManagement
      },
      {
        path: '',
        name: ROUTES.ADMIN_APPLICATION_FORM_VALIDATION_FORM,
        component: AdminApplicationFormValidation,
        meta: {
          [META_KEY_ADDITIONAL_BREADCRUMBS]: [
            ROUTES.ADMIN_APPLICATION_FORM_VALIDATION_MANAGEMENT
          ]
        }
      },
      {
        path: '',
        name: ROUTES.EVALUATOR_MANAGEMENT_LIST,
        component: EvaluatorManagement,
        children: [
          {
            path: '',
            name: ROUTES.EVALUATOR_MANAGEMENT_CREATION,
            component: AdminEvaluatorCategoryAssignmentCreationOverlay
          },
          {
            path: '',
            name: ROUTES.EVALUATOR_MANAGEMENT_UPDATE,
            component: AdminEvaluatorCategoryAssignmentUpdateOverlay
          },
          {
            path: '',
            name: ROUTES.EVALUATOR_MANAGEMENT_EVALUATOR_TOOLBOX_FILE_DEPOSIT,
            component: EvaluatorToolboxFileDepositOverlay
          }
        ]
      },
      {
        path: '',
        name: ROUTES.EVALUATOR_APPLICATION_FORM_DISPLAY,
        component: EvaluatorApplicationFormAssignment
      },
      {
        path: '',
        name: ROUTES.EVALUATOR_APPLICATION_FORM_SUPPORT_DISPLAY,
        component: EvaluatorApplicationFormSupportAssignment
      },
      {
        path: '',
        name: ROUTES.YEAR_CONFIGURATION_MANAGEMENT_LIST,
        component: YearConfigurationManagement,
        children: [
          {
            path: '',
            name: ROUTES.YEAR_CONFIGURATION_MANAGEMENT_UPDATE,
            component: YearConfigurationUpdateDialog
          }
        ]
      },
      {
        path: '',
        name: ROUTES.PUBLIC_FORM_TEMPLATE_PREVIEW,
        component: PublicFormTemplatePreview,
        meta: {
          [META_KEY_DISPLAY_PUBLIC_NAVIGATION_BAR]: true
        }
      }
    ]
  },
  {
    path: '*',
    name: ROUTES.WILDCARD
  }
]

const router = new VueRouter({
  routes: RouterLocalizationHelper(routes)
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  const routeParameters: ILocalizedRouteParameters = extractLocalizedRouteParameters(to.name as string)

  if (routeParameters.name === ROUTES.WILDCARD) {
    return next({
      name: generateLocalizedRouteName({
        name: ROUTES.ERROR,
        locale: Localization.CURRENT_LOCALE
      }),
      params: {
        status: HttpStatusCode.NOT_FOUND.toString()
      }
    })
  }

  if (routeParameters.locale !== Localization.CURRENT_LOCALE) {
    return next({
      name: generateLocalizedRouteName({
        name: routeParameters.name,
        locale: Localization.CURRENT_LOCALE
      }),
      params: to.params,
      query: to.query
    })
  }

  return next()
})

export default router
