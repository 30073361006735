import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './ApplicationFormSupport.html'

import { 
  MButton,
  MDialog,
  MDialogState,
  MDialogWidth,
  MMessage,
  MMessageState,
  MPanel,
  MSpinner,
  MToast,
  MToastDuration,
  MToastPosition,
  MToastState,
  MToastTimeout,
} from '@ulaval/modul-components'

import Breadcrumbs from '@/modules/global/breadcrumbs/Breadcrumbs'
import FormElements from '@/modules/form-management/form-element/FormElements'

import { IFormModel } from '@/modules/form-management/Form.model'
import { IInternalFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'
import { IFormAnswerForm } from '@/modules/form-management/Form.service'
import { IApplicationFormSupportModel } from '../ApplicationFormSupport.model'
import ApplicationFormSupportService, { IFetchApplicationFormSupportResponse } from '../ApplicationFormSupport.service'

const FORM_ELEMENTS_REF = 'form-elements'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    FormElements,
    MButton,
    MDialog,
    MMessage,
    MPanel,
    MSpinner,
    MToast
  }
})
export default class ApplicationFormSupport extends mixins(ApplicationFormSupportService) {
  private applicationFormSupport: IApplicationFormSupportModel | null = null
  private form: IFormModel | null = null
  private internalFormTemplate: IInternalFormTemplateModel | null = null
  private isLoadingStateActive: boolean = true
  private isSubmitConfirmationDialogActive: boolean = false
  private isSubmitSuccessToastOpen: boolean = false
  private isSubmitErrorToastOpen: boolean = false
  private isValidationErrorToastOpen: boolean = false
  private token: string | null = null

  private readonly FORM_ELEMENTS_REF: string = FORM_ELEMENTS_REF
  private readonly MMessageState: object = MMessageState
  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout

  @Ref(FORM_ELEMENTS_REF)
  private formElementsComponent!: FormElements

  private get submitDialogState(): MDialogState {
    if (this.isSubmitErrorToastOpen) {
      return MDialogState.Error
    } else if (this.isSubmitSuccessToastOpen) {
      return MDialogState.Confirmation
    }

    return MDialogState.Warning
  }

  @Watch('$route.params.token', { immediate: true })
  private onRouteParamsTokenChange(value: string | null) {
    this.token = value || null
    this.loadApplicationFormSupport()
  }

  private loadApplicationFormSupport(): void {
    if (this.token) {
      this.isLoadingStateActive = true
      this.isValidationErrorToastOpen = false
  
      this.service_applicationFormSupportService_fetchApplicationFormSupport(this.token)
        .then((response: IFetchApplicationFormSupportResponse) => {
          this.applicationFormSupport = response.application_form_support || null
          this.form = response.form || null
          this.internalFormTemplate = response.internal_form_template || null
        }).catch((error: AxiosError) => {
          this.mixin_router_redirectRequestError(error)
        }).finally(() => {
          this.isLoadingStateActive = false
        })
    }
  }

  private async onSubmitButtonClick(): Promise<void> {
    const answers: Array<IFormAnswerForm> | null = await this.formElementsComponent.getElementAnswers()

    if (answers) {
      this.submitApplicationFormSupport(answers)
    }
  }

  private submitApplicationFormSupport(answers: Array<IFormAnswerForm>): void {
    if (this.token) {
      this.isLoadingStateActive = true

      this.service_applicationFormSupportService_submitApplicationFormSupport(this.token, answers)
        .then(() => {
          this.loadApplicationFormSupport()

          this.isSubmitSuccessToastOpen = true
          this.isSubmitErrorToastOpen = false
        }).catch(() => {
          this.isSubmitSuccessToastOpen = false
          this.isSubmitErrorToastOpen = true
          this.isLoadingStateActive = false
        }).finally(() => {
          setTimeout(() => {
            this.isSubmitSuccessToastOpen = false
          }, MToastDuration.DesktopXShort)
          setTimeout(() => {
            this.isSubmitErrorToastOpen = false
          }, MToastDuration.DesktopXShort)
        })
    }
  }

  private async displaySubmitConfirmationDialog(): Promise<void> {
    const answers: Array<IFormAnswerForm> | null = await this.formElementsComponent.getElementAnswers()

    if (answers) {
      this.isSubmitConfirmationDialogActive = true
    }

    this.isValidationErrorToastOpen = !answers
  }

  private hideSubmitConfirmationDialog(): void {
    this.isSubmitConfirmationDialogActive = true
  }
}
