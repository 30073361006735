<article class="admin-application-form-support-management__application-form-supports">
  <div class="display--flex">
    <application-form-support-reminder-email-overlay
      class="ml-auto"
      :year="year"
    />
  </div>
  <m-table-header>
    <template #left>
      <m-toggle-buttons
        :buttons="statusToggleButtons"
        :multiple="true"
        @click="onToggleButtonClick($event)"
      />
    </template>
    <template #right>
      <m-searchfield
        v-model="searchField.value"
        v-m-control="searchField"
        :label-up="true"
        :label="$i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.search_field.label')"
        :placeholder="$i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.search_field.placeholder')"
        @search="onSearchFieldChange"
        @empty-field="onSearchFieldChange"
      />
    </template>
  </m-table-header>
  <m-table
    :columns="tableColumns"
    :loading="isLoadingStateActive"
    :rows="applicationFormSupports"
  >
    <template #body.status="{ data }">
      <div class="display--flex justify-content-start">
        <template v-if="mixin_applicationFormSupport_computeStatusIcon(data)">
          <m-icon
            class="mr-2"
            size="22px"
            :style="mixin_applicationFormSupport_computeStatusIconStyles(data)"
            :name="mixin_applicationFormSupport_computeStatusIcon(data)"
          />
        </template>
        <div class="mx-0 mt-auto">
          {{ mixin_applicationFormSupport_computeStatusLabel(data.status) }}
        </div>
      </div>
    </template>
    <template #body.options="{ data }">
      <div class="display--flex justify-content--end">
        <template v-if="(data.reminder_emails_status && data.reminder_emails_status.length > 0) || data.creation_emails_status && data.creation_emails_status.length > 0">
          <m-popup placement="left">
            <template #trigger>
              <m-icon-button icon-name="m-svg__email" />
            </template>
            <template #header>
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.popover.emails.header') }}
              </strong>
              <hr />
            </template>
            <template v-if="data.reminder_emails_status && data.reminder_emails_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.popover.emails.reminder_email') }}
              </label>
              <ul>
                <template v-for="status, index in data.reminder_emails_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.reminder_emails_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
            <template v-if="data.creation_emails_status && data.creation_emails_status.length > 0">
              <label class="m-u--font-weight--bold">
                {{ $i18next.t('modules.application-form-support-management.application-form-supports.AdminApplicationFormSupports.popover.emails.creation_email') }}
              </label>
              <ul>
                <template v-for="status, index in data.creation_emails_status">
                  <li>
                    <email-status-row :email-status="status" />
                    <template v-if="index < data.creation_emails_status.length - 1">
                      <hr />
                    </template>
                  </li>
                </template>
              </ul>
            </template>
          </m-popup>
        </template>
        <m-icon-button
          icon-name="m-svg__show"
          @click="onShowApplicationFormSupportIconClick($event, data)"
        />
      </div>
    </template>
  </m-table>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="paginationData">
      <m-pagination
        :items-per-page="paginationData.element_count_per_page"
        :items-total="paginationData.total_element_count"
        :value="paginationData.current_page_index + 1"
        @change="onPageChange($event)"
      />
    </template>
  </transition>
</article>