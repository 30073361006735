import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './EvaluatorCategoryAssignments.html'

import { IEvaluatorCategoryAssignmentModel } from '../EvaluatorCategoryAsssignment.model';

import EvaluatorCategoryAssignment from '../evaluator-category-assignment/EvaluatorCategoryAssignment';

@WithRender
@Component({
  components: {
    EvaluatorCategoryAssignment
  }
})
export default class EvaluatorCategoryAssignments extends mixins() {
  @Prop({ required: true })
  private evaluatorCategoryAssignments!: Array<IEvaluatorCategoryAssignmentModel>
}
