import i18next from 'i18next';
import merge from 'deepmerge'

export enum LOCALES {
  FR = 'fr'
}

export enum MOMENT_LOCALES {
  FR = 'fr-ca'
}

const DEFAULT_NAMESPACE: string = 'localization'
const CURRENT_LOCALE: LOCALES = localStorage.getItem('locale') as LOCALES || LOCALES.FR

function getFromDottedNotation(object: { [key: string]: any }, properties: string): any {
  const propertiesArray: string[] = properties.split('.')

  for (let i: number = 0; i < propertiesArray.length; i++) {
    const property: string = propertiesArray[i]

    if ((object != null) && (typeof (object) === 'object') && Object.prototype.hasOwnProperty.call(object, property)) {
      object = object[property]
    } else {
      return undefined
    }
  }

  return object
}

const getTranslations = (): any => {
  const translations: any = {}
  const context: any = require.context('../', true, /.*?(?=\.lang\.).*?\.json/)

  context.keys().forEach((filePath: string) => {
    const pathParameters: string[] = filePath.split('/').filter((el: string) => !(el === '.'))
    const translationTree: { [key: string]: object } = {}

    pathParameters.forEach((pathParameter: string, index: number) => {
      if (pathParameter.includes('.lang')) {
        const treeParameterPath: string = pathParameters.slice(0, index).join('.')
        const obj: any = getFromDottedNotation(translationTree, treeParameterPath) || translationTree
        obj[pathParameter.split('.')[0]] = context(filePath)

        const lang: string = pathParameter.substring(
          pathParameter.lastIndexOf('lang.') + 5,
          pathParameter.lastIndexOf('.json')
        )

        translations[lang] = merge(translations[lang], translationTree)
      } else if (index === 0) {
        const treeParameterPath: string = pathParameters.slice(0, index + 1).join('.')
        translationTree[treeParameterPath] = {}
      } else {
        const treeParameterPath: string = pathParameters.slice(0, index).join('.')
        const obj: any = getFromDottedNotation(translationTree, treeParameterPath)
        obj[pathParameter] = {}
      }
    })
  })

  return translations
}

i18next
  .init({
    lng: localStorage.getItem('locale') || LOCALES.FR,
    fallbackLng: LOCALES.FR,
    ns: DEFAULT_NAMESPACE,
    defaultNS: DEFAULT_NAMESPACE
  })

const translations: any = getTranslations()

Object.entries(translations).forEach(([locale, resources]: any) => {
  i18next.addResourceBundle(locale, DEFAULT_NAMESPACE, resources)
})

export default {
  CURRENT_LOCALE,
  LOCALES,
  MOMENT_LOCALES,
  i18next
}