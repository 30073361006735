<m-form
  :form-group="formGroup"
  class="display--flex my-3"
>
  <m-textfield
    v-model="titleField.value"
    v-m-control="titleField"
    :label="$i18next.t('modules.category-section-management.category-section-form.CategorySectionFormRow.field.title.label')"
    max-width="none"
    :error="titleField.hasError()"
    :error-message="titleField.errorMessage"
    :requiredMarker="true"
  />
  <m-multi-select
    v-model="selectedCategories"
    :options="categories"
    v-m-control="categoryMultiSelect"
    :required-marker="true"
    max-width="none"
    :link-select-all="true"
    :defaultChipCharsTrunk="100"
    :label="$i18next.t('modules.category-section-management.category-section-form.CategorySectionFormRow.field.categories.label')"
    :placeholder="$i18next.t('modules.category-section-management.category-section-form.CategorySectionFormRow.field.categories.placeholder')"
    :label-up="true"
    :error="categoryMultiSelect.hasError()"
    :error-message="categoryMultiSelect.errorMessage"
  >
    <template #items="{ item }">
      {{ item.title }}
    </template>
    <template #chips="{ item }">
      {{ item.title }}
    </template>
  </m-multi-select>
  <m-icon-button
    class="my-auto ml-4"
    icon-name="m-svg__delete"
    button-size="32px"
    @click="onDeleteIconClick"
  />
</m-form>