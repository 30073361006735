import Component, { mixins } from 'vue-class-component'

import ROLES from './ROLES';
import { STORE_GETTERS } from '@/store';

export interface ISessionMixin {
  mixin_session_userHasRole(role: ROLES): boolean;
}

@Component
export default class SessionMixin extends mixins() implements ISessionMixin {
  public mixin_session_userHasRole(role: ROLES): boolean {
    if (!role) {
      return false
    }
    
    return this.$store.getters[STORE_GETTERS.SESSION_GET_ROLES].includes(role.toString())
  }
}
