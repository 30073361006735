<section class="dashboard-application-form-management main-content">
  <m-panel class="main-content__panel">
    <h3 slot="header">
      {{ $i18next.t('modules.dashboard.dashboard-application-form-management.DashboardApplicationFormManagement.panel_title') }}
    </h3>
    <template #header-right-content>
      <div class="display--flex">
        <m-button
          :full-size="true"
          @click="onCreateApplicationFormButton"
        >
          {{ $i18next.t('modules.dashboard.dashboard-application-form-management.DashboardApplicationFormManagement.button.create_application_form.label') }}
        </m-button>
      </div>
    </template>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="year">
        <application-forms :year="year" />
      </template>
    </transition>
  </m-panel>
  <template v-if="year">
    <router-view :year="year" />
  </template>
</section>