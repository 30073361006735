import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import WithRender from './PublicFormTemplatePreview.html'

import { 
  MButton,
  MButtonSkin,
  MMessage,
  MMessageSkin,
  MMessageState,
  MPanel,
  MSpinner,
  MToast,
  MToastPosition,
  MToastState
} from '@ulaval/modul-components'

import FormElements from '@/modules/form-management/form-element/FormElements'

import FormTemplateService, { IFetchPublicFormTemplateResponse } from '@/modules/form-template-management/FormTemplate.service'
import { IFormTemplateModel, IInternalFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'
import ROUTES from '@/router/ROUTES'
import { AxiosError } from 'axios'

const REF_CONTENT_PANEL: string = 'panel[main-content]'

@WithRender
@Component({
  components: {
    FormElements,
    MButton,
    MMessage,
    MPanel,
    MSpinner,
    MToast
  }
})
export default class PublicFormTemplatePreview extends mixins(FormTemplateService) {
  private formTemplate: IFormTemplateModel | null = null
  private internalFormTemplate: IInternalFormTemplateModel | null = null
  private isLoadingOverlayActive: boolean = true
  private requestErrors: { [key: string]: boolean } = {}
  private isInformationToastActive: boolean = false

  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MMessageState: object = MMessageState
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly REF_CONTENT_PANEL: string = REF_CONTENT_PANEL

  @Ref(REF_CONTENT_PANEL)
  private mainPanelComponent!: MPanel

  @Watch('$route.params.idCategory', { immediate: true })
  private onIdCategoryChange(value: string): void {
    if (value) {
      this.loadFormTemplate(value)
    }
  }

  private get displayCreateApplicationFormButton(): boolean {
    if (!this.formTemplate || !this.formTemplate.category) {
      return false
    }

    return this.$moment(this.formTemplate.category.application_deposit_limit_timestamp).isAfter()
  }

  private mounted(): void {
    window.addEventListener('scroll', this.onScroll)
  }

  protected destroyed(): void {
    window.removeEventListener('scroll', this.onScroll)
  }

  private onScroll(): void {
    if (this.mainPanelComponent) {
      const messageDomRect: DOMRect = this.mainPanelComponent.$el.getBoundingClientRect()
      
      this.isInformationToastActive = messageDomRect.top < 0
    } else {
      this.isInformationToastActive = false
    }
  }

  private loadFormTemplate(categoryId: string): void {
    this.isLoadingOverlayActive = true
    this.requestErrors = {}

    this.service_formTemplateService_fetchPublic(categoryId)
      .then((response: IFetchPublicFormTemplateResponse) => {
        this.formTemplate = response.form_template || null
        this.internalFormTemplate = response.internal_form_template || null
      }).catch((error: AxiosError<any>) => {
        if (error.response) {
          if (error.response.data && error.response.data.validation) {
            this.requestErrors = error.response.data.validation
          }
        }

        if (Object.keys(this.requestErrors).length === 0) {
          this.mixin_router_redirectRequestError(error)
        }
      }).finally(() => {
        this.isLoadingOverlayActive = false
      })
  }

  private onReturnHomeButtonClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.DEFAULT
    })
  }

  private onCreateApplicationFormButtonClick(): void {
    if (this.formTemplate && this.formTemplate.category) {
      this.mixin_router_navigate({
        name: ROUTES.APPLICATION_FORM_CREATION,
        query: {
          category: this.formTemplate.category.id
        }
      })
    }
  }
}
