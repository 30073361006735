import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './FormTemplateUpdateElementPanel.html'

import { 
  MNavbar,
  MNavbarItem,
  MNavbarSkin
} from '@ulaval/modul-components'

import FormTemplateUpdateAvailableElements from './FormTemplateUpdateAvailableElements'
import FormTemplateUpdateSelectedElement from './FormTemplateUpdateSelectedElement'

import { IFormTemplateAvailableElementModel, IInternalFormTemplateElementModel } from '../FormTemplate.model'
import FormTemplateService from '../FormTemplate.service'

import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import { STORE_GETTERS } from '@/store'

enum TABS_IDENTIFIER {
  AVAILABLE_ELEMENTS = 'available_elements',
  SELECTED_ELEMENT = 'selected_element'
} 

@WithRender
@Component({
  components: {
    FormTemplateUpdateAvailableElements,
    FormTemplateUpdateSelectedElement,
    MNavbar,
    MNavbarItem
  }
})
export default class FormTemplateUpdateElementPanel extends mixins(FormTemplateService) {
  private selectedElement: IInternalFormTemplateElementModel | null = null

  private selectedTabIdentifier: string = TABS_IDENTIFIER.AVAILABLE_ELEMENTS

  private readonly MNavbarSkin: any = MNavbarSkin
  private readonly TABS_IDENTIFIER: any = TABS_IDENTIFIER

  @Prop({ required: true })
  private requestErrors!: { [key: string]: string }

  private get availableFormTemplateElements(): Array<IFormTemplateAvailableElementModel> {
    return this.$store.getters[STORE_GETTERS.FORM_TEMPLATE_AVAILABLE_ELEMENT_GET_FORM_TEMPLATE_AVAILABLE_ELEMENTS]
  }

  private created(): void {
    this.onSelectedElementChange()
    this.onElementInsertDragStart()
    this.onFormTemplateElementDelete()
    this.onFormTemplateElementUpdate()
  }

  private onSelectedElementChange(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_SELECTED, (eventSelectedElement: IInternalFormTemplateElementModel) => {
      this.selectedElement = eventSelectedElement
      this.selectedTabIdentifier = TABS_IDENTIFIER.SELECTED_ELEMENT
    })
  }

  private onFormTemplateElementUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_UPDATED, () => {
      this.selectedTabIdentifier = TABS_IDENTIFIER.AVAILABLE_ELEMENTS
      this.selectedElement = null
    })
  }

  private onElementInsertDragStart(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_STARTED, () => {
      this.selectedTabIdentifier = TABS_IDENTIFIER.AVAILABLE_ELEMENTS
      this.selectedElement = null
    })
  }

  private onFormTemplateElementDelete(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_DELETED, () => {
      this.selectedTabIdentifier = TABS_IDENTIFIER.AVAILABLE_ELEMENTS
      this.selectedElement = null 
    })
  }
}