import Component, { mixins } from 'vue-class-component'
import { Ref } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './UserCreationDialog.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay
} from '@ulaval/modul-components'

import UserForm, { IUserFormPayloadData } from '../user-form/UserForm'
import UserService from '../User.service'

import ROUTES from '@/router/ROUTES'

const FORM_REF: string = 'user-form'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MMessage,
    MOverlay,
    UserForm
  }
})
export default class UserCreationDialog extends mixins(UserService) {
  private isLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showCreationSuccessMessage: boolean = false
  private requestErrors: { [key: string]: string | boolean } = {}

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin

  private readonly FORM_REF: string = FORM_REF

  @Ref(FORM_REF)
  private userFormRef!: UserForm

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.USER_MANAGEMENT_USER_LIST
    })
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.userFormRef) {
      const formPayload: IUserFormPayloadData | null = await this.userFormRef.getFormPayload()

      if (formPayload) {
        this.createUser(formPayload)
      }
    }
  }

  private createUser(formPayload: IUserFormPayloadData): void {
    this.isLoadingStateActive = true

    this.service_userService_create({
      idul: formPayload.idul || '',
      roles: formPayload.roles
    }).then(() => {
      this.showCreationSuccessMessage = true
    }).catch((error: AxiosError<any>) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.validation
      ) {
        this.requestErrors = error.response.data.validation
      } else {
        this.requestErrors = {}
      }
    }).finally(() => {
      this.isLoadingStateActive = false
    })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }
}
