<article class="year-configuration-layout">
  <div class="year-configuration-layout__header">
    <year-configuration-description-area-panel
      :year-configuration="yearConfiguration"
      :year="year"
      :is-preview="isPreview"
    />
  </div>
  <year-configuration-category-sections-panel
    class="mt-12"
    :year-configuration="yearConfiguration"
    :year="year"
    :is-preview="isPreview"
  />
  <year-configuration-help-area-panel
    class="mt-12"
    :year-configuration="yearConfiguration"
    :year="year"
    :is-preview="isPreview"
  />
</article>