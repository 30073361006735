import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import BaseService from "../global/utilities/Base.service";
import { IFormTemplateAvailableElementModel, IFormTemplateAvailableElementModelParameter, IFormTemplateElementModel, IFormTemplateElementOptionModel, IFormTemplateElementParameterModel, IFormTemplateModel, IInternalFormTemplateElementModel, IInternalFormTemplateElementOptionModel, IInternalFormTemplateElementParameterModel, IInternalFormTemplateModel } from "./FormTemplate.model";

import { STORE_ACTIONS, STORE_GETTERS } from "@/store";
import { EVENT_BUS_EVENTS } from "../global/utilities/Events";

interface IFormTemplateUpdateForm {
  elements: Array<IFormTemplateElementUpdateForm>;
}

interface IFormTemplateElementUpdateForm {
  id?: string;
  element_type: string;
  element_position_index: number;
  childrens: Array<IFormTemplateUpdateForm>;
  element_parameters: Array<IFormTemplateElementParameterUpdateForm>;
  element_options: Array<IFormTemplateElementOptionUpdateForm>;
}

interface IFormTemplateElementParameterUpdateForm {
  id?: string;
  key: string;
  value: string;
}

interface IFormTemplateElementOptionUpdateForm {
  id?: string;
  value: string;
  order: string;
}

export interface IFetchPublicFormTemplateResponse {
  form_template: IFormTemplateModel;
  internal_form_template: IInternalFormTemplateModel;
}

@Component
export default class FormTemplateService extends BaseService {
  protected service_formTemplateService_fetchAvailableElements(): Promise<Array<IFormTemplateAvailableElementModel>> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/form-templates/available-elements`)
        .then((response: AxiosResponse) => {
          const availableElements: Array<IFormTemplateAvailableElementModel> = response.data.data.elements

          this.$store.dispatch(STORE_ACTIONS.FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_STORE_FORM_TEMPLATE_AVAILABLE_ELEMENTS, availableElements)

          resolve(availableElements)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_formTemplateService_fetch(id: string): Promise<IFormTemplateModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/form-templates/${id}`)
        .then((response: AxiosResponse) => {
          resolve(response.data.data.form_template)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_formTemplateService_fetchPublic(categoryId: string): Promise<IFetchPublicFormTemplateResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/public/categories/${categoryId}/form-template`)
        .then((response: AxiosResponse) => {
          resolve({
            form_template: response.data.data.form_template,
            internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form_template)
          })
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_formTemplateService_update(internalFormTemplate: IInternalFormTemplateModel): Promise<IInternalFormTemplateModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/form-templates/${internalFormTemplate.id}`, this.service_formTemplateService_mapInternalFormTemplateToRequestForm(internalFormTemplate))
        .then((response: AxiosResponse) => {
          const internalFormTemplate: IInternalFormTemplateModel = this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form_template)

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.FORM_TEMPLATE_UPDATED, internalFormTemplate)

          resolve(internalFormTemplate)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_formTemplateService_fetchMappedToInternalFormTemplate(id: string): Promise<IInternalFormTemplateModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.service_formTemplateService_fetch(id)
        .then((response: IFormTemplateModel) => {
          resolve(
            this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response)
          )
        }).catch((error: AxiosError) => reject(error))
    })
  }

  private service_formTemplateService_mapInternalFormTemplateToRequestForm(formTemplate: IInternalFormTemplateModel): IFormTemplateUpdateForm {
    const availableElements: { [key: string]: IFormTemplateAvailableElementModel } = this.$store.getters[STORE_GETTERS.FORM_TEMPLATE_AVAILABLE_ELEMENT_GET_FORM_TEMPLATE_AVAILABLE_ELEMENTS_BY_TYPE]

    return {
      elements: this.service_formTemplateService_mapInternalFormTemplateElementToRequestFormRecursive(formTemplate.elements, availableElements)
    }
  }

  private service_formTemplateService_mapInternalFormTemplateElementToRequestFormRecursive(
    formTemplateElements: Array<IInternalFormTemplateElementModel>,
    availableElements: { [key: string]: IFormTemplateAvailableElementModel }
  ): Array<IFormTemplateElementUpdateForm> {
    return formTemplateElements.reduce((mappedElements: Array<IFormTemplateElementUpdateForm>, element: IInternalFormTemplateElementModel) => {
      return [
        ...mappedElements,
        {
          id: element.id,
          element_type: element.element_type,
          element_position_index: element.element_position_index,
          childrens: this.service_formTemplateService_mapInternalFormTemplateElementToRequestFormRecursive(element.childrens, availableElements),
          element_options: element.element_options.reduce((mappedOptions: Array<IFormTemplateElementOptionUpdateForm>, option: IInternalFormTemplateElementOptionModel) => {
            return [
              ...mappedOptions,
              {
                id: option.id,
                value: option.value,
                order: option.order ? option.order.toString() : null
              } as IFormTemplateElementOptionUpdateForm
            ]
          }, []),
          element_parameters: element.element_parameters.reduce((mappedParameters: Array<IFormTemplateElementParameterUpdateForm>, parameter: IInternalFormTemplateElementParameterModel) => {
            const elementConfiguration: IFormTemplateAvailableElementModel = availableElements[element.element_type]

            if (!elementConfiguration) {
              return mappedParameters
            }
            
            const elementConfigurationParameter: IFormTemplateAvailableElementModelParameter | undefined = elementConfiguration.element_parameters.find(
              (elementConfigurationParameter: IFormTemplateAvailableElementModelParameter) => elementConfigurationParameter.key === parameter.key
            )

            if (!elementConfigurationParameter) {
              return mappedParameters
            }

            return [
              ...mappedParameters,
              {
                id: parameter.id,
                key: parameter.key,
                value: parameter.value ? parameter.value.toString() : parameter.value
              } as IFormTemplateElementParameterUpdateForm
            ]
          }, [])
        } as any
      ]
    }, [])
  }

  protected service_formTemplateService_mapFormTemplateToInternalFormTemplate(formTemplate: IFormTemplateModel): IInternalFormTemplateModel {
    const internalFormTemplate: IInternalFormTemplateModel = {
      id: formTemplate.id,
      type: formTemplate.type,
      is_updateable: formTemplate.is_updateable,
      category: formTemplate.category || {} as any,
      elements: this.service_formTemplateService_mapFormTemplateElementToInternalFormTemplateElement(formTemplate.elements)
    }

    return internalFormTemplate
  }

  private service_formTemplateService_mapFormTemplateElementToInternalFormTemplateElement(
    formTemplateElements: Array<IFormTemplateElementModel> = [], 
    parentInternalIdentifier: string | null = null
  ): Array<IInternalFormTemplateElementModel> {
    return formTemplateElements.reduce((mappedElements: Array<IInternalFormTemplateElementModel>, element: IFormTemplateElementModel) => {
      const elementInternalIdentifier: string = parentInternalIdentifier ? `${parentInternalIdentifier}-${element.element_position_index}` : element.element_position_index.toString()

      return [
        ...mappedElements,
        {
          id: element.id,
          element_type: element.element_type,
          element_position_index: element.element_position_index,
          internal_identifier: elementInternalIdentifier,
          parent_internal_identifier: parentInternalIdentifier,
          component_name: element.component_name,
          option_component_name: element.option_component_name,
          childrens: this.service_formTemplateService_mapFormTemplateElementToInternalFormTemplateElement(element.childrens, elementInternalIdentifier),
          element_options: element.element_options.reduce((mappedOptions: Array<IInternalFormTemplateElementOptionModel>, option: IFormTemplateElementOptionModel) => {
            return [
              ...mappedOptions,
              {
                id: option.id,
                value: option.value,
                order: isNaN(option.order as any) ? null : parseInt(option.order)
              } as IInternalFormTemplateElementOptionModel
            ]
          }, []) as Array<IInternalFormTemplateElementOptionModel>,
          element_parameters: element.element_parameters.reduce((mappedParameters: Array<IInternalFormTemplateElementParameterModel>, parameter: IFormTemplateElementParameterModel) => {
            return [
              ...mappedParameters,
              {
                id: parameter.id,
                key: parameter.key,
                value: parameter.value,
                frontend_key: parameter.frontend_key,
                frontend_type: parameter.frontend_type
              } as IInternalFormTemplateElementParameterModel
            ]
          }, []) as Array<IInternalFormTemplateElementParameterModel>
        } as IInternalFormTemplateElementModel
      ]
    }, []).sort((leftHandSide: IInternalFormTemplateElementModel, rightHandSide: IInternalFormTemplateElementModel) => {
      return leftHandSide.element_position_index - rightHandSide.element_position_index
    })
  }
}