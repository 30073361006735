import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './YearConfigurationCategorySectionsPanel.html'

import { 
  MButton,
  MPanel
} from '@ulaval/modul-components'

import PublicCategoryCard from '@/modules/category-management/category/PublicCategoryCard'

import { IYearConfigurationModel } from '../YearConfiguration.model'
import { IYearModel } from '@/modules/global/year/Year.model'
import { generateLocalizedRouteName } from '@/router/helpers'
import ROUTES from '@/router/ROUTES'
import localization from '@/localization'
import { ICategoryModel } from '@/modules/category-management/Category.model'

@WithRender
@Component({
  components: {
    MButton,
    MPanel,
    PublicCategoryCard
  }
})
export default class YearConfigurationCategorySectionsPanel extends mixins() {
  @Prop({ required: true })
  private yearConfiguration!: IYearConfigurationModel

  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ 
    required: false, 
    default: false 
  })
  private isPreview!: boolean

  private get isCreateApplicationFormButtonAvailable(): boolean {
    return this.$route.name === generateLocalizedRouteName({
      name: ROUTES.DEFAULT,
      locale: localization.CURRENT_LOCALE
    })
  }

  private onCreateApplicationFormButtonClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.APPLICATION_FORM_CREATION
    })
  }

  private sortCategories(categories: Array<ICategoryModel>): Array<ICategoryModel> {
    return Array.from(categories).sort((leftHandSide: ICategoryModel, rightHandSide: ICategoryModel) => leftHandSide.sort_order - rightHandSide.sort_order)
  }
}
