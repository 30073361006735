import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IYearModel } from './Year.model'

export enum STORE_ACTIONS {
  YEAR_STORE_STORE_YEARS = 'year/storeYears',
  YEAR_STORE_STORE_CURRENT_YEAR = 'year/storeCurrentYear'
}

export enum STORE_GETTERS {
  YEAR_GET_YEARS = 'year/years',
  YEAR_GET_CURRENT_YEAR = 'year/currentYear',
  YEAR_GET_YEARS_BY_SLUG = 'year/yearsBySlug'
}

@Module({ namespaced: true })
export default class YearStore extends VuexModule {
  private _years: Array<IYearModel> = []
  private _currentYear: IYearModel | null = null

  @Mutation
  private storeYearsMutation(years: Array<IYearModel>): void {
    this._years = years
  }

  @Mutation
  private storeCurrentYearMutation(year: IYearModel): void {
    this._currentYear = year
  }

  @Action
  public storeYears(years: Array<IYearModel>): void {
    this.context.commit('storeYearsMutation', years)
  }

  @Action
  public storeCurrentYear(year: IYearModel): void {
    this.context.commit('storeCurrentYearMutation', year)
  }

  private get years(): Array<IYearModel> {
    return this._years
  }

  private get currentYear(): IYearModel | null {
    return this._currentYear
  }

  private get yearsBySlug(): { [key: string]: IYearModel } {
    const mappedYears: { [key: string]: IYearModel } = {}

    this._years.forEach((year: IYearModel) => {
      mappedYears[year.slug] = year
    })

    return mappedYears
  }
}
