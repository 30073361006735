import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { IRoleModel } from "./Role.model";
import BaseService from "../global/utilities/Base.service";

@Component
export default class RoleService extends BaseService {
  protected service_roleService_fetchList(): Promise<Array<IRoleModel>> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/roles`)
        .then((response: AxiosResponse) => resolve(response.data.data.roles))
        .catch((error: AxiosError) => reject(error))
    })
  }
}