<article class="admin-application-form-validation-management__application-form-validations">
  <m-table-header>
    <template #left>
      <m-toggle-buttons
        :buttons="statusToggleButtons"
        :multiple="true"
        @click="onToggleButtonClick($event)"
      />
    </template>
    <template #right>
      <m-searchfield
        v-model="searchField.value"
        v-m-control="searchField"
        :label-up="true"
        :label="$i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.search_field.label')"
        :placeholder="$i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.search_field.placeholder')"
        @search="onSearchFieldChange"
        @empty-field="onSearchFieldChange"
      />
    </template>
  </m-table-header>
  <m-table
    :columns="tableColumns"
    :loading="isLoadingStateActive"
    :rows="applicationFormValidations"
  >
    <template #body.timestamp="{ data }">
      <template v-if="!mixin_string_isEmpty(data.updated_at)">
        {{ $i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.table.columns.timestamp.content', {
        timestamp: mixin_timestamp_pretty(data.updated_at),
        user: data.updated_by_name
      }) }}
      </template>
      <template v-else-if="!mixin_string_isEmpty(data.created_at)">
        {{ $i18next.t('modules.application-form-validation-management.application-form-validations.AdminApplicationFormValidations.table.columns.timestamp.content', {
        timestamp: mixin_timestamp_pretty(data.created_at),
        user: data.created_by_name
      }) }}
      </template>
    </template>
    <template #body.status="{ data }">
      <div class="display--flex justify-content-start">
        <template v-if="mixin_applicationFormValidation_computeStatusIcon(data)">
          <m-icon
            class="mr-2"
            size="22px"
            :style="mixin_applicationFormValidation_computeStatusIconStyles(data)"
            :name="mixin_applicationFormValidation_computeStatusIcon(data)"
          />
        </template>
        <div class="mx-0 mt-auto">
          {{ mixin_applicationFormValidation_computeStatusLabel(data.status) }}
        </div>
      </div>
    </template>
    <template #body.options="{ data }">
      <div class="display--flex justify-content--end">
        <m-icon-button
          icon-name="m-svg__show"
          @click="onShowApplicationFormValidationIconClick($event, data)"
        />
      </div>
    </template>
  </m-table>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="paginationData">
      <m-pagination
        :items-per-page="paginationData.element_count_per_page"
        :items-total="paginationData.total_element_count"
        :value="paginationData.current_page_index + 1"
        @change="onPageChange($event)"
      />
    </template>
  </transition>
</article>