import Component, { mixins } from 'vue-class-component'

import WithRender from './EvaluatorManagement.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import YearDropdown from '../global/year/YearDropdown'
import Breadcrumbs from '../global/breadcrumbs/Breadcrumbs'
import AdminEvaluatorCategoryAssignments from './evaluator-category-assignment-management/evaluator-category-assignments/AdminEvaluatorCategoryAssignments'

import { IYearModel } from '../global/year/Year.model'

@WithRender
@Component({
  components: {
    AdminEvaluatorCategoryAssignments,
    Breadcrumbs,
    MPanel,
    YearDropdown
  }
})
export default class EvaluatorManagement extends mixins() {
  private year: IYearModel | null = null
}
