import Component, { mixins } from 'vue-class-component'

import WithRender from './CategoryManagement.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import Categories from './categories/Categories'
import YearDropdown from '../global/year/YearDropdown'
import Breadcrumbs from '../global/breadcrumbs/Breadcrumbs'

import { IYearModel } from '../global/year/Year.model'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    Categories,
    MPanel,
    YearDropdown
  }
})
export default class CategoryManagement extends mixins() {
  private year: IYearModel | null = null
}
