import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './DashboardApplicationFormManagement.html'

import {
  MButton, 
  MPanel
} from '@ulaval/modul-components'

import ApplicationForms from '../../application-form-management/application-forms/ApplicationForms'
import YearDropdown from '@/modules/global/year/YearDropdown'

import { IYearModel } from '@/modules/global/year/Year.model'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    ApplicationForms,
    MButton,
    MPanel,
    YearDropdown
  }
})
export default class DashboardApplicationFormManagement extends mixins() {
  @Prop({ required: true })
  private year!: IYearModel
  
  private onCreateApplicationFormButton(): void {
    this.mixin_router_navigate({
      name: ROUTES.APPLICATION_FORM_CREATION
    })
  }
}
