import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './ApplicationFormReminderEmailOverlay.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MButton,
  MButtonSkin,
  MForm,
  MMessage,
  MMessageSkin,
  MMessageState,
  MMultiSelect,
  MOverlay,
  RequiredValidator
} from '@ulaval/modul-components'

import { IYearModel } from '@/modules/global/year/Year.model'
import ApplicationFormService from '../ApplicationForm.service'
import { ICategoryModel } from '@/modules/category-management/Category.model'

@WithRender
@Component({
  components: {
    MButton,
    MForm,
    MMessage,
    MMultiSelect,
    MOverlay,
  }
})
export default class ApplicationFormReminderEmailOverlay extends mixins(ApplicationFormService) {
  public formGroup: FormGroup = new FormGroup({
    'category-multi-select': new FormControl<Array<string>>([RequiredValidator()])
  })
  private isLoadingStateActive: boolean = false
  private isCategoryLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = false
  private showSuccessMessage: boolean = false
  private categories: Array<ICategoryModel> = []
  private selectedCategories: Array<string> = []

  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin

  @Prop({ required: true })
  private year!: IYearModel

  public get categoryMultiSelect(): AbstractControl<Array<string>> {
    return this.formGroup.getControl('category-multi-select');
  }

  private get categoriesSelectOptions(): Array<any> {
    return this.categories.map((category: ICategoryModel) => category.title)
  }

  private get hasAvailableCategories(): boolean {
    return this.categories.length > 0
  }

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadCategories()
  }

  private loadCategories(): void {
    this.isCategoryLoadingStateActive = true

    this.service_applicationFormService_fetchAvailableCategories(this.year.id)
      .then((response: Array<ICategoryModel>) => {
        this.categories = response || []
      }).catch(() => {
        this.categories = []
      }).finally(() => {
        this.isCategoryLoadingStateActive = false
      })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private onTriggerButtonClick(): void {
    this.isOverlayOpen = true
  }

  private async onSaveButtonClick(): Promise<void> {
    this.categoryMultiSelect.value = this.selectedCategories

    await this.formGroup.submit()

    if (!this.formGroup.hasErrorDeep()) {
      this.sendReminderEmails(this.mapSelectedCategoriesToIds())
    }
  }

  private sendReminderEmails(categoriesId: Array<string>): void {
    this.isLoadingStateActive = true
    
    this.service_applicationFormService_sendApplicationFormReminderEmails({
      categories_id: categoriesId
    }).then(() => {
      this.showSuccessMessage = true
    }).catch((error: AxiosError) => {
      this.mixin_router_redirectRequestError(error)
    }).finally(() => {
      this.isLoadingStateActive = false
    })
  }

  private mapSelectedCategoriesToIds(): Array<string> {
    const ids: Array<string> = []

    this.categories.forEach((category: ICategoryModel) => {
      if (this.selectedCategories.includes(category.title)) {
        ids.push(category.id)
      }
    })

    return ids
  }

  private afterOverlayClose(): void {
    this.isLoadingStateActive = false
    this.isCategoryLoadingStateActive = false
    this.showSuccessMessage = false
    this.selectedCategories = []

    this.formGroup.reset()
  }
}
