import Component, { mixins } from 'vue-class-component'

import WithRender from './RadioGroup.html'

import { 
  MRadio,
  MRadioGroup
} from '@ulaval/modul-components'

import FormElementMixin from '../../form-element/FormElement.mixin'
import { IFormAnswerModel } from '../../Form.model'

@WithRender
@Component({
  components: {
    MRadio,
    MRadioGroup,
  }
})
export default class RadioGroup extends mixins(FormElementMixin) {
  private get answersCountByValue(): { [key: string]: number } {
    return this.formElementAnswers.reduce((acc: { [key: string]: number }, element: IFormAnswerModel) => {
      if (!acc[element.value]) {
        acc[element.value] = 1
      } else {
        acc[element.value]++
      }

      return acc
    }, {})
  }

  private optionAnswerCount(option: IFormAnswerModel): number | null {
    return this.answersCountByValue[option.value] || null
  }
}