<section class="form-template-update__drop-zone">
  <template v-for="element in sortedElements">
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="insertDraggedElement">
        <form-template-update-insert-zone
          :insertionElementPositionIndex="element.element_position_index"
          :dense="true"
        />
      </template>
      <template v-else-if="isDraggedElementMovable(element.element_position_index)">
        <form-template-update-insert-zone
          :insertionElementPositionIndex="element.element_position_index > 0 ? element.element_position_index - 1 : element.element_position_index"
          :dense="true"
        />
      </template>
    </transition>
    <form-template-update-drop-zone-element
      :key="element.id"
      :form-template-element="element"
      :request-errors="requestErrors"
    />
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="insertDraggedElement">
      <form-template-update-insert-zone :insertionElementPositionIndex="formTemplate.elements.length" />
    </template>
    <template v-else-if="isDraggedElementMovable(formTemplate.elements.length)">
      <form-template-update-insert-zone
        :insertionElementPositionIndex="formTemplate.elements.length - 1"
        :dense="true"
      />
    </template>
  </transition>
</section>