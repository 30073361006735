import { SpritesService } from '@ulaval/modul-components';
import Vue, { PluginObject, VueConstructor } from 'vue';

const SVG_PREFIX: string = 'pd'

const IconPlugin: PluginObject<any> = {
    install(v: VueConstructor<Vue>): void {
        const svg: SpritesService = v.prototype.$svg;
        /* eslint-disable @typescript-eslint/no-var-requires */
        svg.addExternalSprites(
          require('@/assets/medias/svg/sprites.svg'),
          SVG_PREFIX
        )
    },
};

export default IconPlugin;