import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './EmailStatusRow.html'

import { 
  MIcon,
  MTooltip,
  MTooltipSize
} from '@ulaval/modul-components'

import { IEmailStatusModel } from './EmailStatus.model'
import EmailStatusMixin from './EmailStatus.mixin'

@WithRender
@Component({
  components: {
    MIcon,
    MTooltip
  }
})
export default class EmailStatusRow extends mixins(EmailStatusMixin) {
  private readonly MTooltipSize: object = MTooltipSize
  
  @Prop({ required: true })
  private emailStatus!: IEmailStatusModel
}
