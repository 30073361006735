<section
  id="public-form-template-preview"
  class="main-content"
>
  <m-panel
    class="main-content__panel"
    :ref="REF_CONTENT_PANEL"
  >
    <template v-if="formTemplate">
      <h3 slot="header">
        {{ formTemplate.category.title }}
      </h3>
    </template>
    <template #header-right-content>
      <template v-if="displayCreateApplicationFormButton">
        <m-button @click="onCreateApplicationFormButtonClick">
          {{ $i18next.t('modules.public-area.form-template-preview.PublicFormTemplatePreview.button.create_application_form.label') }}
        </m-button>
      </template>
      <m-button
        :skin="MButtonSkin.Secondary"
        @click="onReturnHomeButtonClick"
      >
        {{ $i18next.t('modules.public-area.form-template-preview.PublicFormTemplatePreview.button.return_home.label') }}
      </m-button>
    </template>
    <transition name="fade-out-in">
      <template v-if="isLoadingOverlayActive">
        <div class="my-15">
          <m-spinner
            :title="true"
            :description="true"
          />
        </div>
      </template>
      <template v-else-if="requestErrors['year_configuration_not_available']">
        <m-message
          :skin="MMessageSkin.Page"
          :state="MMessageState.Warning"
          :title="$i18next.t('modules.public-area.form-template-preview.PublicFormTemplatePreview.message.year_configuration_not_available.title')"
          class="text-align--center"
        >
          <span v-html="$i18next.t('modules.public-area.form-template-preview.PublicFormTemplatePreview.message.year_configuration_not_available.content')" />
        </m-message>
      </template>
      <template v-else-if="requestErrors['form_template_not_available']">
        <m-message
          :skin="MMessageSkin.Page"
          :state="MMessageState.Error"
          :title="$i18next.t('modules.public-area.form-template-preview.PublicFormTemplatePreview.message.form_template_not_available.title')"
          class="text-align--center"
        >
          <span v-html="$i18next.t('modules.public-area.form-template-preview.PublicFormTemplatePreview.message.form_template_not_available.content')" />
        </m-message>
      </template>
      <template v-else-if="formTemplate && internalFormTemplate">
        <div>
          <form-elements
            :elements="internalFormTemplate.elements"
            :is-form-readonly="true"
          />
        </div>
      </template>
    </transition>
  </m-panel>
  <template v-if="isInformationToastActive">
    <m-toast
      :position="MToastPosition.TopCenter"
      :state="MToastState.Information"
    >
      <span v-html="$i18next.t(`modules.public-area.form-template-preview.PublicFormTemplatePreview.message.preview_only.content`)" />
    </m-toast>
  </template>
</section>