import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './CategorySectionFormRow.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MForm,
  MIconButton,
  MMultiSelect,
  MTextfield,
  RequiredValidator
} from '@ulaval/modul-components'
import { ICategoryModel } from '@/modules/category-management/Category.model'

export interface ICategorySectionFormRowPayloadData {
  id?: string;
  title: string;
  categories_id: Array<string>;
}

export enum CategorySectionFormRow_EVENTS {
  CLICK_DELETE = 'click:delete'
}

@WithRender
@Component({
  components: {
    MForm,
    MIconButton,
    MMultiSelect,
    MTextfield
  }
})
export default class CategorySectionFormRow extends mixins() {
  public formGroup: FormGroup = new FormGroup({
    'title': new FormControl<string>([RequiredValidator()]),
    'category-multi-select': new FormControl<Array<ICategoryModel>>([RequiredValidator()])
  })
  private selectedCategories: Array<ICategoryModel> = []
  private formErrors: { [key: string]: string | boolean } = {}

  @Prop({ required: true })
  private categorySection!: ICategorySectionFormRowPayloadData

  @Prop({ required: true })
  private categories!: Array<ICategoryModel>

  @Prop({ default: {} })
  private requestErrors!: { [key: string]: string | boolean }

  private get errors(): { [key: string]: string | boolean } {
    return {
      ...this.formErrors,
      ...this.requestErrors
    }
  }

  public get titleField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('title');
  }
  
  public get categoryMultiSelect(): AbstractControl<Array<ICategoryModel>> {
    return this.formGroup.getControl('category-multi-select');
  }

  public get categoriesMappedById(): { [key: string]: ICategoryModel } {
    return this.categories.reduce((acc: { [key: string]: ICategoryModel }, category: ICategoryModel) => {
      return {
        ...acc,
        [category.id]: category
      }
    }, {}) || {}
  }

  @Watch('categorySection')
  private onCategorySectionChange(): void {
    this.populateFormFromModel()
  }

  @Watch('categoriesMappedById')
  private onCategoriesMappedByIdChange(): void {
    this.populateFormFromModel()
  }

  private mounted(): void {
    this.populateFormFromModel()
  }

  private populateFormFromModel(): void {
    if (this.categorySection) {
      this.selectedCategories = []
      this.titleField.value = this.categorySection.title

      this.categorySection.categories_id.forEach((categoryId: string) => {
        if (this.categoriesMappedById[categoryId]) {
          this.$set(this.selectedCategories, this.selectedCategories.length, this.categoriesMappedById[categoryId])
        }
      })
    }
  }

  public async getFormPayload(): Promise<ICategorySectionFormRowPayloadData | null> {
    this.categoryMultiSelect.value = this.selectedCategories

    await this.formGroup.submit()

    if (this.formGroup.hasErrorDeep()) {
      return null
    }

    const formPayloadData: ICategorySectionFormRowPayloadData = {
      id: this.categorySection.id,
      title: this.titleField.value,
      categories_id: this.categoryMultiSelect.value.map((category: ICategoryModel) => category.id)
    }

    return formPayloadData
  }

  private onDeleteIconClick(): void {
    this.$emit(CategorySectionFormRow_EVENTS.CLICK_DELETE)
  }
}
