  <div class="display--flex py-1">
    <m-icon
      :name="mixin_emailStatus_computeStatusIcon(emailStatus)"
      :style="mixin_emailStatus_computeStatusIconStyles(emailStatus)"
    />
    <p class="m-0 px-2">
      {{ $i18next.t('modules.global.email.EmailStatusRow.label.sender', {
        'user': emailStatus.sent_by,
        'timestamp': mixin_timestamp_pretty(emailStatus.sent_at)
      }) }}
    </p>
    <m-tooltip :size="MTooltipSize.Large">
      <p class="m-0">
        <template v-if="!mixin_string_isEmpty(emailStatus.error_message)">
          <span v-html="$i18next.t('modules.global.email.EmailStatusRow.label.error_message', emailStatus)" />
          <br />
          <br />
        </template>
        <template v-if="emailStatus.recipients && emailStatus.recipients.length > 0">
          {{ $i18next.t('modules.global.email.EmailStatusRow.label.recipients') }}
          <ul class="mt-1 mb-2 pl-6">
            <template v-for="recipient in emailStatus.recipients">
              <li>
                {{ recipient }}
              </li>
            </template>
          </ul>
        </template>
        <template v-if="emailStatus.carbon_copy_recipients && emailStatus.carbon_copy_recipients.length > 0">
          {{ $i18next.t('modules.global.email.EmailStatusRow.label.carbon_copy_recipients') }}
          <ul class="mt-1 mb-2 pl-6">
            <template v-for="recipient in emailStatus.carbon_copy_recipients">
              <li>
                {{ recipient }}
              </li>
            </template>
          </ul>
        </template>
        <template v-if="emailStatus.blind_carbon_copy_recipients && emailStatus.blind_carbon_copy_recipients.length > 0">
          {{ $i18next.t('modules.global.email.EmailStatusRow.label.blind_carbon_copy_recipients') }}
          <ul class="mt-1 mb-2 pl-6">
            <template v-for="recipient in emailStatus.blind_carbon_copy_recipients">
              <li>
                {{ recipient }}
              </li>
            </template>
          </ul>
        </template>
      </p>
    </m-tooltip>
  </div>