import Component, { mixins } from 'vue-class-component'

import WithRender from './EmbeddedVideoPlayer.html'

import { 
  MLink,
  MTextfield
} from '@ulaval/modul-components'

import FormElementMixin from '../../form-element/FormElement.mixin'

@WithRender
@Component({
  components: {
    MLink,
    MTextfield
  }
})
export default class EmbeddedVideoPlayer extends mixins(FormElementMixin) {

  private formatEmbeddedUrl(url: string): string | null {
    if (!url) {
      return null
    }

    if (url.includes('youtube')) {
      return this.formatYoutubeUrl(url)
    }

    return null;
  }

  private formatYoutubeUrl(url: string): string {
    const videoId: string = url.split("?v=")[1]

    if (!videoId) {
      return url
    }

    return `https://www.youtube.com/embed/${videoId}`
  }

}