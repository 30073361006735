import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './DashboardEvaluatorSection.html'

import {
  MPanel
} from '@ulaval/modul-components'

import DashboardEvaluatorAssignmentManagement from './dashboard-evaluator-assignment-management/DashboardEvaluatorAssignmentManagement'
import DashboardEvaluatorToolbox from './DashboardEvaluatorToolbox'

import { IYearModel } from '../global/year/Year.model'
import EvaluatorToolboxFileService from '../evaluator-toolbox-file-management/EvaluatorToolboxFile.service'
import { IEvaluatorToolboxFileModel } from '../evaluator-toolbox-file-management/EvaluatorToolboxFile.model'
import EvaluatorCategoryAssignmentService from '../evaluator-management/evaluator-category-assignment-management/EvaluatorCategoryAssignment.service'
import { IEvaluatorCategoryAssignmentModel } from '../evaluator-management/evaluator-category-assignment-management/EvaluatorCategoryAsssignment.model'
import { EventBus, EVENT_BUS_EVENTS } from '../global/utilities/Events'

@WithRender
@Component({
  components: {
    DashboardEvaluatorAssignmentManagement,
    DashboardEvaluatorToolbox,
    MPanel
  }
})
export default class DashboardEvaluatorSection extends mixins(EvaluatorToolboxFileService, EvaluatorCategoryAssignmentService) {
  private evaluatorToolboxFiles: Array<IEvaluatorToolboxFileModel> = []
  private evaluatorCategoryAssignments: Array<IEvaluatorCategoryAssignmentModel> = []
  private isUserEvaluator: boolean = false

  @Prop({ required: true })
  private year!: IYearModel

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    if (this.year) {
      this.isUserEvaluator = false
      
      this.loadEvaluatorToolboxFiles()
      this.loadEvaluatorCategoryAssignments()
    }
  }

  private mounted(): void {
    this.afterEvaluatorApplicationFormAssignmentUpdate()
  }

  private afterEvaluatorApplicationFormAssignmentUpdate(): void {
    EventBus.$on(EVENT_BUS_EVENTS.EVALUATOR_APPLICATION_FORM_ASSIGNMENT_UPDATED, () => {
      this.loadEvaluatorCategoryAssignments()
    })
  }

  private loadEvaluatorToolboxFiles(): void {
    this.service_evaluatorToolboxFileService_fetchEvaluatorList({
      year_id: this.year.id
    }).then((responseData: Array<IEvaluatorToolboxFileModel>) => {
      this.evaluatorToolboxFiles = responseData
      this.isUserEvaluator = true
    }).catch(() => {
      this.evaluatorToolboxFiles = []
    })
  }

  private loadEvaluatorCategoryAssignments(): void {
    this.service_evaluatorCategoryAssignmentService_fetchEvaluatorCategoryAssignments({
      year_id: this.year.id
    }).then((responseData: Array<IEvaluatorCategoryAssignmentModel>) => {
      this.evaluatorCategoryAssignments = responseData
      this.isUserEvaluator = true
    }).catch(() => {
      this.evaluatorCategoryAssignments = []
    })
  }
}
