import Component, { mixins } from 'vue-class-component'

import WithRender from './DashboardPage.html'

import DashboardApplicationFormManagement from './dashboard-application-form-management/DashboardApplicationFormManagement'
import DashboardEvaluatorSection from './DashboardEvaluatorSection'
import YearDropdown from '@/modules/global/year/YearDropdown'

import { IYearModel } from '@/modules/global/year/Year.model'

@WithRender
@Component({
  components: {
    DashboardApplicationFormManagement,
    DashboardEvaluatorSection,
    YearDropdown
  }
})
export default class DashboardPage extends mixins() {
  private year: IYearModel | null = null
}
