import Component, { mixins } from 'vue-class-component'

import WithRender from './DefaultLayout.html'

import NavigationBar  from '@/modules/global/navigation-bar/NavigationBar'
import PublicArea from '@/modules/public-area/PublicArea'

import { generateLocalizedRouteName } from '@/router/helpers'
import localization from '@/localization'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    NavigationBar,
    PublicArea
  }
})
export default class DefaultLayout extends mixins() {
  private get displayPublicZone(): boolean {
    return this.$route.name === generateLocalizedRouteName({
      name: ROUTES.DEFAULT,
      locale: localization.CURRENT_LOCALE
    })
  }
}
