import Component, { mixins } from 'vue-class-component'

import ROLES from '../session/ROLES'

@Component
export default class UserRoleMixin extends mixins() {
  protected get mixin_userrole_hasGestionnaireGabaritFormulaireRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_GABARIT_FORMULAIRE)
  }

  protected get mixin_userrole_hasAdministratorRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.ADMINISTRATOR)
  }

  protected get mixin_userrole_hasGestionnaireCategorieRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_CATEGORIE)
  }

  protected get mixin_userrole_hasGestionnaireGabaritCourrielRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_GABARIT_COURRIEL)
  }

  protected get mixin_userrole_hasGestionnaireCandidatureRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_CANDIDATURE)
  }

  protected get mixin_userrole_hasGestionnaireAppuiCandidatureRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_APPUI_CANDIDATURE)
  }

  protected get mixin_userrole_hasGestionnaireValidationCandidatureRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_VALIDATION_CANDIDATURE)
  }

  protected get mixin_userrole_hasGestionnaireEvaluateurRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_EVALUATEUR)
  }

  protected get mixin_userrole_hasGestionnaireSectionPubliqueRole(): boolean {
    return this.mixin_session_userHasRole(ROLES.GESTIONNAIRE_SECTION_PUBLIQUE)
  }
}