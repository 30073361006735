import Component, { mixins } from 'vue-class-component'

import { AxiosError, AxiosResponse } from 'axios';
import HttpStatusCode from 'http-status-codes'

import WithRender from './App.html'

import Localization from '@/localization';
import ROUTES from './router/ROUTES';
import { generateLocalizedRouteName } from '@/router/helpers';
import { STORE_ACTIONS, STORE_GETTERS } from './store';
import { TOKEN_LOCAL_STORAGE_KEY } from './modules/session/Session.store';
import CONSTANTS from './CONSTANTS';

const HTTP_STATUS_TO_REDIRECT: string[] = [
  HttpStatusCode.INTERNAL_SERVER_ERROR.toString(),
  HttpStatusCode.FORBIDDEN.toString(),
  HttpStatusCode.UNAUTHORIZED.toString()
]
const HTTP_STATUS_NOT_LOGGED_IN: string[] = [
  HttpStatusCode.FORBIDDEN.toString(),
  HttpStatusCode.UNAUTHORIZED.toString()
]

@WithRender
@Component
export default class App extends mixins() {
  private created(): void {
    this.initializeAxiosInterceptors()

    this.$store.dispatch(STORE_ACTIONS.SESSION_STORE_SYNCHRONISE_WITH_LOCAL_STORAGE)
    this.setAxiosBasicHeaders()
  }

  private mounted(): void {
    document.title = this.$i18next.t('localization.main.website_title')
  
  }

  private updated(): void {
    this.$store.dispatch(STORE_ACTIONS.SESSION_STORE_SYNCHRONISE_WITH_LOCAL_STORAGE)
    this.setAxiosBasicHeaders()
  }

  private setAxiosBasicHeaders(): void {
    const sessionToken: string | null = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)

    if (sessionToken) {
      this.$axios.defaults.headers.common.Authorization = `Bearer ${sessionToken}`
    }
  }

  private initializeAxiosInterceptors(): void {
    this.$axios.interceptors.response.use((response: AxiosResponse) => {
      return response
    }, (error: AxiosError<any>) => {
      if (!error.response) {
        this.mixin_router_navigate({
          name: ROUTES.ERROR,
          query: {
            token: this.$route.params.token
          }
        })
      } else if (
        this.$route.name !== generateLocalizedRouteName({
          name: ROUTES.LOGIN,
          locale: Localization.CURRENT_LOCALE
        }) && 
        error.response && 
        error.response.status && 
        HTTP_STATUS_TO_REDIRECT.includes(error.response.status.toString())
      ) {
        if (
          HTTP_STATUS_NOT_LOGGED_IN.includes(error.response.status.toString()) &&
          this.mixin_string_isEmpty(this.$store.getters[STORE_GETTERS.SESSION_GET_TOKEN])
        ) {
          this.setLocalStorageRememberedRoute()

          this.$store.dispatch(STORE_ACTIONS.SESSION_STORE_LOGOUT)
          this.mixin_router_navigate({
            name: ROUTES.LOGIN
          })

          return null
        } else if (error.response.data && error.response.data.validation) {
          if (error.response.data.validation.expired_token) {
            this.setLocalStorageRememberedRoute()

            this.$store.dispatch(STORE_ACTIONS.SESSION_STORE_LOGOUT)
            this.mixin_router_navigate({
              name: ROUTES.LOGIN
            })

            return null
          } else if (error.response.data.validation.user_not_an_evaluator) {
            return Promise.reject(error)
          }
        } else {
          this.mixin_router_redirectRequestError(error)
        }
      }

      return Promise.reject(error)
    })
  }

  private setLocalStorageRememberedRoute(): void {
    localStorage.setItem(
      CONSTANTS.LOCAL_STORAGE_REMEMBERED_ROUTE, 
      JSON.stringify({
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query
      })  
    )
  }
}
