import { RouteConfig } from "vue-router";
import { RouteConfigSingleView } from "vue-router/types/router";

import Localization, { LOCALES } from '@/localization'

const ROUTE_LOCALE_DELIMITER: string = '|'

export const META_KEY_HIDDEN_BREADCRUMB: string = 'hidden_breadcrumb'
export const META_KEY_ADDITIONAL_BREADCRUMBS: string = 'additional_breadcrumb'
export const META_KEY_LOCALE: string = 'locale'
export const META_KEY_IS_BREADCRUMB_HOME_PUBLIC_ZONE: string = 'breadcrumb_home_public_zone'
export const META_KEY_DISPLAY_PUBLIC_NAVIGATION_BAR: string = "breadcrumb_display_navigation_bar"

export interface ILocalizedRouteParameters {
  name: string;
  locale: LOCALES;
}

export function generateLocalizedRouteName(parameters: ILocalizedRouteParameters): string {
  return `${parameters.name}${ROUTE_LOCALE_DELIMITER}${parameters.locale}`
}

export function extractLocalizedRouteParameters(name: string): ILocalizedRouteParameters {
  const routeParameters: string[] = name.split(ROUTE_LOCALE_DELIMITER)

  return {
    name: routeParameters[0],
    locale: routeParameters[1] as LOCALES
  }
}

function generateLocalizedRoutePath(name: string, locale: LOCALES = LOCALES.FR): string {
  return Localization.i18next.t(`router.routes.${name}`, { lng: locale })
}

export function localizeBreadcrumbs(locale: LOCALES, breadcrumbs?: Array<string>): Array<string> | null {
  if (!breadcrumbs) {
    return null
  }

  return breadcrumbs.map((breadcrumb: string) => {
    return generateLocalizedRouteName({
      name: breadcrumb, 
      locale
    })
  })
}

function localizeRouteConfigChildrenRecursive(childrens: Array<RouteConfig> | undefined, locale: LOCALES = LOCALES.FR): Array<RouteConfig> | undefined {
  if (!childrens) {
    return undefined
  }

  return childrens.reduce((routes: Array<RouteConfig>, children: RouteConfig) => {
    return [
      ...routes,
      {
        name: generateLocalizedRouteName({
          name: children.name as string,
          locale
        }),
        path: generateLocalizedRoutePath(children.name as string, locale),
        component: (children as RouteConfigSingleView).component,
        meta: {
          ...children.meta,
          [META_KEY_ADDITIONAL_BREADCRUMBS]: localizeBreadcrumbs(locale, children.meta ? children.meta[META_KEY_ADDITIONAL_BREADCRUMBS] : undefined),
          [META_KEY_LOCALE]: locale
        },
        children: localizeRouteConfigChildrenRecursive(children.children, locale)
      } as RouteConfig
    ]
  }, [])
}

function localizeRouteConfig(config: RouteConfig): Array<RouteConfig> {
  return Object.values(Localization.LOCALES).reduce((routes: Array<RouteConfig>, locale: LOCALES) => {
    return [
      ...routes,
      {
        name: generateLocalizedRouteName({
          name: config.name as string,
          locale
        }),
        path: generateLocalizedRoutePath(config.name as string, locale),
        component: (config as RouteConfigSingleView).component,
        meta: {
          ...config.meta,
          [META_KEY_ADDITIONAL_BREADCRUMBS]: localizeBreadcrumbs(locale, config.meta ? config.meta[META_KEY_ADDITIONAL_BREADCRUMBS] : undefined),
          [META_KEY_LOCALE]: locale
        },
        children: localizeRouteConfigChildrenRecursive(config.children, locale)
      } as RouteConfig
    ]
  }, [])
}

export default function localizeRouteConfigs(originalRoutes: Array<RouteConfig>): Array<RouteConfig> {
  return originalRoutes.reduce((routes: Array<RouteConfig>, route: RouteConfig) => {
    return [
      ...routes,
      ...localizeRouteConfig(route)
    ]
  }, [])
}