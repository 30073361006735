import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import { FormTemplateElementParameterType, IInternalFormTemplateElementModel, IInternalFormTemplateElementOptionModel, IInternalFormTemplateElementParameterModel } from '@/modules/form-template-management/FormTemplate.model'
import { IFormAnswerModel } from '../Form.model'
import { IFormAnswerForm } from '../Form.service'

@Component
export default class FormElementMixin extends mixins() {
  protected elementValue: any = null
  protected errorMessage: string | null = null

  @Prop({ required: true })
  protected internalElement!: IInternalFormTemplateElementModel

  @Prop({ 
    required: false,
    default: () => []
  })
  protected answers!: Array<IFormAnswerModel>

  @Prop({ 
    required: false, 
    default: undefined
  })
  protected rowPositionIndex!: number | undefined

  @Prop({ 
    required: false,
    default: false
  })
  protected readonly!: boolean

  @Prop({
    required: false,
    default: false
  })
  protected displayDuplicateAnswers!: boolean

  @Prop({
    required: false,
    default: undefined
  })
  protected duplicateAnswerIndex!: number | undefined

  @Prop({
    required: false,
    default: false
  })
  protected hideSensitiveInformations!: boolean

  @Prop({
    required: false,
    default: false
  })
  protected displayEvaluatorAdditionnalInformations!: boolean

  protected get formElementAnswer(): IFormAnswerModel | null {
    if (this.hideSensitiveInformations && this.isElementSensitiveInformation) {
      return null
    } 
    
    for (const answer of this.answers) {
      if (answer.id_form_template_element === this.internalElement.id) {
        if (this.rowPositionIndex == undefined || answer.element_position_index === this.rowPositionIndex) {
          return answer
        }
      }
    }

    return null
  }

  protected get formElementAnswers(): Array<IFormAnswerModel> {
    const formAnswers: Array<IFormAnswerModel> = []

    if (this.hideSensitiveInformations && this.isElementSensitiveInformation) {
      return formAnswers
    } 

    for (const answer of this.answers) {
      if (answer.id_form_template_element === this.internalElement.id) {
        if (this.rowPositionIndex == undefined || answer.element_position_index === this.rowPositionIndex) {
          formAnswers.push(answer)
        }
      }
    }

    return formAnswers
  }

  protected get elementInternalLogicParameters(): { [key: string]: any } {
    return this.internalElement.element_parameters.reduce((props: { [key: string]: any }, parameter: IInternalFormTemplateElementParameterModel) => {
      if (parameter.frontend_type === FormTemplateElementParameterType.INTERNAL_LOGIC) {
        try {
          props[parameter.frontend_key || parameter.key] = JSON.parse(parameter.value)
        } catch (exception) {
          props[parameter.frontend_key || parameter.key] = parameter.value
        }
      }
      
      return props
    }, {})
  }

  protected get elementDefaultValue(): any | null {
    if (this.hideSensitiveInformations && this.isElementSensitiveInformation) {
      return null
    } 
    
    for (const parameter of this.internalElement.element_parameters) {
      if (parameter.frontend_type === FormTemplateElementParameterType.DEFAULT_VALUE) {
        try {
          return JSON.parse(parameter.value)
        } catch (exception) {
          return parameter.value
        } 
      }
    }

    return null
  }

  protected get elementAdditionnalClasses(): string | null {
    for (const parameter of this.internalElement.element_parameters) {
      if (parameter.frontend_type === FormTemplateElementParameterType.CSS_CLASS) {
        return parameter.value
      }
    }

    return null
  }

  protected get elementAdditionnalStyles(): string | null {
    for (const parameter of this.internalElement.element_parameters) {
      if (parameter.frontend_type === FormTemplateElementParameterType.CSS_STYLE) {
          return parameter.value
      }
    }

    return null
  }

  protected get elementContent(): any | null {
    for (const parameter of this.internalElement.element_parameters) {
      if (parameter.frontend_type === FormTemplateElementParameterType.CONTENT) {
        try {
          return JSON.parse(parameter.value)
        } catch (exception) {
          return parameter.value
        } 
      }
    }

    return null
  }

  protected get componentHasOptions(): boolean {
    return this.internalElement.element_options.length > 0
  }

  protected get assembledElementProps(): { [key: string]: any } {
    const assembledPropsList: { [key: string]: any } = this.internalElement.element_parameters.reduce((props: { [key: string]: any }, parameter: IInternalFormTemplateElementParameterModel) => {
      if (parameter.frontend_type === FormTemplateElementParameterType.PROP) {
        try {
          props[parameter.frontend_key || parameter.key] = JSON.parse(parameter.value)
        } catch (exception) {
          props[parameter.frontend_key || parameter.key] = parameter.value
        }
      }
      
      return props
    }, {})

    if (this.readonly) {
      assembledPropsList['disabled'] = true
    }

    return assembledPropsList
  }

  protected get elementSlots(): Array<IInternalFormTemplateElementParameterModel> {
    return this.internalElement.element_parameters.reduce((slots: Array<IInternalFormTemplateElementParameterModel>, parameter: IInternalFormTemplateElementParameterModel) => {
      if (parameter.frontend_type === FormTemplateElementParameterType.SLOT) {
        slots.push(parameter)
      }
      
      return slots
    }, [])
  }

  protected get elementClasses(): { [key: string]: boolean } {
    const classes: { [key: string]: boolean } = {}

    if (this.elementAdditionnalClasses) {
      classes[this.elementAdditionnalClasses] = true
    }

    return classes
  }

  protected get elementStyles(): { [key: string]: string } {
    const styles: { [key: string]: string } = this.internalElement.element_parameters.reduce((styles: { [key: string]: any }, parameter: IInternalFormTemplateElementParameterModel) => {
      if (parameter.frontend_type === FormTemplateElementParameterType.STYLE) {
        styles[parameter.frontend_key || parameter.key] = parameter.value
      }
      
      return styles
    }, {})

    if (this.elementAdditionnalStyles) {
      const differentStyles: Array<string> = this.elementAdditionnalStyles.split(';')

      differentStyles.forEach((differentStyle: string) => {
        const stylePair: Array<string> = differentStyle.split(':')

        styles[stylePair[0]] = stylePair[1]
      })
    }
    if (!this.displayEvaluatorAdditionnalInformations && this.elementInternalLogicParameters['evaluator-additionnal-informations']) {
      styles['display'] = 'none'
    }

    return styles
  }

  private get isElementSensitiveInformation(): boolean {
    return this.elementInternalLogicParameters['sensitive-information'] || false
  }

  private get sortedElementOptions(): Array<IInternalFormTemplateElementOptionModel> | null {
    if (!this.componentHasOptions || !this.internalElement.element_options) {
      return null
    }
    
    return Array.from(this.internalElement.element_options)
      .sort((lhs: IInternalFormTemplateElementOptionModel, rhs: IInternalFormTemplateElementOptionModel) => {
        return lhs.order - rhs.order
      })
  }

  @Watch('formElementAnswer', { immediate: true })
  @Watch('duplicateAnswerIndex')
  protected onFormElementAnswerChange(): void {
    if (this.displayDuplicateAnswers && this.duplicateAnswerIndex != undefined && this.formElementAnswers.length > 0) {
      this.elementValue = this.formElementAnswers[this.duplicateAnswerIndex].value
    } else if (this.formElementAnswer) {
      this.elementValue = this.formElementAnswer.value
    }
  }

  protected onValueChange(value: any): void {
    if (
      Array.isArray(value) ||
      (
        typeof value !== 'object' && 
        value !== null
      )
    ) {
      this.elementValue = value
    }
  }

  public async getElementAnswersRecursive(validateAnswers: boolean = true): Promise<Array<IFormAnswerForm> | null> {
    let answers: Array<IFormAnswerForm> = []
    let childrenHasError: boolean = false
    
    if (this.elementValue) {
      answers.push({
        element_position_index: this.rowPositionIndex != undefined ? this.rowPositionIndex : undefined,
        value: this.elementValue,
        id_form_template_element: this.internalElement.id as any,
        id_form_answer: this.formElementAnswer ? this.formElementAnswer.id : undefined
      })
    }

    if (this.$children) {
      for (const component of this.$children) {
        if (Object.prototype.hasOwnProperty.call(component, "getElementAnswersRecursive")) {
          const componentAnswers: Array<IFormAnswerForm> | null = await (component as FormElementMixin).getElementAnswersRecursive(validateAnswers)

          if (componentAnswers) {
            answers = [
              ...answers,
              ...componentAnswers
            ]
          } else {
            childrenHasError = true
          }
        }
      }
    }
    
    if (!validateAnswers) {
      return answers
    }
    
    return childrenHasError || !this.validate() ? null : answers
  }

  protected formElementAnswerCount(formElement: IInternalFormTemplateElementModel): number {
    let count: number = 0

    for (const answer of this.answers) {
      if (answer.id_form_template_element === formElement.id) {
        count += 1
      }
    }

    return count
  }

  protected validate(): boolean {
    this.errorMessage = null
    const componentTag: string | null = (this.$options as any)['_componentTag']
    const elementComponentTag: string | undefined = this.internalElement.component_name

    if (
      componentTag &&
      elementComponentTag &&
      componentTag === elementComponentTag
    ) {
      if (this.assembledElementProps['requiredMarker'] && !this.elementValue) {
        this.errorMessage = this.$i18next.t('modules.form-management.form-element.FormElement.validation.required')
      } else if (
        this.assembledElementProps['maxLength'] &&
        this.elementValue &&
        this.elementValue.length > this.assembledElementProps['maxLength']
      ) {
        this.errorMessage = this.$i18next.t('modules.form-management.form-element.FormElement.validation.max-length')
      }
    }
    
    return this.mixin_string_isEmpty(this.errorMessage)
  }
}