import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import WithRender from './YearConfigurationForm.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MCheckbox,
  MForm,
  MMessage,
  MMessageSkin,
  MMessageState,
  MTextfield,
  RequiredValidator
} from '@ulaval/modul-components'

import RichTextEditor from '@/modules/global/components/rich-text-editor/RichTextEditor'
import { IYearConfigurationModel } from '../YearConfiguration.model'
import { ICategorySectionsFormPayloadData } from '@/modules/category-section-management/category-section-form/CategorySectionForm'
import CategorySectionForm from '@/modules/category-section-management/category-section-form/CategorySectionForm'
import { IYearModel } from '@/modules/global/year/Year.model'

export interface IYearConfigurationFormPayloadData {
  is_public_area_available: boolean;
  public_area_description_zone: string;
  public_area_help_zone: string;
  public_area_description_zone_title: string;
  public_area_help_zone_title: string;
  category_sections: ICategorySectionsFormPayloadData;
}

const REF_PUBLIC_AREA_DESCRIPTION_ZONE: string = 'public-area-description-zone'
const REF_PUBLIC_AREA_HELP_ZONE: string = 'public-area-help-zone'
const REF_CATEGORY_SECTION_FORM: string = 'category-section-form'

@WithRender
@Component({
  components: {
    CategorySectionForm,
    MCheckbox,
    MForm,
    MMessage,
    MTextfield,
    RichTextEditor
  }
})
export default class YearConfigurationForm extends mixins() {
  public formGroup: FormGroup = new FormGroup({
    is_public_area_available: new FormControl<boolean>(),
    public_area_description_zone_title: new FormControl<string>([RequiredValidator()]),
    public_area_help_zone_title: new FormControl<string>([RequiredValidator()])
  })
  private publicAreaDescriptionZoneModel: string = ''
  private publicAreaHelpZoneModel: string = ''
  private formErrors: { [key: string]: string | boolean } = {}

  private readonly REF_PUBLIC_AREA_DESCRIPTION_ZONE: string = REF_PUBLIC_AREA_DESCRIPTION_ZONE
  private readonly REF_PUBLIC_AREA_HELP_ZONE: string = REF_PUBLIC_AREA_HELP_ZONE
  private readonly REF_CATEGORY_SECTION_FORM: string = REF_CATEGORY_SECTION_FORM
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin

  @Prop({ required: true })
  private yearConfiguration!: IYearConfigurationModel

  @Prop({ required: true })
  private year!: IYearModel

  @Prop({ default: {} })
  private requestErrors!: { [key: string]: string | boolean }

  @Ref(REF_PUBLIC_AREA_DESCRIPTION_ZONE)
  private publicAreaDescriptionZoneComponent!: RichTextEditor

  @Ref(REF_PUBLIC_AREA_HELP_ZONE)
  private publicAreaHelpZoneComponent!: RichTextEditor

  @Ref(REF_CATEGORY_SECTION_FORM)
  private categorySectionFormComponent!: CategorySectionForm

  private get errors(): { [key: string]: string | boolean } {
    return {
      ...this.formErrors,
      ...this.requestErrors
    }
  }

  public get isPublicAreaAvailableCheckbox(): AbstractControl<boolean> {
    return this.formGroup.getControl<boolean>('is_public_area_available');
  }

  public get publicAreaDescriptionZoneTitleField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('public_area_description_zone_title')
  }

  public get publicAreaHelpZoneTitleField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('public_area_help_zone_title')
  }

  private mounted(): void {
    this.populateFormFromModel()
  }

  private populateFormFromModel(): void {
    if (this.yearConfiguration) {
      this.isPublicAreaAvailableCheckbox.value = this.yearConfiguration.is_public_area_available
      this.publicAreaDescriptionZoneModel = this.yearConfiguration.public_area_description_zone_html
      this.publicAreaHelpZoneModel = this.yearConfiguration.public_area_help_zone_html
      this.publicAreaDescriptionZoneTitleField.value = this.yearConfiguration.public_area_description_zone_title
      this.publicAreaHelpZoneTitleField.value = this.yearConfiguration.public_area_help_zone_title
    }
  }

  public async getFormPayload(): Promise<IYearConfigurationFormPayloadData | null> {
    let areRichTextEditorsValid: boolean = true
    const categorySectionFormPayload: ICategorySectionsFormPayloadData | null = await this.categorySectionFormComponent.getFormPayload()
    await this.formGroup.submit()

    if (this.publicAreaDescriptionZoneComponent && (!await this.publicAreaDescriptionZoneComponent.validate())) {
      areRichTextEditorsValid = false
    }
    if (this.publicAreaHelpZoneComponent && (!await this.publicAreaHelpZoneComponent.validate())) {
      areRichTextEditorsValid = false
    }

    if (this.formGroup.hasErrorDeep() || !areRichTextEditorsValid || !categorySectionFormPayload) {
      return null
    }

    const formPayloadData: IYearConfigurationFormPayloadData = {
      is_public_area_available: this.isPublicAreaAvailableCheckbox.value,
      public_area_description_zone: this.publicAreaDescriptionZoneModel,
      public_area_help_zone: this.publicAreaHelpZoneModel,
      category_sections: categorySectionFormPayload,
      public_area_description_zone_title: this.publicAreaDescriptionZoneTitleField.value,
      public_area_help_zone_title: this.publicAreaHelpZoneTitleField.value
    }

    return formPayloadData
  }
}
