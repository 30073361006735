import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { ApplicationFormValidationStatus, IApplicationFormValidationModel } from "./ApplicationFormValidation.model";
import FormTemplateService from "../form-template-management/FormTemplate.service";
import { IPaginationQueryParameters } from "../global/utilities/Base.service";
import { IPaginatedResponse } from "../global/utilities/Pagination.model";
import { IApplicationFormModel } from "../application-form-management/ApplicationForm.model";
import { IFormAnswerFileModel, IFormModel } from "../form-management/Form.model";
import { IInternalFormTemplateModel } from "../form-template-management/FormTemplate.model";
import { IFormAnswerForm } from "../form-management/Form.service";
import { IFileUploadModel } from "../global/file/File.model";

export interface IFetchApplicationFormValidationsListQueryParameters extends IPaginationQueryParameters {
  year_id: string;
  query?: string;
  status: Array<string>;
}

export interface IFetchApplicationFormValidationsListResponse extends IPaginatedResponse {
  application_form_validations: Array<IApplicationFormValidationModel>;
}

export interface IFetchApplicationFormValidationResponse {
  application_form_validation: {
    application_form_validation: IApplicationFormValidationModel;
    form: IFormModel;
    internal_form_template: IInternalFormTemplateModel;
  };
  application_form: {
    application_form: IApplicationFormModel;
    form: IFormModel;
    internal_form_template: IInternalFormTemplateModel;
  };
}

export interface IUpdateApplicationFormValidationStatusForm {
  status: ApplicationFormValidationStatus;
}

@Component
export default class ApplicationFormValidationService extends FormTemplateService {
  protected service_applicationFormValidationService_fetchAdminApplicationFormValidationList(query: IFetchApplicationFormValidationsListQueryParameters): Promise<IFetchApplicationFormValidationsListResponse> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/application-form-validations?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormValidationService_fetchApplicationForm(id: string): Promise<IFetchApplicationFormValidationResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/application-form-validations/${id}`)
        .then((response: AxiosResponse) => resolve({
            application_form: {
              application_form: response.data.data.application_form.application_form,
              form: response.data.data.application_form.form,
              internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.application_form.form.form_template)
            },
            application_form_validation: {
              application_form_validation: response.data.data.application_form_validation.application_form_validation,
              form: response.data.data.application_form_validation.form,
              internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.application_form_validation.form.form_template)
            }
          } as IFetchApplicationFormValidationResponse
        ))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormValidationService_save(id: string, answers: Array<IFormAnswerForm>): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(
        `/api/application-form-validations/${id}`, 
        this.service_applicationFormValidationService_generateAnswersFormData(answers), 
        {
          headers: this.service_baseService_multipartFormDataHeader()
        }
      ).then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  private service_applicationFormValidationService_generateAnswersFormData(answers: Array<IFormAnswerForm>): FormData {
    const data: FormData = new FormData()

    answers.forEach((answerForm: any, index: number) => {
      for (const key in answerForm) {
        const answerValue: any = answerForm[key]

        if (
          answerValue != undefined && 
          answerValue != null &&
          key != 'files'
        ) {
          data.append(`answers[${index}].${key}`, answerValue)
        }
      }

      if (answerForm.files) {
        answerForm.files.forEach((uploadedFile: IFileUploadModel<IFormAnswerFileModel>, fileIndex: number) => {
          if (uploadedFile.server_file) {
            data.set(`answers[${index}].form_answer_files[${fileIndex}].id`, uploadedFile.server_file.id)
          } else if (uploadedFile && uploadedFile.file) {
            data.append(`answers[${index}].form_answer_files[${fileIndex}].file`, uploadedFile.file.file)
          }
        })
      }
    })

    return data
  }

  protected service_applicationFormValidationService_updateStatus(id: string, form: IUpdateApplicationFormValidationStatusForm): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/application-form-validations/${id}/status`, form)
        .then(() => resolve())
        .catch((error: AxiosError) => reject(error))
    })
  }
}