import Component, { mixins } from 'vue-class-component'

import WithRender from './Breadcrumbs.html'

import { 
  MIconButton,
  MIconButtonSkin,
  MLink,
  MLinkMode
} from '@ulaval/modul-components'

import { extractLocalizedRouteParameters, generateLocalizedRouteName, ILocalizedRouteParameters, META_KEY_ADDITIONAL_BREADCRUMBS, META_KEY_HIDDEN_BREADCRUMB, META_KEY_IS_BREADCRUMB_HOME_PUBLIC_ZONE } from '@/router/helpers'
import ROUTES from '@/router/ROUTES';
import { RouteMeta } from 'vue-router';

export interface IBreadcrumbItem {
  label: string;
  href: string;
}

@WithRender
@Component({
  components: {
    MIconButton,
    MLink
  }
})
export default class Breadcrumbs extends mixins() {
  private readonly MIconButtonSkin: object = MIconButtonSkin
  private readonly MLinkMode: object = MLinkMode

  private get items(): Array<IBreadcrumbItem> {
    if (!this.$route || !this.$route.matched) {
      return []
    }

    const breadcrumbs: Array<IBreadcrumbItem> = this.$route.matched.reduce((breadcrumbs: Array<IBreadcrumbItem>, route: any) => {
      if (route.meta && route.meta[META_KEY_ADDITIONAL_BREADCRUMBS]) {
        route.meta[META_KEY_ADDITIONAL_BREADCRUMBS].forEach((additionalBreadcrumb: string) => {
          const breacrumbRouteParameters: ILocalizedRouteParameters = extractLocalizedRouteParameters(additionalBreadcrumb)
          const breacrumbRoute: any = this.$router.resolve({ 
            name: additionalBreadcrumb
          })
  
          if (breacrumbRoute) {
            breadcrumbs.push({
              href: breacrumbRoute.href,
              label: this.$i18next.t(`router.breadcrumbs.${breacrumbRouteParameters.name}`)
            })
          }
        })
      }

      if (
        ((route.meta && route.meta[META_KEY_HIDDEN_BREADCRUMB]) || !route) ||
        route.name === this.$route.name
      ) {
        return breadcrumbs
      }

      const routeParameters: ILocalizedRouteParameters = extractLocalizedRouteParameters(route.name as string)

      breadcrumbs.push({
        href: this.$router.resolve(route).href,
        label: this.$i18next.t(`router.breadcrumbs.${routeParameters.name}`)
      })
      
      return breadcrumbs
    }, [])

    const meta: RouteMeta = this.$route.meta || []
    if (
      meta[META_KEY_IS_BREADCRUMB_HOME_PUBLIC_ZONE] !== true && 
      breadcrumbs.length > 0
    ) {
      const currentRouteParameters: ILocalizedRouteParameters = extractLocalizedRouteParameters(this.$route.name as string)
      const dashboardRouteName: string = generateLocalizedRouteName({
        name: ROUTES.DASHBOARD,
        locale: currentRouteParameters.locale
      })

      breadcrumbs[0] = {
        href: this.$router.resolve({ name: dashboardRouteName }).href,
        label: this.$i18next.t(`router.breadcrumbs.${ROUTES.DASHBOARD}`)
      }
    }

    return breadcrumbs
  }
}
