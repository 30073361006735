<m-dropdown
  v-model="selectedYearSlug"
  :waiting="isLoadingStateActive"
  :label="$i18next.t('modules.global.year.YearDropdown.label')"
>
  <template v-for="year in years">
    <m-dropdown-item :value="year.slug">
      {{ year.slug }}
    </m-dropdown-item>
  </template>
</m-dropdown>