<m-form
  :form-group="formGroup"
  class="email-template-management__email-template-form"
>
  <div class="email-template-management__email-template-form--row">
    <div class="email-template-management__email-template-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.label.content_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="email-template-management__email-template-form--row">
    <div class="email-template-management__email-template-form--column">
      <m-textfield
        v-model="objectField.value"
        v-m-control="objectField"
        :label="$i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.field.object.label')"
        max-width="none"
        :error="objectField.hasError()"
        :error-message="objectField.errorMessage"
        :requiredMarker="true"
      />
    </div>
  </div>
  <div class="email-template-management__email-template-form--row">
    <div class="email-template-management__email-template-form--column">
      <m-textfield
        v-model="contentHeaderField.value"
        v-m-control="contentHeaderField"
        :label="$i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.field.content_header.label')"
        max-width="none"
        :error="contentHeaderField.hasError()"
        :error-message="contentHeaderField.errorMessage"
        :requiredMarker="true"
      />
    </div>
  </div>
  <div class="email-template-management__email-template-form--row">
    <div class="email-template-management__email-template-form--column">
      <rich-text-editor v-model="contentMessage" />
    </div>
  </div>
  <div class="email-template-management__email-template-form--row mt-8">
    <div class="email-template-management__email-template-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.label.carbon_copy_recipients_zone') }}
      </label>
      <hr />
    </div>
    <div class="email-template-management__email-template-form--column mt-4 mb-2">
      <m-add @click="addCarbonCopyRecipient">
        {{ $i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.button.carbon_copy_recipient_add.label') }}
      </m-add>
    </div>
    <template v-for="recipient, index in carbonCopyRecipients">
      <div class="email-template-management__email-template-form--column display--flex">
        <m-textfield
          :value="recipient.value"
          v-m-control="carbonCopyRecipientFields[index]"
          :label="$i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.field.carbon_copy_recipient.label')"
          max-width="none"
          :error="carbonCopyRecipientFields[index].hasError()"
          :error-message="carbonCopyRecipientFields[index].errorMessage"
          :requiredMarker="true"
          :type="MTextfieldType.Email"
          @input="onCarbonCopyRecipientValueChange(index, $event)"
        />
        <m-icon-button
          class="my-auto"
          icon-name="m-svg__delete"
          button-size="32px"
          @click="deleteCarbonCopyRecipient(index)"
        />
      </div>
    </template>
  </div>
  <div class="email-template-management__email-template-form--row mt-8">
    <div class="email-template-management__email-template-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.label.blind_carbon_copy_recipients_zone') }}
      </label>
      <hr />
    </div>
    <div class="email-template-management__email-template-form--column mt-4 mb-2">
      <m-add @click="addBlindCarbonCopyRecipient">
        {{ $i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.button.blind_carbon_copy_recipient_add.label') }}
      </m-add>
    </div>
    <template v-for="recipient, index in blindCarbonCopyRecipients">
      <div class="email-template-management__email-template-form--column display--flex">
        <m-textfield
          :value="recipient.value"
          v-m-control="blindCarbonCopyRecipientFields[index]"
          :label="$i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.field.blind_carbon_copy_recipient.label')"
          max-width="none"
          :error="blindCarbonCopyRecipientFields[index].hasError()"
          :error-message="blindCarbonCopyRecipientFields[index].errorMessage"
          :requiredMarker="true"
          :type="MTextfieldType.Email"
          @input="onBlindCarbonCopyRecipientValueChange(index, $event)"
        />
        <m-icon-button
          class="my-auto"
          icon-name="m-svg__delete"
          button-size="32px"
          @click="deleteBlindCarbonCopyRecipient(index)"
        />
      </div>
    </template>
  </div>
  <div class="email-template-management__email-template-form--row mt-8">
    <div class="email-template-management__email-template-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.label.attachments_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="email-template-management__email-template-form--row">
    <div class="email-template-management__email-template-form--column mt-4">
      <m-file-upload
        :allowedExtensions="ALLOWED_ATTACHMENT_EXTENSIONS"
        @files-ready="onFilesReady"
        @done="onFilesUploadCompleted"
      >
        <m-add>
          {{ $i18next.t('modules.email-template-management.email-template-form.EmailTemplateForm.button.file_upload_trigger.label') }}
        </m-add>
      </m-file-upload>
    </div>
    <div class="email-template-management__email-template-form--column my-6 pb-8">
      <template v-for="emailTemplateAttachment, index in emailTemplateAttachments">
        <uploaded-file-row
          :uploaded-file="emailTemplateAttachment"
          :display-divider="index < emailTemplateAttachments.length - 1"
          @delete="removeEmailAttachment(index)"
        />
      </template>
    </div>
  </div>
</m-form>