import Component, { mixins } from 'vue-class-component'

export interface IStringMixin {
  mixin_string_isEmpty(value: any): boolean;
}

@Component
export default class StringMixin extends mixins() implements IStringMixin {
  public mixin_string_isEmpty(value: string): boolean {
    return !value || value.trim().length === 0
  }
}
