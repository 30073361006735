import Component, { mixins } from "vue-class-component";

import { EvaluatorApplicationFormAssignmentStatus, IEvaluatorApplicationFormAssignmentModel } from "./EvaluatorApplicationFormAssignment.model";

@Component
export default class EvaluatorApplicationFormAssignmentMixin extends mixins() {
  protected mixin_evaluatorApplicationFormAssignment_computeStatusColor(evaluatorApplicationFormAssignment: IEvaluatorApplicationFormAssignmentModel): string {
    switch (evaluatorApplicationFormAssignment.status) {
      case EvaluatorApplicationFormAssignmentStatus.IN_PROGRESS:
        return '#ffc103'
      case EvaluatorApplicationFormAssignmentStatus.OVERDUE:
        return '#e30513'
      case EvaluatorApplicationFormAssignmentStatus.DONE:
        return '#00c77f'
      default:
        return 'grey'
    }
  }

  protected mixin_evaluatorApplicationFormAssignment_computeStatusIcon(evaluatorApplicationFormAssignment: IEvaluatorApplicationFormAssignmentModel): string | null {
    switch (evaluatorApplicationFormAssignment.status) {
      case EvaluatorApplicationFormAssignmentStatus.IN_PROGRESS:
        return 'pd-svg__user-wait'
      case EvaluatorApplicationFormAssignmentStatus.OVERDUE:
        return 'm-svg__error'
      case EvaluatorApplicationFormAssignmentStatus.DONE:
        return 'm-svg__confirmation'
      default:
        return null
    }
  }

  protected mixin_evaluatorApplicationFormAssignment_computeStatusIconStyles(evaluatorApplicationFormAssignment: IEvaluatorApplicationFormAssignmentModel): object {
    return {
      'color': this.mixin_evaluatorApplicationFormAssignment_computeStatusColor(evaluatorApplicationFormAssignment)
    }
  }
}