import Component, { mixins } from 'vue-class-component'

import WithRender from './TextField.html'

import { 
  MTextfield
} from '@ulaval/modul-components'

import FormElementMixin from '../../form-element/FormElement.mixin'

@WithRender
@Component({
  components: {
    MTextfield
  }
})
export default class TextField extends mixins(FormElementMixin) {

}