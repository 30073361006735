<div class="form-elements">
  <template v-for="element in elements">
    <form-element
      :internal-element="element"
      :answers="answers"
      class="mb-4"
      :readonly="isFormReadonly"
      :display-duplicate-answers="displayDuplicateAnswers"
      :hide-sensitive-informations="hideSensitiveInformations"
      :display-evaluator-additionnal-informations="displayEvaluatorAdditionnalInformations"
    />
  </template>
</div>