<section class="year-configuration">
  <template v-if="isLoadingStateActive">
    <div
      key="spinner"
      class="my-15"
    >
      <m-spinner
        :title="true"
        :description="true"
      />
    </div>
  </template>
  <template v-else-if="!isLoadingStateActive">
    <template v-if="yearConfiguration">
      <div class="display--flex mb-6 year-configuration__header">
        <div
          class="mt-auto display--flex year-configuration__header--status"
          :class="{ 'year-configuration__header--status-no': !yearConfiguration.is_public_area_available, 'year-configuration__header--status-yes': yearConfiguration.is_public_area_available }"
        >
          <label class="m-u--font-weight--bold">
            {{ $i18next.t('modules.year-configuration-management.year-configuration.AdminYearConfiguration.label.published_status.label') }}
          </label>
          <div class="display--flex ml-2">
            <template v-if="yearConfiguration.is_public_area_available">
              {{ $i18next.t('modules.year-configuration-management.year-configuration.AdminYearConfiguration.label.published_status.value.yes') }}
              <m-icon
                name="m-svg__confirmation"
                size="18px"
              />
            </template>
            <template v-else>
              {{ $i18next.t('modules.year-configuration-management.year-configuration.AdminYearConfiguration.label.published_status.value.no') }}
              <m-icon
                name="m-svg__error"
                size="18px"
              />
            </template>
          </div>
        </div>
        <m-button
          class="ml-auto"
          @click="onTriggerUpdateButtonClick"
        >
          {{ $i18next.t('modules.year-configuration-management.year-configuration.AdminYearConfiguration.button.trigger_update.label') }}
        </m-button>
      </div>
      <year-configuration-layout
        :year-configuration="yearConfiguration"
        :year="year"
        :is-preview="true"
      />
      <router-view
        :year-configuration="yearConfiguration"
        :year="year"
      />
    </template>
    <template v-else-if="hasLoadingError">
      <m-message :state="MMessageState.Warning">
        {{ $i18next.t('modules.year-configuration-management.year-configuration.AdminYearConfiguration.message.loading_error.content') }}
      </m-message>
    </template>
  </template>
</section>