import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import WithRender from './UploadedFileRow.html'

import { 
  MIcon,
  MIconFile,
  MIconButton,
  MLink,
  MLinkMode
} from '@ulaval/modul-components'

import { IFileUploadModel } from './File.model'

export enum UploadedFileRow_EVENTS {
  DELETE = 'delete'
}

const REF_DOWNLOAD_LINK: string = 'ref-download-link'

@WithRender
@Component({
  components: {
    MIcon,
    MIconButton,
    MIconFile,
    MLink
  }
})
export default class UploadedFileRow extends mixins() {
  private readonly MLinkMode: any = MLinkMode
  private readonly REF_DOWNLOAD_LINK: string = REF_DOWNLOAD_LINK

  @Ref(REF_DOWNLOAD_LINK)
  protected downloadLinkHtmlElement!: HTMLElement

  @Prop({ required: true })
  private uploadedFile!: IFileUploadModel<any>

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({
    required: false,
    default: false
  })
  private displayDivider!: boolean

  private get fileName(): string {
    if (this.uploadedFile.file) {
      return this.uploadedFile.file.name
    } else if (this.uploadedFile.server_file) {
      return this.uploadedFile.server_file.file_name
    }

    return ''
  }

  private get fileSize(): string | number {
    if (this.uploadedFile.file) {
      return this.uploadedFile.file.file.size
    } else if (this.uploadedFile.server_file) {
      return this.uploadedFile.server_file.file_size
    }

    return ''
  }

  private get fileExtension(): string {
    if (this.uploadedFile.file) {
      return this.uploadedFile.file.extension
    } else if (this.uploadedFile.server_file) {
      const fileNameParts: Array<string> = this.uploadedFile.server_file.file_name.split('.')
      
      return fileNameParts[fileNameParts.length - 1]
    }

    return ''
  }

  private get isFileDownloadAvailable(): boolean {
    if (this.uploadedFile.file) {
      return true
    } else if (this.uploadedFile.server_file) {
      return !!this.uploadedFile.server_file.download_endpoint
    }

    return false
  }

  private onDeleteOptionClick(): void {
    this.$emit(UploadedFileRow_EVENTS.DELETE)
  }

  private downloadFile(): void {
    if (this.uploadedFile.file) {
      if (this.uploadedFile.file.url) {
        this.openFile(this.uploadedFile.file.url, this.uploadedFile.file.name)
      }
    } else if (this.uploadedFile.server_file) {
      this.$axios.get(this.uploadedFile.server_file.download_endpoint, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream'
        }
      }).then((res: any) => {
          this.openFile(
            URL.createObjectURL(new Blob([res.data], { type: this.uploadedFile.server_file.file_format })),
            this.uploadedFile.server_file.file_name
          )
        })
    }
  }

  private openFile(url: string, fileName: string): void {
    if (this.downloadLinkHtmlElement) {
      this.downloadLinkHtmlElement.setAttribute('href', url)
      this.downloadLinkHtmlElement.setAttribute('download', fileName)
      this.downloadLinkHtmlElement.click()

      this.downloadLinkHtmlElement.removeAttribute('link')
      this.downloadLinkHtmlElement.removeAttribute('download')
    }
  }
}
