<m-form
  class="application-form-support-management__application-form-support-form"
  :form-group="formGroup"
>
  <div class="application-form-support-management__application-form-support-form--row">
    <m-textfield
      v-model="supporterNameField.value"
      v-m-control="supporterNameField"
      :label="$i18next.t('modules.application-form-support-management.application-form-support-form.ApplicationFormSupportForm.field.supporter_name.label')"
      :required-marker="true"
      :error="supporterNameField.hasError()"
      :error-message="supporterNameField.errorMessage"
      max-width="none"
    />
    <m-textfield
      v-model="supporterEmailField.value"
      v-m-control="supporterEmailField"
      :label="$i18next.t('modules.application-form-support-management.application-form-support-form.ApplicationFormSupportForm.field.supporter_email.label')"
      :required-marker="true"
      :error="supporterEmailField.hasError()"
      :error-message="supporterEmailField.errorMessage"
      max-width="none"
    />
    <template v-if="isDeleteIconColumnDisplayed">
      <div class="application-form-support-management__application-form-support-form--icon">
        <template v-if="isDeleteIconDisplayed">
          <m-icon-button
            class="my-auto py-auto"
            icon-name="m-svg__delete"
            :skin="MIconButtonSkin.Light"
            @click="onDeleteIconClick"
          />
        </template>
      </div>
    </template>
    <template v-if="isAddIconColumnDisplayed">
      <div class="application-form-support-management__application-form-support-form--icon">
        <template v-if="isAddIconDisplayed">
          <m-icon-button
            class="my-auto"
            icon-name="m-svg__add-circle-filled"
            :skin="MIconButtonSkin.Link"
            @click="onAddIconClick"
          />
        </template>
      </div>
    </template>
  </div>
</m-form>