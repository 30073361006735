import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './EvaluatorApplicationFormAssignmentPopoverRow.html'

import { 
  MIcon
} from '@ulaval/modul-components'

import { IEvaluatorApplicationFormAssignmentModel } from '../EvaluatorApplicationFormAssignment.model'
import EvaluatorApplicationFormAssignmentMixin from '../EvaluatorApplicationFormAssignment.mixin'

@WithRender
@Component({
  components: {
    MIcon
  }
})
export default class EvaluatorApplicationFormAssignmentRow extends mixins(EvaluatorApplicationFormAssignmentMixin) {
  @Prop({ required: true })
  private evaluatorApplicationFormAssignment!: IEvaluatorApplicationFormAssignmentModel
}
