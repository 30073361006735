import Component, { mixins } from 'vue-class-component'

import WithRender from './NavigationBar.html'

import { 
  MButton,
  MButtonSkin,
} from '@ulaval/modul-components'

import UserProfileMenu from './UserProfileMenu'
import AdministratorMenu from './AdministratorMenu'

import ROUTES from '@/router/ROUTES'
import { STORE_GETTERS } from '@/store'
import UserRoleMixin from '@/modules/user-management/UserRole.mixin'
import { META_KEY_DISPLAY_PUBLIC_NAVIGATION_BAR } from '@/router/helpers'
import { RouteMeta } from 'vue-router'

@WithRender
@Component({
  components: {
    AdministratorMenu,
    MButton,
    UserProfileMenu
  }
})
export default class NavigationBar extends mixins(UserRoleMixin) {
  private readonly MButtonSkin: object = MButtonSkin

  private get applicationHomePageLink(): string {
    if (this.isLoggedIn) {
      return this.mixin_router_generateHref({
        name: ROUTES.DASHBOARD
      })
    }
    
    return this.mixin_router_generateHref({
      name: ROUTES.DEFAULT
    })
  }

  private get isLoggedIn(): boolean {
    return this.$store.getters[STORE_GETTERS.SESSION_GET_IS_LOGGED_IN]
  }

  private get displayAdministratorMenu(): boolean {
    return this.mixin_userrole_hasAdministratorRole ||
      this.mixin_userrole_hasGestionnaireCategorieRole ||
      this.mixin_userrole_hasGestionnaireGabaritCourrielRole ||
      this.mixin_userrole_hasGestionnaireCandidatureRole ||
      this.mixin_userrole_hasGestionnaireAppuiCandidatureRole
  }

  private get isNavigationBarInPublicMode(): boolean {
    const meta: RouteMeta = this.$route.meta || []
    
    return !!meta[META_KEY_DISPLAY_PUBLIC_NAVIGATION_BAR]
  }

  private onLoginButtonClick(): void {
    if (this.isLoggedIn) {
      this.mixin_router_navigate({
        name: ROUTES.DASHBOARD
      })
    } else {
      this.mixin_router_navigate({
        name: ROUTES.LOGIN
      })
    }
  }

}
