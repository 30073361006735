import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './EmailTemplateForm.html'

import { 
  AbstractControl,
  EmailValidator,
  FormControl,
  FormGroup,
  MAdd,
  MButton,
  MCheckbox,
  MDatepicker,
  MFileUpload,
  MForm,
  MIconButton,
  MIntegerfield,
  MTextarea,
  MTextfield,
  MTextfieldType,
  MTimepicker,
  RequiredValidator
} from '@ulaval/modul-components'
import { MFile } from '@ulaval/modul-components/dist/utils/file/file'

import RichTextEditor from '@/modules/global/components/rich-text-editor/RichTextEditor'
import UploadedFileRow from '@/modules/global/file/UploadedFileRow'

import { IEmailTemplateAttachmentModel, IEmailTemplateModel, IEmailTemplateRecipientModel } from '../EmailTemplate.model'
import { IFileUploadModel } from '@/modules/global/file/File.model'

export interface IEmailTemplateFormPayloadData {
  object: string;
  content_header: string;
  content_message: string;
  files: Array<IFileUploadModel<IEmailTemplateAttachmentModel>>;
  carbon_copy_recipients: Array<IEmailTemplateRecipientFormPayloadData>;
  blind_carbon_copy_recipients: Array<IEmailTemplateRecipientFormPayloadData>;
}

export interface IEmailTemplateRecipientFormPayloadData {
  id?: string;
  value: string;
}

@WithRender
@Component({
  components: {
    MAdd,
    MButton,
    MCheckbox,
    MDatepicker,
    MFileUpload,
    MForm,
    MIconButton,
    MIntegerfield,
    MTextarea,
    MTextfield,
    MTimepicker,
    RichTextEditor,
    UploadedFileRow
  }
})
export default class EmailTemplateForm extends mixins() {
  public formGroup: FormGroup = new FormGroup({
    object: new FormControl<string>([RequiredValidator()]),
    content_header: new FormControl<string>([RequiredValidator()])
  })
  private contentMessage: string = ''
  private emailTemplateAttachments: Array<IFileUploadModel<IEmailTemplateAttachmentModel>> = []
  private carbonCopyRecipients: Array<IEmailTemplateRecipientFormPayloadData> = []
  private blindCarbonCopyRecipients: Array<IEmailTemplateRecipientFormPayloadData> = []
  private formErrors: { [key: string]: string | boolean } = {}

  private readonly ALLOWED_ATTACHMENT_EXTENSIONS: Array<string> = [
    'pdf'
  ]
  private readonly MTextfieldType: any = MTextfieldType

  @Prop({ default: null })
  private emailTemplate!: IEmailTemplateModel | null

  @Prop({ default: {} })
  private requestErrors!: { [key: string]: string | boolean }

  private get errors(): { [key: string]: string | boolean } {
    return {
      ...this.formErrors,
      ...this.requestErrors
    }
  }

  public get objectField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('object');
  }

  public get contentHeaderField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('content_header');
  }

  public get carbonCopyRecipientFields(): Array<AbstractControl<string>> {
    const fields: Array<AbstractControl<string>> = []

    this.carbonCopyRecipients.forEach((recipient: IEmailTemplateRecipientFormPayloadData, index: number) => {
      fields.push(this.formGroup.getControl<string>(this.computeCarbonCopyRecipientFieldName(index)))
    })

    return fields
  }

  public get blindCarbonCopyRecipientFields(): Array<AbstractControl<string>> {
    const fields: Array<AbstractControl<string>> = []

    this.blindCarbonCopyRecipients.forEach((recipient: IEmailTemplateRecipientFormPayloadData, index: number) => {
      fields.push(this.formGroup.getControl<string>(this.computeBlindCarbonCopyRecipientFieldName(index)))
    })

    return fields
  }

  private mounted(): void {
    this.populateFormFromModel()
  }

  private populateFormFromModel(): void {
    if (this.emailTemplate) {
      this.objectField.value = this.emailTemplate.object
      this.contentHeaderField.value = this.emailTemplate.content_header
      this.contentMessage = this.emailTemplate.content_message

      this.emailTemplate.attachments.forEach((emailTemplateAttachment: IEmailTemplateAttachmentModel) => {
        this.emailTemplateAttachments.push({
          server_file: emailTemplateAttachment
        })
      })

      this.emailTemplate.carbon_copy_recipients.forEach((recipient: IEmailTemplateRecipientModel) => {
        this.addCarbonCopyRecipient(recipient)

        this.carbonCopyRecipients.push({
          id: recipient.id,
          value: recipient.value
        })
      })

      this.emailTemplate.blind_carbon_copy_recipients.forEach((recipient: IEmailTemplateRecipientModel) => {
        this.addBlindCarbonCopyRecipient(recipient)

        this.blindCarbonCopyRecipients.push({
          id: recipient.id,
          value: recipient.value
        })
      })
    }
  }

  public async getFormPayload(): Promise<IEmailTemplateFormPayloadData | null> {
    await this.formGroup.submit()

    if (this.formGroup.hasErrorDeep()) {
      return null
    }

    const formPayloadData: IEmailTemplateFormPayloadData = {
      object: this.objectField.value,
      content_header: this.contentHeaderField.value,
      content_message: this.contentMessage,
      files: this.emailTemplateAttachments,
      carbon_copy_recipients: this.carbonCopyRecipients,
      blind_carbon_copy_recipients: this.blindCarbonCopyRecipients
    }

    return formPayloadData
  }

  private onFilesReady(files: Array<MFile>): void {
    this.$file.uploadTemp(files)
  }

  private onFilesUploadCompleted(files: Array<MFile>): void {
    files.forEach((file: MFile) => {
      this.emailTemplateAttachments.push({ file })
    })
  }

  private removeEmailAttachment(index: number): void {
    this.$delete(this.emailTemplateAttachments, index)
  }

  private computeCarbonCopyRecipientFieldName(index: number): string {
    return `carbon-copy-recipient[${index}]`
  }

  private computeBlindCarbonCopyRecipientFieldName(index: number): string {
    return `blind-carbon-copy-recipient[${index}]`
  }

  private addCarbonCopyRecipient(recipient: IEmailTemplateRecipientModel): void {
    const fieldIdentifier: string = this.computeCarbonCopyRecipientFieldName(this.carbonCopyRecipients.length)
    this.formGroup.addControl(fieldIdentifier, new FormControl<string>([RequiredValidator(), EmailValidator()]))

    if (recipient) {
      this.formGroup.getControl(fieldIdentifier).value = recipient.value
    } else {
      this.carbonCopyRecipients.push({
        value: ''
      })
    }
  }

  private addBlindCarbonCopyRecipient(recipient: IEmailTemplateRecipientModel): void {
    const fieldIdentifier: string = this.computeBlindCarbonCopyRecipientFieldName(this.blindCarbonCopyRecipients.length)
    this.formGroup.addControl(fieldIdentifier, new FormControl<string>([RequiredValidator(), EmailValidator()]))

    if (recipient) {
      this.formGroup.getControl(fieldIdentifier).value = recipient.value
    } else {
      this.blindCarbonCopyRecipients.push({
        value: ''
      })
    }
  }

  private onCarbonCopyRecipientValueChange(index: number, value: string): void {
    this.formGroup.getControl(this.computeCarbonCopyRecipientFieldName(index)).value = value
    this.carbonCopyRecipients[index].value = value
  }

  private onBlindCarbonCopyRecipientValueChange(index: number, value: string): void {
    this.formGroup.getControl(this.computeBlindCarbonCopyRecipientFieldName(index)).value = value
    this.blindCarbonCopyRecipients[index].value = value
  }

  private deleteCarbonCopyRecipient(index: number): void {
    this.formGroup.removeControl(this.computeCarbonCopyRecipientFieldName(index))
    this.$delete(this.carbonCopyRecipients, index)
  }

  private deleteBlindCarbonCopyRecipient(index: number): void {
    this.formGroup.removeControl(this.computeBlindCarbonCopyRecipientFieldName(index))
    this.$delete(this.blindCarbonCopyRecipients, index)
  }
}
