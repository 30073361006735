import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './EmailTemplateUpdateDialog.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay,
  MProgress
} from '@ulaval/modul-components'

import EmailTemplateForm, { IEmailTemplateFormPayloadData } from '../email-template-form/EmailTemplateForm'

import EmailTemplateService, { IUpdateEmailTemplateForm } from '../EmailTemplate.service'

import ROUTES from '@/router/ROUTES'
import { IEmailTemplateModel } from '../EmailTemplate.model'

const FORM_REF: string = 'emailTemplate-form'

@WithRender
@Component({
  components: {
    EmailTemplateForm,
    MButton,
    MIcon,
    MMessage,
    MOverlay,
    MProgress
  }
})
export default class EmailTemplateUpdateDialog extends mixins(EmailTemplateService) {
  private isLoadingStateActive: boolean = false
  private isEmailTemplateFetchLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showUpdateSuccessMessage: boolean = false
  private requestErrors: { [key: string]: string | boolean } = {}
  private originalEmailTemplate: IEmailTemplateModel | null = null

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin

  private readonly FORM_REF: string = FORM_REF

  @Ref(FORM_REF)
  private emailTemplateFormRef!: EmailTemplateForm

  @Watch('$route.params.idEmailTemplate', { immediate: true })
  private onEmailTemplateIdRouteParameterChange(value: string): void {
    this.loadEmailTemplate(value)
  }

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.EMAIL_TEMPLATE_MANAGEMENT_EMAIL_TEMPLATE_LIST
    })
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.emailTemplateFormRef) {
      const formPayload: IEmailTemplateFormPayloadData | null = await this.emailTemplateFormRef.getFormPayload()

      if (formPayload) {
        this.updateEmailTemplate(formPayload)
      }
    }
  }

  private updateEmailTemplate(formPayload: IEmailTemplateFormPayloadData): void {
    if (this.originalEmailTemplate) {
      this.isLoadingStateActive = true

      const requestPayload: IUpdateEmailTemplateForm = formPayload

      this.service_emailTemplateService_update(this.originalEmailTemplate.id, requestPayload)
        .then(() => {
          this.showUpdateSuccessMessage = true
        }).catch((error: AxiosError<any>) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.validation
          ) {
            this.requestErrors = error.response.data.validation
          } else {
            this.requestErrors = {}
          }
        }).finally(() => {
          this.isLoadingStateActive = false
        })
    }
  }

  private loadEmailTemplate(id: string): void {
    this.isEmailTemplateFetchLoadingStateActive = true

    this.service_emailTemplateService_fetch(id)
      .then((responseData: IEmailTemplateModel) => {
        this.originalEmailTemplate = responseData || null
      }).catch(() => {
        this.originalEmailTemplate = null
      }).finally(() => {
        this.isEmailTemplateFetchLoadingStateActive = false
      })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }
}
