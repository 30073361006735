<m-panel
  class="application-form-card"
  :class="panelClasses"
  :style="panelStyles"
>
  <template #header>
    <div @click="navigateToApplicationForm">
      <h5>
        {{ applicationForm.candidate_full_name_with_username }}
      </h5>
      <small>
        {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.requester', applicationForm) }}
      </small>
    </div>
  </template>
  <template
    #header-right-content
    v-if="applicationForm.is_deleteable || applicationForm.is_soft_deleteable || applicationForm.is_restorable"
  >
    <m-option>
      <template v-if="applicationForm.is_deleteable">
        <m-option-item-delete @click="displayDeleteDialog" />
      </template>
      <template v-if="applicationForm.is_soft_deleteable">
        <soft-delete-item-option @click="softDeleteApplicationForm" />
      </template>
      <template v-if="applicationForm.is_restorable">
        <restore-item-option @click="restoreApplicationForm" />
      </template>
    </m-option>
  </template>
  <div @click="navigateToApplicationForm">
    <p>
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.category_title', applicationForm) }}
      <br />
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.category_deposit_limit', {
        timestamp: mixin_timestamp_pretty(applicationForm.category_application_deposit_limit_timestamp)
      }) }}
      <template v-if="!mixin_string_isEmpty(applicationForm.deposit_deadline_extension_timestamp)">
        <br />
        {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.deposit_deadline_extension', {
          timestamp: mixin_timestamp_pretty(applicationForm.deposit_deadline_extension_timestamp)
        }) }}
      </template>
    </p>
    <template v-if="!mixin_string_isEmpty(applicationForm.category_application_support_submit_limit_timestamp) || applicationForm.is_application_form_support_count_under_threshold">
      <div class="mt-3 application-form-card__application-form-supports">
        <template v-if="!mixin_string_isEmpty(applicationForm.category_application_support_submit_limit_timestamp)">
          <p>
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.application_form_support_submit_limit', {
                  timestamp: mixin_timestamp_pretty(applicationForm.category_application_support_submit_limit_timestamp)
                }) }}
          </p>
        </template>
        <template v-if="applicationForm.is_application_form_support_count_under_threshold">
          <div class="application-form-card__application-form-supports--under-threshold-flag">
            <p>
              <m-icon
                name="m-svg__error"
                size="16px"
              />
              <span>
                {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.application_form_support_count_under_threshold') }}
              </span>
            </p>
          </div>
        </template>
        <template v-if="applicationForm.is_application_form_support_submittable">
          <m-link
            class="mt-2 application-form-card__application-form-supports--submit-button"
            icon-name="m-svg__add-circle-filled"
            icon-size="16px"
            :mode="MLinkMode.Button"
            @click.stop="displaySubmitApplicationFormSupportDialog"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.button.submit_application_form_support.label') }}
          </m-link>
        </template>
      </div>
    </template>
    <template v-if="mixin_applicationForm_computeStatusIcon(applicationForm) || (!mixin_string_isEmpty(applicationForm.updated_at) && !mixin_string_isEmpty(applicationForm.updated_by_name))">
      <div class="display--flex mt-2">
        <div class="display--flex">
          <template v-if="(!mixin_string_isEmpty(applicationForm.updated_at) && !mixin_string_isEmpty(applicationForm.updated_by_name)) || (!mixin_string_isEmpty(applicationForm.deleted_at) && !mixin_string_isEmpty(applicationForm.deleted_by_name))">
            <p class="my-auto">
              <template v-if="!mixin_string_isEmpty(applicationForm.updated_at) && !mixin_string_isEmpty(applicationForm.updated_by_name)">
                <small>
                  {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.updated_at', {
                    timestamp: mixin_timestamp_pretty(applicationForm.updated_at),
                    user: applicationForm.updated_by_name
                  }) }}
                </small>
              </template>
              <template v-if="!mixin_string_isEmpty(applicationForm.deleted_at) && !mixin_string_isEmpty(applicationForm.deleted_by_name)">
                <br />
                <small>
                  {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.label.deleted_at', {
                    timestamp: mixin_timestamp_pretty(applicationForm.deleted_at),
                    user: applicationForm.deleted_by_name
                  }) }}
                </small>
              </template>
            </p>
          </template>
        </div>
        <div class="display--flex justify-content-end flex__grow--1 pl-4">
          <template v-if="mixin_applicationForm_computeStatusIcon(applicationForm)">
            <m-icon
              class="ml-auto"
              size="28px"
              :style="statusIconStyles"
              :name="mixin_applicationForm_computeStatusIcon(applicationForm)"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
  <template v-if="isDeleteDialogVisible">
    <m-dialog
      :state="MDialogState.Warning"
      :width="MDialogWidth.Large"
      :open="true"
      :message="$i18next.t('modules.application-form-management.application-form.ApplicationFormCard.dialog.delete_confirmation.label', { 'category': selectedCategoryForDelete })"
      :okLabel="$i18next.t('modules.application-form-management.application-form.ApplicationFormCard.dialog.delete_confirmation.submit_button.label')"
      @portal-after-close="hideDeleteDialog"
      @ok="deleteApplicationForm"
    >
      <template v-if="isDeleteLoadingStateActive">
        <m-progress
          class="mt-6"
          indeterminate="true"
        />
      </template>
    </m-dialog>
  </template>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isSoftDeleteSuccessToastOpen"
  >
    {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.toast.soft_delete_success.label') }}
  </m-toast>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isRestoreSuccessToastOpen"
  >
    {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormCard.toast.restore_success.label') }}
  </m-toast>
  <template v-if="isSubmitApplicationFormSupportOverlayDisplayed">
    <submit-application-form-support-overlay
      :application-form="applicationForm"
      @after-close="hideSubmitApplicationFormSupportDialog"
    />
  </template>
</m-panel>