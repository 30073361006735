import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './UserUpdateDialog.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay,
  MProgress
} from '@ulaval/modul-components'

import UserForm, { IUserFormPayloadData } from '../user-form/UserForm'
import UserService from '../User.service'

import ROUTES from '@/router/ROUTES'
import { IUserModel } from '../User.model'

const FORM_REF: string = 'user-form'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MMessage,
    MOverlay,
    MProgress,
    UserForm
  }
})
export default class UserUpdateDialog extends mixins(UserService) {
  private isLoadingStateActive: boolean = false
  private isUserFetchLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = true
  private showUpdateSuccessMessage: boolean = false
  private requestErrors: { [key: string]: string | boolean } = {}
  private originalUser: IUserModel | null = null

  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin

  private readonly FORM_REF: string = FORM_REF

  @Ref(FORM_REF)
  private userFormRef!: UserForm

  @Watch('$route.params.idUser', { immediate: true })
  private onUserIdRouteParameterChange(value: string): void {
    this.loadUser(value)
  }

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.USER_MANAGEMENT_USER_LIST
    })
  }

  private async onSaveButtonClick(): Promise<void> {
    if (this.userFormRef) {
      const formPayload: IUserFormPayloadData | null = await this.userFormRef.getFormPayload()

      if (formPayload) {
        this.updateUser(formPayload)
      }
    }
  }

  private updateUser(formPayload: IUserFormPayloadData): void {
    if (this.originalUser) {
      this.isLoadingStateActive = true

      this.service_userService_update(this.originalUser.id, {
        roles: formPayload.roles
      }).then(() => {
        this.showUpdateSuccessMessage = true
      }).catch((error: AxiosError<any>) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.validation
        ) {
          this.requestErrors = error.response.data.validation
        } else {
          this.requestErrors = {}
        }
      }).finally(() => {
        this.isLoadingStateActive = false
      })
    }
  }

  private loadUser(id: string): void {
    this.isUserFetchLoadingStateActive = true

    this.service_userService_fetch(id)
      .then((responseData: IUserModel) => {
        this.originalUser = responseData || null
      }).catch(() => {
        this.originalUser = null
      }).finally(() => {
        this.isUserFetchLoadingStateActive = false
      })
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }
}
