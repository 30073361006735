import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './FormElements.html'

import FormElement from './FormElement'

import { IInternalFormTemplateElementModel } from '@/modules/form-template-management/FormTemplate.model'
import { IFormAnswerModel } from '../Form.model'
import { IFormAnswerForm } from '../Form.service'
import FormElementMixin from './FormElement.mixin'

@WithRender
@Component({
  name: 'form-elements',
  components: {
    FormElement
  }
})
export default class FormElements extends mixins() {
  @Prop({ required: true })
  private elements!: Array<IInternalFormTemplateElementModel>

  @Prop({ 
    required: false,
    default: () => []
  })
  private answers!: Array<IFormAnswerModel>

  @Prop({ 
    required: false,
    default: false
  })
  private isFormReadonly!: boolean

  @Prop({
    required: false,
    default: false
  })
  protected displayDuplicateAnswers!: boolean

  @Prop({
    required: false,
    default: false
  })
  protected hideSensitiveInformations!: boolean

  @Prop({
    required: false,
    default: false
  })
  protected displayEvaluatorAdditionnalInformations!: boolean

  public async getElementAnswers(validateAnswers: boolean = true): Promise<Array<IFormAnswerForm> | null> {
    let answers: Array<IFormAnswerForm> = []
    let childrenHasError: boolean = false

    if (this.$children) {
      for (const component of this.$children) {
        const componentAnswers: Array<IFormAnswerForm> | null = await (component as FormElementMixin).getElementAnswersRecursive(validateAnswers)

        if (componentAnswers) {
          answers = [
            ...answers,
            ...componentAnswers
          ]
        } else {
          childrenHasError = true
        }
      }
    }

    if (!validateAnswers) {
      return answers
    }
    
    return childrenHasError ? null : answers
  }
}