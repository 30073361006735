<section class="form-template-update__element-panel">
  <m-navbar
    :selected.sync="selectedTabIdentifier"
    :skin="MNavbarSkin.TabLightMain"
    :multiline="true"
  >
    <m-navbar-item
      :label="$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateElementPanel.headers.${TABS_IDENTIFIER.AVAILABLE_ELEMENTS}`)"
      :value="TABS_IDENTIFIER.AVAILABLE_ELEMENTS"
    />
    <m-navbar-item
      :label="$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateElementPanel.headers.${TABS_IDENTIFIER.SELECTED_ELEMENT}`)"
      :value="TABS_IDENTIFIER.SELECTED_ELEMENT"
      :disabled="!selectedElement"
    />
  </m-navbar>
  <div class="form-template-update__element-panel__selected-tab px-3 py-5">
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="selectedTabIdentifier === TABS_IDENTIFIER.AVAILABLE_ELEMENTS">
        <form-template-update-available-elements
          :key="TABS_IDENTIFIER.AVAILABLE_ELEMENTS"
          :available-form-template-elements="availableFormTemplateElements"
        />
      </template>
      <template v-else-if="selectedTabIdentifier === TABS_IDENTIFIER.SELECTED_ELEMENT">
        <form-template-update-selected-element
          :key="TABS_IDENTIFIER.SELECTED_ELEMENT"
          :available-form-template-elements="availableFormTemplateElements"
          :selected-element="selectedElement"
          :request-errors="requestErrors"
        />
      </template>
    </transition>
  </div>
</section>