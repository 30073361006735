<div :id="internalElement.id">
  <template v-if="!readonly">
    <m-file-upload
      v-bind="assembledElementProps"
      :allowedExtensions="computedAllowedExtensions"
      @files-ready="onFilesReady"
      @done="onFilesUploadCompleted"
    >
      <m-button>
        {{ $i18next.t('modules.form-management.form-custom-element.file-upload.FileUpload.button.file_upload_trigger.label') }}
      </m-button>
      <m-validation-message
        class="mt-2"
        :error="!mixin_string_isEmpty(errorMessage)"
        :error-message="errorMessage"
      />
    </m-file-upload>
  </template>
  <div class="my-6 pb-8">
    <template v-for="file, index in files">
      <uploaded-file-row
        :uploaded-file="file"
        :display-divider="index < files.length - 1"
        :disabled="readonly"
        @delete="removeAttachment(index)"
      />
    </template>
  </div>
</div>