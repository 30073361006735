import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './EvaluatorToolboxFileDepositOverlay.html'

import { 
  MButton,
  MButtonSkin,
  MForm,
  MIcon,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay,
  MProgress,
  MValidationMessage,
  FormGroup
} from '@ulaval/modul-components'

import UploadedFileRow from '@/modules/global/file/UploadedFileRow'

import ROUTES from '@/router/ROUTES'
import { IEvaluatorToolboxFileModel } from '../EvaluatorToolboxFile.model'
import { IFileUploadModel } from '@/modules/global/file/File.model'
import { MFile } from '@ulaval/modul-components/dist/utils/file/file'
import { IYearModel } from '@/modules/global/year/Year.model'
import EvaluatorToolboxFileService from '../EvaluatorToolboxFile.service'

@WithRender
@Component({
  components: {
    MButton,
    MForm,
    MIcon,
    MMessage,
    MOverlay,
    MProgress,
    MValidationMessage,
    UploadedFileRow
  }
})
export default class EvaluatorToolboxFileDepositOverlay extends mixins(EvaluatorToolboxFileService) {
  public formGroup: FormGroup = new FormGroup({
    
  })
  protected errorMessage: string | null = null
  private isOverlayOpen: boolean = true
  private isLoadingStateActive: boolean = false
  private isFilesFetchLoadingStateActive: boolean = false
  private showSubmitSuccessMessage: boolean = false
  private showSubmitErrorMessage: boolean = false
  private evaluatorToolboxFiles: Array<IFileUploadModel<IEvaluatorToolboxFileModel>> = []

  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly allowedExtensions: Array<string> = ['pdf']

  @Prop({ required: true })
  private year!: IYearModel

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    if (this.year) {
      this.loadFiles()
    }
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private afterOverlayClose(): void {
    this.mixin_router_navigate({
      name: ROUTES.EVALUATOR_MANAGEMENT_LIST
    })
  }

  private async onSubmitButtonClick(): Promise<void> {
    this.depositFiles()
  }

  private depositFiles(): void {
    this.isLoadingStateActive = true

    this.service_evaluatorToolboxFileService_deposit(
      {
        year_id: this.year.id
      },
      this.evaluatorToolboxFiles
    ).then(() => {
      this.showSubmitSuccessMessage = true
      this.showSubmitErrorMessage = false
    }).catch(() => {
      this.showSubmitSuccessMessage = false
      this.showSubmitErrorMessage = true
    }).finally(() => {
      this.isLoadingStateActive = false
    })
  }

  private loadFiles(): void {
    this.isFilesFetchLoadingStateActive = true

    this.service_evaluatorToolboxFileService_fetchList({ year_id: this.year.id })
      .then((responseData: Array<IEvaluatorToolboxFileModel>) => {
        responseData.forEach((evaluatorToolboxFile: IEvaluatorToolboxFileModel) => {
          this.$set(this.evaluatorToolboxFiles, this.evaluatorToolboxFiles.length, { server_file: evaluatorToolboxFile })
        })
      }).catch(() => {
        this.evaluatorToolboxFiles = []
      }).finally(() => {
        this.isFilesFetchLoadingStateActive = false
      })
  }
  
  private onFilesReady(files: Array<MFile>): void {
    this.$file.uploadTemp(files)
  }

  private onFilesUploadCompleted(files: Array<MFile>): void {
    files.forEach((file: MFile) => {
      this.$set(this.evaluatorToolboxFiles, this.evaluatorToolboxFiles.length, { file })
    })
  }

  private removeAttachment(index: number): void {
    this.$delete(this.evaluatorToolboxFiles, index)
  }
}
