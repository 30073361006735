import Component, { mixins } from 'vue-class-component'

import WithRender from './AdministratorMenu.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MIconButton,
  MPopup
} from '@ulaval/modul-components'

import ROUTES from '@/router/ROUTES'
import UserRoleMixin from '@/modules/user-management/UserRole.mixin'

@WithRender
@Component({
  components: {
    MButton,
    MIcon,
    MIconButton,
    MPopup
  }
})
export default class AdministratorMenu extends mixins(UserRoleMixin) {
  private readonly MButtonSkin: any = MButtonSkin

  private onManageUsersOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.USER_MANAGEMENT_USER_LIST
    })
  }

  private onManageCategoriesOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.CATEGORY_MANAGEMENT_CATEGORY_LIST
    })
  }

  private onManageEmailTemplatesOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.EMAIL_TEMPLATE_MANAGEMENT_EMAIL_TEMPLATE_LIST
    })
  }

  private onManageApplicationFormsOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.ADMIN_APPLICATION_FORM_MANAGEMENT
    })
  }

  private onManageApplicationFormSupportsOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.ADMIN_APPLICATION_FORM_SUPPORT_MANAGEMENT
    })
  }

  private onManageApplicationFormValidationsOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.ADMIN_APPLICATION_FORM_VALIDATION_MANAGEMENT
    })
  }

  private onManageEvaluatorsOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.EVALUATOR_MANAGEMENT_LIST
    })
  }

  private onManageYearConfigurationsOptionClick(): void {
    this.mixin_router_navigate({
      name: ROUTES.YEAR_CONFIGURATION_MANAGEMENT_LIST
    })
  }
}
