<div id="form-template-management__form-template-preview">
  <div class="form-template-management__form-template-preview--informations mb-6">
    <m-panel>
      <ul class="mb-0">
        <li>
          <strong>
            {{ $i18next.t('modules.form-template-management.form-template-preview.FormTemplatePreview.informations.category') }}
          </strong>
          {{ formTemplate.category.title }}
        </li>
        <li class="mt-2">
          <strong>
            {{ $i18next.t('modules.form-template-management.form-template-preview.FormTemplatePreview.informations.year') }}
          </strong>
          {{ formTemplate.category.year.slug }}
        </li>
        <li class="mt-2">
          <strong>
            {{ $i18next.t('modules.form-template-management.form-template-preview.FormTemplatePreview.informations.form_type') }}
          </strong>
          {{ $i18next.t(`modules.form-template-management.FormTemplateModel.types.${formTemplate.type}`) }}
        </li>
      </ul>
    </m-panel>
  </div>
  <div class="mb-6">
    <template v-if="formTemplate.is_updateable">
      <m-button
        class="mr-2"
        :skin="MButtonSkin.Secondary"
        @click="onUpdateButtonClick"
      >
        {{ $i18next.t('modules.form-template-management.form-template-preview.FormTemplatePreview.button.update.label') }}
      </m-button>
    </template>
    <m-button
      class="form-template-management__form-template-preview-button--show-validation-rules"
      :skin="MButtonSkin.Secondary"
      @click="onShowValidationRulesButtonClick"
    >
      {{ $i18next.t('modules.form-template-management.form-template-preview.FormTemplatePreview.button.display_validation_rules.label') }}
    </m-button>
  </div>
  <template v-if="!hasFormElements">
    <m-message :state="MMessageState.Warning">
      {{ $i18next.t('modules.form-template-management.form-template-preview.FormTemplatePreview.message.empty_form.content') }}
    </m-message>
  </template>
  <form-elements
    :ref="FORM_ELEMENTS_REF"
    :elements="formTemplate.elements"
    :display-evaluator-additionnal-informations="true"
  />
</div>