import Component, { mixins } from 'vue-class-component'

import WithRender from './EmailTemplates.html'

import { 
  MTable,
  MTableHeader,
  MOption,
  MOptionItemEdit,
  MColumnTextAlign,
  MColumnTable,
  MColumnSortDirection,
} from '@ulaval/modul-components'

import EmailTemplateService from '../EmailTemplate.service'
import { IEmailTemplateModel } from '../EmailTemplate.model'
import CONSTANTS from '@/CONSTANTS'
import ROUTES from '@/router/ROUTES'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {
    MOption,
    MOptionItemEdit,
    MTable,
    MTableHeader
  }
})
export default class EmailTemplates extends mixins(EmailTemplateService) {
  private emailTemplates: Array<IEmailTemplateModel> = []
  private isLoadingStateActive: boolean = false
  private appliedSortColumn: MColumnTable | null = null

  private get sortedEmailTemplates(): Array<IEmailTemplateModel> {
    if (!this.sortColumn.sortDirection) {
      return this.emailTemplates
    }

    return Array.from(this.emailTemplates).sort((leftHandSide: any, rightHandSide: any) => {
      const leftHandSideValue: string = leftHandSide[this.sortColumn.dataProp] as string
      const rightHandSideValue: string = rightHandSide[this.sortColumn.dataProp] as string

      return leftHandSideValue.localeCompare(rightHandSideValue) * (this.sortColumn.sortDirection || 1)
    })
  }

  private get sortColumn(): MColumnTable {
    if (!this.appliedSortColumn) {
      return {
        id: 'identifier',
        title: '',
        dataProp: 'slug',
        sortDirection: MColumnSortDirection.Asc,
      }
    }

    return this.appliedSortColumn
  }

  private get tableColumns(): Array<MColumnTable> {
    return [
      {
        id: 'identifier',
        title: this.$i18next.t('modules.email-template-management.email-templates.EmailTemplates.table.headers.identifier.title'),
        dataProp: 'slug',
        sortable: true,
        sortDirection: MColumnSortDirection.Asc,
        defaultSortDirection: MColumnSortDirection.Asc
      },
      {
        id: 'last-update',
        title: this.$i18next.t('modules.email-template-management.email-templates.EmailTemplates.table.headers.last_update.title'),
        dataProp: 'last-update'
      },
      {
        id: 'options',
        title: this.$i18next.t('modules.email-template-management.email-templates.EmailTemplates.table.headers.options.title'),
        dataProp: 'options',
        textAlign: MColumnTextAlign.Right,
        width: '50px'
      }
    ]
  }

  private mounted(): void {
    this.loadEmailTemplates()

    this.afterEmailTemplateUpdated()
  }

  private afterEmailTemplateUpdated(): void {
    EventBus.$on(EVENT_BUS_EVENTS.EMAIL_TEMPLATE_UPDATED, (updatedEmailTemplate: IEmailTemplateModel) => {
      if (updatedEmailTemplate) {
        const originalEmailTemplateIndex: number | undefined = this.emailTemplates.findIndex((emailTemplate: IEmailTemplateModel) => emailTemplate.id === updatedEmailTemplate.id)

        if (~originalEmailTemplateIndex) {
          this.$set(this.emailTemplates, originalEmailTemplateIndex, updatedEmailTemplate)
        }
      }
    })
  }

  private loadEmailTemplates(): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    this.service_emailTemplateService_fetchList()
      .then((responseData: Array<IEmailTemplateModel>) => {
        this.emailTemplates = responseData || []
      }).catch(() => {
        this.emailTemplates = []
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private onEmailTemplateUpdateOptionClick(emailTemplate: IEmailTemplateModel): void {
    if (emailTemplate) {
      this.mixin_router_navigate({
        name: ROUTES.EMAIL_TEMPLATE_MANAGEMENT_EMAIL_TEMPLATE_UPDATE,
        params: {
          idEmailTemplate: emailTemplate.id
        }
      })
    }
  }

  private onTableSortApplied(column: MColumnTable): void {
    this.appliedSortColumn = column || null
  }
}
