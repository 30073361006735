import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import BaseService from "../global/utilities/Base.service";
import { EVENT_BUS_EVENTS } from "../global/utilities/Events";
import { IEmailTemplateAttachmentModel, IEmailTemplateModel } from "./EmailTemplate.model";
import { IFileUploadModel } from "../global/file/File.model";
import { IEmailTemplateRecipientFormPayloadData } from "./email-template-form/EmailTemplateForm";

export interface IUpdateEmailTemplateForm {
  object: string;
  content_header: string;
  content_message: string;
  files: Array<IFileUploadModel<IEmailTemplateAttachmentModel>>;
  carbon_copy_recipients: Array<IEmailTemplateRecipientFormPayloadData>;
  blind_carbon_copy_recipients: Array<IEmailTemplateRecipientFormPayloadData>;
}

@Component
export default class EmailTemplateService extends BaseService {
  protected service_emailTemplateService_fetchList(): Promise<Array<IEmailTemplateModel>> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/email-templates`)
        .then((response: AxiosResponse) => resolve(response.data.data.email_templates))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_emailTemplateService_fetch(id: string): Promise<IEmailTemplateModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/email-templates/${id}`)
        .then((response: AxiosResponse) => resolve(response.data.data.email_template))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_emailTemplateService_update(id: string, payload: IUpdateEmailTemplateForm): Promise<IEmailTemplateModel> {
    return new Promise((resolve: any, reject: any): void => {
      const data: FormData = new FormData()

      data.set('object', payload.object)
      data.set('content_header', payload.content_header)
      data.set('content_message', payload.content_message)

      payload.carbon_copy_recipients.forEach((recipient: IEmailTemplateRecipientFormPayloadData, index: number) => {
        if (recipient.id) {
          data.set(`carbon_copy_recipients[${index}].id`, recipient.id)
        }
        data.set(`carbon_copy_recipients[${index}].value`, recipient.value)
      })

      payload.blind_carbon_copy_recipients.forEach((recipient: IEmailTemplateRecipientFormPayloadData, index: number) => {
        if (recipient.id) {
          data.set(`blind_carbon_copy_recipients[${index}].id`, recipient.id)
        }
        data.set(`blind_carbon_copy_recipients[${index}].value`, recipient.value)
      })
      
      payload.files.forEach((uploadedFile: IFileUploadModel<IEmailTemplateAttachmentModel>, index: number) => {
        if (uploadedFile.server_file) {
          data.set(`attachments[${index}].id`, uploadedFile.server_file.id)
        } else if (uploadedFile && uploadedFile.file) {
          data.append(`attachments[${index}].file`, uploadedFile.file.file)
        }
      })

      this.$axios.put(`/api/email-templates/${id}`, data, {
        headers: this.service_baseService_multipartFormDataHeader()
      }).then((response: AxiosResponse) => {
          const updatedEmailTemplate: IEmailTemplateModel = response.data.data.email_template

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.EMAIL_TEMPLATE_UPDATED, updatedEmailTemplate)

          resolve(updatedEmailTemplate)
        }).catch((error: AxiosError) => reject(error))
    })
  }
}