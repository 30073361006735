<header class="navigation-bar">
  <div class="ul-header">
    <div class="ul-header--red" />
    <div class="ul-header--yellow" />
  </div>
  <nav>
    <div>
      <a :href="applicationHomePageLink">
        <img
          alt="Université Laval"
          :src="require(`@/assets/medias/images/ulaval-logo.png`)"
          height="33"
        />
      </a>
    </div>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="isNavigationBarInPublicMode">
        <div class="navigation-bar__login">
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="onLoginButtonClick"
          >
            {{ $i18next.t('modules.global.navigation-bar.NavigationBar.button.login.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <div class="navigation-bar__icons">
          <transition
            name="fade-fast"
            mode="out-in"
          >
            <template v-if="displayAdministratorMenu">
              <administrator-menu />
            </template>
          </transition>
          <transition
            name="fade-fast"
            mode="out-in"
          >
            <template v-if="isLoggedIn">
              <user-profile-menu class="ml-3" />
            </template>
          </transition>
        </div>
      </template>
    </transition>
  </nav>
</header>