<div class="form-template-update__available-elements">
  <template v-for="sectionKey in Object.keys(mappedFormTemplateElementsBySection)">
    <div class="form-template-update__available-elements-section mb-4">
      <label>
        {{ $i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateAvailableElements.section_key.${sectionKey}.label`) }}
      </label>
      <hr />
      <div class="form-template-update__available-elements-section--chips">
        <template v-for="element in sortedFormTemplateElements(mappedFormTemplateElementsBySection[sectionKey])">
          <div
            class="form-template-update__available-elements-section--chip"
            draggable="true"
            @dragstart="onDragStart(element, $event)"
            @dragend="onDragEnd"
          >
            {{ $i18next.t(`modules.form-template-management.FormTemplateModel.element_types.${element.element_type}`) }}
          </div>
        </template>
      </div>
    </div>
  </template>
</div>