import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './FormTemplateUpdateDropZone.html'

import FormTemplateUpdateInsertZone from './FormTemplateUpdateInsertZone'
import FormTemplateUpdateDropZoneElement from './FormTemplateUpdateDropZoneElement'

import { IFormTemplateAvailableElementModel, IInternalFormTemplateElementModel, IInternalFormTemplateModel } from '../FormTemplate.model'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'

@WithRender
@Component({
  components: {
    FormTemplateUpdateDropZoneElement,
    FormTemplateUpdateInsertZone
  }
})
export default class FormTemplateUpdateDropZone extends mixins() {
  private insertDraggedElement: IFormTemplateAvailableElementModel | null = null
  private moveDraggedElement: IInternalFormTemplateElementModel | null = null
  private isInsertDraggedElementOver: boolean = false

  @Prop({ required: true })
  private formTemplate!: IInternalFormTemplateModel

  @Prop({ required: true })
  private requestErrors!: { [key: string]: string }

  private get sortedElements(): Array<IInternalFormTemplateElementModel> {
    return this.formTemplate.elements.sort((leftHandSide: IInternalFormTemplateElementModel, rightHandSide: IInternalFormTemplateElementModel) => {
      return leftHandSide.element_position_index - rightHandSide.element_position_index
    })
  }

  private created(): void {
    this.onElementInsertDragStart()
    this.onElementInsertDragEnd()
    this.onElementMoveDragStart()
    this.onElementMoveDragEnd()
  }

  private onElementInsertDragStart(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_STARTED, (element: IFormTemplateAvailableElementModel) => {
      if (element.allow_as_root_element) {
        this.insertDraggedElement = element || null
      }
    })
  }

  private onElementInsertDragEnd(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_INSERT_DRAG_ENDED, () => {
      this.insertDraggedElement = null
    })
  }

  private onElementMoveDragStart(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_STARTED, (element: IInternalFormTemplateElementModel) => {
      if (element.parent_internal_identifier == null) {
        this.moveDraggedElement = element || null
      }
    })
  }

  private onElementMoveDragEnd(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_TEMPLATE_ELEMENT_MOVE_DRAG_ENDED, () => {
      this.moveDraggedElement = null
    })
  }

  private isDraggedElementMovable(index: number): boolean {
    if (!this.moveDraggedElement) {
      return false
    }

    if (this.moveDraggedElement.element_position_index === index) {
      return false
    }

    return this.moveDraggedElement.element_position_index !== index &&
      this.moveDraggedElement.element_position_index !== --index
  }
}