<div
  :ref="WRAPPER_REF"
  class="form-template-update__drop-zone-element"
  :class="wrapperClasses"
  :style="wrapperStyles"
  draggable="true"
  @click.stop="onElementClick"
  @mouseover.stop="onMouseOver"
  @mouseleave.stop="onMouseLeave"
  @mouseout.stop="onMouseOut"
  @dragstart.stop="triggerElementMoveDragStart($event)"
  @dragend.stop="triggerElementMoveDragEnd"
  @dragover="onDragOver($event)"
  @dragleave="onDragLeave($event)"
>
  <div :class="labelClasses">
    {{ $i18next.t(`modules.form-template-management.FormTemplateModel.element_types.${formTemplateElement.element_type}`) }}
    <template v-if="labelParameter">
      <span v-html="$i18next.t(`modules.form-template-management.form-template-update.FormTemplateUpdateDropZoneElement.label.element_parameter_label`, { labelParameter })" />
    </template>
  </div>
  <div
    class="form-template-update__drop-zone-element--childrens"
    :class="childrensWrapperClasses"
  >
    <template v-for="children in sortedChildrens">
      <transition
        name="fade-fast"
        mode="out-in"
      >
        <template v-if="isDraggedElementInsertable && isInsertDraggedElementOver">
          <form-template-update-insert-zone
            :parent-internal-identifier="formTemplateElement.internal_identifier"
            :insertionElementPositionIndex="children.element_position_index"
            :dense="true"
          />
        </template>
        <template v-else-if="isDraggedElementMovable(children.element_position_index)">
          <form-template-update-insert-zone
            :parent-internal-identifier="formTemplateElement.internal_identifier"
            :insertionElementPositionIndex="children.element_position_index > 0 ? children.element_position_index - 1 : children.element_position_index"
            :dense="true"
          />
        </template>
      </transition>
      <form-template-update-drop-zone-element
        :key="JSON.stringify(children)"
        :form-template-element="children"
        :request-errors="requestErrors"
      />
    </template>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="isDraggedElementMovable(formTemplateElement.childrens.length)">
        <form-template-update-insert-zone
          :parent-internal-identifier="formTemplateElement.internal_identifier"
          :insertionElementPositionIndex="formTemplateElement.childrens.length"
          :dense="true"
        />
      </template>
    </transition>
  </div>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="isInsertDraggedElementOver && isDraggedElementInsertable">
      <form-template-update-insert-zone
        :parent-internal-identifier="formTemplateElement.internal_identifier"
        :insertionElementPositionIndex="formTemplateElement.childrens.length"
      />
    </template>
  </transition>
</div>