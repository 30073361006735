<section class="year-configuration-management main-content">
  <breadcrumbs />
  <m-panel class="main-content__panel">
    <h3 slot="header">
      {{ $i18next.t('modules.year-configuration-management.YearConfigurationManagement.panel_title') }}
    </h3>
    <template #header-right-content>
      <year-dropdown v-model="year" />
    </template>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="year">
        <admin-year-configuration :year="year" />
      </template>
    </transition>
  </m-panel>
</section>