<component
  :is="internalElement.component_name"
  v-bind="assembledElementProps"
  :value="elementValue"
  class="form-element"
  :class="elementClasses"
  :style="elementStyles"
  style="max-width: none; padding-left: 3px; padding-right: 3px;"
  :internal-element="internalElement"
  :answers="answers"
  :row-position-index="rowPositionIndex"
  :readonly="readonly"
  :error="!mixin_string_isEmpty(errorMessage)"
  :error-message="errorMessage"
  :display-duplicate-answers="displayDuplicateAnswers"
  :hide-sensitive-informations="hideSensitiveInformations"
  :id="internalElement.id"
  :display-evaluator-additionnal-informations="displayEvaluatorAdditionnalInformations"
  @change="onValueChange($event)"
>
  <template v-for="slot in elementSlots">
    <span :slot="slot.frontend_key || slot.key">
      <span v-html="slot.value" />
    </span>
  </template>
  <template v-for="children, index in internalElement.childrens">
    <form-element
      :internal-element="children"
      :answers="answers"
      :row-position-index="rowPositionIndex"
      :readonly="readonly"
      :display-duplicate-answers="displayDuplicateAnswers"
      :hide-sensitive-informations="hideSensitiveInformations"
      :display-evaluator-additionnal-informations="displayEvaluatorAdditionnalInformations"
    />
  </template>
  <template v-if="componentHasOptions">
    <template v-for="option in sortedElementOptions">
      <div>
        <component
          :is="internalElement.option_component_name"
          :value="option.value"
        >
          {{ option.value }}
        </component>
      </div>
    </template>
  </template>
  <template v-if="elementContent">
    <span v-html="elementContent" />
  </template>
</component>