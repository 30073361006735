import Component, { mixins } from 'vue-class-component'

import WithRender from './AdminApplicationFormValidationManagement.html'

import { 
  MPanel
} from '@ulaval/modul-components'

import AdminApplicationFormValidations from './application-form-validations/AdminApplicationFormValidations'
import YearDropdown from '../global/year/YearDropdown'
import Breadcrumbs from '../global/breadcrumbs/Breadcrumbs'

import { IYearModel } from '../global/year/Year.model'

@WithRender
@Component({
  components: {
    AdminApplicationFormValidations,
    Breadcrumbs,
    MPanel,
    YearDropdown
  }
})
export default class AdminApplicationFormValidationManagement extends mixins() {
  private year: IYearModel | null = null
}
