import { IBaseModel } from "@/modules/global/utilities/Base.model";

export enum EvaluatorApplicationFormAssignmentStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  OVERDUE = 'OVERDUE'
}

export interface IEvaluatorApplicationFormAssignmentModel extends IBaseModel {
  status: EvaluatorApplicationFormAssignmentStatus;
  candidate_full_name: string;
  candidate_full_name_with_username: string;
  candidate_full_name_with_email: string;
}