import Component, { mixins } from 'vue-class-component'

import WithRender from './StepWizardStep.html'

import { 
  MSteppersItem, 
  MSteppersItemState 
} from '@ulaval/modul-components'

import FormElementMixin from '@/modules/form-management/form-element/FormElement.mixin'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'

const EVENT_DISPATCH_THRESHOLD: number = 3

@WithRender
@Component({
  components: {
    MSteppersItem
  }
})
export default class StepWizardStep extends mixins(FormElementMixin) {
  private stepWizardStepState: MSteppersItemState = MSteppersItemState.Visited

  private readonly MSteppersItemState: object = MSteppersItemState

  private created(): void {
    this.onStepWizardStepSelected()

    if (this.internalElement.element_position_index === 0) {
      this.onItemClick()
    }
  }

  private onItemClick(): void {
    EventBus.$emit(EVENT_BUS_EVENTS.FORM_ELEMENT_STEP_WIZARD_STEP_SELECTED, this.internalElement.id)
  }

  private onStepWizardStepSelected(): void {
    EventBus.$on(EVENT_BUS_EVENTS.FORM_ELEMENT_STEP_WIZARD_STEP_SELECTED, (elementId: string) => {
      if (this.internalElement.id === elementId) {
        this.stepWizardStepState = MSteppersItemState.InProgress
      } else {
        this.stepWizardStepState = MSteppersItemState.Visited
      }

      this.resizeWindow()
    })
  }

  private resizeWindow(): void {
    let eventDispatchCount: number = 0
    const resizeInterval: NodeJS.Timeout = setInterval(() => {
      window.dispatchEvent(new Event('resize'))
      eventDispatchCount++

      if (eventDispatchCount >= EVENT_DISPATCH_THRESHOLD) {
        clearInterval(resizeInterval)
      }
    }, 500)
  }
}
