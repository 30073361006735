import { IFormTemplateAvailableElementModel, IInternalFormTemplateElementModel, IInternalFormTemplateElementOptionModel } from "@/modules/form-template-management/FormTemplate.model"
import Vue from "vue"

export enum EVENT_BUS_EVENTS {
  USER_CREATED = 'user:created',
  USER_UPDATED = 'user:updated',
  CATEGORY_CREATED = 'category:created',
  CATEGORY_UPDATED = 'category:updated',
  CATEGORY_DELETED = 'category:deleted',
  EMAIL_TEMPLATE_UPDATED = 'email-template:updated',
  FORM_TEMPLATE_UPDATED = 'form-template:updated',
  FORM_TEMPLATE_ELEMENT_SELECTED = 'form-template:element-selected',
  FORM_TEMPLATE_ELEMENT_INSERT_DRAG_STARTED = 'form-template:element-insert-drag-started',
  FORM_TEMPLATE_ELEMENT_INSERT_DRAG_ENDED = 'form-template:element-insert-drag-ended',
  FORM_TEMPLATE_ELEMENT_INSERT_DRAG_DROPPED = 'form-template:element-insert-drag-dropped',
  FORM_TEMPLATE_ELEMENT_MOVE_DRAG_STARTED = 'form-template:element-move-drag-started',
  FORM_TEMPLATE_ELEMENT_MOVE_DRAG_ENDED = 'form-template:element-move-drag-ended',
  FORM_TEMPLATE_ELEMENT_MOVE_DRAG_DROPPED = 'form-template:element-move-drag-dropped',
  FORM_TEMPLATE_ELEMENT_DELETED = 'form-template:element-deleted',
  FORM_TEMPLATE_ELEMENT_UPDATED = 'form-template:element-updated',
  FORM_ELEMENT_STEP_WIZARD_STEP_SELECTED = 'form-element-step-wizard-step:selected',
  APPLICATION_FORM_CREATED = 'application-form:created',
  APPLICATION_FORM_DELETED = 'application-form:deleted',
  APPLICATION_FORM_UPDATED = 'application-form:updated',
  APPLICATION_FORM_REMINDER_EMAIL_SENT = 'application-form:reminder-email-sent',
  APPLICATION_FORM_SUPPORT_REMINDER_EMAIL_SENT = 'application-form-support:reminder-email-sent',
  APPLICATION_FORM_APPLICATION_FORM_SUPPORT_SUBMITTED = 'application-form:application-form-support-submitted',
  APPLICATION_FORM_SUPPORT_SUBMIT_REMINDER_EMAIL_SENT = 'application-form:support-submit-reminder-email-sent',
  EVALUATOR_CATEGORY_ASSIGNMENT_CREATED = 'evaluator-category-assignment:created',
  EVALUATOR_CATEGORY_ASSIGNMENT_UPDATED = 'evaluator-category-assignment:updated',
  EVALUATOR_APPLICATION_FORM_ASSIGNMENT_UPDATED = 'evaluator-application-form-assignment:updated',
  YEAR_CONFIGURATION_UPDATED = 'year-configuration:updated'
}

export const DRAG_AND_DROP_EVENTS_IDENTIFIER_KEY: string = 'form-template-element/key'

export enum DRAG_AND_DROP_EVENTS_IDENTIFIER {
  FORM_TEMPLATE_ELEMENT_INSERT = 'form-template-element/insert',
  FORM_TEMPLATE_ELEMENT_MOVE = 'form-template-element/move',
}

export enum GLOBAL_EVENTS {
  MODEL_UPDATED = 'input',
  MODEL_CHANGE = 'change'
}

export interface IFormTemplateElementInsertDragDroppedEventPayload {
  element_type: IFormTemplateAvailableElementModel;
  parentInternalIdentifier?: string;
  insertionElementPositionIndex: number;
}

export interface IFormTemplateElementMoveDragDroppedEventPayload {
  originalElement: IInternalFormTemplateElementModel;
  parentInternalIdentifier?: string;
  insertionElementPositionIndex: number;
}

export interface IFormTemplateElementUpdatedEventPayload {
  elementInternalIdentifier: string;
  elementParametersValue: { [key: string]: any };
  elementOptions: Array<IInternalFormTemplateElementOptionModel>;
}

export const EventBus: Vue = new Vue()