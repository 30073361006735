<section
  id="admin-application-form-support"
  class="main-content"
>
  <breadcrumbs />
  <m-panel class="main-content__panel">
    <template v-if="applicationFormSupport">
      <h3 slot="header">
        {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.header.title') }}
      </h3>
    </template>
    <template v-if="isLoadingStateActive">
      <div
        key="spinner"
        class="my-15"
      >
        <m-spinner
          :title="true"
          :description="true"
        />
      </div>
    </template>
    <template v-if="!isLoadingStateActive && applicationFormSupport">
      <div class="admin-application-form-support--informations mb-6">
        <m-panel>
          <ul class="mb-0">
            <li>
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.informations.category') }}
              </strong>
              {{ applicationFormSupport.category_title }}
            </li>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.informations.year') }}
              </strong>
              {{ applicationFormSupport.year_slug }}
            </li>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.informations.candidate') }}
              </strong>
              {{ applicationFormSupport.candidate_full_name_with_username }}
            </li>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.informations.supporter') }}
              </strong>
              {{ applicationFormSupport.supporter_name_with_email }}
            </li>
            <li class="mt-2">
              <div class="display--flex">
                <strong class="mx-0 my-auto">
                  {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.informations.status') }}
                </strong>
                <template v-if="mixin_applicationFormSupport_computeStatusIcon(applicationFormSupport)">
                  <m-icon
                    class="mx-2 my-auto"
                    size="22px"
                    :style="mixin_applicationFormSupport_computeStatusIconStyles(applicationFormSupport)"
                    :name="mixin_applicationFormSupport_computeStatusIcon(applicationFormSupport)"
                  />
                </template>
                <div class="mx-0 my-auto">
                  {{ mixin_applicationFormSupport_computeStatusLabel(applicationFormSupport.status) }}
                </div>
              </div>
            </li>
          </ul>
        </m-panel>
      </div>
    </template>
    <div v-show="!isLoadingStateActive">
      <template v-if="internalFormTemplate && form && applicationFormSupport">
        <form-elements
          :ref="FORM_ELEMENTS_REF"
          :key="internalFormTemplate.internal_identifier"
          :elements="internalFormTemplate.elements"
          :answers="form.form_answers"
          :is-form-readonly="false"
          :display-evaluator-additionnal-informations="true"
        />
      </template>
    </div>
    <template
      #footer
      v-show="applicationFormSupport && !isLoadingStateActive"
    >
      <m-dialog
        :state="submitDialogState"
        :width="MDialogWidth.Large"
        :open="isSubmitConfirmationDialogActive"
        :message="$i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.dialog.submit_confirmation.label')"
        :okLabel="$i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.dialog.submit_confirmation.submit_button.label')"
        @ok="onSubmitButtonClick"
        @portal-after-close="hideSubmitConfirmationDialog"
      >
        <template
          #trigger
          v-if="!isLoadingStateActive"
        >
          <m-button @click="displaySubmitConfirmationDialog">
            {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.button.submit.label') }}
          </m-button>
        </template>
      </m-dialog>
    </template>
  </m-panel>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isSubmitSuccessToastOpen"
  >
    {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.toast.submit_success.label') }}
  </m-toast>
  <m-toast
    :state="MToastState.Error"
    :position="MToastPosition.TopRight"
    :open="isSubmitErrorToastOpen"
  >
    {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.toast.submit_error.label') }}
  </m-toast>
  <m-toast
    :state="MToastState.Warning"
    :position="MToastPosition.TopCenter"
    :open="isValidationErrorToastOpen"
  >
    <p class="m-0">
      <strong>
        {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.toast.validation_error.label') }}
      </strong>
      <br />
      {{ $i18next.t('modules.application-form-support-management.application-form-support.AdminApplicationFormSupport.toast.validation_error.help') }}
    </p>
  </m-toast>
</section>