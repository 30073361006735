import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { IFormTemplateAvailableElementModel } from './FormTemplate.model'

export enum STORE_ACTIONS {
  FORM_TEMPLATE_AVAILABLE_ELEMENT_STORE_STORE_FORM_TEMPLATE_AVAILABLE_ELEMENTS = 'formTemplateAvailableElement/storeFormTemplateAvailableElements',
}

export enum STORE_GETTERS {
  FORM_TEMPLATE_AVAILABLE_ELEMENT_GET_FORM_TEMPLATE_AVAILABLE_ELEMENTS = 'formTemplateAvailableElement/formTemplateAvailableElements',
  FORM_TEMPLATE_AVAILABLE_ELEMENT_GET_FORM_TEMPLATE_AVAILABLE_ELEMENTS_BY_TYPE = 'formTemplateAvailableElement/formTemplateAvailableElementsByType'
}

@Module({ namespaced: true })
export default class FormTemplateAvailableElementStore extends VuexModule {
  private _formTemplateAvailableElements: Array<IFormTemplateAvailableElementModel> = []

  @Mutation
  private storeFormTemplateAvailableElementsMutation(formTemplateAvailableElements: Array<IFormTemplateAvailableElementModel>): void {
    this._formTemplateAvailableElements = formTemplateAvailableElements
  }

  @Action
  public storeFormTemplateAvailableElements(formTemplateAvailableElements: Array<IFormTemplateAvailableElementModel>): void {
    this.context.commit('storeFormTemplateAvailableElementsMutation', formTemplateAvailableElements)
  }

  private get formTemplateAvailableElements(): Array<IFormTemplateAvailableElementModel> {
    return this._formTemplateAvailableElements
  }

  private get formTemplateAvailableElementsByType(): { [key: string]: IFormTemplateAvailableElementModel } {
    const mappedFormTemplateAvailableElements: { [key: string]: IFormTemplateAvailableElementModel } = {}

    this._formTemplateAvailableElements.forEach((formTemplateAvailableElement: IFormTemplateAvailableElementModel) => {
      mappedFormTemplateAvailableElements[formTemplateAvailableElement.element_type] = formTemplateAvailableElement
    })

    return mappedFormTemplateAvailableElements
  }
}
