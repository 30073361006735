<section class="form-template-management main-content">
  <breadcrumbs />
  <m-panel class="main-content__panel">
    <h3 slot="header">
      {{ $i18next.t(`modules.form-template-management.FormTemplateManagement.panel_title.routes.${mixin_router_extractLocalizedRouteName($route.name)}`) }}
    </h3>
    <transition name="fade-out-in">
      <template v-if="isLoadingOverlayActive">
        <div class="my-15">
          <m-spinner
            :title="true"
            :description="true"
          />
        </div>
      </template>
      <template v-else-if="formTemplate">
        <div>
          <template v-if="!formTemplate.is_updateable">
            <m-message
              class="mb-10"
              :state="MMessageState.Warning"
            >
              {{ $i18next.t(`modules.form-template-management.FormTemplateManagement.error.update_not_available.label`) }}
            </m-message>
          </template>
          <router-view :form-template="formTemplate" />
        </div>
      </template>
    </transition>
  </m-panel>
</section>