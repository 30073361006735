<div class="rich-text-editor__wrapper">
  <template v-if="label">
    <div class="rich-text-editor__label">
      <label class="m-u--font-weight--bold">
        {{ label }}
      </label>
    </div>
  </template>
  <editor
    v-model="content"
    api-key="66376mgwwuvo3pasccrw65q4mntlgayhqzeorvdesov7qhdx"
    :init="{
         height: 500,
         menubar: false,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
    :class="richTextEditorClasses"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
  />
  <template v-if="hasError">
    <div class="rich-text-editor__error">
      <p>
        <m-icon
          class="rich-text-editor__error--icon"
          name="m-svg__error"
          size="14px"
        />
        <span class="rich-text-editor__error--message">
          {{ $i18next.t('modules.global.components.rich-text-editor.RichTextEditor.label.validation_error') }}
        </span>
      </p>
    </div>
  </template>
</div>