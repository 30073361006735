<section
  id="application-form-support"
  class="main-content"
>
  <m-panel class="main-content__panel">
    <template v-if="applicationFormSupport">
      <h3 slot="header">
        {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.header.title') }}
      </h3>
    </template>
    <template v-if="isLoadingStateActive">
      <div
        key="spinner"
        class="my-15"
      >
        <m-spinner
          :title="true"
          :description="true"
        />
      </div>
    </template>
    <template v-if="!isLoadingStateActive && applicationFormSupport">
      <div class="application-form-support--informations mb-6">
        <m-panel>
          <ul class="mb-0">
            <li>
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.informations.category') }}
              </strong>
              {{ applicationFormSupport.category_title }}
            </li>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.informations.year') }}
              </strong>
              {{ applicationFormSupport.year_slug }}
            </li>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.informations.candidate') }}
              </strong>
              {{ applicationFormSupport.candidate_full_name }}
            </li>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.informations.supporter') }}
              </strong>
              {{ applicationFormSupport.supporter_name_with_email }}
            </li>
          </ul>
        </m-panel>
      </div>
    </template>
    <template v-if="!isLoadingStateActive && applicationFormSupport && !applicationFormSupport.is_updateable">
      <m-message
        class="mb-10"
        :state="MMessageState.Information"
      >
        {{ $i18next.t(`modules.application-form-support-management.application-form-support.ApplicationFormSupport.message.update_not_available.label`) }}
      </m-message>
    </template>
    <div v-show="!isLoadingStateActive">
      <template v-if="internalFormTemplate && form && applicationFormSupport">
        <form-elements
          :ref="FORM_ELEMENTS_REF"
          :key="internalFormTemplate.internal_identifier"
          :elements="internalFormTemplate.elements"
          :answers="form.form_answers"
          :is-form-readonly="!applicationFormSupport.is_updateable"
        />
      </template>
    </div>
    <template
      #footer
      v-if="applicationFormSupport && applicationFormSupport.is_updateable && !isLoadingStateActive"
    >
      <m-dialog
        :state="submitDialogState"
        :width="MDialogWidth.Large"
        :open="isSubmitConfirmationDialogActive"
        :message="$i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.dialog.submit_confirmation.label')"
        :okLabel="$i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.dialog.submit_confirmation.submit_button.label')"
        @ok="onSubmitButtonClick"
        @portal-after-close="hideSubmitConfirmationDialog"
      >
        <template #trigger>
          <m-button @click="displaySubmitConfirmationDialog">
            {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.button.submit.label') }}
          </m-button>
        </template>
      </m-dialog>
    </template>
  </m-panel>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isSubmitSuccessToastOpen"
  >
    {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.toast.submit_success.label') }}
  </m-toast>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isSubmitErrorToastOpen"
  >
    {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.toast.submit_error.label') }}
  </m-toast>
  <m-toast
    :state="MToastState.Warning"
    :position="MToastPosition.TopCenter"
    :open="isValidationErrorToastOpen"
  >
    <p class="m-0">
      <strong>
        {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.toast.validation_error.label') }}
      </strong>
      <br />
      {{ $i18next.t('modules.application-form-support-management.application-form-support.ApplicationFormSupport.toast.validation_error.help') }}
    </p>
  </m-toast>
</section>