import Component, { mixins } from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import WithRender from './SubmitApplicationFormSupportOverlay.html'

import { 
  MAdd,
  MButton,
  MButtonSkin,
  MDialog,
  MDialogState,
  MDialogWidth,
  MIcon,
  MIconButton,
  MMessage,
  MMessageSkin,
  MMessageState,
  MOverlay,
} from '@ulaval/modul-components'

import ApplicationFormSupportForm, { IApplicationFormSupportFormPayloadData } from '@/modules/application-form-support-management/application-form-support-form/ApplicationFormSupportForm'

import { IApplicationFormModel } from '../ApplicationForm.model'
import ApplicationFormService, { IApplicationFormSubmitApplicationFormSupportForm } from '../ApplicationForm.service'
import { IApplicationFormSupportModel } from '@/modules/application-form-support-management/ApplicationFormSupport.model'
import ROUTES from '@/router/ROUTES'
import ApplicationFormSupportMixin from '@/modules/application-form-support-management/ApplicationFormSupport.mixin'

const APPLICATION_FORM_SUPPORT_FORM_REF: string = 'application-form-support-form'

interface ISubmitApplicationFormSupportOverlayPayloadData {
  application_form_supports: Array<IApplicationFormSupportFormPayloadData>;
}

export enum SubmitApplicationFormSupportOverlay_EVENTS {
  AFTER_CLOSE = 'after-close'
}

@WithRender
@Component({
  components: {
    ApplicationFormSupportForm,
    MAdd,
    MButton,
    MDialog,
    MIcon,
    MIconButton,
    MMessage,
    MOverlay
  }
})
export default class SubmitApplicationFormSupportOverlay extends mixins(ApplicationFormService, ApplicationFormSupportMixin) {
  private applicationFormSupportForms: Array<number> = []
  private isOverlayOpen: boolean = true
  private isLoadingStateActive: boolean = false
  private showSubmitSuccessMessage: boolean = false
  private showSubmitErrorMessage: boolean = false
  private isDeleteLoadingStateActive: boolean = false
  private applicationFormSupportToDelete: IApplicationFormSupportModel | null = null
  private showDeleteSuccessMessage: boolean = false
  private showDeleteErrorMessage: boolean = false

  private readonly APPLICATION_FORM_SUPPORT_FORM_REF: string = APPLICATION_FORM_SUPPORT_FORM_REF
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly MButtonSkin: object = MButtonSkin
  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth
  
  @Prop({ required: true })
  private applicationForm!: IApplicationFormModel

  @Ref(APPLICATION_FORM_SUPPORT_FORM_REF)
  private applicationFormSupportFormComponents!: Array<ApplicationFormSupportForm>

  private get applicationFormSupportLeftToDepositCount(): number {
    if (!this.applicationForm.category_application_support_count_upper_bound) {
      return 0
    }

    return this.applicationForm.category_application_support_count_upper_bound - this.applicationForm.application_form_supports.length
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private afterOverlayClose(): void {
    this.$emit(SubmitApplicationFormSupportOverlay_EVENTS.AFTER_CLOSE)
  }

  private increaseApplicationFormSupportFormCount(key: number): void {
    this.$set(this.applicationFormSupportForms, this.applicationFormSupportForms.length, key + 1)
  }

  private decreaseApplicationFormSupportFormCount(index: number): void {
    this.$delete(this.applicationFormSupportForms, index)
  }

  private async onSubmitButtonClick(): Promise<void> {
    const formPayload: ISubmitApplicationFormSupportOverlayPayloadData | null = await this.getFormPayload()

    if (formPayload) {
      this.submitApplicationFormSupports(formPayload)
    }
  }

  public async getFormPayload(): Promise<ISubmitApplicationFormSupportOverlayPayloadData | null> {
    const applicationFormSupports: Array<IApplicationFormSupportFormPayloadData> = []

    if (this.applicationFormSupportFormComponents) {
      for (const applicationFormSupportForm of this.applicationFormSupportFormComponents) {
        const applicationFormSupportFormPayload: IApplicationFormSupportFormPayloadData | null = await applicationFormSupportForm.getFormPayload()

        if (applicationFormSupportFormPayload) {
          applicationFormSupports.push(applicationFormSupportFormPayload)
        } else {
          return null
        }
      }
    }

    return {
      application_form_supports: applicationFormSupports
    }
  }

  private submitApplicationFormSupports(formPayload: ISubmitApplicationFormSupportOverlayPayloadData): void {
    this.isLoadingStateActive = true

    const requestPayload: IApplicationFormSubmitApplicationFormSupportForm = {
      ...formPayload,
      application_form_id: this.applicationForm.id
    }

    this.service_applicationFormService_submitApplicationFormSupport(requestPayload)
      .then(() => {
        this.applicationFormSupportForms = []
        this.showSubmitSuccessMessage = true
        this.showSubmitErrorMessage = false
      }).catch(() => {
        this.showSubmitSuccessMessage = false
        this.showSubmitErrorMessage = true
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private onDisplayDeleteConfirmationIconClick(applicationFormSupport: IApplicationFormSupportModel): void {
    this.applicationFormSupportToDelete = applicationFormSupport || null
  }

  private hideDeleteConfirmationDialog(): void {
    this.applicationFormSupportToDelete = null
  }

  private deleteApplicationFormSupport(): void {
    if (this.applicationFormSupportToDelete) {
      this.isDeleteLoadingStateActive = true

      this.service_applicationFormService_deleteApplicationFormSupport(this.applicationForm.id, this.applicationFormSupportToDelete.id)
        .then(() => {
          this.showDeleteSuccessMessage = true
          this.showDeleteErrorMessage = false

          const applicationFormSupportIndex: number | undefined = this.applicationForm.application_form_supports.findIndex((el: IApplicationFormSupportModel) => el.id === this.applicationFormSupportToDelete?.id)

          if (~applicationFormSupportIndex) {
            this.$delete(this.applicationForm.application_form_supports, applicationFormSupportIndex)
          }
        }).catch(() => {
          this.showDeleteSuccessMessage = false
          this.showDeleteErrorMessage = true
        }).finally(() => {
          this.isDeleteLoadingStateActive = false
          setTimeout(() => {
            this.showDeleteSuccessMessage = false
            this.showDeleteErrorMessage = false
          }, 5000)
        })
    } else {
      this.mixin_router_navigate({
        name: ROUTES.ERROR
      })
    }
  }
}
