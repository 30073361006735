<m-overlay
  id="evalulator-toolbox-file-management_evaluator-toolbox-file-deposit-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @save="onSubmitButtonClick"
  @portal-after-close="afterOverlayClose"
>
  <template #header>
    <h2>
      {{ $i18next.t('modules.evaluator-toolbox-file-management.evaluator-toolbox-file-deposit.EvaluatorToolboxFileDepositOverlay.overlay_title') }}
    </h2>
    <template v-if="year">
      <h6 class="mt-3">
        {{ year.slug }}
      </h6>
    </template>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="isFilesFetchLoadingStateActive">
      <div class="evalulator-toolbox-file-management_evaluator-toolbox-file-deposit-overlay--loading-overlay">
        <m-progress
          class="mx-auto"
          :indeterminate="true"
          :circle="true"
        />
        <p>
          <strong>
            {{ $i18next.t('modules.evaluator-toolbox-file-management.evaluator-toolbox-file-deposit.EvaluatorToolboxFileDepositOverlay.loading_overlay_message') }}
          </strong>
        </p>
      </div>
    </template>
    <template v-else-if="showSubmitSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.evaluator-toolbox-file-management.evaluator-toolbox-file-deposit.EvaluatorToolboxFileDepositOverlay.message.submit_success.title')"
      />
    </template>
    <template v-else-if="showSubmitErrorMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :state="MMessageState.Error"
        :title="$i18next.t('modules.evaluator-toolbox-file-management.evaluator-toolbox-file-deposit.EvaluatorToolboxFileDepositOverlay.message.submit_error.title')"
      />
    </template>
    <template v-else>
      <m-form :form-group="formGroup">
        <m-file-upload
          :allowedExtensions="allowedExtensions"
          @files-ready="onFilesReady"
          @done="onFilesUploadCompleted"
        >
          <m-button>
            {{ $i18next.t('modules.evaluator-toolbox-file-management.evaluator-toolbox-file-deposit.EvaluatorToolboxFileDepositOverlay.button.file_upload_trigger.label') }}
          </m-button>
          <m-validation-message
            class="mt-2"
            :error="!mixin_string_isEmpty(errorMessage)"
            :error-message="errorMessage"
          />
        </m-file-upload>
        <div class="my-6 pb-8">
          <template v-for="file, index in evaluatorToolboxFiles">
            <uploaded-file-row
              :uploaded-file="file"
              :display-divider="index < evaluatorToolboxFiles.length - 1"
              :disabled="readonly"
              @delete="removeAttachment(index)"
            />
          </template>
        </div>
      </m-form>
    </template>
  </transition>
  <template #footer>
    <template v-if="showSubmitSuccessMessage || showSubmitErrorMessage">
      <m-button
        :skin="MButtonSkin.Secondary"
        @click="closeOverlay"
      >
        {{ $i18next.t('modules.evaluator-toolbox-file-management.evaluator-toolbox-file-deposit.EvaluatorToolboxFileDepositOverlay.button.close.label') }}
      </m-button>
    </template>
  </template>
</m-overlay>