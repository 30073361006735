<main class="default-layout">
  <navigation-bar />
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="displayPublicZone">
      <public-area />
    </template>
    <template v-else>
      <router-view />
    </template>
  </transition>
</main>